import { Component, Inject, Optional } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material";

@Component({
selector: 'app-meter-pop-up-component',
templateUrl: 'meterpopup.component.html',
styleUrls: ['./meterpopup.component.scss']
})

export class MeterPopUpComponent{
    public meterArray: string[];
    constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogue: MatDialog){
        console.log(data);
        this.meterArray = data;
    }
}