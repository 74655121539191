import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ContextBase } from '../../shared/_helpers/_contextBase.helper';
import { Router, ActivatedRoute } from '@angular/router';
import { ContactPopUp } from '../../shared/_helpers/_contactpopup.helper';

@Component({
    selector: 'home-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss', '../../../assets/css/bootstrap-grid.min.css']
})

export class HomeFooterComponent implements OnInit, OnChanges {
    public lang: string;
    public curr: string;
    public notAgentRegistration: boolean = true;
    public year: any;
    // public buyer: Buyer;
    @Input() buyer: any;

    constructor(private contexto: ContextBase, private router: Router, public _contactPopUp: ContactPopUp, private activatedRoute: ActivatedRoute) {

        this.initContext();
        let y = new Date();
        this.year = y.getFullYear();
    }
    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges){
        this.buyer = changes.buyer.currentValue;
    }

    initContext() {
        setTimeout(() => {
            this.lang = this.contexto.language;
            this.curr = this.contexto.currency;
        }, 200)

    }

    isActive(path) {
        return this.router.url.indexOf(path) == -1;
    }
} 