import { Component } from "@angular/core";
import { PrivacyPolicyService } from "../../shared/_services/privacy-policy.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    templateUrl: 'privacy-policy.component.html',
    styleUrls: ['../legalconditions/legalconditions.component.scss'],
})

export class PrivacyPolicyComponent{
    public PrivacyPolicyContent: any;
    constructor(private _privacyPolicyService: PrivacyPolicyService, public sanitizer: DomSanitizer){
        this._privacyPolicyService.get().then((data) => {
            this.PrivacyPolicyContent = sanitizer.bypassSecurityTrustHtml(data);
        });
    }

}