import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { MgmComponent } from './mgm.component';


@NgModule({
	imports: [
        SharedModule
	],
	declarations: [
        MgmComponent
	],
	providers: [
       
	],
	entryComponents: [
        MgmComponent 
	],
	exports: [

	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})

export class MGMModule { }