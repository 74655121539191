import { Injectable } from "@angular/core";
import { ContextBase } from "./_contextBase.helper";
import { Conditions } from "../_objects/conditions";
import { TransactionServiceV1 } from "../../core/services/transactionv1.service";
// import { TransactionService } from "../transaction.service";
import { CurrencyExchange } from "../_objects/currexchange";
import { Observable } from "rxjs";

@Injectable()
export class ValidityOrderHelper {
    orderConditions: Conditions;
    minAirtime: number;
    maxAirtime: number;
    minElectricity: number;
    maxElectricity: number;
    exchangeDest: CurrencyExchange;
    cashpowerFactor: number;
    mobileFactor: number;
    storeFactor: number;
    minAmountOrder: number;
    maxAmountOrder: number;
    minRamAmountOrder: number;
    maxRamAmountOrder: number;
    minConstructionAmountOrder: number;
    maxConstructionAmountOrder: number;
    minBillAmountOrder: number;
    maxBillAmountOrder:number;
    storeRate: any;
    minGiftCardAmount:number;
    maxGiftCardAmount:number;

    constructor(public contexto: ContextBase,
        private transactionServiceV1: TransactionServiceV1,
        ) {
        this.init();
    }

    init() {
        this.transactionServiceV1.getConditions().subscribe(res => {
            this.orderConditions = res;
            this.changeCurrencyRef();
        })
    }

    checkValidityOrder(order, sector, minimum?: number) {
        if (sector == 'airtime') {
            !isNaN(minimum) ? this.minAirtime = minimum : this.changeCurrencyRef();
            if (order.srcTotal < this.minAirtime || order.srcTotal > this.maxAirtime) {
                return false
            } else {
                return true;
            }
        } else if (sector == 'cashpower') {
            !isNaN(minimum) ? this.minElectricity = minimum : this.changeCurrencyRef();
            if (order.srcTotal < this.minElectricity || order.srcTotal > this.maxElectricity) {
                return false
            } else {
                return true;
            }
        } else if (sector == 'bill') {
            if (order < this.minBillAmountOrder || order > this.maxBillAmountOrder) {
                return false
            } else {
                return true;
            }
        } else if (sector == 'giftcard') {
            if (order < this.minGiftCardAmount || order > this.maxGiftCardAmount) {
                return false
            } else {
                return true;
            }
        }
    }
    checkValidityOrderCart(order, sector) {
        if (sector == 'airtime') {
            if (order.total < this.minAirtime || order.total > this.maxAirtime) {
                return false
            } else {
                return true;
            }
        } else if (sector == 'cashpower') {
            if (order.total < this.minElectricity || order.total > this.maxElectricity) {
                return false
            } else {
                return true;
            }
        }
    }


    changeCurrencyRef() {
        this.transactionServiceV1.getCurrencyExchange('EUR', this.contexto.currency.toUpperCase()).subscribe((d) => {
            this.exchangeDest = d;
            this.cashpowerFactor = this.exchangeDest.rate / (1.2);
            this.mobileFactor = this.exchangeDest.rate / (1.25);
            this.storeFactor = (1 / this.exchangeDest.rate) * (1.1);

            this.minAirtime = this.orderConditions.minAirTimeOrderAmountEUR * d.rate;
            this.maxAirtime = this.orderConditions.maxAirTimeOrderAmountEUR * d.rate;
            this.minElectricity = this.orderConditions.minCashPowerOrderAmountEUR * d.rate;
            this.maxElectricity = this.orderConditions.maxCashPowerOrderAmountEUR * d.rate;
            this.minAmountOrder = this.orderConditions.foodRestrictions.minOrderAmountEUR * d.rate;
            this.maxAmountOrder = this.orderConditions.foodRestrictions.maxOrderAmountEUR * d.rate;
            this.minRamAmountOrder = this.orderConditions.ramRestrictions.minOrderAmountEUR * d.rate;
            this.maxRamAmountOrder = this.orderConditions.ramRestrictions.maxOrderAmountEUR * d.rate;
            this.minConstructionAmountOrder = this.orderConditions.constructionRestrictions.minOrderAmountEUR * d.rate;
            this.maxConstructionAmountOrder = this.orderConditions.constructionRestrictions.maxOrderAmountEUR * d.rate;
            this.minBillAmountOrder = this.orderConditions.minBillOrderAmountEUR * d.rate;
            this.maxBillAmountOrder = this.orderConditions.maxBillOrderAmountEUR * d.rate;
            this.minGiftCardAmount = this.orderConditions.minGiftCardOrderAmountEUR * d.rate;
            this.maxGiftCardAmount = this.orderConditions.maxGiftCardOrderAmountEUR * d.rate;
        })
    }

    checkFactor(to: string){
        this.transactionServiceV1.getCurrencyExchange(this.contexto.currency.toLocaleUpperCase(), to).subscribe((d) => {
            this.storeRate =  d.rate;
            console.log(this.storeRate);
        })
    }

    checkFactorReturn(to: string){
        this.transactionServiceV1.getCurrencyExchange(this.contexto.currency.toLocaleUpperCase(), to).subscribe((d) => {
            return d.rate;
        })
    }
}