import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class HttpInternalServcie {
    private static CountriesJSONAdapter: string = "countries.json";

    constructor(private http: HttpClient) { }
        

    getCountriesJson() {
        return this.http.get(`assets/` + HttpInternalServcie.CountriesJSONAdapter);
    }
}