import { Buyer } from '../../shared/_objects/buyer';
import { CurrencyExchange } from '../../shared/_objects/currexchange';
import { Transaction } from '../../shared/_objects/transaction';
import { currentBuyer, currentTransaction, webBaseUrl, TransactionId } from '../../globals';
import { Component, OnInit } from '@angular/core';
import { Title, Meta, DomSanitizer } from '@angular/platform-browser';
import { DinamicMetaTags } from '../../shared/_helpers/_dinamicMetaTags';
import { TranslateService } from '@ngx-translate/core';
import { ContextBase } from '../../shared/_helpers/_contextBase.helper';
import { ActivatedRoute, Router } from '@angular/router';
import { TransactionServiceV1 } from '../../core/services/transactionv1.service';
import { EcommerceNewModelPurchase } from '../../shared/_objects/ecommerce';
import { LoginService } from '../../core/login-service/login.service';
import { PaymentService } from '../../core/payment/payment.service';
import { Header } from '../../shared/_objects';
import { CurrencyAmountService } from '../../shared/_services/currencyamount.service';
import { FacebookPixelService } from '../../shared/_services/facebook-pixel.service';
import { AnalyticsHelper } from '../../shared/_helpers/_analytics.helper';
declare const fbq: any;
@Component({
  selector: 'app-thankyoupage',
  templateUrl: './thankyoupage.component.html',
  styleUrls: ['./thankyoupage.component.scss']
})
export class ThankyoupageComponent implements OnInit {
  setTitle: string;
  webUrl = webBaseUrl;
  baluwoSlogan = 'Mejor que enviar dinero';
  beneficiaryCountryPhone: string;
  beneficiaryCountryPrefix: string;
  beneficiaryCountryName: string;
  beneficiaryOrderTypeId: string;
  beneficiaryOrderTypeName: string;
  transactionTotalEUR: any;
  transactionTotalCurrency: string;
  buyerCountryPhone: string;
  buyerCountryPrefix: string;
  buyerCountryName: string;
  buyer: any;
  lang: string;
  curr: string;
  transaction: Transaction;
  exchangeSource: CurrencyExchange;
  isMobile = true;
  isDesktop = false;
  exchangeDest: any;
  totalAmount: number;
  transactionId: number;
  transactionInfo: any;
  receipt: any;
  eCommerce: EcommerceNewModelPurchase;
  koTransaction: number;
  public updateHeader: number = 1;
  noReturn() { return false; }
  countCalls: number = 1;
  interval: any;
  redsysReceiptNotAvailable: boolean = false;
  orders: number[] = [];
  firstTimeBuyer: boolean;

  constructor(
    private meta: Meta,
    private transactionService: TransactionServiceV1,
    private dinamicMetaTags: DinamicMetaTags,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    protected sanitizer: DomSanitizer,
    private loginService: LoginService,
    private title: Title,
    private contexto: ContextBase,
    private facebookPixelService: FacebookPixelService,
    private _currencyAmountService: CurrencyAmountService,
    private _analyticsHelper: AnalyticsHelper,
  ) {
    this.isMobile = (/android|webos|iphone|ipad|ipod|blackberry|windows phone/).test(navigator.userAgent.toLowerCase());
    this.isDesktop = !this.isMobile;
    this.lang = this.contexto.language;
    this.curr = this.contexto.currency;
  }

  initMetaTag() {
    setTimeout(() => {
      this.dinamicMetaTags.MetaTitle = this.translate.instant("Baluwo.metatags.thankyou.MetaTitle");
      this.dinamicMetaTags.addMetaTags();
    }, 200)
  }
  ngOnInit() {
    this.init()
  }

  init() {
    this.buyer = JSON.parse(localStorage.getItem(currentBuyer));
    this.loginService.getBuyer(this.buyer.uuid).subscribe(res => {
      localStorage.setItem(currentBuyer, JSON.stringify(res));
      this.buyer = JSON.parse(localStorage.getItem(currentBuyer));
      console.log('BUYER: ', this.buyer);
      this.updateHeader++;
    })

    let newUpdatedHeader: Header = <Header>{};
    newUpdatedHeader.amount = 0;
    newUpdatedHeader.item = 0;
    this._currencyAmountService.setUpdatedHeader(newUpdatedHeader);

    this.route.queryParams.subscribe(params => {
      console.log(params);
      if (params['transactionIdRedys'] || params['transactionKORedys']) {
        this.transactionId = params['transactionIdRedys'];
        this.koTransaction = params['transactionKORedys'];
        this.showRedysReceipt();
      } else {
        this.transactionId = params['transactionId'];
        this.koTransaction = params['transactionKO'];
      }
      console.log(this.koTransaction);
      if (this.koTransaction != null) this.transactionId = this.koTransaction;
      console.log('Transaction Id parameter: ', this.transactionId);

      this.transactionService.getMyTransactionsTYPage(this.transactionId).subscribe(res => {
        console.log(res);
        this.transaction = res;
        this._analyticsHelper.analyticsPurchaseEvent(this.transaction)
        // (<any>window).dataLayer = (<any>window).dataLayer || [];
        // this.eCommerce = <EcommerceNewModelPurchase>{};
        // this.eCommerce.ecommerce = <any>{};
        // this.eCommerce.ecommerce.purchase = <any>{};
        // this.eCommerce.ecommerce.purchase.actionField = <any>{};
        // this.eCommerce.ecommerce.purchase.products = <any>[];

        // this.transactionInfo = res; // Object for Analytics

        // this.calculateTotalAMount();
        // this.eCommerce.ecommerce.purchase.actionField.id = this.transactionInfo.id;
        // // this.eCommerce.ecommerce.purchase.actionField.revenue = this.totalAmount;

        // this.transactionInfo.orders.forEach((d) => {
        //   if (d.lines) {
        //     d.lines.forEach(x => {
        //       this.eCommerce.ecommerce.purchase.products.push({
        //         'name': x.catalog.product.name,
        //         'id': x.catalog.id,
        //         'category': d.store.sector.name,
        //         'quantity': x.quantity
        //       })
        //     })
        //   } else {
        //     this.eCommerce.ecommerce.purchase.products.push({
        //       'id': d.id,
        //       'category': d.type,
        //       'quantity': d.total
        //     })
        //   }
        // });

        // (<any>window).dataLayer.push(this.eCommerce);

        // console.log(this.transactionInfo);
        // console.log((<any>window).dataLayer);
        // console.log(this.eCommerce);
      })
    })

    this.transactionService.getMyTransactions(1).subscribe(res => {
      if (res.length != 1) {
        this.firstTimeBuyer = false;
      } else {
        this.firstTimeBuyer = true;
        this.facebookPixelService.FirtsTimeBuyer(this.transaction);
      }
    }, error => {
      this.firstTimeBuyer = true;
    })
  }

  showRedysReceipt() {
    this.transactionService.getRedsysReturnReceipt().subscribe(res => {
      this.receipt = this.sanitizer.bypassSecurityTrustHtml(res);
    }, error => {
      if (error.status === 404) {
        this.countCalls++;
        if (this.countCalls <= 3) {
          setTimeout(() => {
            this.showRedysReceipt();
          }, 1000)
          console.log('COUNTCALLS: ', this.countCalls);
        } else {
          console.log('FINNISHED COUNTCALLS AFTER: ', this.countCalls);
          this.redsysReceiptNotAvailable = true;
        }
      }
    })
  }

  print(data): void {
    let popupWin;
    popupWin = window.open(' ', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(data);
    popupWin.document.close();
  }

  calculateTotalAMount() {
    this.totalAmount = 0;
    this.transactionInfo.orders.forEach(d => {
      this.totalAmount += d.total
    })
    this.eCommerce.ecommerce.value = this.totalAmount;
  }

  ngOnDestroy() {
    localStorage.removeItem(currentTransaction);
  }

  navigateTo(link) {
    this.router.navigate(['/' + link]);
  }
}
