import { CollectionViewer } from "@angular/cdk/collections";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { LoginService } from "../../../core/login-service/login.service";
import { TransactionServiceV1 } from "../../../core/services/transactionv1.service";
import { currentBuyer, currentTransaction, landingCurrency, STORE_ORDER_TYPE } from "../../../globals";
import { ContextBase } from "../../../shared/_helpers/_contextBase.helper";
import { Buyer, RegisterToken, Transaction } from "../../../shared/_objects";
import { StoreOrderLine } from "../../../shared/_objects/order";
import { FooterService } from "../../../shared/_services/footer.service";

@Injectable()
export class LoginHelperUserComponent {
    password: string;
    registerToken: RegisterToken = new RegisterToken;
    buyer: Buyer = new Buyer;
    currentTransaction: Transaction;
    mobile: string;

    public setOnBuyerCreated = new BehaviorSubject(<any>{});
    gatNewBuyerCreated = this.setOnBuyerCreated.asObservable();


    private setOnAuthenticationError = new BehaviorSubject(<any>{});
    getErrorAuthentication = this.setOnAuthenticationError.asObservable();


    constructor(
        private loginService: LoginService,
        private transactionService: TransactionServiceV1,
        public contexto: ContextBase,
        private footerService: FooterService,
    ) { }

    thisIsATest() {
        this.setOnBuyerCreated.next('test complete');
    }

    setAuth(mobile: string, password: string) {
        localStorage.setItem('currentAuth', 'Basic ' + btoa(mobile + ':' + password));
        this.authenticatePassword(mobile);
    }

    authenticatePassword(mobile) {
        this.loginService.getuserInfo().subscribe(b => {
            this.buyer.name = b.body.name;
            this.buyer.email = b.body.email;
            this.setOnBuyerCreated.next(b.body);
            this.currentTransaction = JSON.parse(localStorage.getItem(currentTransaction));
            // TODO revisar esta condición
            if (this.currentTransaction !== null) {
                this.buyer.currency = this.currentTransaction.srcCurrency;
            }
        },
            error => this.setOnAuthenticationError.next(error)
        );
    }

    persistTransaction(buyer: Buyer) {
        localStorage.setItem(currentBuyer, JSON.stringify(buyer));
        this.footerService.setFooterBuyer(true);
        if (this.currentTransaction !== null) {
            this.currentTransaction.buyer = buyer;
            this.transactionService.createTransaction(this.currentTransaction).subscribe(t => {
                console.log('created' + t);
                this.currentTransaction = t;
                this.sortOrderLines();
                localStorage.setItem(currentTransaction, JSON.stringify(this.currentTransaction));
            }, error => {
                if (typeof this.currentTransaction.memberGetMember !== 'undefined') {
                    this.currentTransaction.memberGetMember = null;
                    this.transactionService.createTransaction(this.currentTransaction)
                        .subscribe(t => {
                            console.log('created' + t);
                            this.currentTransaction = t;
                            this.sortOrderLines();
                            localStorage.setItem(currentTransaction, JSON.stringify(this.currentTransaction));
                        });
                }
            });
        } else {
            console.log('no transaction');
        }
    }

    sortOrderLines() {
        this.currentTransaction.orders.forEach(o => {
            if (o.type === STORE_ORDER_TYPE) {
                o.lines.sort(this.sortProduct);
                let bags = 0;
                o.lines.forEach(l => {
                    const name = l.catalog.product.name;
                    if (name.toLocaleLowerCase().indexOf('rice') > -1 || name.toLocaleLowerCase().indexOf('riz') > -1
                        || name.toLocaleLowerCase().indexOf('sugar') > -1 || name.toLocaleLowerCase().indexOf('sucre') > -1) {
                        bags += l.quantity;
                    }
                });
            }
        });
    }

    sortProduct(linea: StoreOrderLine, lineb: StoreOrderLine) {
        return linea.catalog.product.priority - lineb.catalog.product.priority;
    }
}

