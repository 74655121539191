import { Injectable } from "@angular/core";
import { CurrencyExchange } from "../_objects/currexchange";
import { Header } from "../_objects/header";
import { CurrencyAmountService } from "../_services/currencyamount.service";
import { TransactionServiceV1 } from "../../core/services/transactionv1.service";
import { TransactionId } from "../../globals";

@Injectable()
export class TransactionHelper {

  exchangeDest: CurrencyExchange;
  cashpowerFactor: number;
  mobileFactor: number;
  storeFactor: number;
  minAmountOrder: number;

  constructor(
    private transactionService: TransactionServiceV1,
    public _currencyAmountService: CurrencyAmountService
  ) { }

  changeCurrencyRef(from: string, to: string) {
    this.transactionService.getCurrencyExchange(from, to).subscribe((d) => {
      this.exchangeDest = d;
      this.cashpowerFactor = this.exchangeDest.rate / (1.2);
      this.mobileFactor = this.exchangeDest.rate / (1.25);
      this.storeFactor = (1 / this.exchangeDest.rate) * (1.1);
    })
  }

  getMobileData() {
    return this.mobileFactor;
  }

  calculateCartTotal(transaction) {
    let totalItems = 0;
    let totalAmount = 0;
    let newUpdatedHeader: Header = <Header>{};
    if (transaction !== undefined) newUpdatedHeader.currency = transaction.currency.symbol;
    if (transaction !== undefined && transaction.id !== localStorage.getItem(TransactionId)) localStorage.setItem(TransactionId, transaction.id)
    let i = 0;
    if (transaction && transaction.orders && transaction.orders.length) {
      transaction.orders.forEach(o => {
        if (o.type === 'store') {
          o.lines.forEach(l => {
            if (l.catalog.price < 1) {
              totalItems += 1;
            } else {
              totalItems += l.quantity;
            }
          });
        } else {
          totalItems++;
        }
        totalAmount += o.purchaseTotal;
      });
      newUpdatedHeader.amount = totalAmount;
      newUpdatedHeader.item = totalItems;
      this._currencyAmountService.setUpdatedHeader(newUpdatedHeader);
    } else {
      newUpdatedHeader.amount = null;
      newUpdatedHeader.item = null;
      this._currencyAmountService.setUpdatedHeader(newUpdatedHeader);
    }
  }
} 