export class AgentRegistration {
    name: string;
    mobile: string;
    email: string;
    cif: string;
    province: string;
    town: string;
    address: string;
    credit: number;
    cmMobile?: string;
    cmPassword?: string;
  }

  export class AgentRegistrationWeb {
    businessname: string;
    name: string;
    cif: string;
    nationality: string;
    address: string;
    town: string;
    province: string;
    postalcode: string;
    country: string;
    phone?: string;
    mobile: string;
    contactperson: string;
    email: string;
    storetype: string;
  }

  export class AgentRegistrationWebAgent {
    agentName: string;
    name: string;
    cif: string;
    nationality: string;
    address: string;
    town: string;
    province: string;
    postalCode: string;
    country?: string;
    phone?: string;
    mobile: string;
    contact: string;
    email: string;
    agentType: string;
    contactMobile?: string
  }