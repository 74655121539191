import { Catalog, CatalogUniq } from './catalog';
import { Product } from './product';
import { Receiver } from './receiver';
import { Store } from './store';
export class Order {
  id: number;
  mobile: string;
  meterId: string;
  beneficiary: Receiver;
  srcTotal: number;
  targetTotal: number;
  type: string;
  factor: number;
  suppliers: string;
  store: Store;
  total: number;
  state: number;
  lines: StoreOrderLine[];
  purchaseTotal?: any;
  pin?:any;
  amount?: number;
}

export class StoreOrderLine {
  id: number;
  quantity: number;
  total: number;
  totalBuyerCurrency: number;
  catalog: Catalog;
}

export class StoreOrderLineUniq {
  quantity: number;
  catalog: CatalogUniq;
}

export class StoreOrderUniq {
  type?: string;
  beneficiary?: {
    mobile: string;
    name: string;
  };
  store?:{
    id: number;
  };
  lines?: StoreOrderLineUniq[];
  total?: number;
  targetTotal?: number;
  meterId?: string;
  openVoucher?: boolean;
  billOrderData?: Object;
  productCode?:string;
  providerCode?: string;
  mobile?: string;
  amount?: number;
  giftCardConfig?:{
    id: number;
  }
}

export class TopUpOrderUniq {
  type: string;
  beneficiary: {
    mobile: string;
    name: string;
  };
  meterid?: string;
  total?: number;
  targetTotal?: number;
}

export class StoreOrderUniq2{
  buyer: {
    mobile: string;
    name: string;
  };
  clientId: string;
  orders: StoreOrderUniq[];
}

export class NewOrder{
  buyer?: {
    mobile: string;
    name: string;
  };
  clientId: string;
  orders: StoreOrderUniq[];

}

export class NationalTopUpOrder{
  mobile: string;
  providerCode: string;
  productCode: string;
  amount: number
}
