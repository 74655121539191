import { Buyer } from '../../shared/_objects/buyer';
import { RegisterToken } from '../../shared/_objects/registerToken';
import { CountryService } from '../../shared/_services/country.services';
import { LoginService } from '../../core/login-service/login.service';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { ContextBase } from '../../shared/_helpers/_contextBase.helper';
import { landingSourceCountry, TransactionId, currentBuyer } from '../../globals';
import { TransactionServiceV1 } from '../../core/services/transactionv1.service';

@Component({
  selector: 'app-loginmodal',
  templateUrl: './loginmodal.component.html',
  styleUrls: ['./loginmodal.component.scss']
})
export class LoginmodalComponent implements OnInit {

  countryPrefix: string;
  registerToken: RegisterToken = new RegisterToken;
  showStep1: boolean;
  showStep2: boolean;
  showStep3: boolean;
  showSuccess: boolean;
  showError: boolean;
  showStepRegistration: boolean;
  showStepPassword: boolean;
  showStepRecovery: boolean;
  showStepReset: boolean;
  buyer: any;
  landingSourceCountry: any;
  transactionIdUniq: number;
  referenceNumber: string;
  referable: boolean;
  askForRegistration: boolean;

  @Output() onSuccessLogin: EventEmitter<Buyer> = new EventEmitter<Buyer>();

  constructor(
    private loginService: LoginService,
    private router: Router,
    private transactionServiceV1: TransactionServiceV1,
    public contexto: ContextBase,
    public dialogRef: MatDialogRef<LoginmodalComponent>) {
    dialogRef.disableClose = true;
    this.showStep1 = true;
    this.showStep2 = false;
    this.showStep3 = false;
    this.showSuccess = false;
    this.showError = false;
    this.referable = false;
    this.showStepPassword = false;
    this.showStepRegistration = false;
    this.showStepRecovery = false;
    this.showStepReset = false;
    this.getCountryCode();
  }

  ngOnInit() {
    $('.toLegalConditions').click(() => {
      this.router.navigate(['/legal-conditions']);
    })
  }

  // Event emmited by child 1 modal-step_1
  onRequestToken(mobile: string) {
    if (mobile) {
      this.showStep2 = true, this.showStep1 = false;
      this.registerToken.mobile = mobile.trim();
    }
  }

  onRegistrationAvailable(available: boolean) {
    this.askForRegistration = !available;
  }

  onReferenceNumberEmit(referenceNumber: string) {
    if (referenceNumber) {
      this.referenceNumber = referenceNumber.trim();
    }
  }
  // Event emmited by child 2 modal-step_2
  onBuyerCreated(buyer: Buyer) {
    if (buyer) {
      console.log('LOGIN tenemos buyer ' + buyer);
      this.onSuccessLogin.emit(buyer);
      this.buyer = buyer;
      setTimeout(() => {
        if (this.askForRegistration) {
          this.showStep2 = false, this.showError = false;
          this.showStepRegistration = true;
        } else {
          this.showStepPassword = false;
          this.showStepReset = false;
          this.showError = false;
        };
        this.createTransactionId(buyer);
      }, 200);
    }
  }

  onBuyerCreatedPassword(buyer: Buyer) {
    if (buyer) {
      console.log('LOGIN tenemos buyer ' + buyer);
      this.onSuccessLogin.emit(buyer);
      this.buyer = buyer;
      this.buyer.currency = this.contexto.getCurrencyInfo(this.contexto.currency.toLowerCase());
      this.contexto.currency = this.buyer.currency.id;
      localStorage.setItem(currentBuyer, JSON.stringify(this.buyer));
      setTimeout(() => {
        if (this.askForRegistration) {
          this.showStep2 = false, this.showError = false;
          this.showStepRegistration = true;
        } else {
          this.showStepPassword = false;
          this.showStepReset = false;
          this.showError = false;
          this.showStepRegistration = false;
          this.showSuccess = true
        };
        this.createTransactionId(buyer);
      }, 200);
    }
  }

  onRegistrationSuccess(evt) {
    if (evt) {
      localStorage.setItem('passwordLogin', 'available');
      this.showStepRegistration = false;
      this.showError = false;
      this.showSuccess = true
    }
  }

  onRegistrationSkip() {
    localStorage.removeItem('passwordLogin');
    this.showStepRegistration = false;
    this.showError = false;
    this.showSuccess = true
  }

  onOpenReset(mobile) {
    this.showStepPassword = false;
    this.showStepReset = true;
    this.registerToken.mobile = mobile;
  }

  onPasswordAvailable(evt) {
    this.showStep1 = false;
    this.showStepPassword = true;
    this.registerToken.mobile = evt;
    localStorage.setItem('passwordLogin', 'available');
  }

  setNewCurrencyBuyer() {
    this.buyer.currency = this.contexto.getCurrencyInfo(this.contexto.currency.toLowerCase());
    this.contexto.currency = this.buyer.currency.id;
    localStorage.setItem(currentBuyer, JSON.stringify(this.buyer));
    this.loginService.patchBuyer(this.buyer).subscribe((res) => {
      this.buyer = res;
      localStorage.setItem(currentBuyer, JSON.stringify(this.buyer));
    })
  }

  createTransactionId(buyer) {
    this.transactionIdUniq = JSON.parse(localStorage.getItem(TransactionId));
    if (this.transactionIdUniq != null) {
      this.transactionServiceV1.setBuyerIdinTransaction(this.transactionIdUniq).subscribe((res) => {
        console.log('TRANSACTION ASSIGNED: ', this.transactionIdUniq);
        this.setNewCurrencyBuyer();
      })
    }

  }

  // Event emmited by child 2 modal-step_2
  onAuthenticationError(event: any) {
    this.showError = true, this.showStep2 = false;
  }
  onSkipReference() {
    this.referable = false;
    this.showSuccess = true;
  }
  onOpenHelp() {
    this.showError = false;
    this.showStep2 = false;
    this.showStep3 = true;
  }
  // Event emmited by succes child
  onCloseLogin() {
    this.showStep1 = false;
    this.showStep2 = false;
    this.showStep3 = false;
    this.showSuccess = false;
    this.showError = false;
    this.dialogRef.close(this.buyer);
  }

  getCountryCode() {
    $.get("https://api.ipdata.co?api-key=2ef5e3bc539ea513cc507b6bee9f8525d02016f53362e83dfa7c3a94", (response) => {
      this.landingSourceCountry = JSON.stringify(response.country_code, null, 4);
      localStorage.setItem(landingSourceCountry, this.landingSourceCountry);
    }, "jsonp");
  }

}
