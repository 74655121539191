import { Component, EventEmitter, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BackToStore } from "../../../shared/_services/backtostore.service";
import { SupplierService } from "../../../core/services/suppliers.service";
import { LocationSectorStoreHelper } from "../../../shared/_helpers/_locationSectorStore.helper";
import { ValidityOrderHelper } from "../../../shared/_helpers/_validityOrder.helper";
import { CustomAmountInput } from "../custom-widgets/custom-input";

@Component({
  selector: 'app-home-bill-payment-component',
  templateUrl: 'home_billpayment.component.html',
  styleUrls: ['../home.component.scss']
})

export class HomeBillPaymentComponent {
  yourNewWidgets: any;
  myLayout: any = [];
  jsonFormOptions: any = {
    defautWidgetOptions: {
      addable: true,
      validationMessages: {
        required: 'This field is required.',
        minLength: 'Must be at least 8 characters long.',
        pattern: 'there is an error with your pattern'
      }
    }
  }
  mySchema: any;
  schemaErrorMsg: string;
  billInfo: any;
  superAmount: number;
  exampleJsonObject: any;
  urlFormatted: string;
  noImport: boolean = false;
  numberTitle: string;
  dateTitle: string;
  public billArray: any[];
  public billEnabled: boolean = true;

  @Output() billPaymentDataInfo: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public _validityOrderHelper: ValidityOrderHelper,
    public locationSectorStoreHelper: LocationSectorStoreHelper,
    private supplierService: SupplierService,
    public backToStore: BackToStore,
    public translate: TranslateService
  ) { this.getPostBillPayment(); }

  getPostBillPayment() {
    this._validityOrderHelper.checkFactor(this.locationSectorStoreHelper.currentCountry.currency.id);
    this.yourNewWidgets = { 'my-amount-input': CustomAmountInput }
    this.supplierService.getBillTypeInformation(this.locationSectorStoreHelper.currentCountry.id, this.locationSectorStoreHelper.currentSector.id).subscribe(res => {
      this.billArray = res;
    })
  }

  selectBill(evt: any) {
    this.billEnabled = evt.value.enabled;
    this.mySchema = JSON.parse(evt.value.dataSchema);
    this.myLayout = [];
    for (let i = 0; i <= Object.keys(this.mySchema.properties).length - 1; i++) {
      this.myLayout.push({
        key: Object.keys(this.mySchema.properties)[i],
        title: this.getSchemaTitle(this.mySchema.properties[Object.keys(this.mySchema.properties)[i]].title),
      })
    }
    this.myLayout.push({ type: "my-amount-input" });
    console.log(this.myLayout);
    this.billInfo = evt.value;
  }

  yourValidationErrorsFn(e) {
    console.log(e);
  }

  yourIsValidFn(e) {
    console.log(e)
  }

  onJsonSchemaFormChange(e) {
    console.log(e);
  }

  getSchemaTitle(title: string) {
    switch (title.toLowerCase()) {
      case 'contract number':
        return this.translate.instant('Baluwo.Agent.bill.contractnumber');
      case 'bill number':
        return this.translate.instant('Baluwo.Agent.bill.billnumber');
      case 'distribution code':
        return this.translate.instant('Baluwo.Agent.bill.distributioncode');
      case 'bill payment date':
        return this.translate.instant('Baluwo.Agent.bill.billpaymentdate');
      case 'bill date':
        return this.translate.instant('Baluwo.Agent.bill.billdate');
    }
  }

  yourOnSubmitFn(evt) {

    const billOrder = <any>{};
    billOrder.billOrderData = <any>{};
    billOrder.billOrderData.billType = <any>{};
    billOrder.billOrderData.data = <any>{};
    billOrder.billOrderData.billType.id = this.billInfo.id;
    billOrder.billOrderData.data = JSON.stringify(evt);
    billOrder.type = 'bill';
    this.backToStore.amountInputCuston.subscribe(res => {
      billOrder.targetTotal = res;
      if (!isNaN(billOrder.targetTotal)) {
        this.backToStore.getImportErrorValue(false);
      } else {
        this.backToStore.getImportErrorValue(true);
        return false;
      }
    })
    this.billPaymentDataInfo.emit(billOrder);

  }

  falseStepTwo() {
    this.cancel.emit();
  }
}