import { Component } from "@angular/core";
import { MatDialog } from "@angular/material";
import { TransactionServiceV1 } from "../../core/services/transactionv1.service";

@Component({
    selector: 'app-customarecare',
    templateUrl: './customarecare.component.html',
    styleUrls:['./customarecare.component.scss']
}) 

export class CustomareCareComponent{
    public customareContactInfo: any;
    constructor(public dialog: MatDialog, private transactionService: TransactionServiceV1){
        this.transactionService.getContacts().subscribe(res=>{
            this.customareContactInfo = res;
        })
    }
    close(){
        this.dialog.closeAll();
    }
}