import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { serverBaseUrl } from "../../globals";

@Injectable()
export class SupplierService {
    private baseUrl = serverBaseUrl;
    constructor(private http: HttpClient) {
    }

    getSuppliers(): Observable<any> {
        const providers$ = <any>this.http
            .get(`${this.baseUrl}/v1/national/supplier`, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return providers$;
    }

    getSuppliersByCountry(country: string): Observable<any> {
        const providers$ = <any>this.http
            .get(`${this.baseUrl}/v1/supplier/${country}`, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return providers$;
    }

    getSuppliersPromotions(data: any): Observable<any> {
        const promotion$ = <any>this.http
            .post(`${this.baseUrl}/v1/supplier/promotion`, data, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return promotion$;
    }

    getBillTypeInformation(country: string, sector: number) {
        const billtype$ = <any>this.http
            .get(`${this.baseUrl}/v1/billtype/${country}/${sector}`, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return billtype$;
    }

    private getHeadersLogged(): HttpHeaders {
        const headers = new HttpHeaders({
            'x-blw-src': 'web',
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('currentAuth') || ''
        });
        return headers;
    }
}

function handleError(error: HttpErrorResponse) {
    // log error
    const errorMsg = error.error;
    // throw an application level error
    return throwError(errorMsg);
}