
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { ContextBase } from '../_helpers/_contextBase.helper';



@Injectable()
export class PrivacyPolicyService {
    private language: string;
    private static PrivacyPolicyAdapter: string = "privacy-policy/#USERLANG#.html";

    constructor(private http: HttpClient, private contexto: ContextBase) {
        this.contexto.language === 'es' ? this.language = 'es' : this.language = 'en';
     }

    public get(): Promise<any> {
        let promise = new Promise((resolve, reject) => {
            return this.http.get("assets/local/" + PrivacyPolicyService.PrivacyPolicyAdapter.replace("#USERLANG#", this.language), {responseType: 'text'})
                .subscribe((res: any) => {
                    resolve(res);
                },
                err => reject(err));
        });
        return promise;
    }
}