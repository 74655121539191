import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { FakeContactComponent } from './fakecontact.component';


@NgModule({
	imports: [
        SharedModule
	],
	declarations: [
        FakeContactComponent
	],
	providers: [
	],
	entryComponents: [
        FakeContactComponent
	],
	exports: [

	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})

export class FakeContactModule { }
