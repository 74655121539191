import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { PromotionService } from "../../core/services/promotion.service";
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-pagenotfound',
    templateUrl: './pagenotfound.component.html',
    styleUrls: []
})

export class PageNotFoundComponent {
    public _ENVIRONMENT_ = environment;
    constructor(private promotionService: PromotionService, private router: Router) {
        const referralLink = this.getQueryParam('code');
        console.log(referralLink);
    }
    getQueryParam(param) {
        var result = window.location.search.match(
            new RegExp("(\\?|&)" + param + "(\\[\\])?=([^&]*)")
        );
        let ua = navigator.userAgent
        console.log(/Android/i.test(ua));
        console.log(/iphone|ipad/i.test(ua));
        console.log(result);
        if (result) {
            console.log('yes')
            if (/Android/i.test(ua)) {
                window.location.href = `https://play.google.com/store/apps/details?id=${this._ENVIRONMENT_.baluwoUserAppStore}&referrer=` + result[3]
            } else if (/iphone|ipad/i.test(ua)) {
                this.promotionService.sendReferralCodeIOS(result[3]).subscribe(res=>{
                    console.log(res);
                });
                window.location.href = 'https://apps.apple.com/es/app/baluwo/id1490261153&referrer=' + result[3]
            } else {
                console.log('going home');
                this.router.navigate(['/home']);
            }
        }

        return result ? result[3] : false;
    }
}