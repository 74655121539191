import { RegisterToken } from '../../../shared/_objects/registerToken';
import { CountryService } from '../../../shared/_services/country.services';
import { LoginService } from '../../../core/login-service/login.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-loginmodal-step3',
  templateUrl: 'modal-step_3.component.html',
  styleUrls: ['../loginmodal.component.scss']
})
export class LoginmodalStep3Component implements OnInit {
  countryPrefix: string;
  registerToken: RegisterToken = new RegisterToken;
  showStep2: boolean;
  @Output() onClosingDialog: EventEmitter<any> = new EventEmitter<any>();
  constructor(private countryService: CountryService,
              private loginService: LoginService) { }

  ngOnInit() {
  }

  close() {
    this.onClosingDialog.emit()
  }

}
