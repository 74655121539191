export class Country {
  id: string;
  name: string;
  currency: Currency;
  managerMobile: string;
  mobilePrefix: string;
  retailer: boolean;
}
export class Currency {
  id: string;
  acronym: string;
  name: string;
  symbol: string;
}
