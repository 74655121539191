import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-loginmodal-success',
  templateUrl: 'modal-step_success.component.html',
  styleUrls: ['../loginmodal.component.scss']
})
export class LoginmodalStepSuccessComponent implements OnInit {

  @Output() onCloseLogin: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }
  closeLogin() {
    this.onCloseLogin.emit();
  }
}


