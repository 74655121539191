import { Component } from "@angular/core";
import { ContextBase } from "../../../shared/_helpers/_contextBase.helper";

@Component({
    selector: 'app-home-donwloadapp-component',
    templateUrl: './home_downloadappSection.component.html',
    styleUrls: ['../home.component.scss']
})

export class HomeDownloadappSectionComponent {
    constructor(public contexto: ContextBase) { }

    getImages(numb:number) {
        switch (this.contexto.language.toLowerCase()) {
            case 'es':
                return `/assets/images/downloadSection/BannerAPPproductos_es_${numb}.jpg`
            case 'fr':
                return `/assets/images/downloadSection/BannerAPPproductos_fr_${numb}.jpg`
            default:
                return `/assets/images/downloadSection/BannerAPPproductos_en_${numb}.jpg`
        }
    }
}