import { Component } from "@angular/core";
import { ContactPopUp } from "../../shared/_helpers/_contactpopup.helper";
import { BaluwoCardLegalTermsService } from "../../shared/_services/baluwocardlegalterms";
import { DomSanitizer } from "@angular/platform-browser";
import * as $ from 'jquery';

@Component({
    selector: 'app-card-terms-conditions',
    templateUrl: 'cardtermsandconditions.component.html',
    styleUrls: ['../legalconditions/legalconditions.component.scss'],
})

export class CardTermsAndConditionsComponent {
    public baluwoCardLegalTerms: any;
    constructor(public _contactPopUp: ContactPopUp,
        sanitizer: DomSanitizer,
        private _baluwoCardLegalTermsService: BaluwoCardLegalTermsService) {
        this._baluwoCardLegalTermsService.get().then((data) => {
            this.baluwoCardLegalTerms = sanitizer.bypassSecurityTrustHtml(data);
        });
        setTimeout(() => {
            $('.openpopup').click(() => {
                this.openContactPopUp();
            });
        }, 200);

    }

    openContactPopUp() {
        this._contactPopUp.showContactPopup();
    }

}