
export class Conditions{
    airTimeMarkup: number;
    cashPowerMarkup: number;
    constructionRestrictions: {
        markup: number;
        maxOrderAmountEUR: number;
        maxOrderQuantity: number;
        minOrderAmountEUR: number;
    };
    fabricRestrictions:{
        markup: number;
        maxOrderAmountEUR: number;
        maxOrderQuantity: number;
        minOrderAmountEUR: number;
    };
    foodRestrictions: {
        markup: number;
        maxOrderAmountEUR: number;
        maxOrderQuantity: number;
        minOrderAmountEUR: number;
    };
    maxAirTimeOrderAmountEUR: number;
    maxCashPowerOrderAmountEUR: number;
    maxFoodRequestQuantity: number;
    minAirTimeOrderAmountEUR: number;
    minCashPowerOrderAmountEUR: number;
    minBillOrderAmountEUR: number;
    maxBillOrderAmountEUR: number;
    minGiftCardOrderAmountEUR: number;
    maxGiftCardOrderAmountEUR: number;
    ramRestrictions: {
        markup: number;
        maxOrderAmountEUR: number;
        maxOrderQuantity: number;
        minOrderAmountEUR: number; 
    }
}