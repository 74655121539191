import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { CashPowerMeterHelper } from "../../../shared/_helpers/_cashPowerMeter.helper";
import { ServerErrorHelper } from "../../../shared/_helpers/servererrors.helper";
import { ContextBase } from "../../../shared/_helpers/_contextBase.helper";
import { HomeUtilsHelper } from "../../../shared/_helpers/_homeUtils.helper";
import { LocationSectorStoreHelper } from "../../../shared/_helpers/_locationSectorStore.helper";
import { Beneficiary, Buyer } from "../../../shared/_objects";

@Component({
    selector: 'app-beneficicary-component',
    templateUrl: 'home_beneficiary.component.html',
    styleUrls: ['../home.component.scss']
})

export class BeneficiaryComponent {
    public premobile: string;
    public allowPayment: boolean;
    isMobile = true;
    isDesktop = false;
    folder: string;
    orderFormControl = new FormControl('', Validators.required);

    @Input() buyer: Buyer;
    @Input() sourceCurrency: string;
    @Input() noSupplierForMeteNumber: any;
    @Input() premobileReturn: string;
    @Input() beneficiaryTransaction: Beneficiary = new Beneficiary;
    @Output() premobileEmit: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        public contexto: ContextBase,
        public _homeUtilsHelper: HomeUtilsHelper,
        public serverErrorHelper: ServerErrorHelper,
        public cashPowerMeterHelper: CashPowerMeterHelper,
        public locationSectorStoreHelper: LocationSectorStoreHelper) {
        this.isMobile = (/android|webos|iphone|ipad|ipod|blackberry|windows phone/).test(navigator.userAgent.toLowerCase());
        this.isDesktop = !this.isMobile;
        (this.contexto.isMobile) ? this.folder = "Mobile" : this.folder = "Desktop";
        console.log(this.premobileReturn)
    }
    ngOnInit(){
        if(this.premobileReturn) this.premobile = this.premobileReturn;
    }

    checkRecieverMobile() {
        this.serverErrorHelper.errorMsgAmountService = null;
        const mobile = this.locationSectorStoreHelper.currentCountry.mobilePrefix + this.premobile;
        if(this.premobile !== '' || this.premobile !== undefined) this.premobileEmit.emit(this.premobile)
        this.allowPayment = false;
        this._homeUtilsHelper.validatePhoneNumber(this.premobile, this.locationSectorStoreHelper.currentCountry.mobilePrefix);
        if(this.locationSectorStoreHelper.currentSector.name ==='Cash power'){
            const mobileData = {mobile: mobile}
            this.cashPowerMeterHelper.init(mobileData);
        }
        if (this.locationSectorStoreHelper.currentSector.type === 'topup' && this._homeUtilsHelper.isValidBeneficiaryMobile) {
            this.locationSectorStoreHelper.changeSector(mobile);
        }
        if (this.locationSectorStoreHelper.currentCountry.id === undefined) this._homeUtilsHelper.isValidBeneficiaryMobile = false;
    }
}