import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ContextBase } from "../../../shared/_helpers/_contextBase.helper";
import { LoginService } from "../../../core/login-service/login.service";
import { LoginHelperUserComponent } from "../loginhelpers/loginHelperUser.component";

@Component({
    selector: 'app-loginmodal-register',
    templateUrl: 'modal-step_register.component.html',
    styleUrls: ['../loginmodal.component.scss']
})

export class LoginmodalStepRegisterComponent implements OnInit {
    buyerRegistrationForm: FormGroup;
    public buyerRegistrationData: any;
    public noMatrchPassowrd: boolean = false;
    public weakPassword: boolean = false;
    fieldTextType: boolean;
    fieldTextType2: boolean;
    isSubmitted: boolean = false;
    keepContinue: boolean = false;
    
    @Input() mobile: string;
    @Output() onRegistrationSuccess: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClosingDialog: EventEmitter<any> = new EventEmitter<any>();
    @Output() onRegistrationSkip: EventEmitter<any> = new EventEmitter<any>();
    constructor(
        private fb: FormBuilder,
        private loginService: LoginService,
        public loginHelperUserComponent: LoginHelperUserComponent,
        public contexto: ContextBase,
        private router: Router) {

        this.buyerRegistrationForm = this.fb.group({
            'name': ['', Validators.compose([Validators.required])],
            'email': ['', Validators.compose([Validators.required, Validators.pattern('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,6}')])],
            'password': ['', Validators.compose([Validators.required])],
            'passwordrepeat': ['']
        });
        this.buyerRegistrationData = <any>{};
        console.log(this.contexto.isMobile);
    }

    ngOnInit() {

            if (this.contexto.name !== null) {
                this.buyerRegistrationForm.controls['name'].setValue(this.contexto.name)
            }
       
    }

    validateControl(control_name: string, validator_name: string) {
        return this.buyerRegistrationForm.controls[control_name].hasError(validator_name) && (!this.buyerRegistrationForm.controls[control_name].pristine || this.buyerRegistrationForm.controls[control_name].touched);
    }

    checkPasswordMatch() {
        if (this.buyerRegistrationForm.get('password').value !== this.buyerRegistrationForm.get('passwordrepeat').value) {
            this.noMatrchPassowrd = true;
            return false;
        } else {

            this.noMatrchPassowrd = false;
            return this.buyerRegistrationForm.get('password').value
        }
    }

    toggleFieldTextType(num) {
        if (num == 1) {
            this.fieldTextType = !this.fieldTextType;
        } else if (num == 2) {
            this.fieldTextType2 = !this.fieldTextType2;
        }
    }

    registerUser() {
        this.isSubmitted = true;
        this.weakPassword = false;
        this.buyerRegistrationData.name = this.buyerRegistrationForm.get('name').value;
        this.buyerRegistrationData.email = this.buyerRegistrationForm.get('email').value
        this.buyerRegistrationData.password = this.checkPasswordMatch();
        console.log(this.buyerRegistrationForm.valid);
        if (this.buyerRegistrationForm.valid && !this.noMatrchPassowrd) {
            this.loginService.putBuyerLoginInfo(this.buyerRegistrationData).subscribe(res => {
                console.log(res);
                this.loginHelperUserComponent.setAuth(this.mobile, this.buyerRegistrationData.password);
                this.onRegistrationSuccess.emit(true);
            }, error => {
                if (error.error.code == 1) {
                    this.weakPassword = true;
                }
            })
        }
    }

    skipRegistration() {
        this.onRegistrationSkip.emit(true);
    }

    close() {
        this.skipRegistration();
        // this.onClosingDialog.emit()
    }

    navigateTo(e: MouseEvent, page: string): void {
        e.preventDefault();
        this.router.navigate(['/' + page]);
        this.close();
    }
}