import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ContextBase } from "./_contextBase.helper";

@Injectable()
export class LogOutHelper {
    public currency: string;
    public language: string;
    
    constructor(private router: Router, private contexto: ContextBase) {

    }

    logout() {

        this.router.navigate(['/home']).then(()=>{
            localStorage.clear();
            document.location.reload();
        });
    }

}