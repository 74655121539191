import { Email } from '../../shared/_objects/email';
import { LoginService } from '../../core/login-service/login.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ContextBase } from '../../shared/_helpers/_contextBase.helper';
import { CountryService } from '../../shared/_services/country.services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SuccesspopupComponent } from '../successpopup/successpopup.component';
import { contactus } from '../../shared/_objects/contactus';
import { AnalyticsHelper } from '../../shared/_helpers/_analytics.helper';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {
  contactEmail = new Email;
  isValidForm = true;
  isValidFormLeadName = true;
  isValidFormLeadPhone = true;
  isValidFormLeadEmail = true;
  isValidFormLeadMessage = true;
  countryPrefix: any;
  countryArray: any[] = [];
  isMobile: boolean;
  landingSourceCountry: string;
  contactForm: FormGroup;
  isSubmitted: boolean = false;
  contact: contactus;
  keepContinue: boolean = false;
  public _ENVIRONMENT_ = environment;

  constructor(private loginService: LoginService,
    private contexto: ContextBase,
    private countryService: CountryService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private _analyticsHelper: AnalyticsHelper,
    public dialogRef: MatDialogRef<SuccesspopupComponent>) {
    this.isMobile = this.contexto.isMobile;
    this.landingSourceCountry = JSON.parse(localStorage.getItem('landingSourceCountry'))
    this.countryService.getBuyingCountries().subscribe(
      c => {
        this.countryArray = c;
        this.countryPrefix = this.countryArray.filter(country => country.id === this.landingSourceCountry)[0];
      });

    this.contactForm = this.fb.group({
      'leadName': ['', Validators.compose([Validators.required])],
      'leadPrefix': ['', Validators.compose([Validators.required])],
      'leadPhone': ['', Validators.compose([Validators.required])],
      'leadEmail': ['', Validators.compose([Validators.required, Validators.pattern('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,6}')])],
      'leadMessage': ['', Validators.compose([Validators.required])]
    });

    this.contactEmail = <Email>{};
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }
  validateControl(control_name: string, validator_name: string) {
    return this.contactForm.controls[control_name].hasError(validator_name) && (!this.contactForm.controls[control_name].pristine || this.contactForm.controls[control_name].touched);
  }

  onSubmit(contactEmail) {
    this.isSubmitted = true;
    if (this.contactForm.valid) {
      this.contactEmail.leadPhone = this.countryPrefix.mobilePrefix + this.contactEmail.leadPhone;
      this.contactEmail.destinationEmail = this._ENVIRONMENT_.EmailHello;
            this.contactEmail.destinationBBCEmail = this._ENVIRONMENT_.EmailBBC;
      this.loginService.sendContactEmail(this.contactEmail).subscribe(() => {
        this.dialogRef.close();
        this._analyticsHelper.buildContactAnalyticsData();
        this.dialogRef.afterClosed().subscribe(()=>{
          this.contactForm.reset();
          this.isSubmitted = false;
          const dialogRefSuccess = this.dialog.open(SuccesspopupComponent, { panelClass: 'loginLayoutResets' });
        })
      });
    }
  }

  navigateTo(e: MouseEvent, page: string): void {
    e.preventDefault();
    this.router.navigate(['/' + page]);
    this.close();
  }
}
