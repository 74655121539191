import { Component, OnInit } from "@angular/core";
import { FaqsService } from "../../shared/_services/faqs.service";
import { DomSanitizer } from "@angular/platform-browser";
import * as $ from 'jquery';
import { ContextBase } from "../../shared/_helpers/_contextBase.helper";
import { Router } from "@angular/router";
import { DinamicMetaTags } from "../../shared/_helpers/_dinamicMetaTags";
import { TranslateService } from "@ngx-translate/core";
import { ContactPopUp } from "../../shared/_helpers/_contactpopup.helper";

@Component({
    selector: '<faqs-component>',
    templateUrl: 'faqs.component.html',
    styleUrls: ['./faqs.component.scss'],
})

export class FaqsComponent implements OnInit {

    public faqsContent: any;
    public lang: string;
    public curr: string;

    constructor(private _faqsService: FaqsService,
        sanitizer: DomSanitizer,
        private contexto: ContextBase,
        private dinamicMetaTags: DinamicMetaTags,
        private translate: TranslateService,
        public _contactPopUp: ContactPopUp,
        private router: Router) {
        this._faqsService.get().then((data) => {
            this.faqsContent = sanitizer.bypassSecurityTrustHtml(data);
        });

        this.curr = this.contexto.currency;
        this.lang = this.contexto.language;

        this.dinamicMetaTags.initFAQSMetaTag();

    }

    ngOnInit() {
        $('.headerMain__navLi--action').addClass('faqs');
        $.noConflict();
        setTimeout(() => {
            $('.faqsSectionContent__accordion-header').each((index, ele: Element) => {
                let $this = $(ele);
                $this.click(() => {
                    $('.faqsSectionContent__accordion-content.active').removeClass('active');
                    ($this.next().hasClass('active')) ? $this.next().removeClass('active') : $this.next().addClass('active');
                })
            });

            $('.baluwo_home').click(() => {
                this.router.navigate(['/home']);
            })
            $('.baluwo_home-login').click(() => {
                this.router.navigate(['/home', { login: true }]);
            })
            $('.baluwo_about').click(() => {
                this.router.navigate(['/about-us']);
            })
            $('.baluwo-contact').click(() => {
                this._contactPopUp.showContactPopup();
            })

            $('.goToFaqs').click(() => {
                let ele: number = $('.header').height();
                $('html, body').animate({
                    scrollTop: $('#faqs').offset().top - ele,
                    duration: 1200
                })
            })
        }, 200)

    }
}