import { Injectable } from "@angular/core";
import { PromotionService } from "../../core/services/promotion.service";
import { TransactionServiceV1 } from "../../core/services/transactionv1.service";
import { currentBuyer } from "../../globals";
import { ContextBase } from "./_contextBase.helper";

@Injectable()
export class BannerHelper {
    private promoLength: number = 0; 
    public firsTimeBuyer: boolean = true;
    constructor(public contexto: ContextBase, 
        private transactionService: TransactionServiceV1,
        private promotionService: PromotionService) { 
        console.log(this.promoLength)
    
        this.isFirtTimeBuyer()
    }

    getPromoValid(promo) {
        const dateDay = new Date().getDate();
        const dateMonth = new Date().getMonth() + 1;
        if (promo.month == dateMonth && dateDay >= promo.startDate && dateDay <= promo.endDate) {
            return true;
        } else {
            return false;
        }
    }

    getRamadanPromoValid(promo) {
        const dateDay = new Date().getDate();
        const dateMonth = new Date().getMonth() + 1;
         // yyyy/mm/dd
        
        if (new Date() <= new Date('2022-05-03')) {
            return true;
        } else {
            return false;
        }
    }

    isFirtTimeBuyer(){
        console.log('3 checking')
        if(localStorage.getItem(currentBuyer) !== null){
            this.transactionService.getMyTransactions(1).subscribe(res => {
                if (res.length > 0) {
                  this.firsTimeBuyer = false;
                  return false;
                } else {
                  this.firsTimeBuyer = true;
                  return true;
                }
              }, error => {
                this.firsTimeBuyer = false;
                return false;
              })
        }else{
            this.firsTimeBuyer = true;
            return true;
        }
    }

    languageBannerCurrencyActive(promo: any) {
        let d = promo.promo;
        if (this.getPromoValid(promo)) {
            switch (this.contexto.currency.toLowerCase()) {
                case 'sek':
                    return `./assets/images/promotions/${d}/${d}-sek.png`;
                case 'usd':
                    return `./assets/images/promotions/${d}/${d}-usd.png`;
                case 'gbp':
                    return `./assets/images/promotions/${d}/${d}-gbp.png`;
                default:
                    return `./assets/images/promotions/${d}/${d}-` + this.contexto.language.toLowerCase() + '.png'
            }
        } else {
            let campaign = 'discount';
            switch (this.contexto.currency.toLowerCase()) {
                case 'sek':
                    return `./assets/images/promotions/${campaign}/${campaign}-sek.png`;
                case 'usd':
                    return `./assets/images/promotions/${campaign}/${campaign}-usd.png`;
                case 'gbp':
                    return `./assets/images/promotions/${campaign}/${campaign}-gbp.png`;
                default:
                    return `./assets/images/promotions/${campaign}/${campaign}-` + this.contexto.language.toLowerCase() + '.png'
            }
        }
    }

    languageBannerActive(promo: any) {
        let d = promo.promo;
        if (this.getPromoValid(promo)) {
            switch (this.contexto.language.toLowerCase()) {
                case 'fr':
                    return `./assets/images/promotions/${d}/${d}-fr.jpg`;
                case 'es':
                    return `./assets/images/promotions/${d}/${d}-es.jpg`;
                default:
                    return `./assets/images/promotions/${d}/${d}-en.jpg`;
            }
        } else {
            let campaign = 'mgm';
            switch (this.contexto.currency.toLowerCase()) {
                case 'sek':
                    return `./assets/images/${campaign}/SEK.jpg`;
                case 'usd':
                    return `./assets/images/${campaign}/USD.jpg`;
                case 'gbp':
                    return `./assets/images/${campaign}/GBP.jpg`;
                default:
                    return `./assets/images/${campaign}/` + this.contexto.language.toUpperCase() + '.jpg'
            }
        }
    }

    currencyBannerFunctionality(campaign: any) {
        switch (this.contexto.currency.toLowerCase()) {
            case 'sek':
                return `./assets/images/promotions/${campaign}/${campaign}-sek.jpg`;
            case 'usd':
                return `./assets/images/promotions/${campaign}/${campaign}-usd.jpg`;
            case 'gbp':
                return `./assets/images/promotions/${campaign}/${campaign}-gbp.jpg`;
            default:
                return `./assets/images/promotions/${campaign}/${campaign}-` + this.contexto.language.toLowerCase() + '.jpg'
        }

    }

    currencyBannerFunctionalityDate(campaign: any) {
        const d = campaign.promo;
        if (this.getPromoValid(campaign)) {
            switch (this.contexto.currency.toLowerCase()) {
                case 'sek':
                    return `./assets/images/promotions/${d}/${d}-sek.png`;
                case 'usd':
                    return `./assets/images/promotions/${d}/${d}-usd.png`;
                case 'gbp':
                    return `./assets/images/promotions/${d}/${d}-gbp.png`;
                default:
                    return `./assets/images/promotions/${d}/${d}-` + this.contexto.language.toLowerCase() + '.png'
            }
        }
    }

    currencyBannerFunctionalityDateLg(campaign: any) {
        const d = campaign.promo;
        if (this.getRamadanPromoValid(campaign)) {
            switch (this.contexto.currency.toLowerCase()) {
                case 'sek':
                    return `./assets/images/promotions/${d}/${d}-lg-sek.png`;
                case 'usd':
                    return `./assets/images/promotions/${d}/${d}-lg-usd.png`;
                case 'gbp':
                    return `./assets/images/promotions/${d}/${d}-lg-gbp.png`;
                default:
                    return `./assets/images/promotions/${d}/${d}-lg-` + this.contexto.language.toLowerCase() + '.png'
            }
        }
    }

    currencyBannerFunctionalityDateDiscount(promo: string) {
        const d = promo;
        if (this.firsTimeBuyer) {
            switch (this.contexto.currency.toLowerCase()) {
                case 'sek':
                    return `./assets/images/promotions/${d}/${d}-sek.png`;
                case 'usd':
                    return `./assets/images/promotions/${d}/${d}-usd.png`;
                case 'gbp':
                    return `./assets/images/promotions/${d}/${d}-gbp.png`;
                default:
                    return `./assets/images/promotions/${d}/${d}-` + this.contexto.language.toLowerCase() + '.png'
            }
        }
    }

    languageBannerActiveStatic(promo) {
        const d = promo.promo;
        if (this.getPromoValid(promo)) {
            switch (this.contexto.language.toLowerCase()) {
                case 'fr':
                    return `./assets/images/promotions/${d}/${d}-fr.jpg`;
                case 'es':
                    return `./assets/images/promotions/${d}/${d}-es.jpg`;
                default:
                    return `./assets/images/promotions/${d}/${d}-en.jpg`;
            }
        }
    }

    currencyBannerFunctionalityMGM() {
        switch (this.contexto.currency.toLowerCase()) {
            case 'sek':
                return './assets/images/mgm/SEK.jpg';
            case 'usd':
                return './assets/images/mgm/USD.jpg'
            case 'gbp':
                return './assets/images/mgm/GBP.jpg'
            default:
                return './assets/images/mgm/' + this.contexto.language.toUpperCase() + '.jpg'
        }
    }

    currencyBannerFunctionalityDiscount(d) {
        switch (this.contexto.language.toLowerCase()) {
            case 'fr':
                return `./assets/images/promotions/${d}/${d}-fr.jpg`;
            case 'es':
                return `./assets/images/promotions/${d}/${d}-es.jpg`;
            default:
                return `./assets/images/promotions/${d}/${d}-en.jpg`;
        }
    }

    currencyBannerFunctionalityDiscount2(d) {
        switch (this.contexto.language.toLowerCase()) {
            case 'fr':
                return `./assets/images/promotions/${d}/${d}-1-fr.jpg`;
            case 'es':
                return `./assets/images/promotions/${d}/${d}-1-es.jpg`;
            default:
                return `./assets/images/promotions/${d}/${d}-1-en.jpg`;
        }
    }

    goToStoreBanner() {
        let ua = navigator.userAgent;
        if (/Android/i.test(ua)) {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.baluwo.userapp&referrer=04-8007'
        } else if (/iphone|ipad/i.test(ua)) {
            this.promotionService.sendReferralCodeIOS('04-8007').subscribe(res => {
                console.log(res);
            });
            window.location.href = 'https://apps.apple.com/es/app/baluwo/id1490261153?referrer=04-8007'
        }
    }
}