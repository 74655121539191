import { Component, Input } from "@angular/core";
import { CarouselHomePage } from "../../../shared/_helpers/_carousel.helper";

@Component({
    selector: 'app-home-provider-component',
    templateUrl: './home_providerSection.component.html',
    styleUrls: ['../home.component.scss']
})

export class HomeProviderSectionComponent {
    @Input() isMobile: boolean;
    constructor(public carouselHomePage: CarouselHomePage) { }
    ngAfterViewInit() {
        this.carouselHomePage.initCarousel();
    }
}