import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { currentBuyer } from "../../../globals";
import { HomeUtilsHelper } from "../../../shared/_helpers/_homeUtils.helper";
import { LocationSectorStoreHelper } from "../../../shared/_helpers/_locationSectorStore.helper";
import { ScrollerTargetHelper } from "../../../shared/_helpers/_scrollerTarget.helper";
import { Buyer, CatalogSelection, Order } from "../../../shared/_objects";
import * as $ from 'jquery';
import { GenericPopUpComponent } from "../../genericpopup/genericpopup.component";
import { MatDialog } from "@angular/material";
import { PromotionHelper } from "../../../shared/_helpers/_promotions.helper";

@Component({
    selector: 'app-home-stores-component',
    templateUrl: 'home_stores.component.html',
    styleUrls: ['./home_store.component.scss','../home.component.scss', '../../../../assets/css/bootstrap-grid.min.css']
})

export class HomeStoresComponent {
    public allowPayment: boolean;
    public openVoucherAdded: boolean;
    public openVoucherTotal: any;
    public buyer: Buyer;
    public voucherAmountAdded: boolean;
    private contentPlaceholderElRef: ElementRef;
    public voucherAmountCalculated: number;
    public currentStoreAllProducts: any;
    public currentStoreProducts: any;

    public _ENVIRONMENT_ = environment;

    @Input() premobile: string;
    @Input() order: Order;
    @Input() buttonBuy: boolean;
    @Input() sourceCurrency: string;
    @Input() catalog: any[];
    @Input() isMobile: boolean;
    @Output() addProductToCartEmit: EventEmitter<Order> = new EventEmitter<Order>();
    @Output() buyMoreEmit: EventEmitter<any> = new EventEmitter<any>();
    @Output() requestProductOnDemandEmit: EventEmitter<any> = new EventEmitter<any>();
    @Output() goToCartlandingEmit: EventEmitter<any> = new EventEmitter<any>();
    @Output() removeProductCartAndQuantityVoucherEmit: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('contentPlaceholder') set contentPlaceholder(content: ElementRef) {
        this.contentPlaceholderElRef = content;
    }

    constructor(public locationSectorStoreHelper: LocationSectorStoreHelper,
        public _homeUtilsHelper: HomeUtilsHelper,
        public dialog: MatDialog,
        public _promotionHelper: PromotionHelper,
        private changeDetectorRef: ChangeDetectorRef,
        public _scrollerTargetHelper: ScrollerTargetHelper) {
        this.currentStoreAllProducts = this.catalog;
    }

    ngOnInit() {
        setTimeout(() => {
            this.currentStoreAllProducts = this.catalog;
        }, 500)

        $(window).scroll(()=>{
            $("#theFixed").css("margin-top",Math.max(-250,0-$(this).scrollTop()));
        });
    }

    ngOnChanges(changes: SimpleChanges){
        if(changes.catalog) this.currentStoreAllProducts = changes.catalog.currentValue;
        $('.category-menu_item').removeClass('selected');
        $('.item-0').addClass('selected');
    }

    filterBy(category) {
        $('.category-menu_item').removeClass('selected');
        $('.item-' + category).addClass('selected');
        this.currentStoreAllProducts = this.locationSectorStoreHelper.currentStore.catalogSelection.filter(x => {
            if (x.catalog.product.category !== undefined) {
                return x.catalog.product.category.id == category
            }
        });
    }

    getAllProducts() {
        $('.category-menu_item').removeClass('selected');
        $('.item-0').addClass('selected');
        this.currentStoreAllProducts = this.locationSectorStoreHelper.currentStore.catalogSelection
    }

    substractProduct(catalogSelection: any, index?: number, storeOrder?: Order) {
        if (catalogSelection.quantity > 0) {
            catalogSelection.quantity--;
            if (catalogSelection.quantity == 0) {
                $("#quant__" + index).addClass('hidden');
                $("#add__" + index).removeClass('hidden');
            }
        } else if (catalogSelection.quantity == 0) {
            catalogSelection.quantity = 0;
        }
        this.addProductToCartEmit.emit(storeOrder)
    }

    addOpenVoucher(catalogSelection: CatalogSelection, evt: any) {
        catalogSelection.quantity = evt.target.value;
        this.openVoucherTotal = evt.target.value;
        this.openVoucherAdded = true;
        if (evt.target.value !== '') {
            $("#add__" + catalogSelection.catalog.id).removeAttr('disabled')
            $("#add__" + catalogSelection.catalog.id).removeClass('disabled')
        } else {
            $("#add__" + catalogSelection.catalog.id).attr('disabled', 'disabled');
            $("#add__" + catalogSelection.catalog.id).addClass('disabled');
        }
    }

    addInputValueProduct(catalogSelection: CatalogSelection, index?: number, storeOrder?: Order) {
        if (catalogSelection.quantity <= 0) {
            catalogSelection.quantity = 0;
            $("#quant__" + index).addClass('hidden');
            $("#add__" + index).removeClass('hidden');
        }
        this.addProductToCartEmit.emit(storeOrder)
    }

    addProduct(catalogSelection: CatalogSelection, storeOrder?: Order) {
        catalogSelection.quantity++;
        this.addProductToCartEmit.emit(storeOrder)
    }

    addProductCartAndQuantityVoucher(catalogSelection: any, storeOrder?: Order) {
        this.changeDetectorRef.detectChanges();
        this._homeUtilsHelper.validatePhoneNumber(this.premobile, this.locationSectorStoreHelper.currentCountry.mobilePrefix);

        if (!this._homeUtilsHelper.isValidBeneficiaryMobile) {
            this._scrollerTargetHelper.scrollToTarget("beneficiary");
            this.allowPayment = false;
            this.order.srcTotal = null;
            this.voucherAmountAdded = false;
            return false;
        } else {
            this.voucherAmountAdded = true;
            $("#voucherinput__" + catalogSelection.catalog.id).attr('disabled', 'disabled');
            $("#add__" + catalogSelection.catalog.id).addClass('hidden');
            $("#remove__" + catalogSelection.catalog.id).removeClass('hidden');
            this.addProductToCartEmit.emit(storeOrder);
        }
    }

    removeProductCartAndQuantityVoucher(catalogSelection: any, storeOrder?: Order) {
        // this.contentPlaceholderElRef.nativeElement.reset();
        $("#voucherinput__" + catalogSelection.catalog.id).val(' ')
        catalogSelection.quantity = 0;
        this.openVoucherTotal = null;
        this.addProductToCartEmit.emit(storeOrder);
        this.voucherAmountAdded = false;
        $("#voucherinput__" + catalogSelection.catalog.id).removeAttr('disabled');
        $("#add__" + catalogSelection.catalog.id).attr('disabled', 'disabled');
        $("#add__" + catalogSelection.catalog.id).removeClass('hidden');
        $("#remove__" + catalogSelection.catalog.id).addClass('hidden');
    }

    addProductCartAndQuantity(catalogSelection: any, quant?: number, storeOrder?: Order) {
        this._homeUtilsHelper.validatePhoneNumber(this.premobile, this.locationSectorStoreHelper.currentCountry.mobilePrefix);
        if (!this._homeUtilsHelper.isValidBeneficiaryMobile) {
            this._scrollerTargetHelper.scrollToTarget("beneficiary");
            this.allowPayment = false;
            return false;
        } else {
            this.allowPayment = true;
            catalogSelection.quantity++;
            if ($("#quant__" + quant).hasClass('hidden')) {
                $("#quant__" + quant).removeClass('hidden');
                $("#add__" + quant).addClass('hidden');
            }
            this.addProductToCartEmit.emit(storeOrder)
        }
    }

    openVoucherPopUpAdvert(storeOrder) {
        const dialogRef = this.dialog.open(GenericPopUpComponent, {
            data: { countryId: this.locationSectorStoreHelper.currentCountry.id }
        })

        dialogRef.componentInstance.accepted.subscribe(res => {
            this.openVoucherAdded = false;
            this.addProductToCartEmit.emit(storeOrder);
        })

        dialogRef.afterClosed().subscribe(res => { });
    }
}