import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { MaterilaBaluwoModule } from "./materialbaluwo.module";
import { HeaderComponent } from "./pages/section/header/header.component";
import { CartlandingComponent } from "./pages/cartlanding/cartlanding.component";
import { BaluwoCardComponent } from "./pages/baluwocard/baluwocard.component";
import { MyHttpInterceptor } from "./core/interceptors/httpInterceptor";
import { ToastrModule,ToastContainerModule } from 'ngx-toastr';
import { CLGiftCardComponent } from "./pages/cartlanding/cl-giftcard/cl-giftcard.component";
import { CLTitlesComponent } from "./pages/cartlanding/cl-titles/cl-titles.component";
import { CLAirtimeComponent } from "./pages/cartlanding/cl-airtime/cl-airtime.component";
import { CLAirdataComponent } from "./pages/cartlanding/cl-airdata/cl-airdata.component";
import { CLNationalRechargeComponent } from "./pages/cartlanding/cl-national/cl-national.component";
import { CLBillComponent } from "./pages/cartlanding/cl-bill/cl-bill.component";
import { CLCashpowerComponent } from "./pages/cartlanding/cl-cashpower/cl-cashpower.compnent";
import { CLStoreComponent } from "./pages/cartlanding/cl-store/cl-store.component";
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
        ReactiveFormsModule,
        MaterilaBaluwoModule,
		HttpClientModule,
		ToastrModule.forRoot({
			positionClass :"toast-top-full-width"
		}),
		ToastContainerModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
	],
	declarations: [
		HeaderComponent,
		CartlandingComponent,
		BaluwoCardComponent,
		CLGiftCardComponent,
		CLTitlesComponent,
		CLAirtimeComponent,
		CLAirdataComponent,
		CLNationalRechargeComponent,
		CLBillComponent,
		CLCashpowerComponent,
		CLStoreComponent
		
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: MyHttpInterceptor, multi: true },
	],
	entryComponents: [
	],
	exports: [
        CommonModule,
		FormsModule,
        ReactiveFormsModule,
        MaterilaBaluwoModule,
        HttpClientModule,
        TranslateModule,
		HeaderComponent,
		CartlandingComponent,
		BaluwoCardComponent,
		CLGiftCardComponent,
		CLTitlesComponent,
		CLAirtimeComponent,
		CLAirdataComponent,
		CLNationalRechargeComponent,
		CLBillComponent,
		CLCashpowerComponent,
		CLStoreComponent
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})

export class SharedModule { }