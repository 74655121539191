import { CurrencyExchange } from '../../shared/_objects/currexchange';
import { Transaction } from '../../shared/_objects/transaction';
import { AmountService } from '../../core/services/amount.service';
import { STORE_ORDER_TYPE, landingCampaign, currentBuyer, TransactionId, TOPUP_ORDER_TYPE, CASHPOWER_ORDER_TYPE, CYBERPACK_PAYMENT_ID, PAYPAL_PAYMENT_ID, SOFORT_PAYMENT_ID, BIZUM_PAYMENT_ID } from '../../globals';
import { LoginmodalComponent } from '../loginmodal/loginmodal.component';
import { PaymentService } from '../../core/payment/payment.service';
import { Component, OnInit, Input, Optional, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Order, StoreOrderLine, StoreOrderUniq } from '../../shared/_objects/order';
import { Router } from '@angular/router';
import { ContextBase } from '../../shared/_helpers/_contextBase.helper';
import { DinamicMetaTags } from '../../shared/_helpers/_dinamicMetaTags';
import * as $ from 'jquery';
import { ErrorPopUpComponent } from '../errorpopup/errorpopup.component';
import { BackToStore } from '../../shared/_services/backtostore.service';
import { Buyer } from '../../shared/_objects/buyer';
import { PromotionHelper } from '../../shared/_helpers/_promotions.helper';
import { ServerErrorHelper } from '../../shared/_helpers/servererrors.helper';
import { TransactionServiceV1 } from '../../core/services/transactionv1.service';
import { LocationSectorStoreHelper } from '../../shared/_helpers/_locationSectorStore.helper';
import * as __ from 'lodash';
import { TransactionHelper } from '../../shared/_helpers/_transactionHelper.helper';
import { ValidityOrderHelper } from '../../shared/_helpers/_validityOrder.helper';
import { HomeUtilsHelper } from '../../shared/_helpers/_homeUtils.helper';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/pairwise';
import { CalculatedEchangedCartlanding } from '../../shared/_objects/calculate';
import { PaymentHelper } from '../../shared/_helpers/_payment.helper';
import { PaymentHelperService } from '../../shared/_services/paymenthelper.service';
import { AnalyticsHelper } from '../../shared/_helpers/_analytics.helper';
import { SupplierService } from '../../core/services/suppliers.service';
import { LoginService } from '../../core/login-service/login.service';
import { AdvertPopUpComponent } from '../advertpopup/advertpopup.component';
import { FraudPopUpComponent } from '../fraudpopup/fraudpopup.component';
import { FacebookPixelService } from '../../shared/_services/facebook-pixel.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { LoginmodalStepReferenceComponent } from '../loginmodal/steps/modal-step_refer.component';
@Component({
  selector: 'app-cartlanding',
  templateUrl: './cartlanding.component.html',
  styleUrls: ['./cartlanding.component.scss', '../../../assets/css/bootstrap-grid.min.css']
})


export class CartlandingComponent implements OnInit, OnDestroy {
  // use transaction history to show in my account transaction details
  @Input() transactionHistory: Transaction;

  transaction: any;
  topupOrder: string = TOPUP_ORDER_TYPE;
  cashpowerOrder: string = CASHPOWER_ORDER_TYPE;
  storeOrder: string = STORE_ORDER_TYPE;
  cyberPackId: number = CYBERPACK_PAYMENT_ID;
  paypalId: number = PAYPAL_PAYMENT_ID;
  sofortPaymentId: number = SOFORT_PAYMENT_ID;
  bizumId: number = BIZUM_PAYMENT_ID;
  amounts: number[] = [];
  totalAmount: number;
  paymentId: number = 1;
  isCampaign: boolean = landingCampaign;
  isLogged = false;
  exchangeSource: CurrencyExchange;
  exchangeDest: CurrencyExchange;
  orderSrcTotal: number;
  isValidAmount = true;
  landingCurrency: string;
  landingSourceCountry: string;
  myControl: FormControl = new FormControl();
  isMini = false;
  cartClass = 'purchaseOrder';
  language = 'ES';
  currency = 'EUR';
  updateHeader = 1;
  showManualField: boolean = false;
  miniCart = new EventEmitter();
  correctAmount: boolean = true;
  errorMesage: string;
  manualAmount: boolean = false;
  manualAmountCash: boolean = false;
  buyer: Buyer;
  uniqOrder: StoreOrderUniq;
  transactionIdUniq: any;
  submodelTotal: number;
  isNotLogged: boolean;
  promoBenef: string;
  promotionV1: any;
  previousUrl: string;
  minValid: number = 10;
  amountsExchange: Array<CalculatedEchangedCartlanding> = [];
  hasBuyerCard: boolean;
  hasBaluwoWalletCash: boolean;
  hasWallet: boolean;
  buyerInfo: Buyer;
  buyerCardAmount: number;
  buyerDiscount: number;
  checked: boolean;
  buyerCardPaymentFull: boolean = false;
  transactionId: string;
  validationCode: boolean;
  onSuccessLogin: boolean;
  sellingCountries: any;
  cleanBtn: boolean = true;
  showMessage: boolean = false;
  isFraudAlert: boolean = false;
  firstTimeBuyer: boolean = false;

  constructor(private amountService: AmountService,
    private paymentService: PaymentService,
    private dialog: MatDialog,
    public contexto: ContextBase,
    private dinamicMetaTags: DinamicMetaTags,
    public locationSectorStoreHelper: LocationSectorStoreHelper,
    public _promotionHelper: PromotionHelper,
    public _transactionHelper: TransactionHelper,
    public _validityOrderHelper: ValidityOrderHelper,
    public _homeUtilsHelper: HomeUtilsHelper,
    public _paymentHelper: PaymentHelper,
    public _paymentHelperService: PaymentHelperService,
    private _analyticsHelper: AnalyticsHelper,
    public facebookPixelService: FacebookPixelService,
    private supplierService: SupplierService,
    public backToStore: BackToStore,
    private router: Router,
    private transactionServiceV1: TransactionServiceV1,
    private loginService: LoginService,
    public serverErrorHelper: ServerErrorHelper,
    private spinnerService: Ng4LoadingSpinnerService,
    @Optional() public dialogRef: MatDialogRef<CartlandingComponent>,
    @Optional() public dialogErrorRef: MatDialogRef<ErrorPopUpComponent>) {
    this.dinamicMetaTags.initCartLandingMetaTag();
    this._paymentHelperService.updatePayment.subscribe(val => {
      this.buyerCardPaymentFull = !val;
      if (this.buyerCardPaymentFull) this.paymentId = null;
    })

    this._paymentHelperService.currentPaymentMethod.subscribe(res => {
      if (!res) {
        this.paymentId = null;
      }
    })

    this.locationSectorStoreHelper.getSellingCountries();
  }

  filter(val: string): number[] {
    return this.amounts.filter(option =>
      option.toString().toLowerCase().indexOf(val.toLowerCase()) === 0);
  }

  successLoginFromModal(event) {
    this.onSuccessLogin = event;
    if (this.onSuccessLogin) {
      this.checkBuyerBaluwoCard();
      this.checkDiscounts();
      this.initPaymentInfoProcess();
    }
  }

  ngOnInit() {
    this.transactionIdUniq = parseInt(localStorage.getItem(TransactionId));
    this.buyerInfo = JSON.parse(localStorage.getItem(currentBuyer));
    if (this.buyerInfo) this.checkBuyerBaluwoCard();
    if (this.transactionHistory == undefined) {
      if (localStorage.getItem(currentBuyer) != null) {
        this.buyerInfo = JSON.parse(localStorage.getItem(currentBuyer));
        this.checkBuyerCard();
        this.getTransactionBuyerLogged();
      } else {
        this.getTransactionBuyerNotLogged();
      }
    } else {
      this.transaction = this.transactionHistory;
      this.historyOrderAmountCalculation();
    }
    this.amounts = this.amountService.getAmountList();
    this.currency = this.contexto.currency.toUpperCase();
    this.language = this.contexto.language.toUpperCase();
  }

  checkBuyerCard() {
    if (this.buyerInfo.wallet !== null) {
      this.hasBuyerCard = true;
      (this.buyerInfo.wallet.aggregated !== 0) ? this.hasBaluwoWalletCash = true : this.hasBaluwoWalletCash = false;
    } else {
      this.hasBuyerCard = false;
    }
  }

  getTransactionBuyerLogged() {
    this.transactionServiceV1.getTransactionLogged(this.contexto.transMessage).subscribe((data) => {
      this.transaction = this.updateInformationOnTransactionObject(data);
      this._transactionHelper.calculateCartTotal(this.transaction);
      this.checkPromotionsLogged();
      this.transaction = this.transactionHistory ? this.transactionHistory : this.updateInformationOnTransactionObject(data);
      this.sortOrderLines();
      this.buildData();
    }, error => {
      this.getErrorMessage(error)
    })
  }

  getTransactionBuyerNotLogged() {
    this.transactionServiceV1.getTransaction(this.transactionIdUniq, this.contexto.transMessage).subscribe((data) => {
      this.transaction = this.updateInformationOnTransactionObject(data);
      // this.transaction = data;
      this.checkPossibleFraud(this.transaction);
      this._transactionHelper.calculateCartTotal(this.transaction);
      this.sortOrderLines();
      this.checkPromotions();
      this.buildData();
    })
  }

  checkPossibleFraud(transaction) {
    this.isFraudAlert = false;
    this.firstTimeBuyer = true;
    if (this.buyer) {
      this.transactionServiceV1.getMyTransactions(1).subscribe(res => {
        if (res.length > 0) {
          this.firstTimeBuyer = false;
        } else {
          this.firstTimeBuyer = true;
        }
      }, error => {
        this.firstTimeBuyer = true;
      })
    }
    transaction.orders.forEach(x => {
      if (this.firstTimeBuyer && x.type === 'store' && x.beneficiary.country.id === 'GM' && x.store.location.name === 'Brikama') {
        this.isFraudAlert = true;
      }
    })
  }

  updateInformationOnTransactionObject(data) {
    if (data.orders) {
      this.checkPossibleFraud(data);
      data.orders.forEach(d => {
        if (d.beneficiary) {
          const countryName = d.beneficiary.country.name;
          if (countryName) {
            d.beneficiary.countryName = countryName;
          } else {
            d.beneficiary.countryName = 'not available'
          }
        }
        if (d.type !== 'store' || d.type !== 'bill' || d.type !== 'national') {
          this.parseNumberSupliers(d);
        }
      })
      return data;
    } else {
      this.router.navigate(['/home']);
    }
  }

  checkedOptionEmit(evt) {
    this.checked = evt;
  }

  checkBuyerBaluwoCard() {
    this.buyerInfo = JSON.parse(localStorage.getItem(currentBuyer));
    if (this.buyerInfo.card !== null || this.buyerInfo.wallet !== null) {
      this.hasBuyerCard = true;
      this.hasWallet = true;
      (this.buyerInfo.wallet.aggregated !== 0) ? this.hasBaluwoWalletCash = true : this.hasBaluwoWalletCash = false;
    } else if (this.buyerInfo.wallet !== null) {
      this.hasWallet = true;
      (this.buyerInfo.wallet.aggregated !== 0) ? this.hasBaluwoWalletCash = true : this.hasBaluwoWalletCash = false;
    } else {
      this.hasBuyerCard = false;
    }
  }

  checkDiscounts() {
    if (this.hasBuyerCard || this.hasWallet) {
      this.transactionServiceV1.checkTransactionDiscounts().subscribe((res) => {
        this.buyerDiscount = res;
        this.totalAmount -= this.buyerDiscount;
        this.totalAmount = this.checkDecimalsOnAmount(this.totalAmount);
      })
    } else {
      console.log('NO DISCOUNT NO BUYER CARD');
    }
  }

  checkDecimalsOnAmount(n) {
    let result = ((n - Math.floor(n)) !== 0);
    if (result) {
      return parseFloat(n.toFixed(2))
    } else {
      return n
    }
  }

  checkPromotions() {
    this.transactionServiceV1.checkTransactionPromotions(this.transactionIdUniq).subscribe((res) => {
      this.promotionV1 = res;
    })
  }

  checkPromotionsLogged() {
    this.transactionServiceV1.checkTransactionPromotionsLogged().subscribe((res) => {
      this.promotionV1 = res;
    })
  }

  buildData() {
    if (this.transaction && this.transaction.currency.id != undefined) {
      this.landingCurrency = this.transaction.currency.id;
    }

    if (!this.transactionHistory) {
      this.recalculeTotalAmount();
    } else {
      this.historyOrderAmountCalculation();
    }

    if (null != localStorage.getItem(currentBuyer) && !this.transactionHistory) {
      this.initPaymentInfoProcess();
    } else {
      this.isNotLogged = true;
      setTimeout(() => {
        $("#paymentOptions").hide();
      }, 200)
    }
  }

  initPaymentInfoProcess() {
    this.isLogged = true;
    this._paymentHelper.getPaymentInfoCyberpack();
    this._paymentHelper.getPaymentInfoPayPal();
    this._paymentHelper.getPaymentInfoSofort();
    this._paymentHelper.getPaymentInfoBizum();
    this.isNotLogged = false;
    setTimeout(() => {
      if (this.hasBuyerCard && !this._paymentHelper.sisalWallet) {
        this.paymentId = 4;
      } else if (this._paymentHelper.sisalWallet) {
        this.paymentId = 5;
      } else {
        this.paymentId = 1
      };
      $("#paymentOptions").show();
      this._analyticsHelper.buildCartLandingAnalyticsData(this.transaction);
      this.facebookPixelService.initiateCheckout(this.transaction,this.totalAmount);

      this.showMessage = false;
    }, 500)
  }

  ngOnDestroy() {
  }

  removeOrderEmit(evt){
    this.removeOrderfromTransaction(evt.id)
  }

  checkSelectedPayment(payid) {
    this.paymentId = payid;
    // $('*[class*="payment-selected-"]').removeClass('selected');
    // $('.payment-selected-' + payid).addClass('selected');
    this.checkSofortMessage();
  }

  checkSofortMessage() {
    if (this.paymentId == 2) {
      let storeOrderAvailable = [];
      storeOrderAvailable = this.transaction.orders.filter(order => order.type === 'store');
      if (storeOrderAvailable.length !== 0) {
        // this.showMessage = true;
      }
    } else {
      this.showMessage = false;
    }

  }

  parseNumberSupliers(order) {
    if (order.beneficiary) {
      this.supplierService.getSuppliersByCountry(order.beneficiary.country.id).subscribe(res => {
        res.forEach(f => {
          if (order.type !== 'cashpower') {
            if (order.beneficiary.mobile.match(f.carrier.regExp)) {
              order.carrier = f.carrier.name.toLowerCase().replace(/ /g, '');
            }
          } else {
            if (order.meterId.match(f.carrier.regExp)) {
              order.carrier = f.carrier.name.trim().toLowerCase().replace(/ /g, '');
            }
          }
        })
      })
    }
  }

  sortOrderLines() {
    if (this.transaction && this.transaction.orders !== undefined) {
      this.transaction.orders.forEach((d) => {
        if (d.type === this.storeOrder) {
          d.lines.sort(this._homeUtilsHelper.sortById);
        }
      })
    } else {
      this.router.navigate(['/home']);
    }
  }

  sortProduct(linea: StoreOrderLine, lineb: StoreOrderLine) {
    return linea.catalog.product.id - lineb.catalog.product.id;
  }


  historyOrderAmountCalculation() {
    this.totalAmount = 0;
    this.transactionHistory.orders.forEach(o => this.totalAmount += parseFloat(o.purchaseTotal.toString()));
  }

  recalculeTotalAmount() {
    this.totalAmount = 0;
    let i = 0;
    this.transaction.orders.forEach(o => {
      this.totalAmount += o.purchaseTotal;
      i++;
    });
    this.checkDiscounts();
  }

  checkValidityOrder(order, sector, index) {
    this.isValidAmount = this._validityOrderHelper.checkValidityOrderCart(order, sector);
    if (this.isValidAmount) {
      $('#error-box-' + index).removeClass('visible');
      this.buildAmountsOrder(order);
    } else {
      $('#error-box-' + index).addClass('visible');
    }
  }

  buildAmountsOrder(order) {
    const exist = this.amounts.filter(x => x === order.purchaseTotal)[0];
    if (typeof exist === 'undefined') {
      this.amounts.push(order.purchaseTotal);
      this.amounts.sort(this._homeUtilsHelper.sortNumber);
      // this.buildAirCashAmounts();
    }
    this.createTransaction(order);
  }

  login() {
    window.scrollTo(0, 0);
    const dialogRef = this.dialog.open(LoginmodalComponent, { panelClass: 'loginLayoutResets' });
    dialogRef.afterClosed().subscribe(() => {
      this.isLogged = null != localStorage.getItem(currentBuyer);
      if (this.isLogged) {
        this.updateHeader++;
        this.checkBuyerBaluwoCard();
        this.checkDiscounts();
        this.initPaymentInfoProcess();
      }
    });
  }

  trackByOrderIndex(index: number, obj: any): any {
    return index;
  }

  createTransaction(order) {
    this.uniqOrder = new StoreOrderUniq;
    this.uniqOrder.beneficiary = { mobile: order.beneficiary.mobile, name: 'C' }
    this.uniqOrder.type = order.type;
    if (this.uniqOrder.type == "store") {
      this.uniqOrder.store = { id: order.store.id }
      this.uniqOrder.lines = [];
      order.lines.forEach((d) => {
        if (d.quantity > 0) {
          this.uniqOrder.lines.push({ catalog: { id: d.catalog.id, price: d.catalog.price }, quantity: d.quantity })
        }
      })
    } else if (this.uniqOrder.type == "airtime") {
      this.uniqOrder.total = order.total;
    } else if (this.uniqOrder.type == "cashpower") {
      this.uniqOrder.meterId = order.meterId;
      this.uniqOrder.total = order.total;
    } else if (this.uniqOrder.type == 'airdata') {
      this.uniqOrder.targetTotal = order.targetTotal;
    }
    this.updateTransaction();
  }

  updateTransaction() {
    if (localStorage.getItem(currentBuyer) != null) {
      this.transactionServiceV1.setTransactionLogged(this.uniqOrder).subscribe((res) => {
        this.transaction = this.updateInformationOnTransactionObject(res);
        this.checkPossibleFraud(this.transaction);
        this._transactionHelper.calculateCartTotal(this.transaction);
        this.sortOrderLines();
        this.getNewTotalAmount(this.transaction);
        this.checkPromotionsLogged();
        this.initPaymentInfoProcess();
        this.correctAmount = true;
      })
    } else {
      this.transactionServiceV1.setTransaction(this.transactionIdUniq, this.uniqOrder).subscribe((res) => {
        this.transaction = this.updateInformationOnTransactionObject(res);
        this.checkPossibleFraud(this.transaction);
        this._transactionHelper.calculateCartTotal(this.transaction);
        this.sortOrderLines();
        this.getNewTotalAmount(this.transaction);
        this.checkPromotions();
      })
    }
  }

  removeOrderfromTransaction(ordId: number) {
    if (localStorage.getItem(currentBuyer) == null) {
      this.transactionServiceV1.deleteTransaction(this.transactionIdUniq, ordId).subscribe((res) => {
        this.transaction = this.updateInformationOnTransactionObject(res);
        this._transactionHelper.calculateCartTotal(this.transaction);
        this.checkTransactionLength(res);
      })
    } else {
      this.transactionServiceV1.deleteTransactionLogged(ordId).subscribe((res) => {
        this.transaction = this.updateInformationOnTransactionObject(res);
        this._transactionHelper.calculateCartTotal(this.transaction);
        this.checkTransactionLength(res);
      })
    }
  }

  checkTransactionLength(transaction) {
    if (!transaction.orders) {
      this._homeUtilsHelper.backToLanding();
    } else {
      if (localStorage.getItem(currentBuyer) != null) {
        this.initPaymentInfoProcess();
      }
      this.getNewTotalAmount(this.transaction);
    }
  }

  getNewTotalAmount(transaction) {
    this.totalAmount = 0;
    transaction.orders.forEach(o => {
      this.totalAmount += o.purchaseTotal;
    });
    this.updateHeader++;
    this.checkDiscounts();
  }

  getErrorMessage(error) {
    console.log(error);
    this.errorMesage = error;
    if (error.headers.get('x-blw-auth') != null) {
      this.buyer = JSON.parse(localStorage.getItem(currentBuyer));
      localStorage.setItem('currentAuth', 'Basic ' + btoa(this.buyer.mobile + ':' + error.headers.get('x-blw-auth')));
      this.updateTransaction();
    } else if (error.status == 401) {
      localStorage.removeItem(currentBuyer);
      this.router.navigate(['/home'])
    } else {
      this.correctAmount = false;
      let errorcode = error
      this.errorMesage = this.serverErrorHelper.getErrorMsg(errorcode.code, errorcode.message);
      const dialogErrorRef = this.dialog.open(ErrorPopUpComponent, {
        panelClass: 'loginLayoutResets',
        data: { error: this.errorMesage }
      });
      dialogErrorRef.afterClosed().subscribe(() => {
        $("#paymentOptions").hide();
        console.log('no payment options')
      });
    }
  }

  closeTransaction(formname: string) {
    event.preventDefault();
    this.continueCloseTransaction(formname);
  }

  continueCloseTransaction(formname: string) {
    this.submitTransactionOk(formname);
  }

  submitTransactionOk(formname: string) {
    localStorage.removeItem(TransactionId);
    this.facebookPixelService.AddPaymentInfo();
    this.transactionServiceV1.closeTransaction().subscribe(() => {
      console.log('transaction closed');
      setTimeout(() => {
        $('#' + formname).submit();
      }, 500)
    })
  }

  buyerCardAmountFullEmit(evt) {
    this.buyerCardAmount = evt;
  }

  buyerCardPaymentFullEmit(evt) {
    this.buyerCardPaymentFull = evt;
    (this.buyerCardPaymentFull) ? this.paymentId = 4 : this.paymentId = null;
  }

  paymentIdEmitterChange(evt) {
    this.paymentId = evt;
  }

  payTransactionBaluwoCard() {
    this.spinnerService.show();
    this.transactionId = localStorage.getItem(TransactionId);
    this.paymentService.setFullPaymentWallet().subscribe((res) => {
      this.spinnerService.hide();
      this.router.navigate(['/thanks'], { queryParams: { transactionId: this.transactionId } });
    }, error => {
      this.spinnerService.hide();
      let errorCode = error;
      errorCode = errorCode.code;
      if (errorCode === 203) {
        this._paymentHelper.sendValidationCode(this.buyerCardAmount);
        this._paymentHelper.validationCode = false;
      } else {
        this.errorMesage = this.serverErrorHelper.getErrorMsg(error.code, error.message);
        const dialogErrorRef = this.dialog.open(ErrorPopUpComponent, {
          panelClass: 'loginLayoutResets',
          data: { error: this.errorMesage }
        });
      }
    })
  }

  showSofortMessage() {
    const storeOrderAvailable = this.transaction.orders.filter(order => order.type === 'store');
    if (storeOrderAvailable.length !== 0) {
      this.showMessage = true;
    }
  }

  fraudPopupOpen() {
    const dialogFraudRef = this.dialog.open(FraudPopUpComponent, {
      panelClass: 'loginLayoutResets'
    });
  }
}
