import { Transaction } from '../../shared/_objects/transaction';
import { currentBuyer, serverBaseUrl } from '../../globals';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { StoreOrderUniq } from '../../shared/_objects/order';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TransactionServiceV1 {
    private baseUrl = serverBaseUrl;
    constructor(private http: HttpClient) { }

    getConditions() {
        const transaction$ = <any>this.http
            .get(`${this.baseUrl}/v1/transaction/conditions`, { headers: this.getNoAuthHeader() })
            .pipe(catchError(handleError));
        return transaction$;
    }

    getCurrencyExchange(from, sourceCurrency) {
        const sector$ = <any>this.http
            .get(`${this.baseUrl}/fx/latest?from=${from}&to=${sourceCurrency}`, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return sector$;
    }

    createTransaction(transaction: Transaction) {

        const transaction$ = <any>this.http
            .post(`${this.baseUrl}/transaction?origin=web`, transaction, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return transaction$;
    }

    buildCalculateUrl(currency: string) {
        const buyer = JSON.parse(localStorage.getItem(currentBuyer));
        let url;
        if (buyer) {
            return url = `${this.baseUrl}/v1/transaction/calculate`
        } else {
            return url = `${this.baseUrl}/v1/transaction/calculate/web?currency=${currency}`
        }
    }

    buildMSGUrl(currency: string) {
        const buyer = JSON.parse(localStorage.getItem(currentBuyer));
        let url;
        if (buyer) {
            return url = `${this.baseUrl}/v1/transaction/calculate`
        } else {
            return url = `${this.baseUrl}/v1/transaction/calculate/web?currency=${currency}`
        }
    }

    getCalculations(data: any, currency: string) {
        const calculation$ = <any>this.http
            .post(this.buildCalculateUrl(currency), data, { headers: this.getNoAuthHeader() })
            .pipe(catchError(handleError));
        return calculation$;
    }

    getTransaction(transactionId: number, msg: any) {
        const transaction$ = this.http
            .get(`${this.baseUrl}/v1/transaction/${transactionId}${(msg != undefined) ? `?message=${msg}` : ``}`, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return transaction$;
    }

    getTransactionLogged(msg: any) {
        const transaction$ = this.http
            .get(`${this.baseUrl}/v1/transaction${(msg != undefined) ? `?message=${msg}` : ``}`, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return transaction$;
    }

    setBuyerIdinTransaction(transactionId: number) {
        const transaction$ = this.http
            .put(`${this.baseUrl}/v1/transaction/assign/${transactionId}`, null, { headers: this.getHeadersAssignLogged() })
            .pipe(catchError(handleError));
        return transaction$;
    }

    setTransaction(transactionId: number, transaction: StoreOrderUniq) {
        const transaction$ = this.http
            .put(`${this.baseUrl}/v1/transaction/${transactionId}`, transaction, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return transaction$;
    }

    getDefaultTransaction(msg: any): Observable<any> {
        const transaction$ = this.http
            .get(`${this.baseUrl}/v1/transaction/-1${(msg != undefined) ? `?message=${msg}` : ``}`, { headers: this.getNoAuthHeader() })
            .pipe(catchError(handleError));
        return transaction$;
    }

    getAirtimeAmountFixed(mobile: any, currency: string): Observable<any> {
        const amount$ = this.http
            .get(`${this.baseUrl}/v1/airtime/fixed/calculated/${mobile}?currency=${currency}`, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return amount$;
    }

    getAirDataAmountFixed(mobile: any, currency: string): Observable<any> {
        const amountdata$ = this.http
            .get(`${this.baseUrl}/v1/airdata/fixed/calculated/${mobile}?currency=${currency}`, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return amountdata$;
    }

    getBundleAmountFixed(mobile: any, currency: string): Observable<any> {
        const amountdata$ = this.http
            .get(`${this.baseUrl}/v1/bundle/fixed/calculated/${mobile}?currency=${currency}`, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return amountdata$;
    }

    getMyTransactions(page): Observable<any> {
        const transaction$ = this.http
            .get(`${this.baseUrl}/v1/transaction/mytransactions`, { headers: this.getHeadersLoggedPagination(page) })
            .pipe(catchError(handleError));
        return transaction$;
    }

    getMyBaluwoCardHistory(): Observable<any> {
        const transaction$ = this.http
            .get(`${this.baseUrl}/v1/buyercard/history`, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return transaction$;
    }

    getMyWalletHistory(): Observable<any> {
        const transaction$ = this.http
            .get(`${this.baseUrl}/v1/buyerwallet/history`, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return transaction$;
    }

    setTransferToWallet(transfer: any): Observable<any> {
        const transaction$ = this.http
            .post(`${this.baseUrl}/v1/buyerwallet/transfer`, transfer, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return transaction$;
    }

    getMyTransactionsTYPage(transactionId): Observable<any> {
        const transaction$ = this.http
            .get(`${this.baseUrl}/v1/transaction/mytransactions/${transactionId}`, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return transaction$;
    }

    setCurrencyTransaction(transactionId: number, currency: string): Observable<any> {
        const transaction$ = this.http
            .put(`${this.baseUrl}/v1/transaction/currency/${transactionId}/${currency}`, null, { headers: this.getNoAuthHeader() })
            .pipe(catchError(handleError));
        return transaction$;
    }

    setTransactionLogged(transaction: StoreOrderUniq): Observable<any> {
        const transaction$ = this.http
            .put(`${this.baseUrl}/v1/transaction`, transaction, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return transaction$;
    }

    setRepeatTransaction(orderId: number): Observable<any> {
        const transaction$ = this.http
            .put(`${this.baseUrl}/v1/transaction/repeat/${orderId}`, null, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return transaction$;
    }

    deleteTransaction(transactionId: number, orderId: number): Observable<any> {
        const transaction$ = this.http
            .delete(`${this.baseUrl}/v1/transaction/${transactionId}/${orderId}`, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return transaction$;
    }

    deleteTransactionLogged(orderId: number): Observable<any> {
        const transaction$ = this.http
            .delete(`${this.baseUrl}/v1/transaction/${orderId}`, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return transaction$;
    }

    getPaymentInfo(paymentType: any): Observable<any> {
        const transaction$ = this.http
            .get(`${this.baseUrl}/v1/paymentinfo/${paymentType}`, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return transaction$;
    }

    checkCashPowerMeterId(country: string, meterId: number): Observable<any> {
        const transaction$ = this.http
            .get(`${this.baseUrl}/v1/cashpower/check/${country}/${meterId}`, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return transaction$;
    }

    checkBeneficiaryCashPower(mobile: any): Observable<any> {
        const transaction$ = this.http
            .post(`${this.baseUrl}/v1/cashpower/meter/beneficiary`, mobile, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return transaction$;
    }

    checkTransactionPromotions(transactionId: number): Observable<any> {
        const transaction$ = this.http
            .get(`${this.baseUrl}/v1/transaction/promotion/${transactionId}`, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return transaction$;
    }

    checkTransactionPromotionsLogged(): Observable<any> {
        const transaction$ = this.http
            .get(`${this.baseUrl}/v1/transaction/promotion`, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return transaction$;
    }

    checkTransactionDiscounts(): Observable<any> {
        const transaction$ = this.http
            .get(`${this.baseUrl}/v1/transaction/discount`, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return transaction$;
    }

    closeTransaction() {
        const transaction$ = this.http
            .put(`${this.baseUrl}/v1/transaction/close`, null, { headers: this.getHeadersLoggedNoCache() })
            .pipe(catchError(handleError));
        return transaction$;
    }

    clearTransaction(transactionId: number) {
        const transaction$ = this.http
            .put(`${this.baseUrl}/v1/transaction/clear/${transactionId}`, null, { headers: this.getNoAuthHeader() })
            .pipe(catchError(handleError));
        return transaction$;
    }

    getRedsysReturnReceipt() {
        const baluwocashpin$ = <any>this.http
            .get(`${this.baseUrl}/v1/transaction/cyberpack/receipt`, { headers: this.getHeadersLoggedReceipt(), responseType: 'text' })
            .pipe(catchError(handleErrorRedsys));
        return baluwocashpin$;
    }

    getContacts() {
        const contact$ = <any>this.http
            .get(`${this.baseUrl}/v1/contact`, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return contact$;
    }

    postContactOnDemand() {
        const contact$ = <any>this.http
            .post(`${this.baseUrl}/v1/contact/ondemand`, null, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return contact$;
    }

    private getHeadersLoggedReceipt() {
        const headers = new HttpHeaders({
            'x-blw-src': 'baluwoWeb',
            'Accept': 'text/html',
            'Content-type': 'application/json',
            'Authorization': localStorage.getItem('currentAuth') || ''
        });
        return headers;
    }

    private getHeadersLogged() {
        const headers = new HttpHeaders({
            'x-blw-src': 'baluwoWeb',
            'Content-type': 'application/json',
            'Authorization': localStorage.getItem('currentAuth') || ''
            // headers.append('Cache-Control', 'no-cache, no-store');
        });
        return headers;
    }

    private getHeadersLoggedNoCache() {
        const headers = new HttpHeaders({
            'x-blw-src': 'baluwoWeb',
            'Content-type': 'application/json',
            'Authorization': localStorage.getItem('currentAuth') || ''
            // headers.append('Cache-Control', 'no-cache, no-store');
        });
        return headers;
    }


    private getNoAuthHeader() {
        const headers = new HttpHeaders({
            'x-blw-src': 'baluwoWeb',
            'Accept': 'application/json'
        });
        return headers;
    }

    private getHeadersAssignLogged() {
        const headers = new HttpHeaders({
            'x-blw-src': 'baluwoWeb',
            'Authorization': localStorage.getItem('currentAuth') || ''
        });
        return headers;
    }

    private getHeadersLoggedPagination(pageNumber: any) {
        console.log(pageNumber)
        const headers = new HttpHeaders({
            'x-blw-src': 'web',
            'x-blw-page': pageNumber.toString(),
            // 'x-blw-page': '1',
            'x-blw-size': '14',
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('currentAuth') || ''
        });
        return headers;
    }


}

function handleError(error: HttpErrorResponse) {
    console.log(error);
    // return Observable.throw(error);
    return throwError(error.error);
}

function handleErrorRedsys(error: HttpErrorResponse) {
    console.log(error);
    // return Observable.throw(error);
    return throwError(error);
}