import { Component, Optional, Inject } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
    selector: 'successpopupgeneric-popup',
    templateUrl: './successpopupgeneric.component.html',
    styleUrls: ['./successpopupgeneric.component.scss']
})

export class SuccessPopupGenericComponent {
    successmessage: SafeHtml;
    successPIN: SafeHtml;
    constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any, sanitizer: DomSanitizer, public dialogue: MatDialog) {
        console.log(data.message);
        this.successmessage = sanitizer.bypassSecurityTrustHtml(data.message);
        if (data.pin) this.successPIN = sanitizer.bypassSecurityTrustHtml(data.pin);
    }

    close() {
        this.dialogue.closeAll();
    }
}