// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  ApiIdAirTime: 51,
  ApiIdAirData: 301,
  ApiIdCashPower: 101,
  ApiIdFood: 1,
  ApiIdConstruction: 201,
  ApiIdFabric: 151,
  ApiIdRam: 261,
  ApiIdBill: 251,
  ApiIdHealthcare: 401,
  ApiIdBundle: 451,
  ApiIdOnDemand: 351,
  ApiIdGiftCard: 501,
  ApiIdMoney: 601,
  captchaKey: '6LcOnfcUAAAAAGY-K9q2-pZmTliDJzAqu5gBCohB',
  cookieDomain: 'webqa.baluwo.com',
  EmailHello: 'mgallardo@baluwo.com',
  EmailSupport: 'mgallardo@baluwo.com',
  EmailBBC: 'ptorres@baluwo.com' ,
  baluwoUserAppStore: 'com.baluwodirectapp'
};
