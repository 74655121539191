import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-successpopup',
  templateUrl: './successpopup.component.html',
  styleUrls: ['./successpopup.component.scss']
})
export class SuccesspopupComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
