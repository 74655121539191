import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../../environments/environment";
import { CountryService } from "./country.services";

@Injectable()
export class HeaderCountryService {
    public countryObject: any[] = [];
    public _ENVIRONMENT_ = environment;
    public headerCountryInfo = new BehaviorSubject(<any>{});
    getheaderCountry = this.headerCountryInfo.asObservable();

    public headerCountryDefault = new BehaviorSubject(<any>{});
    getCountryDefault = this.headerCountryDefault.asObservable();

    constructor(private _CountryService: CountryService) { }

    setCountryDefaultList():void{
        this._CountryService.getSellingCountries().subscribe(res=>{
            this.headerCountryDefault.next(res.filter(country => country.retailer === true));
        })
    }
    
    setCountryList(countries: any): void {
        const activeIds = [this._ENVIRONMENT_.ApiIdAirData , this._ENVIRONMENT_.ApiIdAirTime, this._ENVIRONMENT_.ApiIdCashPower];
        const objIndexMA = countries.findIndex((obj => obj.name == 'Morocco'));
        const objIndexCI = countries.findIndex((obj => obj.name == 'Côte d\'Ivoire'));
        const objIndexSL = countries.findIndex((obj => obj.name == 'Sierra Leone'));
        countries[objIndexMA].name = 'Marruecos';
        countries[objIndexCI].name = 'Costa de Marfil';
        countries[objIndexSL].name = 'Sierra Leona';
        countries.forEach(country => {
            let countryLevel:any = {};
            countryLevel.name = country.name;
            countryLevel.id = country.id;
            this._CountryService.getSectorsV1(country.id).subscribe(res=>{
                countryLevel.sectors = res.filter(sector=>activeIds.indexOf(sector.id) !== -1);
            })
            this.countryObject.push(countryLevel);
        })
        this.headerCountryInfo.next(this.countryObject);
    }
}