import { Component, Output, EventEmitter } from "@angular/core";
import { DinamicMetaTags } from "../../shared/_helpers/_dinamicMetaTags";
import { TranslateService } from "@ngx-translate/core";
import { ContactPopUp } from "../../shared/_helpers/_contactpopup.helper";
import * as $ from 'jquery';
import { LegalConditionsService } from "../../shared/_services/legal-condition.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    templateUrl: 'fakecontact.component.html',
    styleUrls: [],
})

export class FakeContactComponent{
    constructor(){}
    ngOnInit(){
        $('.footer').hide();
    }
}