import { serverBaseUrl } from '../../globals';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class DiemmesseService {
  private baseUrl = serverBaseUrl;
  constructor(private http: HttpClient) { }

  getSellingCountries(): Observable<any> {
    const country$ = this.http
      .get(`${this.baseUrl}/country`, { headers: this.getHeadersLogged() })
      .pipe(catchError(handleError));
    return country$;
  }

  getStoresV1(locationId: string, sectorId: any, currency: string): Observable<any> {
    const store$ = this.http
      .get(`${this.baseUrl}/v1/store/${locationId}/${sectorId}`, { headers: this.getHeadersLogged() })
      .pipe(catchError(handleError));
    return store$;
  }

  getLocationsV1(countryId: string, sectorId: number): Observable<any> {
    const location$ = this.http
      .get(`${this.baseUrl}/v1/location/${countryId}/${sectorId}`, { headers: this.getHeadersLogged() })
      .pipe(catchError(handleError));
    return location$;
  }

  getSectorsV1(countryId): Observable<any> {
    const sector$ = this.http
      .get(`${this.baseUrl}/v1/sector/${countryId}`, { headers: this.getHeadersLogged() })
      .pipe(catchError(handleError));
    return sector$;
  }

  getAllSectorsV1(): Observable<any> {
    const sector$ = this.http
      .get(`${this.baseUrl}/v1/sector/`, { headers: this.getHeadersLogged() })
      .pipe(catchError(handleError));
    return sector$;
  }

  getSuppliersByCountry(country: string): Observable<any> {
    const providers$ = <any>this.http
      .get(`${this.baseUrl}/v1/supplier/${country}`, { headers: this.getHeadersLogged() })
      .pipe(catchError(handleError));
    return providers$;
  }

  getStoreLogged(storeId: number): Observable<any> {
    const store$ = this.http
      .get(`${this.baseUrl}/v1/store/${storeId}`, { headers: this.getHeadersLogged() })
      .pipe(catchError(handleError));
    return store$;
  }

  private getHeadersLogged() {
    const headers = new HttpHeaders({
      'x-blw-src': 'web',
      'Content-type': 'application/json',
      'Authorization': localStorage.getItem('currentAuth') || ''
    });
    return headers;
  }

}

function handleError(error: HttpErrorResponse) {
  console.log(error);
  return throwError(error.error);
}