import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Categories } from "../../shared/_objects/categories";
import { serverBaseUrl } from "../../globals";

@Injectable()
export class CategoriesService {
    private baseUrl = serverBaseUrl;
    constructor(private http: HttpClient) {
    }

    getCategories(sectorId: number, language: string): Observable<Categories[]> {
        const categorie$ = <any>this.http
            .get(`${this.baseUrl}/v1/product/category/${sectorId}?lang=${language}`, { headers: this.getHeadersLogged() })
            .pipe(catchError(handleError));
        return categorie$;
    }

    private getHeadersLogged(): HttpHeaders {
        const headers = new HttpHeaders({
            'x-blw-src': 'web',
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('currentAuth') || ''
        });
        return headers;
    }
}

function handleError(error: HttpErrorResponse) {
    // log error
    const errorMsg = error.error;
    // throw an application level error
    return throwError(errorMsg);
}
