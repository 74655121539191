import { Injectable, Injector } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse
} from '@angular/common/http'
import { Observable } from 'rxjs';
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/catch';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoginmodalComponent } from '../../pages/loginmodal/loginmodal.component';
import { MatDialog } from '@angular/material';
import { LogOutHelper } from '../../shared/_helpers/_logout.helper';
import { Buyer } from '../../shared/_objects';


@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
    buyer: Buyer;
    constructor(private router: Router, private dialog: MatDialog, private _logoutHelper: LogOutHelper) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // console.log("intercepted request ... ");

        // Clone the request to add the new header.
        const authToken = localStorage.getItem('currentAuth');
        request = request.clone({
            setHeaders: {
                Authorization: `${authToken}`
            }
        });
        // console.log("Sending request with new header now ...");

        //send the newly created request
        return next.handle(request)
            .pipe(
                tap(event => {
                    if (event instanceof HttpResponse) {

                        // console.log(" all looks good");
                        // http response status code
                        // console.log(event.status);
                    }
                }, error => {
                    // http response status code
                    // console.log("----response----");
                    console.error("status code:");
                    // console.error(error.status);
                    // console.error(error.message);
                    // console.log("--- end of response---");
                    if (error.status === 401) {
                        console.log(error.error.code)
                        if (localStorage.getItem('currentBuyer') !== null && error.error.code !== 3) {
                            const dialogRef = this.dialog.open(LoginmodalComponent, { panelClass: 'loginLayoutResets' });
                            dialogRef.afterClosed().subscribe(b => {
                                if (typeof b !== 'undefined') {
                                    this.buyer = b;
                                    console.log('confirmamos buyer ' + this.buyer.mobile);
                                } else {
                                    this._logoutHelper.logout();
                                }
                            });
                            return false;
                        }else{
                            console.log('no buyer');
                        }
                    }

                })
            )
    }
}