import { Component, EventEmitter, Input, Output } from "@angular/core";
import { HomeUtilsHelper } from "../../../shared/_helpers/_homeUtils.helper";
import { ValidityOrderHelper } from "../../../shared/_helpers/_validityOrder.helper";
import { Order, Transaction } from "../../../shared/_objects";

@Component({
    selector: 'app-cl-bill-component',
    templateUrl: './cl-bill.component.html',
    styleUrls: ['../cartlanding.component.scss','../../../../assets/css/bootstrap-grid.min.css']
})
export class CLBillComponent {
    cartClass = 'purchaseOrder';

    @Input() order:Order;
    @Input() transaction:Transaction;
    @Input() transactionHistory: Transaction;
    @Input() amountsExchange: any;
    @Input() isValidAmount: boolean;
    @Output() removeOrderEmit: EventEmitter<any> = new EventEmitter<any>();

    constructor(public _homeUtilsHelper: HomeUtilsHelper,
        public _validityOrderHelper: ValidityOrderHelper) { }

    removeOrder(){
        this.removeOrderEmit.emit(this.order);
    }
}