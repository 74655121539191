import { Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import * as $ from 'jquery';



@Injectable()
export class ContextBase {
    public language: string;
    public currency: string;
    public isMobile: boolean;
    public isDesktop: boolean;
    public params: string;
    public country: string;
    public transMessage: string | null;
    id: string;
    name: string;
    acronym: string;
    symbol: string;
    constructor(private router: Router, private route: ActivatedRoute) {
        this.getUrlEndpointClass();
        this.isMobile = (/android|webos|iphone|ipad|ipod|blackberry|windows phone/).test(navigator.userAgent.toLowerCase());
        this.isDesktop = !this.isMobile;
    }

    getUrlEndpointClass() {
        this.router.events.subscribe((loc: any) => {
            this.params = loc.url;
        });

    }

    getCountryCode() {
        $.get("http://ip-api.com/json", (response) => {
            return response.countryCode;
        }, "jsonp");
    }

    getCurrencyInfo(curr) {
        switch (curr) {
            case "eur":
                return { id: "EUR", acronym: "EUR", name: "Euro Member Countries", symbol: "€" };
            case "gbp":
                return { id: "GBP", acronym: "GBP", name: "Pound", symbol: "£" };
            case "usd":
                return { id: "USD", acronym: "USD", name: "United States dollar", symbol: "$" };
            case "sek":
                return { id: "SEK", acronym: "SEK", name: "Swedish Krona", symbol: "sek" };
        }
    }

    getLanguageInfo(lang: string) {
        switch (lang) {
            case 'es':
                return { id: "es_ES", code: "es_ES", name: "Español - España" }
            case 'en':
                return { id: "en_US", code: "en_US", name: "English - United States of America" }
            case 'de':
                return { id: "de_DE", code: "de_DE", name: "German - Germany" }
            case 'fr':
                return { id: "fr_FR", code: "fr_FR", name: "French - France" }
        }
    }



}