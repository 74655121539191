import { Component } from "@angular/core";
import { PrivacyPolicyService } from "../../shared/_services/privacy-policy.service";
import { DomSanitizer } from "@angular/platform-browser";
import * as $ from 'jquery';

@Component({
    templateUrl: 'privacy-policy.component.html',
    styleUrls: ['../legalconditions/legalconditions.component.scss'],
})

export class AppPrivacyPolicyComponent{
    public PrivacyPolicyContent: any;
    constructor(private _privacyPolicyService: PrivacyPolicyService, public sanitizer: DomSanitizer){
        this._privacyPolicyService.get().then((data) => {
            this.PrivacyPolicyContent = sanitizer.bypassSecurityTrustHtml(data);
        });
    }
    ngOnInit(){
        $('.footer').hide(); 
    }

}