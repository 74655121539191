import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Buyer } from "../_objects";

@Injectable()
export class BuyerInfoService {
    public updatedBuyerInfo = new BehaviorSubject(<any>{});
    getBuyerInfo = this.updatedBuyerInfo.asObservable();

    public updatedBuyerCode = new BehaviorSubject(<string>{});
    getBuyerCode = this.updatedBuyerCode.asObservable();

    public updatedBuyerLanguage = new BehaviorSubject(<string>{});
    getBuyerLanguage = this.updatedBuyerLanguage.asObservable();

    public updatedBuyerCurrency = new BehaviorSubject(<string>{});
    getBuyerCurrency = this.updatedBuyerCurrency.asObservable();

    public updatedBuyerWallet = new BehaviorSubject(<number>{});
    getBuyerWallet = this.updatedBuyerWallet.asObservable();

    constructor() { }

    setCountryCode(countryCode: string): void {
        this.updatedBuyerCode.next(countryCode);
    }

    setBuyerLanguage(lang: string): void {
        this.updatedBuyerLanguage.next(lang);
    }

    setBuyerCurrency(currency: string): void {
        this.updatedBuyerCurrency.next(currency);
    }

    setBuyerWallet(amount: number): void {
        this.updatedBuyerWallet.next(amount);
    }
}