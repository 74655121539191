import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Buyer } from "../../shared/_objects/buyer";
import { PaymentHelper } from "../../shared/_helpers/_payment.helper";
import { ContextBase } from "../../shared/_helpers/_contextBase.helper";
import { Transaction } from "../../shared/_objects/transaction";

@Component({
    selector: 'app-baluwocard-component',
    templateUrl: './baluwocard.component.html',
    styleUrls: ['baluwocard.component.scss', '../cartlanding/cartlanding.component.scss', '../../../assets/css/bootstrap-grid.min.css']
})

export class BaluwoCardComponent {
    buyerCardAmount: number;
    buyerCardDiscount: number;
    checked: boolean;
    transactionId: string;

    @Input() paymentId: number;
    @Input() totalAmount: number;
    @Input() hasBaluwoWalletCash: boolean;
    @Input() hasBuyerCard: boolean;
    @Input() buyerInfo: Buyer;
    @Input() transaction: Transaction

    @Output() checkedOption: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() buyerCardPaymentFull: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() buyerCardAmountFull: EventEmitter<number> = new EventEmitter<number>();
    @Output() paymentIdEmitter: EventEmitter<number> = new EventEmitter<number>();

    constructor(
        public contexto: ContextBase,
        public _paymentHelper: PaymentHelper) { }

    changedBaluwoCardAmount() {
        const totalAmountFixed = this.totalAmount.toFixed(2)
        this.checkedOption.emit(this.checked);
        this.buyerCardAmountFull.emit(this.buyerCardAmount);
        if (!this.checked) {
            this.buyerCardAmount = null;
            this._paymentHelper.getPaymentInfoCyberpack();
            this._paymentHelper.getPaymentInfoPayPal();
            this._paymentHelper.getPaymentInfoSofort();
            this._paymentHelper.getPaymentInfoBizum();
            this.buyerCardPaymentFull.emit(false);
        } else {
            if (this.buyerCardAmount !== undefined && this.buyerCardAmount != 0 && (this.buyerCardAmount != null && this.buyerCardAmount < this.totalAmount)) {
                this._paymentHelper.getPaymentInfoCyberpack(this.buyerCardAmount);
                this._paymentHelper.getPaymentInfoPayPal(this.buyerCardAmount);
                this._paymentHelper.getPaymentInfoSofort(this.buyerCardAmount);
                this._paymentHelper.getPaymentInfoBizum(this.buyerCardAmount);
                // this.paymentIdEmitter.emit(1);
            } else if (this.buyerCardAmount == parseFloat(totalAmountFixed)) {
                this.paymentIdEmitter.emit(4);
                this.buyerCardPaymentFull.emit(true);
            } else {
                this.buyerCardPaymentFull.emit(true);
                // this.paymentIdEmitter.emit(null);
            }
        }
    }
}