import { Injectable } from "@angular/core";
import { currentBuyer } from "../../globals";
import { TransactionServiceV1 } from "../../core/services/transactionv1.service";
import { Buyer, Order } from "../_objects";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { MatDialog } from "@angular/material";
import { MeterPopUpComponent } from "../../pages/meterpopup/meterpopup.component";
import { CommonService } from "../../core/services/common.service";
import { LocationSectorStoreHelper } from "./_locationSectorStore.helper";

interface UserMobile {
    mobile: string
}

@Injectable()
export class CashPowerMeterHelper {
    private mobileData: UserMobile;
    private meterId: number;
    public buyer: Buyer;
    public beneficiaryAvailableMeters: Array<{meterId: string}> = [];
    public mockupMeters = [{ meterId: '12323264938' }, { meterId: '12345654321' }, { meterId: '58938411027' }];
    public validMeter: boolean;

    constructor(public transactionService: TransactionServiceV1,
        private spinnerService: Ng4LoadingSpinnerService,
        public commonService: CommonService,
        public locationSectorStoreHelper: LocationSectorStoreHelper,
        public dialogue: MatDialog) {
    }

    init(mobiledata: UserMobile) {
        this.mobileData = mobiledata;
        this.buyer = JSON.parse(localStorage.getItem(currentBuyer));
        this.getBeneficiaryMeter();
    }

    getBeneficiaryMeter() {
        this.transactionService.checkBeneficiaryCashPower(this.mobileData).subscribe(res => {
            this.beneficiaryAvailableMeters = res;
        })
    }

    openBeneficiaryMeters(meterArray) {
        const dialogRef = this.dialogue.open(MeterPopUpComponent, {
            data: meterArray
        })
    }

    checkMeterId(countryId: string, meterId: number) {
        this.meterId = meterId;
        this.spinnerService.show();
        return this.transactionService.checkCashPowerMeterId(countryId, this.meterId).subscribe((res) => {
            if (res.customerName == "Meter not found") {
                console.log('not valid meter');
                this.validMeter = false;
                this.spinnerService.hide();
            } else {
                this.validMeter = true;
                this.spinnerService.hide();
            }
        }, error => {
            console.log('Error: not valid meter');
            this.validMeter = false;
            this.spinnerService.hide();
        })
    }
}