import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CarouselHomePage } from "./_carousel.helper";
import { ContactPopUp } from "./_contactpopup.helper";
import { ContextBase } from "./_contextBase.helper";
import { DinamicMetaTags } from "./_dinamicMetaTags";
import { LogOutHelper } from "./_logout.helper";
import { GoogleMapsHelper } from "./_googlemaps.helper";
import { ScrollerTargetHelper } from "./_scrollerTarget.helper";
import { TopUpOrderHelper } from "./_topUpOrder.helper";
import { LocationSectorStoreHelper } from "./_locationSectorStore.helper";
import { HomeUtilsHelper } from "./_homeUtils.helper";
import { ServerErrorHelper } from "./servererrors.helper";
import { PromotionHelper } from "./_promotions.helper";
import { UtmCampaignHelper } from "./_utmcampaigns.helper";
import { TransactionHelper } from "./_transactionHelper.helper";
import { ValidityOrderHelper } from "./_validityOrder.helper";
import { PaymentHelper } from "./_payment.helper";
import { AnalyticsHelper } from "./_analytics.helper";
import { UtilsHelper } from "./_utils.helper";
import { BannerHelper } from "./_banner.helper";
import { CashPowerMeterHelper } from "./_cashPowerMeter.helper";


@NgModule({
    imports:[],
    providers:[
        CarouselHomePage,
        ContactPopUp,
        ContextBase,
        DinamicMetaTags,
        LogOutHelper,
        TopUpOrderHelper,
        GoogleMapsHelper,
        ScrollerTargetHelper,
        LocationSectorStoreHelper,
        HomeUtilsHelper,
        ServerErrorHelper,
        PromotionHelper,
        UtmCampaignHelper,
        TransactionHelper,
        ValidityOrderHelper,
        BannerHelper,
        PaymentHelper,
        AnalyticsHelper,
        UtilsHelper,
        CashPowerMeterHelper
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HelperModule { }