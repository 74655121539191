import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-errorpopup',
  templateUrl: './errorpopup.component.html',
  styleUrls: ['./errorpopup.component.scss']
})
export class ErrorPopUpComponent implements OnInit {
public error:string;
  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.error = data.error;
   }

  ngOnInit() {

  }

}
