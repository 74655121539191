export class Calculate {
    type: string;
    total: number;
    targetTotal?: number;
    beneficiary: {
        mobile: string;
        name?: string;
    }
    meterId?: string;
}

export class CalculatedEchanged {
    amount: number;
    amountExchanged: number;
    promo?: number;
    description?: string;
    beneficiary?: string;
    allFixed?: boolean;
}

export class CalculatedEchangedCartlanding {
    amount: number;
    amountExchanged: number;
    beneficiary: string;
    type: string;
    description: string;
    allFixed: boolean;
}

export class CalculatedManual {
    amountExchanged: number;
    promo?: number;
}

export class BuildExhangedArray {
    id: number;
    beneficiary: string;
    rates: CalculatedEchanged[];
  }

  export class BuildAmountArray{
      beneficiary: string;
      allFixed: boolean;
      amounts: CalculatedEchangedCartlanding[];
  }