import { Injectable } from "@angular/core";
import { BackStore, BackCharge, Sector } from '../_objects/store';
import { BehaviorSubject } from "rxjs";
import { Order } from "../_objects/order";
import { parse, getCountryCallingCode } from 'libphonenumber-js';
import { environment } from '../../../environments/environment';
import { CountryService } from "./country.services";
import { Country } from "../_objects";

@Injectable()
export class BackToStore {
    public sellingCountries: any;
    public _ENVIRONMENT_ = environment;

    public selectedStore = new BehaviorSubject(<BackStore>{});
    currentStore = this.selectedStore.asObservable();

    public selectedCharge = new BehaviorSubject(<Sector>{});
    currentCharge = this.selectedCharge.asObservable();

    public selectedEditCharge = new BehaviorSubject(<any>[]);
    currentEditCharge = this.selectedEditCharge.asObservable();

    public emptyTransaction = new BehaviorSubject(<boolean>{});
    currentTransactionEmpty = this.emptyTransaction.asObservable();

    public newStoreLine = new BehaviorSubject(<BackStore>{});
    storeLineRenew = this.newStoreLine.asObservable();

    public rmStoreLine = new BehaviorSubject(<any>{});
    rmStoreLineRenew = this.rmStoreLine.asObservable();

    public rmStoreOrder = new BehaviorSubject(<any>{});
    rmStoreOrderRenew = this.rmStoreOrder.asObservable();

    public amountInputCuston = new BehaviorSubject(<any>{});
    getCustomInputAmount = this.amountInputCuston.asObservable();

    public getImportError = new BehaviorSubject<boolean>(false);
    getImportErrorMsg = this.getImportError.asObservable();


    newStore: BackStore = <BackStore>{};
    newCharge: Sector = <Sector>{};
    newEditCharge: any = <any>{};
    newStoreOrderLine: any = <any>{};
    newStoreOrder: any = <any>{};

    constructor(public countryService: CountryService) {

        this.countryService.getSellingCountries()
            .subscribe(c => {
                this.sellingCountries = c;
                this.sellingCountries = this.sellingCountries.filter(country => country.retailer === true);
            });

    }

    buildBackToStore(store: any) {
        console.log(store)
        this.newStore.id = store.store.id;
        this.checkCountryPosition(parse(store.beneficiary.mobile).country).then(res=>{
            this.newStore.country = res;
        });
        this.newStore.location = store.store.location;
        this.newStore.phone = parse(store.beneficiary.mobile).phone;
        this.newStore.prefix = store.beneficiary.mobile.replace(this.newStore.phone, '').replace('+','');
        this.newStore.sector = store.store.sector;
        this.newStore.store = store.store;
        this.newStore.lines = store.lines;
        this.selectedStore.next(this.newStore);
        console.log(this.newStore.prefix);
    }

    buildBackToCharge(sector: Order) {
        if (sector.type == 'airtime') {
            this.newCharge = { id: this._ENVIRONMENT_.ApiIdAirTime, name: 'Air time', type: 'topup' };
        } else {
            this.newCharge = { id: this._ENVIRONMENT_.ApiIdCashPower, name: 'Cash power', type: 'topup' };
        }
        this.selectedCharge.next(this.newCharge);
    }

    buildBackToEditCharge(sector: Order) {
        if (sector.type == 'airtime') {
            this.newEditCharge = [{ id: this._ENVIRONMENT_.ApiIdAirTime, name: 'Air time', type: 'topup' }, { beneficiary: this.buildBeneficiaryMobileCode(sector.beneficiary.mobile, sector.beneficiary.country.id), country: sector.beneficiary.country.id }];
        } else if (sector.type == 'airdata') {
            this.newEditCharge = [{ id: this._ENVIRONMENT_.ApiIdAirData, name: 'Air data', type: 'topup' }, { beneficiary: this.buildBeneficiaryMobileCode(sector.beneficiary.mobile, sector.beneficiary.country.id), country: sector.beneficiary.country.id }];
        } else if (sector.type == 'bundle') {
            this.newEditCharge = [{ id: this._ENVIRONMENT_.ApiIdBundle, name: 'Bundle', type: 'topup' }, { beneficiary: this.buildBeneficiaryMobileCode(sector.beneficiary.mobile, sector.beneficiary.country.id), country: sector.beneficiary.country.id }];
        }else {
            this.newEditCharge = [{ id: this._ENVIRONMENT_.ApiIdCashPower, name: 'Cash power', type: 'topup' }, { beneficiary: this.buildBeneficiaryMobileCode(sector.beneficiary.mobile, sector.beneficiary.country.id), country: sector.beneficiary.country.id }];
        }
        this.selectedEditCharge.next(this.newEditCharge);
    }

    buildBeneficiaryMobileCode(mobile: any, countryId: any){
        const countryCode = getCountryCallingCode(countryId);
        return mobile.replace('+', '').replace(countryCode, '');
    }

    buildBackToStoreFromMap(store: any) {
        this.checkCountryPosition(parse(store.location.country.id)).then(res=>{
            this.newStore.country = res;
        });
        this.newStore.phone = null;
        this.newStore.location = store.location;
        this.newStore.sector = store.sector;
        this.newStore.store = store;
        this.selectedStore.next(this.newStore);
    }

    async checkCountryPosition(n) {
        let numbIndex
        await this.sellingCountries.forEach((d:Country, index:any) => {
            if (d.id === n) {
                numbIndex = index;
               
            }
        })
        return numbIndex;
    }

    buildTransitionEmpty(boolean: boolean) {
        this.emptyTransaction.next(boolean);
    }

    buildNewStoreLinesMiniCart(store: any) {
        this.newStoreOrderLine = store;
        this.newStoreLine.next(this.newStoreOrderLine);
    }

    removeNewStoreLinesMiniCart(orderLine: any) {
        this.newStoreOrderLine = orderLine;
        this.rmStoreLine.next(this.newStoreOrderLine)
    }

    removeNewStoreLinesMiniCartOrder(order: Order) {
        this.newStoreOrder = order;
        this.rmStoreOrder.next(this.newStoreOrder)
    }

    getInputAmountCustom(amount: number) {
        this.amountInputCuston.next(amount);
    }

    getImportErrorValue(val: boolean) {
        this.getImportError.next(val)
    }
}