import { Component, Input } from "@angular/core";
import { Order, Transaction } from "../../../shared/_objects";

@Component({
    selector: 'app-cl-titles-component',
    templateUrl: './cl-titles.component.html',
    styleUrls: ['../cartlanding.component.scss','../../../../assets/css/bootstrap-grid.min.css']
})

export class CLTitlesComponent{
    cartClass = 'purchaseOrder';
    @Input() order:Order;
    constructor(){}
}