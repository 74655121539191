import { Injectable } from "@angular/core";
import Swiper, { SwiperOptions } from 'swiper';

@Injectable()
export class CarouselHomePage{

    constructor(){}
    
    initCarousel() {
        let mySwiper = new Swiper('.swiper-container', {
          slidesPerView: 5, spaceBetween: 50, centeredSlides: true, initialSlide: 2,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            hiddenClass: 'swiper-button-hidden'
          }
        });

        let mySwiperText = new Swiper('.swiper-container-text', {
          slidesPerView: 1, spaceBetween: 0, speed: 600, initialSlide: 2,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });
        let mySwiperPartners = new Swiper('.swiper-container-partners', {
          slidesPerView: 10, spaceBetween: 10,
        });

        let mySwiperPartnersMobile = new Swiper('.swiper-container-partners-mobile', {
          slidesPerView: 5, spaceBetween: 10,
        });
      }
    
      onChange(id) {
        let theId = document.getElementsByClassName('swiper-slide-active').item.name;
      }
    
      clickNext() {
        let nextSlide = document.getElementById('swiper-button-next-text').click();
      }
      clickPrev() {
        let prevSlide = document.getElementById('swiper-button-prev-text').click();
      }
}