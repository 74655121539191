import { Injectable } from "@angular/core";
import { Title, Meta } from '@angular/platform-browser';
import { webBaseUrl } from '../../globals';
import { TranslateService } from "@ngx-translate/core";
import { ContextBase } from "./_contextBase.helper";

@Injectable()
export class DinamicMetaTags {

    MetaTitle: string;
    MetaDescription: string;
    MetaImage: string;
    hostUrl = webBaseUrl;
    landingServiceName: string;
    thisLandingUrl: string;

    constructor(private title: Title,
        private translate: TranslateService,
        public contexto: ContextBase,
        private meta: Meta) { }

    addMetaTags() {
        const metaTitle = this.MetaTitle;
        console.log(metaTitle);
        const metaDescription = this.MetaDescription;
        const metaImage = this.hostUrl + '/' + this.MetaImage;
        const metaUrl = 'https://baluwo.com/' + this.thisLandingUrl;
        this.title.setTitle(metaTitle);
        this.addTag('author', 'Baluwo');
        this.updateThisTag('description', metaDescription);
        this.updateThisTag('twitter:title', metaTitle);
        this.updateThisTag('twitter:description', metaDescription);
        this.updateThisTag('twitter:image', metaImage);
        this.meta.updateTag({ property: 'og:image', content: metaImage });
        this.meta.updateTag({ property: 'og:title', content: metaTitle });
        this.meta.updateTag({ property: 'og:description', content: metaDescription });
        this.meta.updateTag({ property: 'og:url', content: metaUrl });
    }

    addTag(name, content) {
        this.meta.addTags([{
            name: name,
            content: content
        }]);
    }
    updateThisTag(name, content) {
        this.meta.updateTag(
            { name: name, content: content },
        );
    }

    initAboutUsMetaTag() {
        setTimeout(() => {
            this.MetaTitle = this.translate.instant("Baluwo.metatags.aboutus.MetaTitle");
            this.MetaDescription = this.translate.instant('Baluwo.metatags.aboutus.MetaDescription');
            this.addMetaTags();
        }, 200)
    }

    initCartLandingMetaTag() {
        if (this.translate.instant("Baluwo.metatags.cart.MetaTitle") == 'Baluwo.metatags.cart.MetaTitle') {
            this.MetaTitle = this.getMetaTagStatic();
        } else {
            this.MetaTitle = this.translate.instant("Baluwo.metatags.cart.MetaTitle");
        }
        this.addMetaTags();
    }

    initFAQSMetaTag() {
        setTimeout(() => {
            this.MetaTitle = this.translate.instant("Baluwo.metatags.faqs.MetaTitle");
            this.MetaDescription = this.translate.instant('Baluwo.metatags.faqs.MetaDescription');
            this.addMetaTags();
        }, 200)
    }

    initHomeMetaTag() {
        if (this.translate.instant("Baluwo.metatags.homepage.MetaTitle") == 'Baluwo.metatags.homepage.MetaTitle') {
            this.MetaTitle = this.getMetaTagStatic();
        } else {
            this.MetaTitle = this.translate.instant("Baluwo.metatags.homepage.MetaTitle");
        }
        this.MetaDescription = this.translate.instant('Baluwo.metatags.homepage.MetaDescription');
        this.MetaImage = "assets/images/promotions/lady_promo-scaled.jpg";
        this.addMetaTags();
    }

    initLegalConditionsMetaTag() {
        if (this.translate.instant("Baluwo.metatags.legaladvice.MetaTitle") == 'Baluwo.metatags.legaladvice.MetaTitle') {
            this.MetaTitle = this.getMetaTagStatic();
        } else {
            this.MetaTitle = this.translate.instant("Baluwo.metatags.legaladvice.MetaTitle");
        }
        this.MetaDescription = this.translate.instant('Baluwo.metatags.legaladvice.MetaDescription');
        this.addMetaTags();

    }

    initPartnersMetaTag() {
        setTimeout(() => {
            this.MetaTitle = this.translate.instant("Baluwo.metatags.partners.MetaTitle");
            this.MetaDescription = this.translate.instant('Baluwo.metatags.partners.MetaDescription');
            this.addMetaTags();
        }, 200)
    }

    initPromotionsMetaTag(charge: any, amount: any) {
        setTimeout(() => {
            this.MetaTitle = this.translate.instant('Baluwo.fbmetatags.promotions.Title', { offer: charge });
            this.MetaDescription = this.translate.instant('Baluwo.fbmetatags.promotions.MetaDescription', { offer: charge, charge: amount });
            this.MetaImage = "assets/images/promotions/lady_promo-scaled.jpg";
            this.thisLandingUrl = this.contexto.language + '/' + this.contexto.currency + '/promotions';
            this.addMetaTags();
        }, 200)
    }

    getMetaTagStatic() {
        switch (this.contexto.language.toLowerCase()) {
            case 'es':
                return 'Connecting African Families'
            case 'en':
                return 'Connecting African Families'
            case 'fr':
                return 'Connecting African Families'
            case 'de':
                return 'Connecting African Families'

        }
    }

    getMetaTagCartStatic() {
        switch (this.contexto.language.toLowerCase()) {
            case 'es':
                return 'Resumen de tu carrito | Baluwo'
            case 'en':
                return 'Summary of your shopping cart | Baluwo'
            case 'fr':
                return 'Résumé de votre panier | Baluwo'
            case 'de':
                return 'Überblick Ihres Warenkorbs | Baluwo'

        }
    }

    getMetaTagLegalStatic() {
        switch (this.contexto.language.toLowerCase()) {
            case 'es':
                return 'Condiciones de uso y política de privacidad| Baluwo'
            case 'en':
                return 'Conditions of use and privacy policy | Baluwo'
            case 'fr':
                return 'Conditions d\'utilisation et politique de confidentialité | Baluwo'
            case 'de':
                return 'Allgemeinen Geschäftsbedingungen, Datenschutzrichtlinie| Baluwo'

        }
    }
}