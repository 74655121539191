import { Buyer } from '../../shared/_objects/buyer';
import { Transaction } from '../../shared/_objects/transaction';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { currentBuyer, currentTransaction, TransactionId } from '../../globals';
import { LoginService } from '../../core/login-service/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreOrderLine, StoreOrderUniq } from '../../shared/_objects/order';
import { ContextBase } from '../../shared/_helpers/_contextBase.helper';
import { DinamicMetaTags } from '../../shared/_helpers/_dinamicMetaTags';
import { TranslateService } from '@ngx-translate/core';
import { LogOutHelper } from '../../shared/_helpers/_logout.helper';
import * as $ from 'jquery';
import * as moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import 'moment/locale/it';
import { TransactionServiceV1 } from '../../core/services/transactionv1.service';
import { LocationSectorStoreHelper } from '../../shared/_helpers/_locationSectorStore.helper';
import { ErrorPopUpComponent } from '../errorpopup/errorpopup.component';
import { ServerErrorHelper } from '../../shared/_helpers/servererrors.helper';
import { MatDialog } from '@angular/material';
import { SuccessPopupGenericComponent } from '../successpopupgeneric/successpopupgeneric.component';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { TransactionHelper } from '../../shared/_helpers/_transactionHelper.helper';
import { SupplierService } from '../../core/services/suppliers.service';
import { CurrencyAmountService } from '../../shared/_services/currencyamount.service';
import { PaymentService } from '../../core/payment/payment.service';
import { environment } from '../../../environments/environment';
import { LoginHelperUserComponent } from '../loginmodal/loginhelpers/loginHelperUser.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-my-account',
  templateUrl: './myaccount.component.html',
  styleUrls: ['./myaccount.component.scss', '../../../assets/css/bootstrap-grid.min.css'],
})

export class MyaccountComponent implements OnInit {
  buyer: Buyer;
  myTransactions: any[];
  myCardHistory: any;
  // retrieving path parameters
  sub: any;
  currency: string;
  language: string;
  state_content: string;
  selectedIndexTab: number = 0;
  storeOrderUniq: StoreOrderUniq;
  errorMesage: string;
  @Output() TabIndexAccount: EventEmitter<number> = new EventEmitter<number>();
  public page: number = 1;
  transaction: any;
  baluwoCashPinCodeNumber: any;
  baluwoCashPinCodeRedeem: any;
  baluwoCashPinCodeChecked: any;
  pinCheckError: boolean;
  environement: string;
  oldPassword: string;
  newPassword: string;
  repeatPassword: string;
  noMatrchPassowrd: boolean = false;
  weakPassword: boolean = false;
  noMatrchPassowrdRegistration: boolean = false;
  weakPasswordRegistration: boolean = false;
  newPasswordRegistration: string;
  repeatPasswordRegistration: string;
  wrongUser: boolean = false;
  fieldTextType: boolean;
  fieldTextType2: boolean;
  fieldTextType3: boolean;
  fieldTextType4: boolean;
  fieldTextType5: boolean;
  isSubmitted: boolean = false;
  passwordAvailable: boolean = false;

  buyerRegistrationForm: FormGroup;
  public buyerRegistrationData: any;

  constructor(
    private loginService: LoginService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private contexto: ContextBase,
    private dinamicMetaTags: DinamicMetaTags,
    private dialog: MatDialog,
    private translate: TranslateService,
    private spinnerService: Ng4LoadingSpinnerService,
    public _transactionHelper: TransactionHelper,
    public locationSectorStoreHelper: LocationSectorStoreHelper,
    public serverErrorHelper: ServerErrorHelper,
    public supplierService: SupplierService,
    public paymentService: PaymentService,
    public _logOutHelper: LogOutHelper,
    public loginHelperUserComponent: LoginHelperUserComponent,
    private _currencyAmountService: CurrencyAmountService,
    private transactionServiceV1: TransactionServiceV1) {
    this.environement = environment.captchaKey;
    this.route.params.subscribe(params => {
      if (params['tabindex']) {
        this.selectedIndexTab = params['tabindex'];
        if (this.selectedIndexTab == 3) {
          this.loadMyOrders();
        } else {
          this.loadMyBaluwoCardHistory();
        }
      }
    });
    let lang: string = this.contexto.language.toString()
    moment.locale(lang);

    this.buyerRegistrationForm = this.fb.group({
      'name': ['', Validators.compose([Validators.required])],
      'email': ['', Validators.compose([Validators.required, Validators.pattern('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,6}')])],
      'password': ['', Validators.compose([Validators.required])],
      'passwordrepeat': ['', Validators.compose([Validators.required])]
    });
    this.buyerRegistrationData = <any>{};
  }

  ngOnInit() {
    $('.headerMain__navLi--action').addClass('account');

    this.currency = this.contexto.currency.toUpperCase();
    this.language = this.contexto.language.toUpperCase();

    this.buyer = JSON.parse(localStorage.getItem(currentBuyer));
    console.log(this.buyer);

    if (this.buyer.name !== null) {
      this.buyerRegistrationForm.controls['name'].setValue(this.buyer.name);
    }

    if (localStorage.getItem('passwordLogin') === 'available') {
      this.passwordAvailable = true;
      console.log(this.passwordAvailable);
    } else {
      this.passwordAvailable = false;
    }

  }

  tabChanged(evt) {
    console.log(evt)
    switch (evt.index) {
      case 1:
        if (localStorage.getItem('passwordLogin') === 'available') {
          this.passwordAvailable = true;
          console.log(this.passwordAvailable);
        } else {
          this.passwordAvailable = false;
        }
        break;
      case 3:
        this.page = 1;
        this.loadMyOrders();
        break;
      case 2:
        this.loadMyBaluwoCardHistory();
        break;
    }
  }

  loadMoreTransactions() {
    this.page++;
    this.loadMyOrders();
  }

  loadLessTransactions() {
    this.page--;
    this.loadMyOrders();
  }

  loadMyOrders() {
    this.spinnerService.show();
    this.transactionServiceV1.getMyTransactions(this.page)
      .subscribe(t => {
        this.myTransactions = t;
        this.spinnerService.hide();
        this.myTransactions.forEach(f => {
          f.orders.forEach(x => {
            this.parseNumberSupliers(x)
          })
        })
      });
  }

  loadMyBaluwoCardHistory() {
    this.spinnerService.show();
    this.updateBuyer()
    this.transactionServiceV1.getMyWalletHistory()
      .subscribe(t => {
        this.myCardHistory = t;
        // this.myCardHistory = t.filter(history => history.concept !== 'CURRENCY_UPDATE');
        this.spinnerService.hide();
      });
  }

  transferDoneEmit(){
    this.transactionServiceV1.getMyWalletHistory()
      .subscribe(t => {
        this.myCardHistory = t;
        this.spinnerService.hide();
      });
      this.updateBuyer();
  }

  updateBuyer() {
    this.buyer = JSON.parse(localStorage.getItem(currentBuyer));
    this.loginService.getBuyer(this.buyer.uuid).subscribe(res => {
      localStorage.setItem(currentBuyer, JSON.stringify(res));
      this.buyer = JSON.parse(localStorage.getItem(currentBuyer));
      console.log('BUYER: ', this.buyer);
      if (this.buyer.wallet) this._currencyAmountService.setUpdatedWallet(this.buyer.wallet.aggregated)
    })
  }

  checkPasswordMatch() {
    this.weakPassword = false;
    if (this.repeatPassword !== undefined) {
      if (this.newPassword !== this.repeatPassword) {
        this.noMatrchPassowrd = true;
        return;
      } else {
        this.noMatrchPassowrd = false;
        return this.newPassword
      }
    }
  }

  checkPasswordMatchRegistration() {
    this.newPasswordRegistration = this.buyerRegistrationForm.get('password').value;
    this.repeatPasswordRegistration = this.buyerRegistrationForm.get('passwordrepeat').value;
    if (this.newPasswordRegistration !== this.repeatPasswordRegistration) {
      this.noMatrchPassowrdRegistration = true;
      return;
    } else {
      this.noMatrchPassowrdRegistration = false;
      return this.newPasswordRegistration
    }
  }

  toggleFieldTextType(num) {
    console.log(num);
    if (num == 1) {
      this.fieldTextType = !this.fieldTextType;
    } else if (num == 2) {
      this.fieldTextType2 = !this.fieldTextType2;
    } else if (num == 3) {
      this.fieldTextType3 = !this.fieldTextType3;
    } else if (num == 4) {
      this.fieldTextType4 = !this.fieldTextType4;
    } else if (num == 5) {
      this.fieldTextType5 = !this.fieldTextType5;
    }
  }

  savePassword() {
    this.weakPassword = false;
    const updatePassword = {
      oldPassword: this.oldPassword,
      newPassword: this.checkPasswordMatch()
    }

    if (!this.noMatrchPassowrd) {
      this.loginService.updateUserPassword(updatePassword).subscribe(res => {
        console.log(res);
        this.oldPassword = null;
        this.newPassword = null;
        this.repeatPassword = null;
        this.showSuccessModal();
        localStorage.setItem('passwordLogin', 'available');
        this.loginHelperUserComponent.setAuth(this.buyer.mobile, updatePassword.newPassword);
      }, error => {
        if (error.error.code == 1) {
          this.weakPassword = true;
          this.wrongUser = false;
        } else if (error.error.code == 3) {
          this.weakPassword = false;
          this.wrongUser = true;
        } else {
          this.ErrorPopupInfo(error.error);
        }
      })
    }
  }

  executeCaptcha() {
    grecaptcha.execute();
  }

  public resolved(captchaResponse: string) {
    this.checkBaluwoCashPin(captchaResponse);
  }

  checkBaluwoCashPin(token: string) {
    console.log(token);
    this.baluwoCashPinCodeRedeem = <any>{};
    this.baluwoCashPinCodeRedeem.pin = this.baluwoCashPinCodeNumber;
    this.loginService.postSisalBuyerWallet(this.baluwoCashPinCodeRedeem, token).subscribe(res => {
      this.pinCheckError = false;
      this.baluwoCashPinCodeChecked = res;
      this.loadMyBaluwoCardHistory();
    }, error => {
      this.pinCheckError = true;
    })
  }

  changeMomentLang(time) {
    return moment(time).format('MMMM D, YYYY')
  }

  closeDetail(transaction: Transaction) {
    transaction.showDetail = false;
  }

  initMetaTag() {
    setTimeout(() => {
      this.dinamicMetaTags.MetaTitle = this.translate.instant("Baluwo.metatags.myaccount.MetaTitle");
      this.dinamicMetaTags.addMetaTags();
    }, 200)
  }

  repeatTransaction(order: any) {
    console.log(order);
    if (localStorage.getItem(currentBuyer) != null) {
      this.transactionServiceV1.getTransactionLogged(this.contexto.transMessage).subscribe((data) => {
        this.transaction = data;
        localStorage.setItem(TransactionId, this.transaction.id);
        console.log('transaction active logged: ', this.transaction);
        this.setRepeatTransaction(order);
      });
    } else {
      this.setRepeatTransaction(order);
    }
  }

  setRepeatTransaction(order) {
    this.transactionServiceV1.setRepeatTransaction(order.id).subscribe((res) => {
      this.router.navigate(['/cartlanding']);
    }, error => {
      console.log(error);
      let errorcode = error;
      this.errorMesage = this.serverErrorHelper.getErrorMsg(errorcode.code);
      const dialogErrorRef = this.dialog.open(ErrorPopUpComponent, {
        panelClass: 'loginLayoutResets',
        data: { error: this.errorMesage }
      });
      dialogErrorRef.afterClosed().subscribe(() => { });
    })
  }

  rechargeDoneEmit(evt){
    console.log(evt);
  }

  ErrorPopupInfo(error) {
    console.log(error);
    let errorcode = error;
    this.errorMesage = this.serverErrorHelper.getErrorMsg(errorcode.code);
    const dialogErrorRef = this.dialog.open(ErrorPopUpComponent, {
      panelClass: 'loginLayoutResets',
      data: { error: this.errorMesage }
    });
    dialogErrorRef.afterClosed().subscribe(() => { });
  }


  saveUser() {
    this.loginService.patchBuyer(this.buyer).subscribe(
      r => {
        this.buyer = r;
        localStorage.setItem(currentBuyer, JSON.stringify(this.buyer));
        const dialogSuccessRef = this.dialog.open(SuccessPopupGenericComponent, {
          panelClass: 'loginLayoutResets',
          data: { message: this.translate.instant('Baluwo.account.personal.save.success') }
        });
        dialogSuccessRef.afterClosed().subscribe(() => { });
      });
  }

  showSuccessModal() {
    const dialogSuccessRef = this.dialog.open(SuccessPopupGenericComponent, {
      panelClass: 'loginLayoutResets',
      data: { message: this.translate.instant('Baluwo.account.personal.save.success') }
    });
    dialogSuccessRef.afterClosed().subscribe(() => { });
  }

  showOrder(order, id) {
    if (order.showDetail == true) {
      order.showDetail = false;
      $('.icon-arrow-blue--' + id).removeClass('open');
    } else {
      order.showDetail = true;
      $('.icon-arrow-blue--' + id).addClass('open');
    }
  }


  trackByTransactionId(index: number, obj: Transaction): number {
    return obj.id;
  }

  getContent(state: number) {
    switch (state) {
      case 5:
        this.state_content = this.translate.instant("Baluwo.account.history.state.sofort");
        return 'state state-created';
      case 8:
      case 9:
        this.state_content = this.translate.instant("Baluwo.account.history.state.delivered");
        return 'state state-payed-provider';
      case 10:
        this.state_content = this.translate.instant("Baluwo.account.history.state.failure");
        return 'state state-error';
      default:
        this.state_content = this.translate.instant("Baluwo.account.history.state.pending");
        return 'state state-payed';
    }
  }

  parseNumberSupliers(order) {
    if (order.beneficiary) {
      this.supplierService.getSuppliersByCountry(order.beneficiary.country.id).subscribe(res => {
        res.forEach(f => {
          if (order.type !== 'cashpower') {
            if (order.beneficiary.mobile.match(f.carrier.regExp)) {
              order.carrier = f.carrier.name.toLowerCase().replace(/ /g, '');
            }
          } else {
            if (order.meterId.match(f.carrier.regExp)) {
              order.carrier = f.carrier.name.trim().toLowerCase().replace(/ /g, '');
            }
          }
        })
      })
    }
  }

  validateControl(control_name: string, validator_name: string) {
    return this.buyerRegistrationForm.controls[control_name].hasError(validator_name) && (!this.buyerRegistrationForm.controls[control_name].pristine || this.buyerRegistrationForm.controls[control_name].touched);
  }

  registerUser() {
    this.isSubmitted = true;
    this.weakPasswordRegistration = false;
    this.buyerRegistrationData.name = this.buyerRegistrationForm.get('name').value;
    this.buyerRegistrationData.email = (this.buyerRegistrationForm.get('email').value === '') ? null : this.buyerRegistrationForm.get('email').value;
    this.buyerRegistrationData.password = this.checkPasswordMatchRegistration();
    if (this.buyerRegistrationForm.valid && !this.noMatrchPassowrdRegistration) {
      this.loginService.putBuyerLoginInfo(this.buyerRegistrationData).subscribe(res => {
        console.log(res);
        this.isSubmitted = false;
        this.showSuccessModal();
        this.buyerRegistrationForm.reset();
        this.loginHelperUserComponent.setAuth(this.buyer.mobile, this.buyerRegistrationData.password);
      }, error => {
        this.isSubmitted = false;
        if (error.error.code == 1) {
          this.weakPasswordRegistration = true;
        } else {
          this.ErrorPopupInfo(error.error);
        }
      })
    }
  }
}


