import { Component } from "@angular/core";
import { MatDialog } from "@angular/material";

@Component({
    selector: 'app_fraud-popup-component',
    templateUrl: 'fraudpopup.component.html',
    styleUrls: ['./fraudpopup.component.scss']
})

export class FraudPopUpComponent{
    constructor(private dialogue: MatDialog){}

    cancel() {
        this.dialogue.closeAll();
    }
}