import { Component, Optional } from "@angular/core";
import { AgentRegistration } from "../../shared/_objects/agentregistration";
import { LoginService } from "../../core/login-service/login.service";
import { ContextBase } from "../../shared/_helpers/_contextBase.helper";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { LoginmodalComponent } from "../loginmodal/loginmodal.component";
import * as $ from 'jquery';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CountryService } from "../../shared/_services/country.services";
import { SuccesspopupComponent } from "../successpopup/successpopup.component";
import { ErrorPopUpComponent } from "../errorpopup/errorpopup.component";
import { TranslateService } from "@ngx-translate/core";
import { ServerErrorHelper } from "../../shared/_helpers/servererrors.helper";

@Component({
    selector: 'agent-baluwo-registration',
    templateUrl: 'agentbaluworegistration.component.html',
    styleUrls: ['./agentbaluworegistration.component.scss']
})

export class AgentBaluwoRegistrationComponent {

    agentRegistration = new AgentRegistration;
    agentRegistrationForm: FormGroup;
    countryArray: any[] = [];
    countryPrefix: any;
    data: any;
    landingSourceCountry: string;
    errorMesage: string;
    isSubmitted: boolean = false;

    constructor(private loginService: LoginService,
        private contexto: ContextBase,
        private fb: FormBuilder,
        private dialog: MatDialog,
        private translate: TranslateService,
        public serverErrorHelper: ServerErrorHelper,
        private countryService: CountryService,
        @Optional() public dialogRef: MatDialogRef<LoginmodalComponent>) {

        this.landingSourceCountry = JSON.parse(localStorage.getItem('landingSourceCountry'))
        this.countryService.getBuyingCountries().subscribe(
            c => {
                this.countryArray = c;
                this.data.countryPrefix = this.countryArray.filter(country => country.id === this.landingSourceCountry)[0];
            });

        this.agentRegistrationForm = this.fb.group({
            'name': ['', Validators.compose([Validators.required])],
            'leadPrefix': ['', Validators.compose([Validators.required])],
            'mobile': ['', Validators.compose([Validators.required])],
            'email': ['', Validators.compose([Validators.required, Validators.pattern('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,6}')])],
            'cif': ['', Validators.compose([Validators.required])],
            'province': ['', Validators.compose([Validators.required])],
            'town': ['', Validators.compose([Validators.required])],
            'address': ['', Validators.compose([Validators.required])],
            'credit': [''],
            'cmMobile': [''],
            'cmPassword': [''],
        });

        this.data = <AgentRegistration>{};

    }

    validateControl(control_name: string, validator_name: string) {
        return this.agentRegistrationForm.controls[control_name].hasError(validator_name) && (!this.agentRegistrationForm.controls[control_name].pristine || this.agentRegistrationForm.controls[control_name].touched);
    }

    onAgentSubmit(data) {
        this.isSubmitted = true;
        this.agentRegistration.name = data.name;
        this.agentRegistration.mobile = data.countryPrefix.mobilePrefix + data.mobile;
        this.agentRegistration.email = data.email;
        this.agentRegistration.cif = data.cif;
        this.agentRegistration.province = data.province;
        this.agentRegistration.town = data.town;
        this.agentRegistration.address = data.address;
        this.agentRegistration.credit = data.credit;
        this.agentRegistration.cmMobile = data.cmMobile;
        this.agentRegistration.cmPassword = data.cmPassword;

        if (this.agentRegistrationForm.valid) {
            this.loginService.sendAgentRegistration(this.agentRegistration).subscribe(() => {

                    this.isSubmitted = false;
                    this.agentRegistrationForm.reset();
                    const dialogRefSuccess = this.dialog.open(SuccesspopupComponent, { panelClass: 'loginLayoutResets' });
                
            }, error => {
                this.errorMesage =  this.serverErrorHelper.getErrorMsg(error.code)
                console.log('ERROR: ', error.message);
                const dialogErrorRef = this.dialog.open(ErrorPopUpComponent, {
                    panelClass: 'loginLayoutResets',
                    data: { error: this.errorMesage }
                });
                dialogErrorRef.afterClosed().subscribe(() => {
                });
            });
        }
    }

    showCMForm() {
        $('.agentRegistrationSection_countryManag').toggleClass('active');
    }
}