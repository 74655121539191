export class Email {
  leadName: string;
  leadPhone: any;
  leadEmail: string;
  leadMessage: string;
  destinationEmail?: string;
  destinationBBCEmail?: string;
}

export class NewLocation {
  name: string;
  phone: string;
  email: string;
  location: string;
}

export class NewPinEmail {
  mobile: string;
}
