import { Component, EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material";

@Component({
    selector: 'app-popup-transferok-component',
    templateUrl: './popuptransferok.component.html',
    styleUrls: ['./popuptransferok.component.scss']
})
export class PopUpTransferOkComponent {
    onContinueTransfer = new EventEmitter();
    constructor(public dialogue: MatDialog) { }

    continue() {
        this.onContinueTransfer.emit();
        this.close();
    }

    close() {
        this.dialogue.closeAll();
    }
}