import { serverBaseUrl } from '../../globals';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class NationalRechargeService {
    private baseUrl = serverBaseUrl;
    constructor(private http: HttpClient) { }

    getProviders(){
        const provider$ = <any>this.http
            .get(`${this.baseUrl}/v1/national/provider`, { headers: this.getNoAuthHeader() })
            .pipe(catchError(handleError));
        return provider$;
    }

    getProvidersLogged(mobile: string){
        const provider$ = <any>this.http
            .get(`${this.baseUrl}/v1/national/provider/${mobile}`, { headers: this.getNoAuthHeader() })
            .pipe(catchError(handleError));
        return provider$;
    }

    getProducts(providercode:string){
        const product$ = <any>this.http
            .get(`${this.baseUrl}/v1/national/product/${providercode}`, { headers: this.getNoAuthHeader() })
            .pipe(catchError(handleError));
        return product$;
    }

    private getNoAuthHeader() {
        const headers = new HttpHeaders({
            'x-blw-src': 'baluwoWeb',
            'Accept': 'application/json'
        });
        return headers;
    }
}

function handleError(error: HttpErrorResponse) {
    console.log(error);
    return throwError(error.error);
}