import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { DiemmesseService } from '../../core/services/diemmesse.service';
import { SharedModule } from '../../shared.module';
import { DiemmesseProductsComponent } from './products/diemmesse_products.component';


@NgModule({
	imports: [
        SharedModule
	],
	declarations: [
        DiemmesseProductsComponent
	],
	providers: [
		DiemmesseService
	],
	entryComponents: [
        DiemmesseProductsComponent
	],
	exports: [

	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})

export class DiemmesseProductsComponentModule { }