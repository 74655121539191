import { Component, OnInit, Optional } from '@angular/core';
import { DinamicMetaTags } from '../../../shared/_helpers/_dinamicMetaTags';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { ContactPopUp } from '../../../shared/_helpers/_contactpopup.helper';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { UtmCampaignHelper } from '../../../shared/_helpers/_utmcampaigns.helper';
import { AgentRegistrationWebComponent } from '../../agentregistrationweb/agentregistrationweb.component';
import { SuccessPopupGenericComponent } from '../../successpopupgeneric/successpopupgeneric.component';
import { ContextBase } from '../../../shared/_helpers/_contextBase.helper';

@Component({
  selector: 'app-partners-agents',
  templateUrl: './partnersagents.component.html',
  styleUrls: ['./partnersagents.component.scss'],
})

export class PartnersAgentsComponent implements OnInit {
  public message: string;
  constructor(
    private dinamicMetaTags: DinamicMetaTags, 
    private translate: TranslateService,
    public contexto: ContextBase,
    public _utmCampaignHelper: UtmCampaignHelper, 
    public _contactPopUp: ContactPopUp,
    private dialog: MatDialog,
    @Optional() public dialogAgentRegistrationRef: MatDialogRef<AgentRegistrationWebComponent>,
    @Optional() public dialogContactRef: MatDialogRef<ContactPopUp>,
    private router: Router,
    private route: ActivatedRoute) {
      this.route.queryParams.subscribe(params =>{
        if(params['success']){
          setTimeout(()=>{
            this.message =  this.translate.instant('Baluwo.autoregister.agents.thanks');
            this.showAutoregistrationThanksPopUp(this.message);
          }, 200);
        }
    });
  }

  ngOnInit() {
    this.dinamicMetaTags.initPartnersMetaTag();
    $('.headerMain__navLi--action').addClass('partners');
    this._utmCampaignHelper.checkCampaign();
  }

  showAgentRegistration(){
    this.dialogAgentRegistrationRef = this.dialog.open(AgentRegistrationWebComponent, {
      panelClass: 'loginLayoutResets'
    });
  }

  showContactPopUp(){
    this._contactPopUp.showContactPopup();
  }

  showAutoregistrationThanksPopUp(message){
    const dialogRef = this.dialog.open(SuccessPopupGenericComponent,{
      panelClass: 'loginLayoutResets',
      data: {message: message}
    })
    dialogRef.afterClosed().subscribe(()=>{
      this.router.navigate(['/partners/agents']);
    })
  }
}


