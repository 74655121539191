import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AnalyticsHelper } from "../../../shared/_helpers/_analytics.helper";
import { LocationSectorStoreHelper } from "../../../shared/_helpers/_locationSectorStore.helper";
import { ScrollerTargetHelper } from "../../../shared/_helpers/_scrollerTarget.helper";
import { environment } from "../../../../environments/environment";
import * as $ from 'jquery';
import { GenericPopUpComponent } from "../../genericpopup/genericpopup.component";
import { MatDialog } from "@angular/material";

@Component({
    selector: 'app-home-sectors-component',
    templateUrl: './home_sectors.component.html',
    styleUrls: ['../home.component.scss', './home_sectors.component.scss']
})

export class HomeSectorsComponent {
    public _ENVIRONMENT_ = environment;
    public sectorChoosed: boolean;

    @Input() showNetworkFail: boolean;
    @Input() sectorsIds: any;
    @Input() isMobile: boolean = false;
    @Output() nextStepEmit: EventEmitter<number> = new EventEmitter<number>();

    constructor(public locationSectorStoreHelper: LocationSectorStoreHelper,
        public _analyticsHelper: AnalyticsHelper,
        private dialog: MatDialog,
        public _scrollerTargetHelper: ScrollerTargetHelper) { }


    nextStep() {
        this.nextStepEmit.emit(2);
    }

    openPopAdvert(){
        const dialogRef = this.dialog.open(GenericPopUpComponent, {
        })
    }

    sectorSelected(id) {
        if (this.locationSectorStoreHelper.sectors && this.sectorsIds.length !== 0) {
            this.locationSectorStoreHelper.sectors.forEach(d => {
                if (id === d.id) {
                    this._analyticsHelper.sendGoogleAnalyticsSelectedSector(d);
                    this.sectorSelectedClassChange(id);
                    this.locationSectorStoreHelper.currentSector = d;
                    this.sectorChoosed = true;
                    if (this.locationSectorStoreHelper.currentSector.type === 'store') {
                        this.locationSectorStoreHelper.changeSector();
                    }
                    if (this.locationSectorStoreHelper.currentSector.type === 'generic') {
                        this.locationSectorStoreHelper.getGiftCard();
                    }
                    if (screen.height <= 768 || this.isMobile) {
                        this._scrollerTargetHelper.scrollToTarget("buttonStep");
                    }

                }
            })
        }
    }

    sectorSelectedClassChange(id) {
        $('.mscBox__selection-product').removeClass('selected');
        $('.sector-' + id).addClass('selected');
    }
}