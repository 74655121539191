import { Component, EventEmitter, Input, Output } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { LoginService } from "../../../core/login-service/login.service";
import { TransactionServiceV1 } from "../../../core/services/transactionv1.service";
import { Buyer, Transaction } from "../../../shared/_objects";
import { LoginHelperUserComponent } from "../loginhelpers/loginHelperUser.component";

@Component({
    selector: 'app-loginmodal-password',
    templateUrl: 'modal-step_password.component.html',
    styleUrls: ['../loginmodal.component.scss']
})

export class LoginmodalStepPasswordComponent {
    public password: string;
    buyer: Buyer = new Buyer;
    currentTransaction: Transaction;
    captchKey: string;
    wrongUser: boolean = false;
    fieldTextType: boolean;

    @Input() mobile: string;
    @Output() onOpenReset: EventEmitter<any> = new EventEmitter<any>();
    @Output() onBuyerCreated: EventEmitter<Buyer> = new EventEmitter<Buyer>();
    @Output() onClosingDialog: EventEmitter<any> = new EventEmitter<any>();
    constructor(public loginService: LoginService,
        public transactionService: TransactionServiceV1,
        public loginHelperUserComponent: LoginHelperUserComponent
    ) {
        this.captchKey = environment.captchaKey;
    }

    authenticatePassword() {
        this.wrongUser = false;
        this.loginHelperUserComponent.setAuth(this.mobile, this.password);
        setTimeout(() => {
            this.loginHelperUserComponent.gatNewBuyerCreated.subscribe(res => {
                console.log(res.id);
                if (res.id !== undefined) {
                    this.onBuyerCreated.emit(res);
                } else {
                    this.wrongUser = true;
                }
            })
        }, 300)
    }

    toggleFieldTextType() {
        this.fieldTextType = !this.fieldTextType;
    }

    public resolved(captchaResponse: string) {
        this.sendRecoveryPassword(captchaResponse);
    }

    sendRecoveryPassword(token: string) {
        const mobile = { mobile: this.mobile }
        this.loginService.recoverPassword(mobile, token).subscribe(res => {
            this.onOpenReset.emit(this.mobile)
        }, error => {
            console.log(error);
        })
    }

    close() {
        this.onClosingDialog.emit()
    }
}