import { Component, Input } from "@angular/core";
import { Categories } from "../../../shared/_objects/categories";

@Component({
    selector: 'app-categories-component',
    templateUrl: './home_categories.component.html',
    styleUrls: ['./home_categories.component.scss']
})

export class HomeCategoriesComponent {
    @Input() defaultCategories: Categories[]
    @Input() categories: any[]
    constructor() {
    }

    getActiveCategories(catg: number){
       return this.categories.some(cat => cat.id == catg)
    }
}