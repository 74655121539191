import { Component } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { CookiePolicyService } from "../../shared/_services/cookie-policy.service";

@Component({
    selector: 'app-cookie-policy-component',
    templateUrl: 'cookie-policy.component.html',
    styleUrls: ['../legalconditions/legalconditions.component.scss', '../../../assets/css/bootstrap-grid.min.css'],
})

export class CookiePolicyComponent{
    public cookiePolicyContent: any;
    constructor(private _cookiePolicyService: CookiePolicyService, public sanitizer: DomSanitizer){
        this._cookiePolicyService.get().then((data) => {
            this.cookiePolicyContent = sanitizer.bypassSecurityTrustHtml(data);
        });
    }
}