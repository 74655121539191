import { Component, Input } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Email } from "../../../shared/_objects/email";
import { LoginService } from "../../../core/login-service/login.service";
import { MatDialog } from "@angular/material";
import { SuccesspopupComponent } from "../../successpopup/successpopup.component";
import { CountryService } from "../../../shared/_services/country.services";
import { environment } from "../../../../environments/environment";
import { ContextBase } from "../../../shared/_helpers/_contextBase.helper";
import { Router } from "@angular/router";

@Component({
    selector: 'app-home-contact-component',
    templateUrl: './home_contactSection.component.html',
    styleUrls: ['../home.component.scss']
})

export class HomeContactSectionComponent {
    contactForm: FormGroup;
    contactEmail = new Email;
    isSubmitted: boolean = false;
    isValidForm = true;
    isValidFormLeadName = true;
    isValidFormLeadPhone = true;
    isValidFormLeadEmail = true;
    isValidFormLeadMessage = true;
    landingSourceCountry: any;
    countryArray: any[] = [];
    countryPrefix: any;
    public _ENVIRONMENT_ = environment;
    keepContinue: boolean = false;

    constructor(
        private fb: FormBuilder,
        private loginService: LoginService,
        private dialog: MatDialog,
        public contexto: ContextBase,
        private router: Router,
        private countryService: CountryService
    ) {
        this.landingSourceCountry = JSON.parse(localStorage.getItem('landingSourceCountry'))
        this.countryService.getBuyingCountries().subscribe(
            c => {
                this.countryArray = c;
                this.countryPrefix = this.countryArray.filter(country => country.id === this.landingSourceCountry)[0];
            });
        this.contactForm = this.fb.group({
            'leadName': ['', Validators.compose([Validators.required])],
            'leadPrefix': ['', Validators.compose([Validators.required])],
            'leadPhone': ['', Validators.compose([Validators.required])],
            'leadEmail': ['', Validators.compose([Validators.required, Validators.pattern('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,6}')])],
            'leadMessage': ['', Validators.compose([Validators.required])]
        });

        this.contactEmail = <Email>{};
    }
    validateControl(control_name: string, validator_name: string) {
        return this.contactForm.controls[control_name].hasError(validator_name) && (!this.contactForm.controls[control_name].pristine || this.contactForm.controls[control_name].touched);
    }

    onSubmit(contactEmail) {
        this.isSubmitted = true;
        if (this.contactForm.valid) {
            this.contactEmail.leadPhone = this.countryPrefix.mobilePrefix + this.contactEmail.leadPhone;
            this.contactEmail.destinationEmail = this._ENVIRONMENT_.EmailHello;
            this.contactEmail.destinationBBCEmail = this._ENVIRONMENT_.EmailBBC;
            this.loginService.sendContactEmail(this.contactEmail).subscribe(() => {
                const dialogContactRef = this.dialog.open(SuccesspopupComponent);
                dialogContactRef.afterClosed().subscribe(() => {
                    this.contactForm.reset();
                    this.isSubmitted = false;
                })
            });
        }
    }

    navigateTo(e: MouseEvent, page: string): void {
        e.preventDefault();
        this.router.navigate(['/'+ page]);
      }
}