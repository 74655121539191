import { CartlandingComponent } from './pages/cartlanding/cartlanding.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginmodalComponent } from './pages/loginmodal/loginmodal.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyaccountComponent } from './pages/myaccount/myaccount.component';
import { AboutUsComponent } from './pages/aboutus/aboutus.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { SuccesspopupComponent } from './pages/successpopup/successpopup.component';
import { PartnersAgentsComponent } from './pages/partners/agents/partnersagents.component';
import { PartnersRetailersComponent } from './pages/partners/retailers/partnersretailers.component';
import { FaqsComponent } from './pages/faqs/faqs.component';
import { LegalConditionsComponent } from './pages/legalconditions/legalconditions.component';
import { ErrorPopUpComponent } from './pages/errorpopup/errorpopup.component';
import { AgentBaluwoRegistrationComponent } from './pages/agentbaluworegistration/agentbaluworegistration.component';
import { ThankyoupageComponent } from './pages/thankyoupage/thankyoupage.component';
import { CardTermsAndConditionsComponent } from './pages/cardtermsandconditions/cardtermsandconditions.component';
import { AppCardTermsAndConditionsComponent } from './pages/cardtermsandconditions/appcardtermsandconditions.component';
import { GDPRComponent } from './pages/gdpr/gdpr.component';
import { APPLegalConditionsComponent } from './pages/legalconditions/applegalconditions.component';
import { PromoSectionComponent } from './pages/promotionsection/promotionsection.component';
import { NationalRechargeComponent } from './pages/nationalrecharge/nationalrecharge.component';
import { MgmComponent } from './pages/mgm/mgm.component';
import { SisalComponent } from './pages/sisal/sisal.component';
import { DiemmesseProductsComponent } from './pages/diemmesse/products/diemmesse_products.component';
import { CookiePolicyComponent } from './pages/cookie-policy/cookie-policy.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { AppPrivacyPolicyComponent } from './pages/privacy-policy/app-privacy-policy.component';
import { RemitlyComponent } from './pages/remitly/remitly.component';
import { PageNotFoundComponent } from './pages/pagenotfound/pagenotfound.component';
import { FakeContactComponent } from './pages/fakecontact/fakecontact.component';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: ':lang/:curr/home', redirectTo: 'home' },
  // { path: 'cartlanding', component: CartlandingComponent },
  // { path: 'thanks', component: ThankyoupageComponent },
  // { path: 'login', component: LoginmodalComponent },
  // { path: 'account', component: MyaccountComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'contact-us', component: ContactusComponent },
  { path: 'success-popup', component: SuccesspopupComponent },
  { path: 'error-popup', component: ErrorPopUpComponent },
  { path: 'partners/agents', component: PartnersAgentsComponent },
  { path: 'partners/retailers', component: PartnersRetailersComponent },
  { path: 'faqs', component: FaqsComponent },
  { path: 'legal-conditions', component: LegalConditionsComponent },
  { path: 'agent-baluwo-registration', component: AgentBaluwoRegistrationComponent },
  { path: 'card-terms-and-conditions', component: CardTermsAndConditionsComponent },
  { path: ':lang/:curr/app-card-terms-and-conditions', component: AppCardTermsAndConditionsComponent },
  { path: ':lang/:curr/app-legal-conditions', component: APPLegalConditionsComponent },
  { path: ':lang/:curr/app-privacy-policy', component: AppPrivacyPolicyComponent },
  { path: 'gdpr', component: GDPRComponent },
  // { path: 'nationalrecharge', component: NationalRechargeComponent },
  { path: 'promotion-section', component: PromoSectionComponent },
  { path: 'diemmeesse/:pin', component: SisalComponent },
  { path: 'diemmeesse/products/price', component: DiemmesseProductsComponent },
  { path: 'cookie-policy', component: CookiePolicyComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'fake-contact', component: FakeContactComponent },
  { path: 'en/eur/remitly', component: RemitlyComponent },
  //Wild Card Route for 404 request
  {
    path: '**',
    component: PageNotFoundComponent
  },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
