import { Injectable } from "@angular/core";
import { ContextBase } from "./_contextBase.helper";
import { Order } from "../_objects/order";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class PromotionHelper {
    amount: string;
    charge: string;
    amountFood: string;
    chargeFood: string;

    public updatePromoNumber = new BehaviorSubject(<any>{});
    getPromoNumber = this.updatePromoNumber.asObservable();

    constructor(public contexto: ContextBase) { }

    setPromoLength(length: number): void {
        this.updatePromoNumber.next(length);
    }

    getDakarStorePromo() {
        switch (this.contexto.currency) {
            case 'eur':
                return '50 €';
            case 'gbp':
                return '50 GBP';
            case 'sek':
                return '500 SEK';
            case 'usd':
                return '$50';
        }
    }

    getCurrencyMGMPromo() {
        switch (this.contexto.currency.toLowerCase()) {
            case 'eur':
                return '5€';
            case 'gbp':
                return '5 GBP';
            case 'sek':
                return '50 SEK';
            case 'usd':
                return '$5';
            default:
                return '5€';
        }
    }

    getCurrencyMGMPromoFood() {
        switch (this.contexto.currency.toLowerCase()) {
            case 'eur':
                return '25€';
            case 'gbp':
                return '25 GBP';
            case 'sek':
                return '250 SEK';
            case 'usd':
                return '$25';
            default:
                return '25€';
        }
    }

    getCurrencyMGMPromoTopUp() {
        switch (this.contexto.currency.toLowerCase()) {
            case 'eur':
                return '10€';
            case 'gbp':
                return '10 GBP';
            case 'sek':
                return '100 SEK';
            case 'usd':
                return '$10';
            default:
                return '10€';
        }
    }

    calculatePromotion(o: Order) {
        // Leverage this function to add ramadan promotion
        let bags = 0;
        if (o.lines != undefined) {
            o.lines.forEach(l => {
                const name = l.catalog.product.name;
                if (name.toLocaleLowerCase().indexOf('rice') > -1 || name.toLocaleLowerCase().indexOf('riz') > -1
                    || name.toLocaleLowerCase().indexOf('sugar') > -1 || name.toLocaleLowerCase().indexOf('sucre') > -1) {
                    bags += l.quantity;
                }
            });
        }
    }

    checkPromoElectricityCurrency(curr) {
        switch (curr) {
            case 'eur':
                this.amount = '10€';
                this.charge = '3€';
                break;
            case 'usd':
                this.amount = '$12';
                this.charge = '$3.50';
                break;
            case 'gbp':
                this.amount = '£9';
                this.charge = '£2.70';
                break;
            case 'sek':
                this.amount = '110SEK';
                this.charge = '32SEK';
                break;
            default:
                this.amount = '10€';
                this.charge = '3€';
        }
    }

    checkPromoFoodCurrency(curr) {
        switch (curr) {
            case 'eur':
                this.amountFood = '20€';
                this.chargeFood = '3€';
                break;
            case 'usd':
                this.amountFood = '$24';
                this.chargeFood = '$3.50';
                break;
            case 'gbp':
                this.amountFood = '£18';
                this.chargeFood = '£2.70';
                break;
            case 'sek':
                this.amountFood = '210SEK';
                this.chargeFood = '32SEK';
                break;
            default:
                this.amountFood = '20€';
                this.chargeFood = '3€';
        }
    }


}