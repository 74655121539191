import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { FaqsComponent } from './faqs.component';


@NgModule({
	imports: [
        SharedModule
	],
	declarations: [
        FaqsComponent
	],
	providers: [
	],
	entryComponents: [
        FaqsComponent
	],
	exports: [

	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})

export class FaqsComponentModule { }
