import { Component, Output, EventEmitter } from "@angular/core";
import { DinamicMetaTags } from "../../shared/_helpers/_dinamicMetaTags";
import { TranslateService } from "@ngx-translate/core";
import { ContactPopUp } from "../../shared/_helpers/_contactpopup.helper";
import * as $ from 'jquery';
import { LegalConditionsService } from "../../shared/_services/legal-condition.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    templateUrl: 'applegalconditions.component.html',
    styleUrls: ['./legalconditions.component.scss'],
})

export class APPLegalConditionsComponent{
    @Output() SuccesspopupComponent: EventEmitter<any> = new EventEmitter<any>();
    public LegalConditionsContent: any;

    constructor(public _contactPopUp: ContactPopUp, 
        private _legalConditionsService: LegalConditionsService, 
        public sanitizer: DomSanitizer,
        private dinamicMetaTags:DinamicMetaTags, 
        private translate: TranslateService){
        this.dinamicMetaTags.initLegalConditionsMetaTag();
        $('.headerMain__navLi--action').addClass('legal');
        this._legalConditionsService.get().then((data) => {
            this.LegalConditionsContent = sanitizer.bypassSecurityTrustHtml(data);
        });
    }
    ngOnInit(){
        $('.footer').hide();
    }

}