import { Component } from "@angular/core";

@Component({
    selector: 'app-home-service-component',
    templateUrl: './home_serviceSection.component.html',
    styleUrls:['../home.component.scss']
})

export class HomeServiceSectionComponent{
    constructor(){}
}