import { Country } from "./country";
import { Sector, Store } from "./store";

export class EcommerceModelPurchase {
    'event': 'ecommerce_purchase'
    'ecommerce': {
        'purchase': {
            'actionField': {
                'id': number,
                'revenue': number,
            },
            'products': [{
                'name'?: string,
                'id': number,
                'category': string,
                'quantity': number
            }]
        }

    }
}

export class EcommerceModelCheckout {
    'event': 'checkout'
    'ecommerce': {
        'checkout': {
            'actionField': {
                'step': number,
            },
            'products': [{
                'name'?: string,
                'id': number,
                'price'?: number,
                'category': string,
                'quantity': number
            }]
        }

    }
}

export class userlanguage {
    'event': 'userlanguage'
    'userLangValue': string
}

export class previewcountry {
    'event': 'previewcountry'
    'previewCountryValue': Country["name"]
}

export class previewsector {
    'event': 'previewsector'
    'previewSectorValue': Sector["name"]
}

export class previewstore {
    'event': 'previewstore'
    'previewStoreValue': Store["name"]
}

export class EcommerceNewModelPurchase {
    event: 'purchase'
    ecommerce: {
        transaction_id: number,
        value: number,
        currency: number,
        items:[{
            item_name?:string,
            item_id: number,
            quantity: number,
            item_category: string
        }]
    }
}

export class EcommerceNewModelCheckout {
    'event': 'checkout'
    ecommerce: {
        step:number,
        items:[{
            item_name?:string,
            item_id: number,
            quantity: number,
            price?:number,
            item_category: string
        }]
    }
}