import { Component } from "@angular/core";
import { NationalRechargeService } from "../../core/services/nationalrecharge.service";
import { NationalRecharge, NationalInputData, NationalProductAmount, NationalProduct, NationalProductPrice } from "../../shared/_objects";
import { LocationSectorStoreHelper } from "../../shared/_helpers/_locationSectorStore.helper";
import { ScrollerTargetHelper } from "../../shared/_helpers/_scrollerTarget.helper";
import * as $ from 'jquery';
import { TransactionServiceV1 } from "../../core/services/transactionv1.service";
import { TransactionHelper } from "../../shared/_helpers/_transactionHelper.helper";
import { ServerErrorHelper } from "../../shared/_helpers/servererrors.helper";
import { ErrorPopUpComponent } from "../errorpopup/errorpopup.component";
import { currentBuyer, TransactionId } from '../../globals';
import { StoreOrderUniq } from "../../shared/_objects/order";
import { MatDialog } from "@angular/material";
import { ContextBase } from "../../shared/_helpers/_contextBase.helper";

@Component({
    selector: 'app-nationalrecharge-component',
    templateUrl: './nationalrecharge.component.html',
    styleUrls: ['./nationalrecharge.component.scss', '../home/home.component.scss', '../../../assets/css/bootstrap-grid.min.css']
})

export class NationalRechargeComponent {
    public nationalPrefix: string;
    public premobile: string;
    public PROVIDERDATA: NationalRecharge[];
    public PRODUCTDATA: any;
    public openRange: boolean;
    public inputData: NationalInputData;
    public inputProductPrice: NationalProductAmount;
    public openRangeValue: number;
    public openRangeMin: number;
    public openRangeMax: number;
    public openRangeProduct: any;
    public openRangeProductCode: string;
    public showTarget: boolean;
    public phone: string;
    public phoneFull: string;
    public lycaProduct: boolean;
    public termsArray: Array<string>;
    transactionIdUniq: any;
    uniqOrder: StoreOrderUniq;
    sourceCurrency: string;
    updateHeader = 1;
    transaction: any;
    buttonBuy: boolean = false;
    public errorMesage: string;
    public BUYER: any;
    public invalidPhone: boolean;
    constructor(
        private nationalRechargeService: NationalRechargeService,
        public locationSectorStoreHelper: LocationSectorStoreHelper,
        public _scrollerTargetHelper: ScrollerTargetHelper,
        public transactionServiceV1: TransactionServiceV1,
        public _transactionHelper: TransactionHelper,
        public serverErrorHelper: ServerErrorHelper,
        public contexto: ContextBase,
        private dialog: MatDialog
    ) {
        setTimeout(() => {
            this.nationalPrefix = JSON.parse(localStorage.getItem('landingSourceCountryCode'));
        }, 200);
        this.transactionIdUniq = JSON.parse(localStorage.getItem(TransactionId));
        console.log(this.nationalPrefix)
        this.inputData = <NationalInputData>{};
        this.BUYER = JSON.parse(localStorage.getItem(currentBuyer));
        this.sourceCurrency = this.contexto.currency.toUpperCase();
        console.log(this.BUYER);
    }

    ngOnInit() {
        if (localStorage.getItem(currentBuyer) != null) {
            this.transactionServiceV1.getTransactionLogged(this.contexto.transMessage).subscribe((data) => {
                this.transaction = data;
                this._transactionHelper.calculateCartTotal(data);
                this.checkTransactionOrders();
                localStorage.setItem(TransactionId, this.transaction.id);
                console.log('transaction active logged: ', this.transaction);
            });
        } else {
            if (localStorage.getItem(TransactionId) != null) {
                this.getTransactionNotLogged();
            }
        }
    }

    getTransactionNotLogged() {
        this.transactionServiceV1.getTransaction(this.transactionIdUniq, this.contexto.transMessage).subscribe((data) => {
            this.transaction = data;
            this._transactionHelper.calculateCartTotal(data);
            this.checkTransactionOrders();
            if (this.transaction.currency.id != this.sourceCurrency) {
                this.getTransactionNewCurrency();
            }
            console.log('transaction active: ', this.transaction);
        }, error => {
            console.log('ERROR');
        });
    }

    getTransactionNewCurrency() {
        this.transactionServiceV1.setCurrencyTransaction(this.transactionIdUniq, this.sourceCurrency.toLowerCase()).subscribe((res) => {
            console.log('transaction new currency: ', res);
            this.updateHeader++;
            this._transactionHelper.calculateCartTotal(res);
        })
    }

    getLogos(provider:string){
        let tmp =provider.replace(/\s/g, '').toUpperCase()
        return "/assets/images/landing/logos/national/" + tmp +".png"
    }

    checkAndCreatePhone() {
        this.phone = this.premobile.trim().replace(/\s/g, "");
        if (this.phone.charAt(0) !== '+') {
            this.phoneFull = '+' + this.nationalPrefix + this.phone;
        } else {
            this.phoneFull = this.phone;
        }
        this.inputData.mobile = this.phoneFull;
        console.log(this.phoneFull);
        this.getProvidersList();
    }

    getProvidersList() {
        this.PROVIDERDATA = [];
        this.PRODUCTDATA = [];
        this.openRangeValue = null;
        this.openRange = null;
        this.nationalRechargeService.getProvidersLogged(this.phoneFull).subscribe(res => {
            console.log(res);
            this.PROVIDERDATA = res;
            this.invalidPhone = false;
        }, error => {
            console.log(error)
            if (error.code === 18) {
                this.invalidPhone = true;
            } else {
                this.getError(error);
            }
        })
    }

    getProviderSelected(e) {
        if (e.value !== undefined && e.value !== '') {
            (e.value === 'lyca') ? this.lycaProduct = true : this.lycaProduct = false;
            this.nationalRechargeService.getProducts(e.value).subscribe(res => {
                console.log(res);
                this.inputData.providerCode = e.value;
                this.setMaxMinAmounts(res);
                this._scrollerTargetHelper.scrollToTarget('storeselected');
            })
        }
    }

    getProductDescrinption(desc: string) {
        const orderedDescription = desc.split('-').join(',').split('+').join(',').split(',');
        return orderedDescription;
    }

    getProductAmount(product: any, evt: any, best?: any) {
        this.inputData.productCode = product.productCode;
        this.inputData.amount = product.maxAmount.srcValue;
        this.openRangeValue = null;
        this.removeProductFocus();
        this.addProductClass(evt, best);
        console.log(this.inputData);
        this.uniqOrder = <any>{};
        this.uniqOrder.mobile = this.inputData.mobile;
        this.uniqOrder.productCode = this.inputData.productCode;
        this.uniqOrder.providerCode = this.inputData.providerCode;
        this.uniqOrder.total = this.inputData.amount;
        this.uniqOrder.type = 'national';
        // this._scrollerTargetHelper.scrollToTarget('sendpayment');
    }

    setMaxMinAmounts(res: NationalProduct[]) {
        if (res[0].maxAmount.targetValue === res[0].minAmount.targetValue) {
            this.PRODUCTDATA = res;
            this.openRange = false;
            this.inputData.amount = null;
            this.openRangeValue = null;
            // this.spinnerService.hide();
            this.checkContextDisplayedText(res[0]);
        } else {
            // this.spinnerService.hide();
            this.openRange = true;
            this.openRangeProduct = res;
            this.openRangeMax = res[0].maxAmount.srcValue;
            this.openRangeMin = res[0].minAmount.srcValue;
        }
    }

    checkContextDisplayedText(res: NationalProduct) {
        if (res.displayText.indexOf(res.maxAmount.targetValue.toString()) == -1) {
            this.showTarget = true;
        } else {
            this.showTarget = false;
        }
    }

    createInputDataPrice() {
        this.inputProductPrice = <NationalProductAmount>{};
        this.inputProductPrice.productCode = this.openRangeProduct[0].productCode;
        this.inputProductPrice.providerCode = this.openRangeProduct[0].providerCode;
        this.inputProductPrice.amount = this.openRangeValue;
        this.openRangeProductCode = this.openRangeProduct[0].productCode;

    }

    setInputDataContent(res: NationalProductPrice) {
        this.inputData.productCode = res.productCode;
        this.inputData.amount = res.price.srcValue;
        this.inputData.providerCode = res.providerCode;
        console.log(this.inputData);
    }

    addProductClass(evt: any, best?: string) {
        if (best !== undefined) {
            $('.boxp-' + evt + best).addClass('selected');
        } else {
            $('.boxp-' + evt).addClass('selected');
        }
        $('.frpd-' + evt).addClass('fake-active');
    }

    removeProductFocus() {
        $('div[class^=product__box]').each((index, ele: Element) => {
            const $this = $(ele);
            $this.removeClass('selected');
        });
        $('.frpd').each((index, ele: Element) => {
            const $this = $(ele);
            $this.removeClass('fake-active');
        });
    }

    getGBProductAmount(desc: string) {
        if (desc) {
            const word = desc.indexOf('GB');
            if (word !== -1) {
                let n = 2;
                while (!isNaN(parseInt(desc.substr((word - n), 1)))) {
                    n += 1;
                }
                return desc.substr((word - n + 1), 2 + (n - 1))
            } else { return ''; }
        }
    }

    getLycaBundleBundle(desc: string) {
        if (desc) {
            const word = desc.indexOf('GB');
            if (word !== -1) {
                return true;
            } else {
                return false;
            }
        }
    }

    getCountryRechargeStyle(desc: string) {
        if (desc.toUpperCase().indexOf('MALI') !== -1) {
            return { 'background-color': 'rgba(0, 95, 161, 0.4)' }
        } else if (desc.toUpperCase().indexOf('IVORY COAST') !== -1) {
            return { 'background-color': 'rgba(0, 95, 161, 0.4)' }
        } else if (desc.toUpperCase().indexOf('GUINNEA') !== -1) {
            return { 'background-color': 'rgba(0, 95, 161, 0.4)' }
        } else {
            return { 'background-color': 'rgba(0, 95, 161, 0.1)' }
        }
    }

    getCountryRechargeBoolean(desc: string) {
        const word = desc.indexOf('GB');
        if (desc.toUpperCase().indexOf('MALI') !== -1
            || desc.toUpperCase().indexOf('IVORY COAST') !== -1
            || desc.toUpperCase().indexOf('GUINNEA') !== -1
            || word !== -1) {
            return true;
        } else { return false; }
    }

    getArrayFromTerms(terms: any) {
        let prelArray = [];
        this.termsArray = [];
        prelArray = terms.split(String(['\r\n']));
        for (let x = 0; x < prelArray.length; x++) {
            if (prelArray[x] != "") {
                this.termsArray.push(prelArray[x]);
            }
        }
        return this.termsArray;
    }


    createTransaction() {
        if (this.transactionIdUniq == null && localStorage.getItem(currentBuyer) == null) {
            this.initTransaction();
        } else if (localStorage.getItem(currentBuyer) !== null) {
            this.transactionServiceV1.setTransactionLogged(this.uniqOrder).subscribe((res) => {
                localStorage.setItem(TransactionId, res.id);
                this.updateHeader++;
                this._transactionHelper.calculateCartTotal(res);
                this.transaction = res;
                this.checkTransactionOrders();
            }, error => {
                let errorcode = error;
                this.fialTransactionBeneficiary();
                if (errorcode.status !== 401) this.getError(error);
            })
        } else {
            this.transactionServiceV1.setTransaction(this.transactionIdUniq, this.uniqOrder).subscribe((res) => {
                this.updateHeader++;
                this._transactionHelper.calculateCartTotal(res);
                this.transaction = res;
                this.checkTransactionOrders();
            }, error => {
                let errorcode = error;
                this.fialTransactionBeneficiary();
                this.getError(error);
            })
        }
    }

    getError(error, msg?) {
        let errorcode = error;
        if (msg) {
            this.errorMesage = this.serverErrorHelper.getErrorMsg(errorcode.code, errorcode.message);
        } else {
            this.errorMesage = this.serverErrorHelper.getErrorMsg(errorcode.code);
        }
        const dialogErrorRef = this.dialog.open(ErrorPopUpComponent, {
            panelClass: 'loginLayoutResets',
            data: { error: this.errorMesage }
        });
        dialogErrorRef.afterClosed().subscribe(() => { });
    }

    fialTransactionBeneficiary() {
        // this.allowPayment = false;
        // this.buttonBuy = false;
    }

    initTransaction() {
        this.transactionServiceV1.getDefaultTransaction(this.contexto.transMessage).subscribe((res) => {
            this.transactionIdUniq = res.id;
            localStorage.setItem(TransactionId, this.transactionIdUniq);
            this.transactionServiceV1.setCurrencyTransaction(this.transactionIdUniq, 'eur').subscribe((res) => {
                console.log('transaction new currency: ', res);
                console.log(this.uniqOrder);
                this.transactionServiceV1.setTransaction(this.transactionIdUniq, this.uniqOrder).subscribe((res) => {
                    this.updateHeader++;
                    this._transactionHelper.calculateCartTotal(res);
                    this.transaction = res;
                    this.checkTransactionOrders();
                })
            })
        })
    }

    checkTransactionOrders() {
        (this.transaction.orders) ? this.buttonBuy = true : this.buttonBuy = false;
    }
}