import { Component, Optional, Inject, EventEmitter } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material";

@Component({
    selector: 'generic-popup-component',
    templateUrl: './genericpopup.component.html',
    styleUrls: ['./genericpopup.component.scss']
})

export class GenericPopUpComponent {
    countryId: any;
    GMNI: boolean;
    accepted = new EventEmitter();
    constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogue: MatDialog) {
        // this.countryId = data.countryId;
        // if (this.countryId === 'GM' || this.countryId === 'NG') {
        //     console.log(this.countryId);
        //     console.log(typeof this.countryId);
        //     this.GMNI = true;
        // } else {
        //     console.log(this.countryId);
        //     console.log(typeof this.countryId);
        //     this.GMNI = false;
        // }
    }

    cancel() {
        this.dialogue.closeAll();
    }

    continue() {
        this.accepted.emit();
        this.cancel();
    }
}