import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { HomeComponent } from './home.component';
import { HomeTestimonySectionComponent } from './home_testimonySection/home_testimonySection.component';
import { HomeServiceSectionComponent } from './home_serviceSection/home_serviceSection.component';
import { HomeProviderSectionComponent } from './home_providerSection/home_providerSection.component';
import { HomeDownloadappSectionComponent } from './home_downloadappSection/home_dowloadappSection.component';
import { HomeContactSectionComponent } from './home_constactSection/home_contactSection.component';
import { SharedModule } from '../../shared.module';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { MaterialDesignFrameworkModule } from 'angular6-json-schema-form';
import { CustomAmountInput } from './custom-widgets/custom-input';
import { HomeBillPaymentComponent } from './home_billpayment/home_billpayment.component';
import { HealtCareTermsAndConditionsComponent } from './home_healthcare/healthcare_t&c.component';
import { GiftCardComponent } from './home_giftcard/home_giftcard.component';
import { HomeBannerComponent } from './home_banner/home_banner.component';
import { BeneficiaryComponent } from './home_beneficiary/home_beneficiary.component';
import { HomeSectorsComponent } from './home_sectors/home_sectors.component';
import { HomeStoresComponent } from './home_stores/home_stores.component';
import { HomeCategoriesComponent } from './home_categories/home_categories.component';


@NgModule({
	imports: [
        SharedModule,
		NgxUsefulSwiperModule,
		MaterialDesignFrameworkModule
	],
	declarations: [
		HomeComponent,
		CustomAmountInput,
        HomeTestimonySectionComponent,
        HomeServiceSectionComponent,
        HomeProviderSectionComponent,
        HomeDownloadappSectionComponent,
		HomeContactSectionComponent,
		HomeBillPaymentComponent,
		HealtCareTermsAndConditionsComponent,
		GiftCardComponent,
		HomeBannerComponent,
		BeneficiaryComponent,
		HomeSectorsComponent,
		HomeStoresComponent,
		HomeCategoriesComponent
	],
	providers: [
	],
	entryComponents: [
		HomeComponent,
		CustomAmountInput
	],
	exports: [

	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})

export class HomeComponentModule { }
