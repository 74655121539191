import { Component, Input } from "@angular/core";
import { Order } from "../../../shared/_objects";
import { parse } from 'libphonenumber-js';
import { ValidityOrderHelper } from "../../../shared/_helpers/_validityOrder.helper";
import { LocationSectorStoreHelper } from "../../../shared/_helpers/_locationSectorStore.helper";

@Component({
    selector: 'app-order-details-control',
    templateUrl: './order-details.control.html',
    styleUrls: ['./order-details.control.scss']
})

export class OrderdetailControl {
    @Input() orderData: Order;
    @Input() transactionCurrency: string;
    public tmpOrderTotal: number;
    public currencyExchange: any;
    constructor(public _ValidityOrderHelper: ValidityOrderHelper,
        public _LocationSectorStoreHelper: LocationSectorStoreHelper) { }

    ngOnInit() {
        console.log(this.orderData)
        if (this.orderData.type == 'store') {
            this._ValidityOrderHelper.checkFactor('GMD')
            const orderLength = this.orderData.lines.length;
            if (orderLength > 1) {
                this.tmpOrderTotal = this.orderData.lines.reduce((total, obj) => obj.catalog.price + total, 0);
                setTimeout(() => {
                    console.log(this._ValidityOrderHelper.storeRate);
                }, 500)
            }
            if (orderLength == 1) {
                this.tmpOrderTotal = 1;
                console.log(this.tmpOrderTotal)
                setTimeout(() => {
                    console.log(this._ValidityOrderHelper.storeRate);
                }, 500)
            }
        }
    }

    getLastItemPrice() {
        let tmpTotal = 0;
        this.orderData.lines.forEach((obj)=>{
            tmpTotal += (((obj.catalog.price / this.tmpOrderTotal) * this.orderData.purchaseTotal) / obj.quantity);
            console.log(tmpTotal);
        })
        let tmpLast = (((this.orderData.lines[this.orderData.lines.length - 1].catalog.price / this.tmpOrderTotal) * this.orderData.purchaseTotal)/this.orderData.lines[this.orderData.lines.length - 1].quantity);
        console.log(tmpLast);
        console.log(tmpTotal - (tmpTotal - tmpLast))
    }

    parseNumberCountry(mobile: string) {
        return parse(mobile).country;
    }
}