import { Buyer } from '../../../shared/_objects/buyer';
import { Currency } from '../../../shared/_objects/country';
import { Transaction } from '../../../shared/_objects/transaction';
import { CartlandingComponent } from '../../cartlanding/cartlanding.component';
import { CommonService } from '../../../core/services/common.service';
import { currentBuyer, landingSourceCountry, landingSourceCountryCode, TransactionId, webBaseUrl, webBaseBlogUrl } from '../../../globals';
import { LoginmodalComponent } from '../../loginmodal/loginmodal.component';
import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild, QueryList, Optional, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ContextBase } from '../../../shared/_helpers/_contextBase.helper';
import { LogOutHelper } from '../../../shared/_helpers/_logout.helper';
import { ContactPopUp } from '../../../shared/_helpers/_contactpopup.helper';
import { HomeUtilsHelper } from '../../../shared/_helpers/_homeUtils.helper';
import { TransactionServiceV1 } from '../../../core/services/transactionv1.service';
import { LoginService } from '../../../core/login-service/login.service';
import { CurrencyAmountService } from '../../../shared/_services/currencyamount.service';
import { userlanguage } from '../../../shared/_objects/ecommerce';
import { AnalyticsHelper } from '../../../shared/_helpers/_analytics.helper';
import { UtilsHelper } from '../../../shared/_helpers/_utils.helper';
import { Header } from '../../../shared/_objects';
import { CustomareCareComponent } from '../../customarecare/customarecare.component';
import { TransactionHelper } from '../../../shared/_helpers/_transactionHelper.helper';
import { LocationSectorStoreHelper } from '../../../shared/_helpers/_locationSectorStore.helper';
import { PromotionHelper } from '../../../shared/_helpers/_promotions.helper';
import { NationalRechargeService } from '../../../core/services/nationalrecharge.service';
import { BuyerInfoService } from '../../../shared/_services/buyerinfo.service';
import { TranslateService } from '@ngx-translate/core';
import { HeaderCountryService } from '../../../shared/_services/header-country.service';
import { environment } from '../../../../environments/environment';
import * as $ from 'jquery';
import * as _ from 'lodash';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', '../../../../assets/css/bootstrap-grid.min.css']
})

export class HeaderComponent implements OnInit, OnChanges {
  sub: any;
  // buyer and transaction
  buyer: Buyer;
  buyerNameSalutation: string;
  transaction: Transaction;
  // language and currency
  languageList = [];
  currencyList = [];
  languageNames = [];
  language = 'ES';
  currency = 'EUR';
  currencySymbol: string;
  // check if is mobile
  isMobile = true;
  isDesktop = false;
  hamburgerActive = false;
  partnersActive = false;
  productsActive = false;
  subProductsActive = false;

  totalItems: number;
  totalAmount: number;
  href: string;

  allCurrency: Currency;
  helpingUrl: string = webBaseUrl;

  updateCurrTotalAmount = new EventEmitter();
  // Receives an event to update
  @Input() updateHeader: number;
  @Input() buttonBuy: boolean;
  @Input() updateTotalAmount: number;
  @Input() clean: boolean;
  // Event emiter as output for the contact form
  @Output() SuccesspopupComponent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSuccessLoginModal: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(CartlandingComponent)
  cartlandingComponent: CartlandingComponent;
  url: string;
  transactionIdUniq: number;
  userLanguage: userlanguage;
  public buyerWalletAmount: any;
  public param = { amount: '' };
  public landingSourceCountry: string;
  public showNationalRecharge: boolean;
  public promoLength: number;
  public baluwoCountries: any[] = [];
  public baluwoCountriesFiltered: any[] = [];
  public _ENVIRONMENT_ = environment;

  @ViewChild('productsMenu', { read: ElementRef }) elementView: ElementRef;

  // CONSTRUCTOR
  constructor(private commonService: CommonService,
    private router: Router,
    private contexto: ContextBase,
    public _contactPopUp: ContactPopUp,
    public _logOutHelper: LogOutHelper,
    private transactionServiceV1: TransactionServiceV1,
    public _homeUtilsHelper: HomeUtilsHelper,
    private activatedRoute: ActivatedRoute,
    public _transactionHelper: TransactionHelper,
    public locationSectorStoreHelper: LocationSectorStoreHelper,
    public nationalrechargeService: NationalRechargeService,
    private loginService: LoginService,
    public _currencyAmountService: CurrencyAmountService,
    private _analyticsHelper: AnalyticsHelper,
    public _utilsHelper: UtilsHelper,
    public translate: TranslateService,
    public _promoHelper: PromotionHelper,
    private _BuyerInfoService: BuyerInfoService,
    private _HeaderCountryService: HeaderCountryService,
    private dialog: MatDialog) {

    this.totalAmount = null;
    this.totalItems = null;
    this.currencySymbol = null;
    this.fetchData();

    this._currencyAmountService.curUpdatedHeader.subscribe(res => {
      this.totalAmount = res.amount;
      this.totalItems = res.item;
      this.currencySymbol = res.currency;
      if (localStorage.getItem(currentBuyer) !== null) {
        this.buyer = JSON.parse(localStorage.getItem(currentBuyer));
        if (this.buyer.name) this.buyerNameSalutation = this.buyer.name.split(' ')[0];
        if (this.buyer.wallet) this.buyerWalletAmount = this.buyer.wallet.aggregated;
      }
    })

    this._currencyAmountService.curWalletHeader.subscribe(res => {
      this.buyer = JSON.parse(localStorage.getItem(currentBuyer));
      if (this.buyer && this.buyer.wallet) this.buyerWalletAmount = this.buyer.wallet.aggregated;
    })

    this._BuyerInfoService.getBuyerCode.subscribe(res => {
      if (res !== 'object') {
        (res == "ES") ? this.showNationalRecharge = true : this.showNationalRecharge = false;
      }
    })

    this._promoHelper.getPromoNumber.subscribe(res => {
      (isNaN(res)) ? this.promoLength = null : this.promoLength = res + 2
    })

    this._HeaderCountryService.getheaderCountry.subscribe(res => {
      console.log(res);
      if (res.length) {
        this.baluwoCountries = res.filter(x => (x.id != 'CM') && (x.id != 'TD') && (x.id != 'LR'));
      }
    })
  }

  ngOnInit() {
    if (this.totalItems == 0) {
      this.totalItems = null;
      this.totalAmount = null;
    }
    if (this.updateTotalAmount) {
      this.totalAmount = this.updateTotalAmount;
    }

    const heightView = this.elementView.nativeElement.offsetHeight;
    console.log(heightView);
  }

  checkNationalRechargeAvailability(phone) {
    this.nationalrechargeService.getProvidersLogged(phone).subscribe(res => {
      console.log(res);
      (res.length !== 0) ? this.showNationalRecharge = true : this.showNationalRecharge = false;
    }, error => {
      this.showNationalRecharge = false;
    })
  }

  fetchData() {
    if (this.contexto.currency !== undefined && this.contexto.language !== undefined) {
      this.currency = this.contexto.currency.toUpperCase();
      this.language = this.contexto.language.toUpperCase();
    }
    if (localStorage.getItem(currentBuyer) !== null) {
      this.buyer = JSON.parse(localStorage.getItem(currentBuyer));
      this.currency = this.buyer.currency.id.toUpperCase();
      this.contexto.currency = this.currency;
      if (this.buyer.wallet) this.buyerWalletAmount = this.buyer.wallet.aggregated;
    }
    this.languageList = ["ES", "EN", "FR", "DE"];
    this.currencyList = this.commonService.getCurrencyList();
    this.languageNames = this.commonService.getLanguageNames();
  }

  login() {
    window.scrollTo(0, 0);
    const dialogRef = this.dialog.open(LoginmodalComponent, { panelClass: 'loginLayoutResets' });
    dialogRef.afterClosed().subscribe(b => {
      if (typeof b !== 'undefined') {
        this.buyer = b;
        this.currency = this.buyer.currency.id.toUpperCase();
        if (this.buyer.name) this.buyerNameSalutation = this.buyer.name.split(' ')[0];
        if (this.buyer.wallet) this.buyerWalletAmount = this.buyer.wallet.aggregated;
        console.log('confirmamos buyer ' + this.buyer.mobile);
        this.onSuccessLoginModal.emit(true);
        this._transactionHelper.changeCurrencyRef(this.buyer.currency.id.toUpperCase(), this.locationSectorStoreHelper.destinationCurrencyId);
        // document.location.reload();
        localStorage.setItem(landingSourceCountry, JSON.stringify(this.buyer.country.id));
        localStorage.setItem(landingSourceCountryCode, JSON.stringify(this.buyer.country.mobilePrefix.replace(/\+/g, '')));
        this.checkNationalRechargeAvailability(this.buyer.mobile);
      }
    });
  }

  logout() {
    this._logOutHelper.logout();
  }

  selectTabIndex(tab: number) {
    this.router.navigate(['/account', { tabindex: tab }]);
  }

  goToCart() {
    if (this.totalItems > 0) {
      this.router.navigate(['/cartlanding']);
    }
  }

  onChangeLang() {
    console.log(this.language)
    this._analyticsHelper.sendGoogleAnalyticsLanguage(this.language);
    localStorage.setItem('baluwoUserLanguage', this.language.toLocaleLowerCase());
    if (this.buyer !== null) {
      this.buyer.language = this.contexto.getLanguageInfo(this.language.toLowerCase());
      this.loginService.patchBuyer(this.buyer).subscribe((res) => {
        this.buyer = res;
        localStorage.setItem(currentBuyer, JSON.stringify(this.buyer));
      })
      this._BuyerInfoService.setBuyerLanguage(this.language);
    }
    this.translate.setDefaultLang(this.language.toLowerCase());
  }

  onChangeCurrency() {
    localStorage.setItem('baluwoUserCurrency', this.currency.toLocaleLowerCase());
    if (localStorage.getItem(currentBuyer) != null) {
      this.buyer = JSON.parse(localStorage.getItem(currentBuyer));
      this.buyer.currency = this.contexto.getCurrencyInfo(this.currency.toLowerCase());
      this.loginService.patchBuyer(this.buyer).subscribe((res) => {
        this.buyer = res;
        localStorage.setItem(currentBuyer, JSON.stringify(this.buyer));
        this.router.navigate(['/home']).then(() => {
          document.location.reload();
        });
      })
    } else {
      if (this.activatedRoute.snapshot.url[1] == undefined && this.activatedRoute.snapshot.url[0].path != 'cartlanding') {
        this.url = '/' + this.activatedRoute.snapshot.url[0].path;
      } else if (this.activatedRoute.snapshot.url[0].path == 'cartlanding') {
        this.url = '/home';
      } else {
        this.url = '/' + this.activatedRoute.snapshot.url[0].path + '/' + this.activatedRoute.snapshot.url[1].path;
      }
      this.router.navigate([this.url]).then(() => {
        document.location.reload();
      });

    }
  }

  goToBlog() {
    switch (this.language.toLowerCase()) {
      case 'es':
        return webBaseUrl + "contents/blog/";
      case 'fr':
        return webBaseUrl + "contents/blog/?lang=fr";
      default:
        return webBaseUrl + "contents/blog/?lang=en"

    }
  }

  gotToBlogContent(countryId) {
    const webBaseBlogUrlFalse = 'http://baluwo.com/contents/'
    switch (this.language.toLowerCase()) {
      case 'es':
        return webBaseBlogUrlFalse + this.translate.instant(`Baluwo.blog.country.${countryId}`).toLocaleLowerCase().replace(' ', '-').replace("´","");
      case 'fr':
        return webBaseBlogUrlFalse +"fr/"+ this.translate.instant(`Baluwo.blog.country.${countryId}`).toLocaleLowerCase().replace(' ', '-').replace("´","");
      default:
        return webBaseBlogUrlFalse +"en/"+ this.translate.instant(`Baluwo.blog.country.${countryId}`).toLocaleLowerCase().replace(' ', '-').replace("´","");
    }
  }

  gotToBlogContentLevel2(sectorId, countryId) {
    const webBaseBlogUrlFalse = 'http://baluwo.com/contents/'
    if(sectorId === this._ENVIRONMENT_.ApiIdAirTime){
      // return webBaseBlogUrlFalse + countryName.toLocaleLowerCase().replace(/\s/g, '-').replace("´","") + '/recargar-movil-'+ countryName.toLocaleLowerCase().replace(/\s/g, '-').replace("´","");
      switch (this.language.toLowerCase()) {
        case 'es':
          return webBaseBlogUrlFalse + this.translate.instant('Baluwo.blog.link.airtime') + this.translate.instant(`Baluwo.blog.country.${countryId}`).toLocaleLowerCase().replace("´","");
        case 'fr':
          return webBaseBlogUrlFalse +"fr/" + this.translate.instant(`Baluwo.blog.country.${countryId}`).toLocaleLowerCase().replace("´","") +"/"+this.translate.instant('Baluwo.blog.link.airtime') + this.translate.instant(`Baluwo.blog.country.${countryId}`).toLocaleLowerCase().replace("´","");
        default:
          return webBaseBlogUrlFalse + "en/" + this.translate.instant(`Baluwo.blog.country.${countryId}`).toLocaleLowerCase().replace("´","") +"/"+this.translate.instant('Baluwo.blog.link.airtime') + this.translate.instant(`Baluwo.blog.country.${countryId}`).toLocaleLowerCase().replace("´","");
      }
    } else if (sectorId === this._ENVIRONMENT_.ApiIdAirData){
      // return webBaseBlogUrlFalse + countryName.toLocaleLowerCase().replace(/\s/g, '-').replace("´","")+ '/recargar-datos-'+ countryName.toLocaleLowerCase().replace(/\s/g, '-').replace("´","");
      switch (this.language.toLowerCase()) {
        case 'es':
          return webBaseBlogUrlFalse + this.translate.instant('Baluwo.blog.link.airdata') + this.translate.instant(`Baluwo.blog.country.${countryId}`).toLocaleLowerCase().replace("´","");
        case 'fr':
          return webBaseBlogUrlFalse +"fr/" + this.translate.instant(`Baluwo.blog.country.${countryId}`).toLocaleLowerCase().replace("´","") +"/"+ this.translate.instant('Baluwo.blog.link.airdata') + this.translate.instant(`Baluwo.blog.country.${countryId}`).toLocaleLowerCase().replace("´","");
        default:
          return webBaseBlogUrlFalse + "en/" + this.translate.instant(`Baluwo.blog.country.${countryId}`).toLocaleLowerCase().replace("´","") +"/"+ this.translate.instant('Baluwo.blog.link.airdata') + this.translate.instant(`Baluwo.blog.country.${countryId}`).toLocaleLowerCase().replace("´","");
      }
    } else if (sectorId === this._ENVIRONMENT_.ApiIdCashPower){
      // return webBaseBlogUrlFalse + countryName.toLocaleLowerCase().replace(/\s/g, '-').replace("´","") +'/recargar-electricidad-'+ countryName.toLocaleLowerCase().replace(/\s/g, '-').replace("´","");
      switch (this.language.toLowerCase()) {
        case 'es':
          return webBaseBlogUrlFalse + this.translate.instant('Baluwo.blog.link.cashpower') + this.translate.instant(`Baluwo.blog.country.${countryId}`).toLocaleLowerCase();
        case 'fr':
          return webBaseBlogUrlFalse +"/fr/" + this.translate.instant(`Baluwo.blog.country.${countryId}`).toLocaleLowerCase() +"/"+ this.translate.instant('Baluwo.blog.link.cashpower') + this.translate.instant(`Baluwo.blog.country.${countryId}`).toLocaleLowerCase();
        default:
          return webBaseBlogUrlFalse + "/en/" + this.translate.instant(`Baluwo.blog.country.${countryId}`).toLocaleLowerCase() +"/"+ this.translate.instant('Baluwo.blog.link.cashpower') + this.translate.instant(`Baluwo.blog.country.${countryId}`).toLocaleLowerCase();
      }
    }
    
  }

  gotToBlogProducts() {
    switch (this.language.toLowerCase()) {
      case 'es':
        return webBaseBlogUrl;
      case 'fr':
        return webBaseBlogUrl + "fr/";
      default:
        return webBaseBlogUrl + "en/"
    }
  }

  gotToBlogMobileProducts() {
    console.log(this.productsActive);
  }

  openMobileSubMenu(countryISO: string) {
    console.log(countryISO);
    $('.dropdown-mobile-content_subproducts').removeClass('open')
    $('#dropdown-' + countryISO).addClass('open')
  }


  ngOnChanges(changes: SimpleChanges): void {
    // console.log('GETTING CHANGES: ', changes)
    if (changes.updateHeader) this.fetchData();
  }

  navigateToEvt() {
    (this.buttonBuy) ?
      this.router.navigate(['/cartlanding']) :
      this.router.navigate(['/home'])
  }

  navigateTo(page: string) {
    this.router.navigate(['/' + page]);
  }

  navigateToBlog(page: string) {
    this.router.navigate(['/' + page]);
  }

  cleanTransaction() {
    let newUpdatedHeader: Header = <Header>{};
    newUpdatedHeader.amount = 0;
    newUpdatedHeader.item = 0;
    this._currencyAmountService.setUpdatedHeader(newUpdatedHeader);
    const transactionId = localStorage.getItem(TransactionId);
    if (this.buyer === undefined) {
      localStorage.removeItem(TransactionId);
      this.clearTransaction(transactionId);
    } else {
      this.closeTransaction();
    }

  }

  clearTransaction(transactionId) {
    this.transactionServiceV1.clearTransaction(parseInt(transactionId)).subscribe(() => {
      console.log('transaction closed');
      this.router.navigate(['/home']);
    })
  }

  closeTransaction() {
    this.transactionServiceV1.closeTransaction().subscribe(() => {
      console.log('transaction closed');
      this.router.navigate(['/home']);
    })
  }

  openCustomareCare() {
    this.dialog.open(CustomareCareComponent, { panelClass: 'loginLayoutResets' });
  }
}
