import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DinamicMetaTags } from '../../shared/_helpers/_dinamicMetaTags';
import { TranslateService } from '@ngx-translate/core';
import { ContactPopUp } from '../../shared/_helpers/_contactpopup.helper';
import * as $ from 'jquery';
import { ContextBase } from '../../shared/_helpers/_contextBase.helper';
import { Email, NewLocation } from '../../shared/_objects/email';
import { LoginService } from '../../core/login-service/login.service';
import { SuccesspopupComponent } from '../successpopup/successpopup.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GoogleMapsHelper } from '../../shared/_helpers/_googlemaps.helper';
import { ScrollerTargetHelper } from '../../shared/_helpers/_scrollerTarget.helper';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CountryService } from '../../shared/_services/country.services';


@Component({
  selector: 'app-about-us',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss'],
})

export class AboutUsComponent implements OnInit {
  newLocation = new NewLocation;
  landingSourceCountry: string;
  newLocationForm: FormGroup;
  isSubmitted: boolean = false;
  countryArray: any[] = [];
  countryPrefix: string;
  contactEmail: Email;
  prefix: string;

  constructor(
    public dinamicMetaTags: DinamicMetaTags,
    public _contactPopUp: ContactPopUp,
    public googleMapsHelper: GoogleMapsHelper,
    private loginService: LoginService,
    private countryService: CountryService,
    public _scrollTargetHelper: ScrollerTargetHelper,
    private dialog: MatDialog,
    private fb: FormBuilder) {
    this.landingSourceCountry = JSON.parse(localStorage.getItem('landingSourceCountry'));
    this.getCountryCode();
    this.newLocationForm = this.fb.group({
      'name': ['', Validators.compose([Validators.required])],
      'prefix': ['', Validators.compose([Validators.required])],
      'phone': ['', Validators.compose([Validators.required])],
      'email': ['', Validators.compose([Validators.required, Validators.pattern('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,6}')])],
      'location': ['', Validators.compose([Validators.required])]
    });

    this.newLocation = <NewLocation>{};
  }

  getCountryCode() {
    this.countryService.getBuyingCountries().subscribe(
      c => {
        this.countryArray = c;
        setTimeout(() => {
          this.countryPrefix = this.countryArray.filter(country => country.id === this.landingSourceCountry)[0];
        }, 200)
      });
  }

  ngOnInit() {
    this.googleMapsHelper.selectAllMap();
    this.dinamicMetaTags.initAboutUsMetaTag();
    $('.headerMain__navLi--action').addClass('aboutus');
  }

  displayForm() {
    this.isSubmitted = false;
    this.getCountryCode();
    document.getElementById('store-form').classList.toggle('active');
  }

  onSubmit(newlocation: NewLocation) {
    this.isSubmitted = true;
    if (this.newLocationForm.valid) {
      this.newLocation.phone = this.countryPrefix['mobilePrefix'] + this.newLocation.phone;
      this.loginService.sendNewLocation(this.newLocation).subscribe(() => {

        const dialogRef = this.dialog.open(SuccesspopupComponent, { panelClass: 'loginLayoutResets' });
        dialogRef.afterClosed().subscribe(() => {
          this.newLocationForm.reset();
          this.isSubmitted = false;
          this.getCountryCode();
        })
      })
    }
  }
}


