import { Country } from '../_objects/country';
import { Sector } from '../_objects/sector';
import { Store } from '../_objects/store';
import { serverBaseUrl } from '../../globals';
import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, Headers } from '@angular/http';

import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class CountryService {
  private baseUrl = serverBaseUrl;
  constructor(private http: HttpClient) {
  }

  getSellingCountries(): Observable<any> {
    const country$ = this.http
      .get(`${this.baseUrl}/country`, { headers: this.getNoAuthHeader() })
      .pipe(catchError(handleError));
    return country$;
  }
  getSectors(): Observable<any> {
    const sector$ = this.http
      .get(`${this.baseUrl}/sector`, { headers: this.getNoAuthHeader() })
      .pipe(catchError(handleError));
    return sector$;
  }
  getBuyingCountries(): Observable<any> {
    const country$ = <any>this.http
      .get(`${this.baseUrl}/country`, { headers: this.getNoAuthHeader() })
      .pipe(catchError(handleError));
    return country$;
  }

  getLocations(countryId?: string, sectorId?: string): Observable<any> {
    let url = this.buildURl(countryId, sectorId);
    const location$ = <any>this.http
      .get(url, { headers: this.getNoAuthHeader() })
      .pipe(catchError(handleError));
    return location$;
  }

  buildURl(countryId?: string, sectorId?: string) {
    if (countryId && sectorId) {
      return `${this.baseUrl}/country/${countryId}/${sectorId}/location`;
    } else if (countryId && !sectorId) {
      return `${this.baseUrl}/country/${countryId}/location`;
    } else if (!countryId && sectorId) {
      return `${this.baseUrl}/country/${sectorId}/location`;
    }
  }

  getLocationsNoSector(countryId: string): Observable<any> {
    const location$ = this.http
      .get(`${this.baseUrl}/country/${countryId}/location`, { headers: this.getNoAuthHeader() })
      .pipe(catchError(handleError));
    return location$;
  }

  getStores(sectorId: string, locationId: number): Observable<any> {
    let url = this.buildStoreUrl(sectorId, locationId);
    const store$ = this.http
      .get(url, { headers: this.getNoAuthHeader() })
      .pipe(catchError(handleError));
    return store$;
  }

  buildStoreUrl(sectorId?: string, locationId?: number) {
    if (locationId && sectorId) {
      return `${this.baseUrl}/store?sector=${sectorId}&locationId=${locationId}`;
    } else if (locationId && !sectorId) {
      return `${this.baseUrl}/store?locationId=${locationId}`;
    } else if (!locationId && sectorId) {
      return `${this.baseUrl}/store?sector=${sectorId}`;
    }
  }

  getStore(storeId: number, currency: string): Observable<any> {
    const store$ = this.http
      .get(`${this.baseUrl}/v1/store/${storeId}/wc/${currency}`, { headers: this.getNoAuthHeader() })
      .pipe(catchError(handleError));
    return store$;
  }

  getStoreLogged(storeId: number): Observable<any> {
    const store$ = this.http
      .get(`${this.baseUrl}/v1/store/${storeId}`, { headers: this.getHeadersLogged() })
      .pipe(catchError(handleError));
    return store$;
  }

  getStoresV1(sectorId: number, locationId: number, currency: string): Observable<any> {
    const store$ = this.http
      .get(`${this.baseUrl}/v1/store/${locationId}/${sectorId}/wc/${currency}`, { headers: this.getNoAuthHeader() })
      .pipe(catchError(handleError));
    return store$;
  }

  getStoresV1Logged(sectorId: number, locationId: number) {
    const store$ = this.http
      .get(`${this.baseUrl}/v1/store/${locationId}/${sectorId}`, { headers: this.getHeadersLogged() })
      .pipe(catchError(handleError));
    return store$;
  }

  getLocationsV1(countryId: string, sectorId: number): Observable<any> {
    const location$ = this.http
      .get(`${this.baseUrl}/v1/location/${countryId}/${sectorId}`, { headers: this.getNoAuthHeader() })
      .pipe(catchError(handleError));
    return location$;
  }

  getSectorsV1(countryId): Observable<any> {
    const sector$ = this.http
      .get(`${this.baseUrl}/v1/sector/${countryId}`, { headers: this.getNoAuthHeader() })
      .pipe(catchError(handleError));
    return sector$;
  }

  getAllSectorsV1(): Observable<any> {
    const sector$ = this.http
      .get(`${this.baseUrl}/v1/sector/`, { headers: this.getNoAuthHeader() })
      .pipe(catchError(handleError));
    return sector$;
  }

  getAll(): Observable<any> {
    const store$ = this.http
      .get(`${this.baseUrl}/store`, {headers: this.getNoAuthHeader()})
      .pipe(catchError(handleError));
    return store$;
  }
  //
  //  updateCatalog(id: any, catalog: Catalog[]) {
  //    return this.http.patch(`${this.baseUrl}/store/` + id,
  //      catalog,
  //      {headers: this.getHeadersLogin()}).map((response: Response) => {
  //          return response.json();
  //      });
  //  }

  private getNoAuthHeader() {
    const headers = new HttpHeaders({
    'x-blw-src': 'baluwoWeb',
    'Accept': 'application/json'
    // headers.append('x-blw-src', 'web');
    });
    return headers;
  }

  private getHeaders() {
    // I included these headers because otherwise FireFox
    // will request text/html
    const headers = new HttpHeaders({
      'x-blw-src': 'baluwoWeb',
      'Content-type': 'application/json',
      'Authorization': localStorage.getItem('credentials')
    });
    return headers;
  }

  private getHeadersLogged() {
    const headers = new HttpHeaders({
    'x-blw-src': 'baluwoWeb',
    'Content-type': 'application/json',
    'Authorization': localStorage.getItem('currentAuth')
    });
    return headers;
  }


}




function mapCountries(response: Response): Country[] {
  return response.json();
}
function mapSector(response: Response): Sector[] {
  return response.json();
}
function mapLocations(response: Response): Location[] {
  return response.json();
}
function mapStores(response: Response): Store[] {
  return response.json();
}
//
// function toStore(r: any): Store {
//  const store = <Store>({
//    id: r.id,
//    name: r.name,
//  });
//  console.log('Parsed store:', store);
//  return store;
// }


function handleError(error: HttpErrorResponse) {
  // log error
  // const errorMsg = error.message;
  // throw an application level error
  return Observable.throw(error);
}

