import { Injectable } from "@angular/core";
import { CountryService } from "../_services/country.services";
import { Store } from "../_objects/store";
import { Location } from '../_objects/location';
import * as $ from 'jquery';
import { BackToStore } from "../_services/backtostore.service";
import { Router } from "@angular/router";
import { ContextBase } from "./_contextBase.helper";
// import { } from '@types/googlemaps';
import {} from "googlemaps";


@Injectable()
export class GoogleMapsHelper {

    private _map: google.maps.Map;
    sub: any;
    countrybyquery: string;
    servicebyquery: string;
    locationList: Location[] = [];
    currentLocation: Location;
    storeList: Store[] = [];
    storesList: Store[] = [];
    allStoresNumber: number;
    latitud: number;
    longitud: number;

    constructor(private countryService: CountryService,
        public backToStore: BackToStore,
        private router: Router,
        public contexto: ContextBase) {
    }

    fetchData(lat: number, lng: number, zoom: number) {
        this.storesList = [];
        if(this.countrybyquery){
        this.countryService.getLocations(this.countrybyquery, this.servicebyquery).subscribe(
            l => {
                console.log(l)
                this.locationList = l; this.locationList = this.locationList.filter(l1 => l1.stores !== null);
                const locationList2 = this.locationList;
                for (const location of locationList2) {
                    const particularLocationId = location.id;
                    console.log(particularLocationId);
                    this.countryService.getStores(this.servicebyquery, particularLocationId).subscribe(s => {
                        this.storesList.push(s);
                        this.initMap(lat, lng, zoom);
                    });
                    // TO-DO: This is not working this way in this point
                    for (const store of this.storesList) {
                        console.log('Store: ' + store.address); // TO-DO: Service is not ready for Latitude & Longitude
                    }
                }
            }
        );
    }else{
        this.countryService.getStores(this.servicebyquery, null).subscribe(s => {
            this.storesList = [];
            s.forEach(d=>{
                this.storesList.push(d);
            });
            this.initMap(lat, lng, zoom);
        });
        // TO-DO: This is not working this way in this point
        for (const store of this.storesList) {
            console.log('Store: ' + store.address); // TO-DO: Service is not ready for Latitude & Longitude
        }
    }
    }

    initMap(lat: number, lng: number, zoom: number) {
        let map = new google.maps.Map(document.getElementById('map'), {
            center: new google.maps.LatLng(lat, lng),
            mapTypeControl: true,
            zoomControl: true,
            zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_TOP },
            scaleControl: true,
            streetViewControl: true,
            streetViewControlOptions: { position: google.maps.ControlPosition.RIGHT_BOTTOM }
        });
        let marker, i, n, x;
        let infowindow = new google.maps.InfoWindow({});
        let storeInfo: any[] = [];
        for (i = 0; i < this.storesList.length; i++) {
            if (this.storesList[i][0] != undefined) {
                map.setZoom(zoom);
                marker = new google.maps.Marker({
                    position: new google.maps.LatLng(this.storesList[i][0]['latitude'], this.storesList[i][0]['longitude']),
                    map: map
                });
                google.maps.event.addListener(marker, 'click', ((marker, i) => {
                    return () => {
                        let info = '<div class="left-column float-left store_' + i + '" mrr30>' +
                            '<img src="/assets/images/icons/baluwo-blue-house.png" title="BUY NOW!"></div><div class="right-column float-right mrr30"><b>' + this.storesList[i][0]['name'] + '</b><br>' + this.storesList[i][0]['address'] +
                            '<br/><div class="store_' + i + '"" style="color: blue; margin:10px auto; cursor: pointer;">BUY NOW</div></div>';
                        infowindow.setContent(info);
                        infowindow.open(map, marker);
                        console.log(this.storesList[i][0]);
                        this.backToStore.buildBackToStoreFromMap(this.storesList[i][0]);
                        $('.store_' + i).on('click', () => {
                            this.router.navigate(['/home', { 'backstore': true }]);
                        })
                    }
                })(marker, i));
            } else {
                map.setZoom(zoom);
                marker = new google.maps.Marker({
                    position: new google.maps.LatLng(this.storesList[i]['latitude'], this.storesList[i]['longitude']),
                    map: map
                });
                google.maps.event.addListener(marker, 'click', ((marker, i) => {
                    return () => {
                        let info = '<div class="left-column float-left store_' + i + '"" mrr30 (click)="goToStore(' + this.storesList[i] + ')">' +
                            '<img src="/assets/images/icons/baluwo-blue-house.png" title="BUY NOW!"></a></div><div class="right-column float-right mrr30"><b>' + this.storesList[i]['name'] + '</b><br>' + this.storesList[i]['address'] +
                            '<br/><div class="store_' + i + '"" style="color: blue; margin:10px auto; cursor: pointer;">BUY NOW</div></div>';
                        infowindow.setContent(info);
                        infowindow.open(map, marker);
                        this.backToStore.buildBackToStoreFromMap(this.storesList[i]);
                        $('.store_' + i).on('click', () => {
                            this.router.navigate(['/home', { 'backstore': true }]);
                        })
                    }
                })(marker, i));
            }
        }
    }

    goToStore(store) {
        console.log(store);
    }

    selectItemMap(ev: any, item: string, classitem: string) {
        console.log(item);
        console.log(classitem);
        console.log(this.servicebyquery);
        this.toogleSelectedClass(classitem);
        this.toogleSelectedClass('allitem');
        ev.srcElement.classList.toggle('aboutSectionStore__button--selected');
        (classitem == 'locate') ? this.countrybyquery = item : this.servicebyquery = item;
        if (this.countrybyquery != undefined) {
            if (this.countrybyquery == 'SN') this.fetchData(14.4974, -14.4524, 8);
            if (this.countrybyquery == 'GM') this.fetchData(13.4432, -15.3101, 9);
            if (this.countrybyquery == 'NG') this.fetchData(7.0820, 8.6753, 7);
        }else{
            this.fetchData(11.000916, -4.202259, 5);
        }
    }

    selectAllItemLocationMap(ev: any) {
        this.toogleSelectedClass('orderitem')
        this.toogleSelectedClass('locate')
        ev.srcElement.classList.toggle('aboutSectionStore__button--selected');
        this.selectAllMap();
    }

    toogleSelectedClass(item: string) {
        let mapbtn = document.getElementsByClassName(item + ' aboutSectionStore__button--selected');
        for (let i = 0; i < mapbtn.length; i++) {
            mapbtn[i].classList.remove('aboutSectionStore__button--selected')
        }
    }

    selectAllMap() {
        console.log('calling all');
        this.countrybyquery = null;  
        this.servicebyquery = null;
        this.storesList = [];
        this.countryService.getAll().subscribe(l => {
            console.log(l);
            this.storesList = l;
            this.allStoresNumber = this.storesList.length;
            this.initMap(11.000916, -4.202259, 5);
        })
    }
}