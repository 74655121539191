import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { GiftCardImport, GiftCardSectors } from "../../shared/_objects/giftcard";
import { serverBaseUrl } from "../../globals";

@Injectable()
export class GiftCardService{
    private baseUrl = serverBaseUrl;
  constructor(private http: HttpClient) { }

  getGiftCardBySector(): Observable<GiftCardSectors[]> {
    const giftcard$ = <any>this.http
      .get(`${this.baseUrl}/v1/giftcard/sector`, { headers: this.getHeadersLogged() })
      .pipe(catchError(handleError));
    return giftcard$;
  }

  getGiftCardAmountList(sectorId): Observable<GiftCardImport[]> {
    const giftcard$ = <any>this.http
      .get(`${this.baseUrl}/v1/giftcard/${sectorId}`, { headers: this.getHeadersLogged() })
      .pipe(catchError(handleError));
    return giftcard$;
  }

  getGiftCardAmountListNotLogged(sectorId, currency): Observable<GiftCardImport[]> {
    const giftcard$ = <any>this.http
      .get(`${this.baseUrl}/v1/giftcard/${sectorId}?currency=${currency}`, { headers: this.getHeadersLogged() })
      .pipe(catchError(handleError));
    return giftcard$;
  }

  private getHeadersLogged() {
    const headers = new HttpHeaders({
      'x-blw-src': 'web',
      'Content-type': 'application/json',
      'Authorization': localStorage.getItem('currentAuth') || ''
    });
    return headers;
  }
}

function handleError(error: HttpErrorResponse) {
    console.log(error);
    return throwError(error.error);
  }