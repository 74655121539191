import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Observable } from "rxjs";

@Injectable()
export class FooterService{
    private footerChange = new BehaviorSubject(true);

    setFooterBuyer(any){
        this.footerChange.next(any);
    }

    getFooterBuyer(): Observable<any>{
        return this.footerChange.asObservable()
    }
}