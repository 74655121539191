import { Injectable } from "@angular/core";
import { CountryService } from "../_services/country.services";
import { Store, CatalogSelection } from "../_objects/store";
import { Location } from '../_objects/location';
import { Country } from "../_objects/country";
import { Sector } from "../_objects/sector";
import { Order } from '../_objects/order';
import { currentBuyer } from "../../globals";
import { ScrollerTargetHelper } from "./_scrollerTarget.helper";
import { ContextBase } from "./_contextBase.helper";
import { TransactionHelper } from "./_transactionHelper.helper";
import { LoginmodalComponent } from "../../pages/loginmodal/loginmodal.component";
import { MatDialog } from "@angular/material";
import { Buyer } from "../_objects/buyer";
import { AmountService } from "../../core/services/amount.service";
import { AnalyticsHelper } from "./_analytics.helper";
import { environment } from '../../../environments/environment';
import { CommonService } from "../../core/services/common.service";
import { SupplierService } from "../../core/services/suppliers.service";
import { TranslateService } from "@ngx-translate/core";
import { GiftCardService } from "../../core/services/giftcard.service";
import * as $ from 'jquery';
import { GiftCardImport, GiftCardSectors } from "../_objects/giftcard";
import { CategoriesService } from "../../core/services/categories.service";
import { Categories } from "../_objects/categories";
import { HeaderCountryService } from "../_services/header-country.service";


@Injectable()
export class LocationSectorStoreHelper {
    public _ENVIRONMENT_ = environment;
    Country: Country[];
    sellingCountries: Country[] = [];
    sectors: Sector[] = [];
    amounts: number[] = [];
    currentSector: Sector;
    currentSectorId: number;
    currentCountry: Country;
    destinationCurrency = 'GMD';
    destinationCurrencyId = 'GMD';
    order: Order = new Order;
    locationList: Location[] = [];
    currentLocation: Location;
    storeList: Store[] = [];
    currentStore: Store;
    orderType: string;
    manualAmount: boolean = false;
    isItMali: boolean = false;
    mobileFactor: any = 44;
    buyer: Buyer;
    fakeBeneficiary: any;
    showAirtimeAmounts: boolean = false;
    onDemandStoreSelected: boolean = false;
    giftCardStoreSelected: boolean = false;
    onDemandOptions: string[] = ['ORDER', 'REQUEST']
    onDemandOptionOrder: boolean = false;
    giftCardsModel: GiftCardSectors[] = [];
    giftCardAmounts: GiftCardImport[] = [];
    isMobile: boolean;
    categoryList: Categories[]

    constructor(private countryService: CountryService,
        public contexto: ContextBase,
        public dialog: MatDialog,
        private _analyticsHelper: AnalyticsHelper,
        public amountService: AmountService,
        public commonService: CommonService,
        public categoriesService: CategoriesService,
        public supplierService: SupplierService,
        public translate: TranslateService,
        public _transactionHelper: TransactionHelper,
        public giftCardService: GiftCardService,
        private _HeaderCountryService: HeaderCountryService,
        public _scrollerTargetHelper: ScrollerTargetHelper) {
        this.isMobile = (/android|webos|iphone|ipad|ipod|blackberry|windows phone/).test(navigator.userAgent.toLowerCase());
    }

    init() {
        this.currentStore = null;
        this.countryService.getSellingCountries()
            .subscribe(c => {
                this.sellingCountries = c;
                this.sellingCountries = this.sellingCountries.filter(country => country.retailer === true);
                this._HeaderCountryService.setCountryList(this.sellingCountries);
            });
    }

    getCountrySectors(countryId) {
        this.countryService.getSectorsV1(countryId)
            .subscribe(s => {
                this.sectors = s;
            });
    }

    getSellingCountries() {
        this.countryService.getSellingCountries()
            .subscribe(c => {
                this.sellingCountries = c;
                this.sellingCountries = this.sellingCountries.filter(country => country.retailer === true);
                return this.sellingCountries;
            });
    }

    changeLocation() {
        this.currentStore = null;
        if (localStorage.getItem(currentBuyer) != null) {
            this.countryService.getStoresV1Logged(this.currentSector.id, this.currentLocation.id).subscribe(
                s => {
                    this.buildStoreList(s);
                    this.getStoreCategories(this.currentSector.id)
                })

        } else {
            this.countryService.getStoresV1(this.currentSector.id, this.currentLocation.id, this.contexto.currency).subscribe(
                s => {
                    this.buildStoreList(s);
                    this.getStoreCategories(this.currentSector.id)
                }
            )
        }
    }

    getStoreCategories(currentSectorId) {
        this.categoriesService.getCategories(currentSectorId, this.contexto.language.toLowerCase()).subscribe(res => {
            this.categoryList = res;
            this.getStoreCategoriesUniq(res);
        })
    }

    getStoreCategoriesUniq(categories) {
        this.storeList.forEach(x => {
            x.category = [];
            x.catalog.forEach(y => {
                categories.forEach((w:any) => {
                    if (y.product.category && y.product.category.id == w.id) {
                        if (x.category.indexOf(w) === -1) {
                            x.category.push(w);
                        }
                    }
                })
            })
        })
    }

    buildStoreList(data) {
        this.storeList = data;
        if (this.storeList.length == 1) {
            (this.currentSector.id === this._ENVIRONMENT_.ApiIdOnDemand) ? this.onDemandStoreSelected = true : this.onDemandStoreSelected = false;
            (this.currentSector.id === this._ENVIRONMENT_.ApiIdGiftCard) ? this.giftCardStoreSelected = true : this.giftCardStoreSelected = false;
            this.currentStore = this.storeList[0];
            this.changeStore();
        }
    }

    changeSector(beneficiaryMobile?: string) {
        this.currentLocation = null;
        this.currentStore = null;
        this.manualAmount = false;
        this.order.meterId = null;
        this.onDemandStoreSelected = false;
        this.giftCardStoreSelected = false;
        this.countryService.getLocationsV1(this.currentCountry.id, this.currentSector.id).subscribe(
            l => {
                this.locationList = l
            }
        );
        if (this.currentSector.type === 'topup') {
            this.commonService.getStaticSupplierOnce(this.currentCountry.id);
            if (this.currentSector.id === this._ENVIRONMENT_.ApiIdAirTime) {
                this.amountService.getFixedAmounts(this.contexto.currency.toUpperCase(), beneficiaryMobile);
                this.commonService.getPromoAirtime(beneficiaryMobile);
                setTimeout(() => {
                    this.commonService.getSupplierMinimumAmount(beneficiaryMobile);
                }, 200);
            }
            if (this.currentSector.id === this._ENVIRONMENT_.ApiIdAirData) {
                this.amountService.getAirDataAmounts(this.contexto.currency.toUpperCase(), beneficiaryMobile);
                this.commonService.getPromoAirtime(beneficiaryMobile);
                setTimeout(() => {
                    this.commonService.getSupplierDataMinimumAmount(beneficiaryMobile);
                }, 200);
            }
            if (this.currentSector.id === this._ENVIRONMENT_.ApiIdBundle) {
                this.amountService.getBundleAmounts(this.contexto.currency.toUpperCase(), beneficiaryMobile);
                this.commonService.getPromoAirtime(beneficiaryMobile);
                setTimeout(() => {
                    this.commonService.getSupplierBundleMinimumAmount(beneficiaryMobile);
                }, 200);
            }
        }
    }

    getGiftCard() {
        this.giftCardService.getGiftCardBySector().subscribe(res => {
            this.giftCardsModel = res;
        })
    }

    createGiftCardStore(evt) {
        $('.img-border').removeClass('selected');
        $('.img-border').addClass('noselected');
        $('.selected_' + evt.id).addClass('selected');
        $('.selected_' + evt.id).removeClass('noselected');
        if (localStorage.getItem(currentBuyer) != null) {
            this.giftCardService.getGiftCardAmountList(evt.id).subscribe(res => {
                this.giftCardAmounts = res;
                this.giftCardStoreSelected = true;
                setTimeout(() => {
                    if (screen.height <= 768 || this.isMobile) {
                        this._scrollerTargetHelper.scrollToTarget("amountSelector");
                    }
                }, 500)
            })
        } else {
            this.giftCardService.getGiftCardAmountListNotLogged(evt.id, this.contexto.currency.toUpperCase()).subscribe(res => {
                this.giftCardAmounts = res;
                this.giftCardStoreSelected = true;
                setTimeout(() => {
                    if (screen.height <= 768 || this.isMobile) {
                        this._scrollerTargetHelper.scrollToTarget("amountSelector");
                    }
                }, 500)
            })
        }

    }



    changeBackToStoreSector(beneficiary) {
        switch (this.currentSector.id) {
            case this._ENVIRONMENT_.ApiIdAirTime:
                this.amountService.getFixedAmounts(this.contexto.currency.toUpperCase(), beneficiary);
                break;
            case this._ENVIRONMENT_.ApiIdAirData:
                this.amountService.getAirDataAmounts(this.contexto.currency.toUpperCase(), beneficiary);
                break;
            case this._ENVIRONMENT_.ApiIdBundle:
                this.amountService.getBundleAmounts(this.contexto.currency.toUpperCase(), beneficiary);
                break;
        }
    }

    changeStore() {
        this._analyticsHelper.sendGoogleAnalyticsSelectedStore(this.currentStore);

        if (localStorage.getItem(currentBuyer) != null) {
            this.countryService.getStoreLogged(this.currentStore.id).subscribe((store) => {
                this.buildCatalogueSelection(store)
            });
        } else {
            this.countryService.getStore(this.currentStore.id, this.contexto.currency).subscribe((store) => { this.buildCatalogueSelection(store) })
        }
        setTimeout(() => {
            this._scrollerTargetHelper.scrollToTarget('storeselected');
        }, 200)
    }

    buildCatalogueSelection(store) {
        this.currentStore = store;
        this.currentStore.catalogSelection = [];

        let filterCatalog = [];
        filterCatalog = this.currentStore.catalog;
        for (const catalog of filterCatalog) {
            const catalogSelection = new CatalogSelection;
            catalogSelection.catalog = catalog;
            catalogSelection.quantity = 0;
            this.currentStore.catalogSelection.push(catalogSelection);
        }
        this.currentStore.category = [];
        this.currentStore.catalog.forEach(y => {
            this.categoryList.forEach((w:any) => {
                if (y.product.category && y.product.category.id == w.id) {
                    if (this.currentStore.category.indexOf(w) === -1) {
                        this.currentStore.category.push(w);
                    }
                }
            })
        })
    }

    changeGlobalLocation(currentCountry) {
        this._analyticsHelper.sendGoogleAnalyticsSelectedCountry(this.currentCountry);
        this.getCountrySectors(currentCountry.id);
        this._transactionHelper.changeCurrencyRef(this.contexto.currency.toUpperCase(), currentCountry.currency.id);
        setTimeout(() => {
            this.mobileFactor = this._transactionHelper.getMobileData();
        }, 1000);
        this.orderType = null;
        if (this.orderType != undefined) {
            this.changeSector();
            this.changeLocation();
        }
        this.currentStore = null;
        this.currentSector = null;
        this.currentLocation = null;
        this.destinationCurrency = currentCountry.currency.acronym;
        this.destinationCurrencyId = currentCountry.currency.id;
        (currentCountry.id === 'ML') ? this.isItMali = true : this.isItMali = false;
    }

    buildBackToStoreEditCharge(sector, currentCountry, beneficiary) {
        this.getCountrySectors(currentCountry.id);
        this._transactionHelper.changeCurrencyRef(this.contexto.currency.toUpperCase(), currentCountry.currency.id);
        this.currentSector = sector;
        switch (this.currentSector.id) {
            case this._ENVIRONMENT_.ApiIdAirTime:
                this.amountService.getFixedAmounts(this.contexto.currency.toUpperCase(), beneficiary);
                break;
            case this._ENVIRONMENT_.ApiIdAirData:
                this.amountService.getAirDataAmounts(this.contexto.currency.toUpperCase(), beneficiary);
                break;
            case this._ENVIRONMENT_.ApiIdBundle:
                this.amountService.getBundleAmounts(this.contexto.currency.toUpperCase(), beneficiary);
                break;
            default:
                break;
        }
        this.destinationCurrency = this.currentCountry.currency.acronym;
        this.destinationCurrencyId = currentCountry.currency.id;
    }

    buildSectors(country: any) {
        this.currentCountry = country;
        this.changeGlobalLocation(this.currentCountry);
    }

    backToStoreStore(store) {
        this.currentCountry = this.sellingCountries[store.country];
        if (this.currentCountry) {
            this._transactionHelper.changeCurrencyRef(this.contexto.currency.toUpperCase(), this.currentCountry.currency.id);
        }
        this.currentSector = store.sector;
        this.getStoreCategories(this.currentSector.id)
        this.changeSector();
        setTimeout(() => {
            this.locationList = [];
            this.locationList[0] = store.location;
            this.currentLocation = this.locationList[0];
        }, 200);
        this.storeList[0] = store.store;
        this.currentStore = this.storeList[0];
        this.changeStore();
    }

    login() {
        const dialogRef = this.dialog.open(LoginmodalComponent, { panelClass: 'loginLayoutResets' });
        dialogRef.afterClosed().subscribe(b => {
            if (typeof b !== 'undefined') {
                this.buyer = b;
                console.log('confirmamos buyer ' + this.buyer.mobile);
                this.changeLocation();
            }
        });
    }
}