import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { landingSourceCountry } from "../../../globals";
import { Country } from "../../../shared/_objects";
import { CountryService } from "../../../shared/_services/country.services";
import { environment } from "../../../../environments/environment";
import { LoginService } from "../../../core/login-service/login.service";

@Component({
    selector: 'app-loginmodal-recovery',
    templateUrl: 'modal-step_recovery.component.html',
    styleUrls: ['../loginmodal.component.scss']
})

export class LoginmodalStepRecoveryComponent implements OnInit {
    countryArray: Country[];
    countryPrefix: string;
    selectedCountry: Country;
    public captchKey: string;
    defaultSourceCountry: string;
    public mobile: string;
    public isValidPhoneNumber: boolean = true;

    @Output() onOpenReset: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClosingDialog: EventEmitter<any> = new EventEmitter<any>();

    constructor(public countryService: CountryService,
        private loginService: LoginService) {
        this.defaultSourceCountry = JSON.parse(localStorage.getItem(landingSourceCountry));
    }

    ngOnInit() {
        this.countryService.getBuyingCountries().subscribe(
            c => {
                this.countryArray = c;
                this.selectedCountry = this.countryArray.filter(country => country.id === this.defaultSourceCountry)[0];
            });
        this.captchKey = environment.captchaKey;
        console.log(this.captchKey);
    }

    public resolved(captchaResponse: string) {
        console.log('executing')
        this.sendRecoveryPassword(captchaResponse);
    }

    public checkBeforeCaptcha() {
        if (this.mobile !== undefined) {
          grecaptcha.execute();
        } else {
          this.isValidPhoneNumber = false;
          return;
        }
      }

    sendRecoveryPassword(token:string) {
        let mobileRecovery = { mobile: this.selectedCountry.mobilePrefix + this.mobile.replace(/[^\d]/g, '') };
        console.log(mobileRecovery);
        console.log(token);
        this.loginService.recoverPassword(mobileRecovery, token).subscribe(res => {
            console.log(res);
            this.onOpenReset.emit(mobileRecovery.mobile)
        }, error=>{
            console.log(error);
        })
    }

    close() {
        this.onClosingDialog.emit()
    }
}