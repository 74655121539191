import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { RecaptchaModule } from "ng-recaptcha";
import { SharedModule } from "../../shared.module";
import { SisalComponent } from "./sisal.component";

@NgModule({
    imports:[
        SharedModule,
        RecaptchaModule
    ],
    declarations:[
        SisalComponent
    ],
    providers:[
    ],
    exports:[   
    ],
    entryComponents:[],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SisalModule { }