import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Observable } from "rxjs";
import { Subject } from "rxjs";
import { Header } from "../_objects/header";

@Injectable()
export class CurrencyAmountService{
    private updateHeader = new BehaviorSubject(<Header>{});
    curUpdatedHeader = this.updateHeader.asObservable();

    private currencyAmount = new BehaviorSubject(<number>{});
    curAmountHeader = this.currencyAmount.asObservable();

    private itemAmount = new BehaviorSubject(<number>{});
    curItemHeader = this.itemAmount.asObservable();

    private walletAmount = new BehaviorSubject(<number>{});
    curWalletHeader = this.walletAmount.asObservable();

    getListen(): Observable<any>{
        return this.currencyAmount.asObservable();
    }

    setCurrencyAmount(any){
        this.currencyAmount.next(any);
    }

    setUpdatedHeader(newH: Header){
        this.updateHeader.next(newH);
    }

    setUpdatedWallet(newH: any){
        this.walletAmount.next(newH);
    }
}