import { NgModule } from '@angular/core';
import {
  MatCommonModule, MatSelectModule, MatFormFieldModule, MatOptionModule,
  MatInputModule, MatRadioModule, MatDialogModule, MatButtonModule,
  MatTabsModule, MatTableModule,
  MatExpansionModule,
  MatTooltipModule,
  MatAutocompleteModule,
  MatSortModule,
  MatPaginatorModule,
  MatCheckboxModule
} from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    MatCommonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatOptionModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    MatTableModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    MatRadioModule,
    MatSortModule,
    MatPaginatorModule,
    MatDialogModule],
  exports: [
    MatCommonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatOptionModule,
    MatAutocompleteModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatExpansionModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatTabsModule,
    BrowserAnimationsModule,
    MatRadioModule,
    MatDialogModule],
})
export class MaterilaBaluwoModule { }
