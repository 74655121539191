import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Buyer } from "../../../shared/_objects/buyer";
import { environment } from "../../../../environments/environment";
import { LoginService } from "../../../core/login-service/login.service";
import { LoginHelperUserComponent } from "../loginhelpers/loginHelperUser.component";

@Component({
    selector: 'app-loginmodal-reset',
    templateUrl: 'modal-step_reset.component.html',
    styleUrls: ['../loginmodal.component.scss']
})

export class LoginmodalStepResetComponent implements OnInit {
    buyerPasswordReset: FormGroup;
    public buyerPasswordResetData: any;
    public noMatrchPassowrd: boolean = false;
    public captchKey: string;
    public weakPassword: boolean = false;
    fieldTextType: boolean;
    fieldTextType2: boolean;
    fieldTextType3: boolean;
    wrongUser: boolean = false;

    @Input() mobile: string;
    @Output() onBuyerCreated: EventEmitter<Buyer> = new EventEmitter<Buyer>();
    @Output() onClosingDialog: EventEmitter<any> = new EventEmitter<any>();
    constructor(
        private fb: FormBuilder,
        private loginService: LoginService,
        public loginHelperUserComponent: LoginHelperUserComponent,
    ) {
        this.buyerPasswordReset = this.fb.group({
            'oldpassword': ['', Validators.compose([Validators.required])],
            'password': ['', Validators.compose([Validators.required])],
            'passwordrepeat': ['', Validators.compose([Validators.required])]
        });
        this.buyerPasswordResetData = <any>{};
        this.captchKey = environment.captchaKey;
    }
    ngOnInit() {

    }

    public resolved(captchaResponse: string) {
        this.resetPassword(captchaResponse);
    }

    public checkBeforeCaptcha() {
        if (this.buyerPasswordReset.valid) {
            grecaptcha.execute();
        } else {
            return;
        }
    }

    toggleFieldTextType(num) {
        if (num == 1) {
            this.fieldTextType = !this.fieldTextType;
        } else if (num == 2) {
            this.fieldTextType2 = !this.fieldTextType2;
        } else if (num == 3) {
            this.fieldTextType3 = !this.fieldTextType3;
        }
    }

    checkPasswordMatch() {
        if (this.buyerPasswordReset.get('password').value !== this.buyerPasswordReset.get('passwordrepeat').value) {
            this.noMatrchPassowrd = true;
            return false;
        } else {
            this.noMatrchPassowrd = false;
            return this.buyerPasswordReset.get('password').value
        }
    }

    cleanInput() {
        this.weakPassword = false;
    }

    resetPassword(token: string) {
        this.weakPassword = false;
        this.wrongUser = false;
        this.buyerPasswordResetData.mobile = this.mobile;
        this.buyerPasswordResetData.oldPassword = this.buyerPasswordReset.get('oldpassword').value
        this.buyerPasswordResetData.newPassword = this.checkPasswordMatch();
        if (this.buyerPasswordReset.valid && !this.noMatrchPassowrd) {
            this.loginService.recoverPasswordReset(this.buyerPasswordResetData, token).subscribe(res => {
                if (res.success) {
                    this.loginHelperUserComponent.setAuth(this.mobile, this.buyerPasswordReset.get('password').value);
                    setTimeout(() => {
                        this.loginHelperUserComponent.gatNewBuyerCreated.subscribe(res => {
                            this.onBuyerCreated.emit(res);
                        })
                    }, 300)
                } else {
                    console.log('resetting captcha');
                    grecaptcha.reset();
                    this.wrongUser = true;
                }
            }, error => {
                console.log('resetting captcha 2');
                grecaptcha.reset();
                if (error.error.code == 1) {
                    this.weakPassword = true;
                } else {

                }
            })
        }
    }

    close() {
        this.onClosingDialog.emit()
    }
}