import { Country, Currency } from "./country";

export class Buyer {
  id: number;
  name: string;
  mobile: string;
  email: string;
  uuid: string;
  currency: Currency;
  location: string;
  language: any;
  facebook: string;
  card: BuyerCard;
  buyerData: BuyerData;
  referenceNumber?: string;
  wallet: Wallet;
  country?: Country;
}

export class Wallet{
  aggregated: number;
  id: string;
}

export class BuyerCard {
  aggregated: number;
  id: string
}

export class BuyerData {
  address: string;
  documentId: string;
  town: string;
}