import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { CartlandingComponent } from './cartlanding.component';


@NgModule({
	imports: [
        SharedModule
	],
	declarations: [
        // CartlandingComponent
	],
	providers: [
	],
	entryComponents: [
        // CartlandingComponent
		
	],
	exports: [
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})

export class CartlandingComponentModule { }
