import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ContextBase } from "../../shared/_helpers/_contextBase.helper";
import { PromotionHelper } from "../../shared/_helpers/_promotions.helper";
import { TranslateService } from "@ngx-translate/core";
import { BannerHelper } from "../../shared/_helpers/_banner.helper";
import { TransactionServiceV1 } from "../../core/services/transactionv1.service";
import { currentBuyer } from "../../globals";

@Component({
    selector: 'promotion-section',
    templateUrl: './promotionsection.component.html',
    styleUrls: ['./promotionsection.component.scss', '../../../assets/css/bootstrap-grid.min.css']
})

export class PromoSectionComponent implements OnInit {
    amount: string;
    charge: string;
    chargeFood: string;
    amountFood: string;
    promoFood: any;
    public promosAvailable: {}[] = [{ promo: 'tobaski', startDate: '22', endDate: '31', month: '05' }];
    public promosAvailableCurrency: string[] = [];
    public promosAvailableCurrencyDate: {}[] = [{ promo: 'airdata', startDate: '14', endDate: '17', month: '09' }];
    promoValid: boolean;
    firstTimeBuyer: boolean = false;

    constructor(private router: Router,
        public contexto: ContextBase,
        public translate: TranslateService,
        public _bannerHelper: BannerHelper,
        public transactionService: TransactionServiceV1,
        public _promotionHelper: PromotionHelper) {
    }

    ngOnInit() {
        this._promotionHelper.checkPromoElectricityCurrency(this.contexto.currency);
        this.amount = this._promotionHelper.amount;
        this.charge = this._promotionHelper.charge;
        this._promotionHelper.checkPromoFoodCurrency(this.contexto.currency);
        this.chargeFood = this._promotionHelper.amountFood;
        this.amountFood = this._promotionHelper.chargeFood;
        setTimeout(() => {
            this.promoFood = this.translate.instant('Baluwo.promotions.food.banner', { chargeFood: this.chargeFood, amountFood: this.amountFood });
        }, 200);
        this.getFirstTimeBuyer();
    }

    getFirstTimeBuyer(){
        if(localStorage.getItem(currentBuyer) !== null){
        this.transactionService.getMyTransactions(1).subscribe(res => {
            console.log(res.length)
            if (res.length != 0) {
              this.firstTimeBuyer = true;
            } else {
              this.firstTimeBuyer = false;
            }
          }, error => {
            this.firstTimeBuyer = false;
          })
        } else{
            this.firstTimeBuyer = false;
        }
    }

    navigateTo(page: string) {
        this.router.navigate(['/'+ page]);
      }

    }