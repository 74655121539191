import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { LoginService } from "../../../core/login-service/login.service";
import { Router } from "@angular/router";
import { ContextBase } from "../../../shared/_helpers/_contextBase.helper";
import { MatDialog } from "@angular/material";

@Component({
    selector: 'app-loginmodal-refer',
    templateUrl: 'modal-step_refer.component.html',
    styleUrls: ['../loginmodal.component.scss']
})
export class LoginmodalStepReferenceComponent implements OnInit {
    public referenceNumber: string;
    public wrongReferenceNumber: boolean = false;
    @Output() onCloseLogin: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSkipReference: EventEmitter<any> = new EventEmitter<any>();

    constructor(private loginService: LoginService,
        private router: Router,
        private dialog: MatDialog,
        public contexto: ContextBase) { }

    ngOnInit() {
    }

    authenticateRefer() {
        this.wrongReferenceNumber = false;
        this.loginService.postMgmRefer(this.referenceNumber).subscribe(res => {
            console.log(res);
            // this.onCloseLogin.emit();
            this.dialog.closeAll();
        }, error=> {
            console.log(error);
            this.wrongReferenceNumber = true;
        })
    }

    skipReferenceNumber() {
        this.dialog.closeAll();
    }
    navigateTo(page: string) {
        this.router.navigate(['/' + page]);
      }
}