import { Injectable, OnInit } from "@angular/core";
import { Transaction } from "../_objects/transaction";
import { ContextBase } from "./_contextBase.helper";
import { landingPath } from "../../globals";
import { BackToStore } from "../_services/backtostore.service";
import { Router } from "@angular/router";
import { CommonService } from "../../core/services/common.service";
import { environment } from "../../../environments/environment";

@Injectable()
export class HomeUtilsHelper implements OnInit{
  isValidPhoneNumber = true;
  isValidBeneficiaryMobile: boolean;
  allowPayment: boolean = false;
  totalAmount: number;
  transaction: Transaction;
  landingCurrency: string;
  exchangeSource: any;
  exchangeDest: any;
  currency: string;
  language: string;
  notValidBeneficiaryPhone: boolean = false;
  public _ENVIRONMENT_ = environment;
  private sectorsBuild = [{ id: this._ENVIRONMENT_.ApiIdAirTime, name: 'Air time', type: 'topup' },
  { id: this._ENVIRONMENT_.ApiIdAirData, name: 'Air data', type: 'topup' },
  { id: this._ENVIRONMENT_.ApiIdBundle, name: 'Bundle', type: 'topup' },
  { id: this._ENVIRONMENT_.ApiIdCashPower, name: 'Cash power', type: 'topup' },
  { id: this._ENVIRONMENT_.ApiIdConstruction, name: 'Construction', type: 'store' },
  { id: this._ENVIRONMENT_.ApiIdFood, name: 'Food', type: 'store' }]

  constructor(
    public contexto: ContextBase,
    public backToStore: BackToStore,
    public commonService: CommonService,
    private router: Router) {
  }

  ngOnInit() {
      this.landingCurrency = this.contexto.currency.toUpperCase();
      this.currency = this.contexto.currency.toUpperCase();
      this.language = this.contexto.language.toUpperCase();
  }

  validatePhoneNumber(premobile, id) {
    try {
      let isnum = /^\d+$/.test(id.replace('+','') + premobile);
      if (isnum && premobile !== '') {
        this.isValidBeneficiaryMobile = true;
        // setTimeout(()=>{
        //   this.checkMobile(id.replace('+','') + premobile);
        // }, 1000)
        
      } else {
        this.isValidBeneficiaryMobile = false;
        this.allowPayment = false;
      }
    } catch (e) {
      this.isValidBeneficiaryMobile = false;
      this.allowPayment = false;
    }
  }
  getSectorContentsReturn(sectorname: string) {
    return this.sectorsBuild.filter(sector=>sector.name.toLowerCase().replace(/\s/g, '') == sectorname.toString());
}

  phoneValidationReset() {
    this.isValidBeneficiaryMobile = true;
    this.notValidBeneficiaryPhone = false;
  }

  trackByProductId(index: number, obj: any): any {
    return obj.id;
  }

  sortNumber(a, b) {
    return a - b;
  }

  sortById(a, b) {
    return a.catalog.id - b.catalog.id
  }

  checkMobile(mobile){
    console.log(mobile);
    console.log(this.commonService.dynamicSupplierArray);
    this.commonService.dynamicSupplierArray.forEach(x=>{
     if(mobile.match(x.carrier.regExp)){
      this.isValidBeneficiaryMobile = true;
      console.log(mobile)
      return;
     }else{
      this.isValidBeneficiaryMobile = false; 
      console.log(mobile)
     }
    })
  }


  // parseNumberSuplier(order) {
  //   return this.commonService.getSupplier(parse(order.beneficiary.mobile).country, order.beneficiary.mobile)
  // }

  checkCountryCurrency(id) {
    switch (id) {
      case 'GM':
        return 'GMD';
      case 'SN':
        return 'FACFA';
      case 'NG':
        return 'NGN';
      case 'ML':
        return 'FACFA'
    }
  }

  backToLanding() {
    const landPath = JSON.parse(localStorage.getItem(landingPath));
    this.router.navigate(['/home']);
  }

  backToHomeStore(order) {
    this.backToStore.buildBackToStore(order);
    this.router.navigate(['/home', { 'backstore': true }]);
  }

  backToHomeCharge(order) {
    this.backToStore.buildBackToEditCharge(order);
    this.router.navigate(['/home', { 'backstorecharge': true }]);
  }

  getCurrencySymbol(id) {
    switch (id) {
      case 'EUR':
        return '€';
      case 'GBP':
        return '£';
      case 'USD':
        return '$';
      case 'SEK':
        return 'sek';
    }
  }

  print(data): void {
    let popupWin;
    popupWin = window.open(' ', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(data);
    popupWin.document.close();
  }
}