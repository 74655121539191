
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { ContextBase } from '../_helpers/_contextBase.helper';



@Injectable()
export class GDPRService {

    private static GDPRAdapter: string = "gdpr/#USERLANG#.html";

    constructor(private http: HttpClient, private contexto: ContextBase) { }

    public get(): Promise<any> {
        let promise = new Promise((resolve, reject) => {
            return this.http.get("assets/local/" + GDPRService.GDPRAdapter.replace("#USERLANG#", this.contexto.language), {responseType: 'text'})
                .subscribe((res: any) => {
                    resolve(res);
                },
                err => reject(err));
        });
        return promise;
    }
}