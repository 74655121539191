import { Component } from "@angular/core";
import { Router } from "@angular/router";
import * as $ from 'jquery';
import { PromotionService } from "../../../core/services/promotion.service";
import { BannerHelper } from "../../../shared/_helpers/_banner.helper";

@Component({
    selector: 'app-home-banner-component',
    templateUrl: 'home_banner.component.html',
    styleUrls: ['../home.component.scss']
})

export class HomeBannerComponent {
    public bannerAvailable: boolean = false;
    public step1: boolean = true;
    public step2: boolean = false;
    public step3: boolean = false;
    constructor(public _bannerHelper: BannerHelper, private router: Router, private promotionService: PromotionService) { }

    ngOnInit() {
        if (sessionStorage.getItem('PromoViewed') === undefined || sessionStorage.getItem('PromoViewed') === null) {
            setTimeout(() => {
                this.bannerAvailable = true;
            }, 3000)
        }
    }

    changeStep(step: number) {
        console.log(this._bannerHelper.firsTimeBuyer)
        console.log(step)
        switch (step) {
            case 1:
                this.step1 = false;
                this.step2 = true;
                (this._bannerHelper.firsTimeBuyer) ? this.step2 = true : this.close();
                break;
            case 2:
                this.step2 = false;
                this.closeAndGo();
                // (this._bannerHelper.firsTimeBuyer) ? this.step3 = true : this.close();
                console.log(this.step3)
                break;
            case 3:
                this.step3 = false;
                this.closeAndGo();
        }
    }

    goToStoreBanner(ban, referenceCode) {
        let ua = navigator.userAgent;
        if (/Android/i.test(ua)) {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.baluwo.userapp&referrer=' + referenceCode
        } else if (/iphone|ipad/i.test(ua)) {
            this.promotionService.sendReferralCodeIOS(referenceCode).subscribe(res => {
                console.log(res);
            });
            window.location.href = 'https://apps.apple.com/es/app/baluwo/id1490261153?referrer=' + referenceCode
        } else {
            this.changeStep(ban);
        }
    }

    close() {
        $('._blank').addClass('hide');
        $('.bigPopUp').addClass('hide');
        sessionStorage.setItem('PromoViewed', 'true');
    }

    open() {
        $('._blank').removeClass('hide');
        $('.bigPopUp').removeClass('hide');
    }

    closeAndGo() {
        this.close();
    }
}