import { Component, EventEmitter, Output } from "@angular/core";
import { MatDialog } from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { TransactionServiceV1 } from "../../core/services/transactionv1.service";
import { PaymentService } from "../../core/payment/payment.service";
import { PaymentPopUpWalletComponent } from "../paymentpopup/paymentpopupwallet.component";

@Component({
    selector: 'app-wallet-recharge-component',
    templateUrl: './walletrecharge.component.html'
})

export class WalletRechargeComponent {
    public amountToRecharge: number;
    receipt: any;
    noAuthforPaymentError: boolean = false;
    amountToTransfer: number;

    @Output() rechargeDone: EventEmitter<any> = new EventEmitter<any>();

    constructor(public dialog: MatDialog,
        private route: ActivatedRoute,
        protected sanitizer: DomSanitizer,
        public transactionService: TransactionServiceV1,
        private _paymentService: PaymentService) { }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            console.log(params);
            if (params['transactionOkRedys'] || params['transactionKORedys']) {
                this._paymentService.getWalletRechargeReceipt().subscribe(res => {
                    this.receipt = this.sanitizer.bypassSecurityTrustHtml(res);
                })
            }
        })
    }

    rechargeWallet() {
        console.log(this.amountToRecharge);
        const dialogRef = this.dialog.open(PaymentPopUpWalletComponent, { panelClass: 'loginLayoutResets', data: this.amountToRecharge });
        dialogRef.componentInstance.noAuthforPayment.subscribe(res => {
            this.noAuthforPaymentError = true;
            console.log(res);
        })
        dialogRef.afterClosed().subscribe(res => {
            this.rechargeDone.emit();
            this.amountToRecharge = null;
        });
    }
}