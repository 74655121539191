import { Component } from "@angular/core";
import { Buyer } from "../../shared/_objects";
import { currentBuyer } from '../../globals';
import { MatDialog } from "@angular/material";
import { LoginmodalComponent } from "../loginmodal/loginmodal.component";
import { PromotionHelper } from "../../shared/_helpers/_promotions.helper";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-mgm-component',
    templateUrl: './mgm.component.html',
    styleUrls: ['../partners/agents/partnersagents.component.scss', '../../../assets/css/bootstrap-grid.min.css'],
})

export class MgmComponent {
    public buyer: Buyer;
    public buyerReference: string;
    public isLogged: boolean;
    public param = { amount: '', buyerReference: '' };
    public socialMediaMessage: string;
    buttonBuy: boolean = false;
    updateHeader = 1;
    MgmFaqsQuestionsRecomendador: any[] = [];
    MgmFaqsQuestionsRecomendado: any[] = [];
    showFaqs: boolean = false;
    panelOpenState: boolean = false;
    constructor(private dialog: MatDialog,
        private _promoHelper: PromotionHelper,
        private translate: TranslateService) {
        if (localStorage.getItem(currentBuyer) !== null) {
            this.buyer = JSON.parse(localStorage.getItem(currentBuyer));
            if (this.buyer.wallet) this.buyerReference = this.buyer.wallet.id;
        }
        this.getParamsForMessages();
        this.getFaqs();

    }

    copyContent(content) {
        content.select();
        document.execCommand('copy');
        content.setSelectionRange(0, 0);
    }

    getParamsForMessages() {
        this.param = { amount: this._promoHelper.getCurrencyMGMPromo(), buyerReference: this.buyer ? this.buyer.wallet.id : null };
        setTimeout(() => {
            this.socialMediaMessage = this.translate.instant('Baluwo.mgm.social.message', { amount: this._promoHelper.getCurrencyMGMPromo(), buyerReference: this.buyer ? this.buyer.wallet.id : null });
        }, 500)
    }

    getFaqs() {
        this.translate.get('Baluwo.mgm.faqs.questions.recomendador').subscribe(res => {
            res.forEach((d: any) => {
                d.question = this.replaceVariables(d.question);
                d.answer = this.replaceVariables(d.answer);
            })
            this.MgmFaqsQuestionsRecomendador = res
        })
        
        this.translate.get('Baluwo.mgm.faqs.questions.recomendado').subscribe(res => {
            res.forEach((d: any) => {
                d.question = this.replaceVariables(d.question);
                d.answer = this.replaceVariables(d.answer);
            })
            this.MgmFaqsQuestionsRecomendado = res;
        })
    }

    replaceVariables(text: string) {
        return text
            .replace(/{{amount}}/g, this._promoHelper.getCurrencyMGMPromo())
            .replace(/{{discount}}/g, this._promoHelper.getCurrencyMGMPromoTopUp())
            .replace(/{{amountFood}}/g, this._promoHelper.getCurrencyMGMPromoFood())
            .replace(/{{amountTopUp}}/g, this._promoHelper.getCurrencyMGMPromoTopUp())
    }

    login() {
        const dialogRef = this.dialog.open(LoginmodalComponent, { panelClass: 'loginLayoutResets' });
        dialogRef.afterClosed().subscribe(() => {
            this.isLogged = null != localStorage.getItem(currentBuyer);
            if (this.isLogged) {
                this.updateHeader++;
                this.buyer = JSON.parse(localStorage.getItem(currentBuyer));
                this.buyerReference = this.buyer.wallet.id;
                this.getParamsForMessages();
            }
        });
    }

    showFaqsCall() {
        this.showFaqs = true;
    }
}