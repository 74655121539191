import { Component, EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ContextBase } from "../../shared/_helpers/_contextBase.helper";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-advert-popup-component',
    templateUrl: 'advertpopup.component.html',
    styleUrls: ['../loginmodal/loginmodal.component.scss']
})

export class AdvertPopUpComponent {
    public foodCondition: string;
    public cashpowerCondition: string;
    public airtimeCondition: string;
    public amountToSpend: string;
    public amountDiscount: string;
    public message: string;
    public continueTransaction = new EventEmitter();
    constructor(
        public context: ContextBase,
        private translate: TranslateService,
        private dialogue: MatDialog) {
        this.getCurrency(this.context.currency.toLowerCase());
        this.message = this.translate.instant('Baluwo.mgm.advert.popup', {amountToSpend: this.amountToSpend, amountDiscount: this.amountDiscount});
    }

    getCurrency(currency: string) {
        switch (currency) {
            case 'sek':
                this.amountToSpend = '100 sek';
                this.amountDiscount = '50 sek';
                break;
            default:
                this.amountToSpend = '10' + this.checkCurrency(currency);
                this.amountDiscount = '5' + this.checkCurrency(currency);
        }
    }

    checkCurrency(curr) {
        switch (curr) {
            case 'eur':
                return '€';
            case 'usd':
                return '$';
            case 'gbp':
                return '£';
            case 'sek':
                return 'SEK'
        }
    }

    cancel(){
        this.dialogue.closeAll();
    }

    continue(){
        this.continueTransaction.emit();
        this.cancel();
    }
}