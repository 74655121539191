import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Order, Transaction } from "../../../shared/_objects";

@Component({
    selector: 'app-cl-giftcard-component',
    templateUrl: './cl-giftcard.component.html',
    styleUrls: ['../cartlanding.component.scss','../../../../assets/css/bootstrap-grid.min.css']
})
export class CLGiftCardComponent {
    cartClass = 'purchaseOrder';

    @Input() order:Order;
    @Input() transaction:Transaction;
    @Input() transactionHistory: Transaction;
    @Input() isValidAmount: boolean;
    @Output() removeOrderEmit: EventEmitter<any> = new EventEmitter<any>();

    constructor(){
    }

    ngOnInit(){
    }

    removeOrder(){
        this.removeOrderEmit.emit(this.order);
    }
}