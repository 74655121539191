import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { serverBaseUrl } from "../../globals";

@Injectable()
export class PromotionService {
  private baseUrl = serverBaseUrl;
  constructor(private http: HttpClient) { }

  getPromotionActive(): Observable<any> {
    const country$ = this.http
      .get(`${this.baseUrl}/promotion/active`, { headers: this.getNoAuthHeader() })
      .pipe(catchError(handleError));
    return country$;
  }

  sendReferralCodeIOS(code:any): Observable<any>{
    const referral$ = this.http
    .post(`${this.baseUrl}/v1/buyer/referral/ios/${code}`,null, { headers: this.getNoAuthHeader() })
    .pipe(catchError(handleError));
  return referral$;
  }

  private getNoAuthHeader() {
    const headers = new HttpHeaders({
        'x-blw-src': 'baluwoWeb',
        'Accept': 'application/json'
    });
    return headers;
}
}

function handleError(error: HttpErrorResponse) {
    console.log(error);
    return throwError(error.error);
  }