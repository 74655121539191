import { Injectable } from "@angular/core";
import { Order } from "../_objects/order";
import { Transaction } from "../_objects/transaction";
import { currentTransaction } from "../../globals";
currentTransaction

@Injectable()
export class TopUpOrderHelper {
    currentTransaction: Transaction;
    manualAmount: boolean = false;
    minElectricity: number = 2.5;
    maxElectricity: number = 50;
    isValidAmount: boolean = true;
    amounts: number[] = [];
    mobileArray: any[] = [];

    constructor() { }

    checkMobileOrderArray() {
        let mobileArray = [];
        this.currentTransaction = JSON.parse(localStorage.getItem(currentTransaction));
        if (this.currentTransaction != null) {
            this.currentTransaction.orders.forEach((o) => {
                if (o.type == "topuporder") {
                    this.mobileArray.push(o.mobile);
                }
            })
            return this.mobileArray;
        }
    }
    topUpOrderPersonalized(order: Order) {
        this.checkMobileOrderArray();
        return new Promise((resolve, reject) => {
            if (this.mobileArray.indexOf(order.mobile) == -1) {
                this.recalculeTotalAmountPersonalized(order);
                resolve();
            } else {
                reject();
                return false;
            }
        })
    }

    recalculeTotalAmountPersonalized(order: Order) {
        let promise = new Promise((resolve, reject) => {
            if (order.srcTotal == undefined || order.srcTotal == null) {
                this.manualAmount = true;
                resolve();
            } else {
                this.manualAmount = false;
                if (order.srcTotal < this.minElectricity) {
                    this.isValidAmount = false;
                } else if (order.srcTotal > this.maxElectricity) {
                    this.isValidAmount = false;
                } else {
                    this.isValidAmount = true;
                    const exist = this.amounts.filter(x => x === order.srcTotal)[0];
                    if (typeof exist === 'undefined') {
                        this.amounts.push(order.srcTotal);
                        this.amounts.sort(this.sortNumber);
                    }
                    // this.addProductCart();
                }
                resolve();
            }

        });
        return promise;
    }
    sortNumber(a, b) {
        return a - b;
    }
}