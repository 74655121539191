import { Component, EventEmitter, Input, Output } from "@angular/core";
import { HomeUtilsHelper } from "../../../shared/_helpers/_homeUtils.helper";
import { ScrollerTargetHelper } from "../../../shared/_helpers/_scrollerTarget.helper";
import { Buyer, Order } from "../../../shared/_objects";
import { GiftCardService } from "../../../core/services/giftcard.service";
import { LocationSectorStoreHelper } from "../../../shared/_helpers/_locationSectorStore.helper";
import { currentBuyer } from "../../../globals";
import { ContextBase } from "../../../shared/_helpers/_contextBase.helper";
import { ValidityOrderHelper } from "../../../shared/_helpers/_validityOrder.helper";

@Component({
    selector: 'app-giftcard-component',
    templateUrl: './home_giftcard.component.html',
    styleUrls: ['./home_giftcard.component.scss', '../home.component.scss', '../../../../assets/css/bootstrap-grid.min.css']
})

export class GiftCardComponent {
    public manualAmount: boolean;
    public order: Order = new Order;
    public allowPayment: boolean = false;
    public wrongAmount: boolean = false;
    public buyer: Buyer;
    sourceCurrency: string;

    @Input() premobile: any;
    @Output() giftCardData: EventEmitter<any> = new EventEmitter<any>();
    @Output() goToCartLanding: EventEmitter<any> = new EventEmitter<any>();
    @Output() goToHome: EventEmitter<any> = new EventEmitter<any>();

    constructor(public locationSectorStoreHelper: LocationSectorStoreHelper,
        public _homeUtilsHelper: HomeUtilsHelper,
        public contexto: ContextBase,
        public _scrollerTargetHelper: ScrollerTargetHelper,
        public _validityOrderHelper: ValidityOrderHelper,
        public giftCardService: GiftCardService) { }

    ngOnInit() {
        this.buyer = JSON.parse(localStorage.getItem(currentBuyer));
        (this.buyer === null) ? this.sourceCurrency = this.contexto.currency.toUpperCase() : this.sourceCurrency = this.buyer.currency.id;
    }

    getName(text: string) {
        return text.replace('Gift Card for', '');
    }

    checkEnterButton(evt, cardamount: number){
        if(evt.keyCode === 13){
            this.checkGiftCardAmountPersonalized(cardamount);
        }
    }

    getGiftCardsAmount(card) {
        if (this.allowPayment) return false;
        this._homeUtilsHelper.validatePhoneNumber(this.premobile, this.locationSectorStoreHelper.currentCountry.mobilePrefix);
        if (!this._homeUtilsHelper.isValidBeneficiaryMobile) {
            this._scrollerTargetHelper.scrollToTarget("beneficiary");
            this.allowPayment = false;
            return false;
        } else {
            this.locationSectorStoreHelper.createGiftCardStore(card);
        }
    }

    addGiftCardFixedAmount(card: any) {
        if (card.amount == undefined) {
            this.manualAmount = true;
            this.order = card;
        } else {
            this._homeUtilsHelper.validatePhoneNumber(this.premobile, this.locationSectorStoreHelper.currentCountry.mobilePrefix);
            if (!this._homeUtilsHelper.isValidBeneficiaryMobile) {
                this._scrollerTargetHelper.scrollToTarget("beneficiary");
                this.allowPayment = false;
                return false;
            } else {
                this.order = card;
            }
        }
    }

    checkGiftCardAmountPersonalized(amount) {
        if (amount == null || !this._validityOrderHelper.checkValidityOrder(amount, 'giftcard')) {
            this.wrongAmount = true;
            this.order.amount = null;
        } else {
            this.wrongAmount = false;
            this.order.amount = amount;
        }
    }

    buildGiftCardOrder(order) {
        this.allowPayment = true;
        this.giftCardData.emit(order);
    }
}