import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { TransactionHelper } from "./_transactionHelper.helper";
import { ContextBase } from "./_contextBase.helper";
import { ValidityOrderHelper } from "./_validityOrder.helper";
import { TransactionServiceV1 } from "../../core/services/transactionv1.service";

@Injectable()
export class ServerErrorHelper {
    public errorMsg: string;
    public errorConditions: any;
    public errorAmount: number;
    defaultMinAmount: number;
    defaultMaxAmount: number;
    public errorMsgAmountService: any;
    constructor(private translate: TranslateService,
        public transaction: TransactionServiceV1,
        public contexto: ContextBase,
        public _validityOrderHelper: ValidityOrderHelper,
        public _transactionHelper: TransactionHelper) {
        this.transaction.getConditions().subscribe(t => {
            this.errorConditions = t;
        })
    }

    getErrorMsg(code, msg?: string) {
        switch (code) {
            case 4:
                return this.translate.instant('Baluwo.SERVER_ERROR.4');
            case 5:
                return this.translate.instant('Baluwo.SERVER_ERROR.5');
            case 6:
                return this.translate.instant('Baluwo.SERVER_ERROR.6');
            case 7:
                return this.translate.instant('Baluwo.SERVER_ERROR.7');
            case 8:
                return this.translate.instant('Baluwo.SERVER_ERROR.8');
            case 9:
                return this.translate.instant('Baluwo.SERVER_ERROR.9');
            case 10:
                return this.translate.instant('Baluwo.SERVER_ERROR.10');
            case 11:
                this.errorAmount = this._validityOrderHelper.minAmountOrder;
                this.checkAMountsErrors(msg);
                return this.translate.instant('Baluwo.SERVER_ERROR.11', { minAmount: this.defaultMinAmount.toFixed(2), maxAmount: this.defaultMaxAmount.toFixed(2) });
            case 12:
                return this.translate.instant('Baluwo.SERVER_ERROR.12');
            case 13:
                return this.translate.instant('Baluwo.SERVER_ERROR.13');
            case 14:
                return this.translate.instant('Baluwo.SERVER_ERROR.14');
            case 15:
                return this.translate.instant('Baluwo.SERVER_ERROR.15');
            case 16:
                return this.translate.instant('Baluwo.SERVER_ERROR.16');
            case 17:
                return this.translate.instant('Baluwo.SERVER_ERROR.17');
            case 18:
                return this.translate.instant('Baluwo.SERVER_ERROR.18');
            case 23:
                return this.translate.instant('Baluwo.SERVER_ERROR.23');
            case 25:
                return this.translate.instant('Baluwo.Agent.Supplier.disabled');
            case 400:
                return this.translate.instant('Baluwo.SERVER_ERROR.400');
            default:
                return this.translate.instant('Baluwo.SERVER_ERROR.500');
        }
    }

    checkAMountsErrors(msg) {
        console.log(msg + ' -- ' + this._validityOrderHelper.maxAmountOrder)
        if (msg.indexOf(this._validityOrderHelper.maxAmountOrder) !== -1) {
            this.defaultMinAmount = this._validityOrderHelper.minAmountOrder;
            this.defaultMaxAmount = this._validityOrderHelper.maxAmountOrder;
        }
        if (msg.indexOf(this._validityOrderHelper.maxRamAmountOrder) !== -1) {
            this.defaultMinAmount = this._validityOrderHelper.minRamAmountOrder;
            this.defaultMaxAmount = this._validityOrderHelper.maxRamAmountOrder;
        }
        if (msg.indexOf(this._validityOrderHelper.maxConstructionAmountOrder) !== -1) {
            this.defaultMinAmount = this._validityOrderHelper.minConstructionAmountOrder;
            this.defaultMaxAmount = this._validityOrderHelper.maxConstructionAmountOrder;
        }
        if (msg.indexOf(this._validityOrderHelper.maxBillAmountOrder) !== -1) {
            this.defaultMinAmount = this._validityOrderHelper.minBillAmountOrder;
            this.defaultMaxAmount = this._validityOrderHelper.maxBillAmountOrder;
        }
        if (msg.indexOf(this._validityOrderHelper.maxElectricity) !== -1) {
            this.defaultMinAmount = this._validityOrderHelper.minElectricity;
            this.defaultMaxAmount = this._validityOrderHelper.maxElectricity;
            console.log(this.defaultMinAmount);
            console.log(this.defaultMaxAmount);
        }
    }
}
