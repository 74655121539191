import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { MatDialog, MatSelect } from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";
import { TransactionServiceV1 } from "../../core/services/transactionv1.service";
import { Buyer, Country } from "../../shared/_objects";
import { currentBuyer } from "../../globals";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { HttpInternalServcie } from "../../shared/_services/_http-internal.service";
import { ServerErrorHelper } from "../../shared/_helpers/servererrors.helper";
import { ErrorPopUpComponent } from "../errorpopup/errorpopup.component";
import { CurrencyAmountService } from "../../shared/_services/currencyamount.service";
import { PopUpTransferOkComponent } from "./popuptransferok/popuptransferok.component";
import { CountryService } from "../../shared/_services/country.services";

@Component({
    selector: 'app-wallet-transfer-component',
    templateUrl: './wallettransfer.component.html',
    styleUrls: ['./wallettransfer.component.scss']
})

export class WalletTransferComponent {
    public amountToTransfer: number;
    public beneficiaryphone: string;
    insufficientBalance: boolean = false;
    receipt: any;
    noAuthforPaymentError: boolean = false;
    buyer: Buyer;
    myControl: FormControl;
    filteredOptions: Observable<any[]>;
    testValues: any = [];
    selectedCountry: any;
    countryPrefixOpen: boolean = false;
    invalidInputPhone: boolean;
    sameInputPhone: boolean;
    countries: any;
    baluwoCountries: any;
    public phone: string;
    public phoneFull: string;
    prefix: any;
    errorMesage: string;
    public UserWalletAmount: number;
    public wrongPrefix: boolean = false;

    @ViewChild('selectCountry') selectCountry: ElementRef;
    @Output() transferDone: EventEmitter<any> = new EventEmitter<any>();

    constructor(public dialog: MatDialog,
        protected sanitizer: DomSanitizer,
        public transactionService: TransactionServiceV1,
        public serverErrorHelper: ServerErrorHelper,
        public _currencyAmountService: CurrencyAmountService,
        public countryService: CountryService,
        private _httpInternalService: HttpInternalServcie) {
        this.myControl = new FormControl();
        this.buyer = JSON.parse(localStorage.getItem(currentBuyer));
        this._currencyAmountService.curWalletHeader.subscribe(res=>{
            if(!isNaN(res)){
                this.UserWalletAmount = res;
              } else {
                this.UserWalletAmount = this.buyer.wallet.aggregated;
              }
        })
        this.countryService.getSellingCountries()
            .subscribe(c => {
                this.baluwoCountries = c;
                console.log(c)
                this.baluwoCountries = this.baluwoCountries.filter(country => country.retailer === true);
                console.log(this.baluwoCountries);
                this.startFiltering();
            });
    }

    ngOnInit() {
        console.log(this.baluwoCountries)
        this.UserWalletAmount = this.buyer.wallet.aggregated;
        // this._httpInternalService.getCountriesJson()
        //     .subscribe((countries: any[]) => {
        //         this.countries = countries;
        //         this.testValues = countries;
        //         this.startFiltering();
        //     });
    }

    public startFiltering() {
        this.filteredOptions = this.myControl.valueChanges
            .pipe(
                startWith(''),
                map(value => this._filter(value))
            );
    }

    private _filter(value: string): string[] {
        const filterValue = value;
        if(this.baluwoCountries != undefined){
        return this.baluwoCountries.filter(option => option.name.toLowerCase().includes(filterValue));
    }
    }

    displayFn(country: any): string {
        // return country && country.callingCode !== undefined && country.callingCode[0] ? '+' + country.callingCode[0] : country;
        return country && country.mobilePrefix !== undefined && country.mobilePrefix ? country.mobilePrefix : country;
    }

    openCountryPrefix() {
        this.countryPrefixOpen = true;
        this.myControl.reset();
        this.invalidInputPhone = false;
        this.sameInputPhone = false;

    }

    changeBeneficiaryMobilStatic(){
        console.log(this.prefix);
        if(this.baluwoCountries.some(el=> el.mobilePrefix == this.prefix)){
            this.wrongPrefix = false;
        } else {
            this.wrongPrefix = true;
        }
    }

    checkUserWallet() {
        if (this.amountToTransfer > this.UserWalletAmount) {
            this.insufficientBalance = true;
            return false;
        } else {
            this.insufficientBalance = false;
            return true;
        }
    }

    TransferToAnotherWallet() {
        if (this.checkUserWallet()) {
            const data: Object = {
                mobile: this.beneficiaryphone,
                amount: this.amountToTransfer
            }
            const dialogRef= this.dialog.open(PopUpTransferOkComponent,{panelClass: 'loginLayoutResets'})
            dialogRef.componentInstance.onContinueTransfer.subscribe(res => {
                this.doTransfer(data);
            })
        }
    }

    doTransfer(data){
        this.transactionService.setTransferToWallet(data).subscribe(res => {
            console.log(res);
            this._currencyAmountService.setUpdatedWallet(res);
            this.myControl.reset();
            this.phone = null;
            this.amountToTransfer = null;
            this.transferDone.emit();
        }, error => {
            console.log(error);
            let errorcode = error;
            this.errorMesage = this.serverErrorHelper.getErrorMsg(errorcode.code);
            const dialogErrorRef = this.dialog.open(ErrorPopUpComponent, {
                panelClass: 'loginLayoutResets',
                data: { error: this.errorMesage }
            });
            dialogErrorRef.afterClosed().subscribe(() => { });
        })
    }

    changeBeneficiaryMobil(country) {
        this.phone = null;
        this.beneficiaryphone = null;
        this.prefix = country.mobilePrefix;
    }

    checkAndCreatePhone(): void {
        if(typeof this.prefix === 'object') this.prefix = this.prefix.mobilePrefix;
        const regExp = /[a-zA-Z]/g;
        if (!regExp.test(this.phone) && this.prefix != undefined) {
            this.phone = this.phone.trim().replace(/\s/g, "");
            this.beneficiaryphone = this.prefix + this.phone;
            if (this.beneficiaryphone === this.buyer.mobile) {
                this.invalidInputPhone = false;
                this.sameInputPhone = true;
            }else{
                this.invalidInputPhone = false;
                this.sameInputPhone = false;
            }
        } else {
            console.log('fuck')
            this.invalidInputPhone = true;
            this.sameInputPhone = false;
        }
    }

    getSelectedCountry(event) {
        this.countryPrefixOpen = false;
        this.phone = event.callingCode[0];
        this.invalidInputPhone = false;
        this.sameInputPhone = false;
    }
}