import { Component } from "@angular/core";
import { ContextBase } from "../../../shared/_helpers/_contextBase.helper";
import { CarouselHomePage } from "../../../shared/_helpers/_carousel.helper";

@Component({
    selector: 'app-home-testimony-component',
    templateUrl: './home_testimonySection.component.html',
    styleUrls: ['../home.component.scss']
})

export class HomeTestimonySectionComponent {
    private reviewUrl: string;
    constructor(public carouselHomePage: CarouselHomePage, public contexto: ContextBase) { }
    ngAfterViewInit() {
        this.carouselHomePage.initCarousel();
    }

    goToReviews() {
        switch (this.contexto.language.toLowerCase()) {
          case 'es':
            return "https://baluwo.com/contents/resenas/";
          case 'fr':
            return "https://baluwo.com/contents/fr/commentaires/";
          default:
            return "https://baluwo.com/contents/en/reviews/"
    
        }
      }
}