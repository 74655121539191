import { Catalog } from './catalog';
import { Location } from './location';
import { StoreOrderLine } from './order';
export class Store implements Store {
}
export class Sector implements Sector {
}
export interface Store {
  id: number;
  enabled: boolean;
  name: string;
  sector: Sector;
  location: Location;
  address: string;
  owner: StoreOwner;
  catalog: Catalog[];
  catalogSelection: CatalogSelection[];
  paymentMethod: string;
  bankAccount: string;
  xtrMobile1: string;
  xtrMobile2: string;
  xtrMobile3: string;
  email: string;
  forexBureau: number;
  category?: any[];
}

export class CatalogSelection {
  catalog: Catalog;
  quantity: number;
  total?:any;
}


export interface Sector {
  id: number;
  name: string;
  type: string;
}

export interface StoreOwner {
  id: number;
}

export interface BackStore {
  id: number;
  country: number;
  phone?: any;
  prefix?: number;
  sector: string;
  location: Location;
  store: Store;
  lines: StoreOrderLine[];
}

export interface BackCharge {
  sector: Sector;
}
