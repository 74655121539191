export class NationalRecharge {
    providerCode: string;
    name: string;
}

export class NationalProduct {
    displayText: string;
    productCode: string;
    providerCode: string;
    maxAmount: NationalAmount;
    minAmount: NationalAmount;
}

export class NationalAmount {
    srcCurrency: string;
    srcValue: number;
    targetCurrency: string;
    targetValue: number;
}

export class NationalProductAmount {
    amount: number;
    providerCode: string;
    productCode: string;
}

export class NationalProductPrice {
    productCode: string;
    price: NationalAmount;
    providerCode?: string;
}

export class NationalInputData {
    productCode: string;
    amount?: number;
    mobile: string;
    providerCode: string;
}

export class NationalInputButton {
    displayText: string;
    beneficiary: string;
    amount?: number;
    currency?: string;
    targetValue: number;
}

export class NationalOutputData {
    created: string;
    currency: Currency;
    id: number;
    orders: NationalOrder[];
}

export class NationalOrder {
    faceValue: number;
    id: number;
    mobile: string;
    purchaseTotal: number;
    srcTotal: number;
    state: number;
    targetCurrency: string;
    targetTotal: number;
    total: number;
    type: string;
}

export class Currency {
    id: string;
    acronym: string;
    name: string;
    symbol: string;
}