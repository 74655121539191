import { Country } from '../../../shared/_objects/country';
import { RegisterToken } from '../../../shared/_objects/registerToken';
import { CountryService } from '../../../shared/_services/country.services';
import { landingSourceCountry } from '../../../globals';
import { LoginService } from '../../../core/login-service/login.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { RegisterTokenCard } from '../../../shared/_objects/registerTokenCard';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { ServerErrorHelper } from '../../../shared/_helpers/servererrors.helper';
import { ErrorPopUpComponent } from '../../errorpopup/errorpopup.component';
import { MatDialog } from '@angular/material';
import * as $ from 'jquery';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Router } from '@angular/router';
import { ContextBase } from '../../../shared/_helpers/_contextBase.helper';

declare const window: any;
@Component({
  selector: 'app-loginmodal-step1',
  templateUrl: 'modal-step_1.component.html',
  styleUrls: ['../loginmodal.component.scss']
})
export class LoginmodalStep1Component implements OnInit {
  countryArray: Country[];
  countryPrefix: string;
  selectedCountry: Country;
  registerToken: RegisterToken = new RegisterToken;
  registerTokenCard: RegisterTokenCard = new RegisterTokenCard;
  mobile: string;
  cardNumber: string;
  defaultSourceCountry: string;
  isValidPhoneNumber = true;
  @Output() onRequestToken: EventEmitter<string> = new EventEmitter<string>();
  @Output() onClosingDialog: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRegistrationAvailable: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPasswordAvailable: EventEmitter<any> = new EventEmitter<any>();
  isValidCardNumber: boolean = true;
  isValidCardNumberErrorMsg: string;
  public captchaResponse: string = '';
  public captchKey: string;
  errorMesage: string;
  public newToken: string;
  keepContinue: boolean = false;
  constructor(private countryService: CountryService,
    private translate: TranslateService,
    public serverErrorHelper: ServerErrorHelper,
    private spinnerService: Ng4LoadingSpinnerService,
    public dialog: MatDialog,
    private contexto: ContextBase,
    private router: Router,
    private loginService: LoginService) {
    this.defaultSourceCountry = JSON.parse(localStorage.getItem(landingSourceCountry));
  }

  ngOnInit() {
    // this.defaultSourceCountry = localStorage.getItem(landingSourceCountry);
    this.countryService.getBuyingCountries().subscribe(
      c => {
        this.countryArray = c;
        this.selectedCountry = this.countryArray.filter(country => country.id === this.defaultSourceCountry)[0];
      });
    this.captchKey = environment.captchaKey;
  }
  filterCurrentCountry(country: Country) {
    return country.id === localStorage.getItem('landingSourceCountry').toString();
  }

  public resolved(captchaResponse: string) {
    this.sendRegisterTokenMobile(captchaResponse);
  }

  public resolvedNew(captchaResponse: string) {
    this.newToken = captchaResponse;
    this.getLoginPin();
  }

  public checkBeforeCaptcha() {
    if (this.validatePhoneNumber()) {
      grecaptcha.execute();
    } else {
      this.isValidPhoneNumber = false;
      return;
    }
  }

  sendRegisterTokenMobile(token?: string) {
    this.spinnerService.show();
    this.isValidCardNumber = true;
    if (!this.validatePhoneNumber()) {
      this.isValidPhoneNumber = false;
      return;
    }
    this.isValidPhoneNumber = true;
    this.registerToken.mobile = this.selectedCountry.mobilePrefix + this.mobile.replace(/[^\d]/g, '');
    this.loginService.getBuyerLoginInfo(this.registerToken, token)
      .subscribe((x) => {
        this.onRegistrationAvailable.emit(x.passwordLogin);
        if (!x.passwordLogin) {
          $('#hiddenbutton').trigger('click');
        } else {
          this.spinnerService.hide();
          this.onPasswordAvailable.emit(this.registerToken.mobile);
        }
      })
  }

  getLoginPin() {
    this.loginService.getRegisterToken(this.registerToken, this.newToken).subscribe(r => {
      this.onRequestToken.emit(r.mobile);
      this.spinnerService.hide();
    }, error => {
      this.spinnerService.hide();
      console.log('ERROR: ', error);
      this.errorMesage = this.serverErrorHelper.getErrorMsg(error.error.code);

      const dialogErrorRef = this.dialog.open(ErrorPopUpComponent, {
        panelClass: 'loginLayoutResets',
        data: { error: this.errorMesage }
      });
      dialogErrorRef.afterClosed().subscribe(() => { });
    });
  }

  cleanBaluwoCard() {
    this.cardNumber = '';
    this.isValidCardNumber = true;
  }

  validatePhoneNumber(): boolean {
    if (typeof this.selectedCountry === 'undefined') {
      return false;
    }else if (typeof this.mobile === 'undefined' || this.mobile.length < 2) {
      return false;
    } else {
      return true;
    }
  }
  close() {
    this.onClosingDialog.emit()
  }

  navigateTo(e: MouseEvent, page: string): void {
    e.preventDefault();
    this.router.navigate(['/' + page]);
    this.close();
  }

}

// html key: 6LenMvcUAAAAADAYgGVnEWWhIzMPISh1Qkt66j9D
// captcha key: 6LenMvcUAAAAAKjprv-1YYz5kdeJ9tBwuEq-imyo
