import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class PaymentHelperService {
    public updatePayment = new BehaviorSubject(<boolean>{});
    thisis: boolean;
    currentPaymentMethod = this.updatePayment.asObservable();

    constructor() { }

    updateCall(fire: boolean) {
        this.thisis = fire;
        this.updatePayment.next(fire);
    }

    getCall(){
        return this.thisis;
    }
}