import { Injectable } from '@angular/core';
import { SupplierService } from './suppliers.service';
import { ValidityOrderHelper } from '../../shared/_helpers/_validityOrder.helper';
import { TransactionServiceV1 } from './transactionv1.service';
import { ContextBase } from '../../shared/_helpers/_contextBase.helper';
import { Calculate } from '../../shared/_objects';
import { ToastrService } from 'ngx-toastr';
import { ServerErrorHelper } from '../../shared/_helpers/servererrors.helper';

@Injectable()
export class CommonService {
  public dynamicSupplierArray: any = [];
  public dynamicSupplierCashArray: any = [];
  public dynamicSupplierDataArray: any = [];
  public dynamicSupplierBundleArray: any = [];
  public beneficiaryMinimumAmount: number;
  public beneficiaryCashPowerMinimumAmount: number;
  public beneficiaryDataMinimumAmount: number;
  public beneficiaryBundleMinimumAmount: number;
  public promoTitle: string;
  public promoContent: string;
  public supplierIsEnabled: boolean = true;
  public supplierAirtimeIsEnabled: boolean = true;
  public supplierCashpowerIsEnabled: boolean = true;
  errorMesage: string;
  constructor(
    private supplierService: SupplierService,
    public _validityOrderHelper: ValidityOrderHelper,
    public transactionService: TransactionServiceV1,
    public serverErrorHelper: ServerErrorHelper,
    private toastr: ToastrService,
    public contexto: ContextBase) { }

  getLanguageList() {
    return ['ES', 'EN', 'FR', 'DE', 'SE', 'IT'];
  }
  getLanguageNames() {
    const langName = new Map<string, string>();
    langName.set('ES', 'Español');
    //return langName;
    return ['Español', 'English', 'Français', 'Deutsch', 'Svenska', 'Italiano'];
  }
  getCurrencyList() {
    return ['EUR', 'USD', 'GBP', 'SEK'];
  }

  getStaticSupplierOnce(countryId: string) {
    this.dynamicSupplierArray = [];
    this.dynamicSupplierCashArray = [];
    this.dynamicSupplierDataArray = [];
    this.dynamicSupplierBundleArray = [];
    this.cleanBeneficiaryMinAmounts();
    this.supplierService.getSuppliersByCountry(countryId).subscribe(res => {
      res.forEach(f => {
        if (f.sector.name === 'Cash power') {
          this.dynamicSupplierCashArray.push(f);
        } else if (f.sector.name === 'Air time') {
          this.dynamicSupplierArray.push(f);
        } else if (f.sector.name === 'Bundle') {
          this.dynamicSupplierBundleArray.push(f);
        } else {
          this.dynamicSupplierDataArray.push(f);
        }
      })
    })
  }

  buildAmountTargetObject(type, dat, beneficiaryphone, meterId?) {
    let data: Calculate = <Calculate>{};
    data.type = type;
    data.targetTotal = dat;
    if (meterId) data.meterId = meterId;
    data.beneficiary = <any>{};
    data.beneficiary.mobile = beneficiaryphone;
    data.beneficiary.name = 'C';
    return data;
  }

  cleanBeneficiaryMinAmounts() {
    this.beneficiaryMinimumAmount = null;
    this.beneficiaryCashPowerMinimumAmount = null;
    this.beneficiaryDataMinimumAmount = null;
    this._validityOrderHelper.changeCurrencyRef();
  }

  getSupplierMinimumAmount(beneficiary: string) {
    const stop = false;
    this.dynamicSupplierArray.forEach(x => {
      if (beneficiary.match(x.carrier.regExp)) {
        this.supplierAirtimeIsEnabled = x.enabled;
        if (!this.supplierAirtimeIsEnabled) {
          this.errorMesage = this.serverErrorHelper.getErrorMsg(25);
          this.serverErrorHelper.errorMsgAmountService = this.errorMesage;
        }
        if (x.minAmount) {
          let minAmountConvert: number;
          (this.contexto.currency.toLowerCase() === 'sek') ? minAmountConvert = x.minAmount * 10 : minAmountConvert = x.minAmount;
          const data = this.buildAmountTargetObject('airtime', minAmountConvert, beneficiary);
          this.transactionService.getCalculations(data, this.contexto.currency.toUpperCase()).subscribe(res => {
            this.beneficiaryMinimumAmount = res[0].purchaseTotal;
          }, error => {
            this.beneficiaryMinimumAmount = this._validityOrderHelper.minAirtime;
            return false;
          })
        } else {
          this.beneficiaryMinimumAmount = undefined;
        }
      }
    });
  }

  getSupplierCashPowerMinimumAmount(beneficiary: string, meterId: any) {
    console.log(meterId)
    this.dynamicSupplierCashArray.forEach(x => {
      if ((<string>meterId.toString()).match(x.carrier.regExp)) {
        this.supplierCashpowerIsEnabled = x.enabled;
        if (x.minAmount) {
          let minAmountConvert: number;
          (this.contexto.currency.toLowerCase() === 'sek') ? minAmountConvert = x.minAmount * 10 : minAmountConvert = x.minAmount;
          const data = this.buildAmountTargetObject('cashpower', minAmountConvert, beneficiary, meterId);
          this.transactionService.getCalculations(data, this.contexto.currency.toUpperCase()).subscribe(res => {
            this.beneficiaryCashPowerMinimumAmount = res[0].purchaseTotal;
          }, error=>{
            this.beneficiaryCashPowerMinimumAmount = this._validityOrderHelper.minElectricity;
          })
        } else {
          this.beneficiaryCashPowerMinimumAmount = undefined;
        }
      }
    });
  }

  getSupplierDataMinimumAmount(beneficiary: string) {
    this.dynamicSupplierDataArray.forEach(x => {
      if (beneficiary.match(x.carrier.regExp)) {
        this.supplierIsEnabled = x.enabled;
        if (!this.supplierIsEnabled) {
          this.errorMesage = this.serverErrorHelper.getErrorMsg(25);
          this.serverErrorHelper.errorMsgAmountService = this.errorMesage;
        }
        let minAmountConvert: number;
        (this.contexto.currency.toLowerCase() === 'sek') ? minAmountConvert = x.minAmount * 10 : minAmountConvert = x.minAmount;
        this.beneficiaryDataMinimumAmount = minAmountConvert;
      }
    });
  }

  getSupplierBundleMinimumAmount(beneficiary: string) {
    this.dynamicSupplierBundleArray.forEach(x => {
      if (beneficiary.match(x.carrier.regExp)) {
        this.supplierIsEnabled = x.enabled;
        if (!this.supplierIsEnabled) {
          this.errorMesage = this.serverErrorHelper.getErrorMsg(25);
          this.serverErrorHelper.errorMsgAmountService = this.errorMesage;
        }
        let minAmountConvert: number;
        (this.contexto.currency.toLowerCase() === 'sek') ? minAmountConvert = x.minAmount * 10 : minAmountConvert = x.minAmount;
        this.beneficiaryBundleMinimumAmount = minAmountConvert;
      }
    });
  }

  getPromoAirtime(beneficiaryMobile) {
    const promo = {
      type: 'airtime',
      beneficiary: { 'mobile': beneficiaryMobile }
    }
    this.supplierService.getSuppliersPromotions(promo).subscribe(res => {
      if (res) {
        this.showPromotion(res);
      }
    })
  }

  getPromoCashPower(beneficiaryMobile: string, meterId: any) {
    const promo = {
      type: 'cashpower',
      beneficiary: { 'mobile': beneficiaryMobile },
      meterId: meterId
    }
    this.supplierService.getSuppliersPromotions(promo).subscribe(res => {
      if (res) {
        this.showPromotion(res);
      }
    })
  }

  showPromotion(promo) {
    this.toastr.info(promo.description, promo.name, {
      closeButton: true,
      disableTimeOut: true
    });
  }
}
