import { Component, Optional, Inject, EventEmitter } from "@angular/core";
import { CyberpackPaymentInfo } from "../../shared/_objects/cyberpackPaymentInfo";
import { SofortPaymentInfo } from "../../shared/_objects/sofortPaymentInfo";
import { PaymentService } from "../../core/payment/payment.service";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import * as $ from 'jquery';

@Component({
    selector: 'app_payment-popup',
    templateUrl: './paymentpopupwallet.component.html',
    styleUrls: ['./paymentpopup.component.scss', '../agentregistrationweb/agentregistrationweb.component.scss']
})

export class PaymentPopUpWalletComponent {
    paymentInfoCyberpack: CyberpackPaymentInfo;
    paymentInfoSofort: SofortPaymentInfo;
    paymentInfoBizum:any;
    cyberPackId: number = 1;
    sofortPaymentId: number = 2;
    bizumId: number =  11;
    allowPaymentMethod: boolean = true;
    allowPaymentMethodSofort: boolean = true;
    allowPaymentMethodBizum: boolean;
    paymentId: number = 1;
    dataPayment: any;
    registrationAmount: number = 100;
    public methodSelected: boolean = false;
    public noAuthforPayment=  new EventEmitter();
    
    constructor(private _paymentService: PaymentService,
        private dialogue: MatDialog,
        private route: ActivatedRoute,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
        console.log(data);
        this.getPaymentInfoCyberpack();
        this.getPaymentInfoSofort();
        this.getPaymentInfoBizum();
    }

    getPaymentInfoCyberpack() {
        this.paymentInfoCyberpack = <CyberpackPaymentInfo>{};
        this._paymentService.getPaymentInfoWalletCyberpac(this.cyberPackId, this.data).subscribe(d => {
            console.log(d);
            this.paymentInfoCyberpack = d;
            this.allowPaymentMethod = true;
        }, error => {
            if (error.status === 104) {
                this.noAuthforPayment.emit(true);
                this.close();
            }
        });
    }

    getPaymentInfoBizum() {
        this.paymentInfoBizum = <any>{};
        this._paymentService.getPaymentInfoWalletBizum(this.bizumId, this.data).subscribe(d => {
            console.log(d);
            this.paymentInfoBizum = d;
            this.allowPaymentMethodBizum = true;
        }, error => {
            if (error.status === 104) {
                this.allowPaymentMethodBizum = false;
                this.noAuthforPayment.emit(true);
                this.close();
            }
        });
        console.log(this.allowPaymentMethodBizum)
    }

    getPaymentInfoSofort() {
        this.paymentInfoSofort = <SofortPaymentInfo>{};
        this._paymentService.getPaymentInfoWalletSofort(this.sofortPaymentId, this.data).subscribe(p => {
            this.paymentInfoSofort = p;
            this.allowPaymentMethodSofort = true;
            console.log(p);
        }, error => {
            console.log(error)
            if (error.status == 406) {
                this.allowPaymentMethodSofort = false;
            }
        });
    }

    checkSelectedPayment(payid) {
        console.log(payid);
        this.paymentId = payid;
        this.methodSelected = true;
        $('*[class*="payment-selected-"]').removeClass('selected');
        $('.payment-selected-' + payid).addClass('selected');
    }

    updateAmount(ev) {
        this.registrationAmount = ev.target.value;
    }

    close() {
        this.dialogue.closeAll();
        console.log('closing');
    }
}