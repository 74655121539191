import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: 'remitly',
    templateUrl: './remitly.component.html'
})

export class RemitlyComponent{
    constructor(private router: Router){
        console.log('here we are')
        this.router.navigate(['/en/eur/home']);
    }
}