import { Buyer } from './buyer';
import { Currency } from './country';
import { Order, StoreOrderUniq } from './order';
import { User } from './user';
export class Transaction {
  id: number;
  buyer: Buyer;
  srcCurrency: Currency;
  origin: User;
  created: string;
  message: string;
  promotion: Transaction;
  orders: Order[];
  memberGetMember: string;
  // AUX member as sum of orders
  total: number;
  // Aux member for showing detail in my account
  showDetail = false;
  closedDate?: string;
  currency?:any;
}

export class NewTransaction{
  buyer?: {
    mobile: string;
    name: string;
  };
  clientId: string;
  orders: StoreOrderUniq[];
}

