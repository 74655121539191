import { Component, Optional } from "@angular/core";
import { Buyer } from "../../shared/_objects/buyer";
import { currentBuyer } from "../../globals";
import { LoginService } from "../../core/login-service/login.service";
import { GDPRService } from "../../shared/_services/gdpr.service";
import { DomSanitizer } from "@angular/platform-browser";
import { MatDialog, MatDialogRef } from "@angular/material";
import { SuccessPopupGenericComponent } from "../successpopupgeneric/successpopupgeneric.component";
import { TranslateService } from "@ngx-translate/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
    selector: 'app-gdpr-component',
    templateUrl: 'gdpr.component.html',
    styleUrls: ['../legalconditions/legalconditions.component.scss', '../../../assets/css/bootstrap-grid.min.css'],
})

export class GDPRComponent {
    buyer: Buyer;
    gdprContent: any;
    contactForm: FormGroup;
    isSubmitted: boolean = false;
    public contactType: [string, string, string] = ['Email', 'WhatsApp', 'Phone'];
    constructor(private loginService: LoginService,
        private _gdprService: GDPRService,
        private dialog: MatDialog,
        private fb: FormBuilder,
        private translate: TranslateService,
        @Optional() public dialogRef: MatDialogRef<SuccessPopupGenericComponent>,
        sanitizer: DomSanitizer) {
        this.buyer = JSON.parse(localStorage.getItem(currentBuyer));
        this._gdprService.get().then((data) => {
            this.gdprContent = sanitizer.bypassSecurityTrustHtml(data);
        });

        this.contactForm = this.fb.group({
            'contactType': ['', Validators.compose([Validators.required])],
            'message': ['']
        })

    }

    sendMessage() {
        this.isSubmitted = true;
        let contactMessageInformation: any = <any>{};
        contactMessageInformation.message = this.contactForm.get('message').value;
        contactMessageInformation.contactType = this.contactForm.get('contactType').value;
        if (this.contactForm.valid) {
            this.loginService.unsubscribe(contactMessageInformation).subscribe(res => {
                const dialogRef = this.dialog.open(SuccessPopupGenericComponent, {
                    panelClass: 'loginLayoutResets',
                    data: { message: this.translate.instant('Baluwo.baluwocard.gdpr.unsub') }
                })
                dialogRef.afterClosed().subscribe(() => { 
                    this.contactForm.reset();
                });
            })
        }
    }
}