import { Component, Output, EventEmitter } from "@angular/core";
import { DinamicMetaTags } from "../../shared/_helpers/_dinamicMetaTags";
import * as $ from 'jquery';
import { LegalConditionsService } from "../../shared/_services/legal-condition.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    templateUrl: 'legalconditions.component.html',
    styleUrls: ['./legalconditions.component.scss'],
})

export class LegalConditionsComponent{
    @Output() SuccesspopupComponent: EventEmitter<any> = new EventEmitter<any>();

    public LegalConditionsContent: any;
    constructor(private _legalConditionsService: LegalConditionsService,private dinamicMetaTags:DinamicMetaTags, public sanitizer: DomSanitizer){
        this.dinamicMetaTags.initLegalConditionsMetaTag();
       $('.headerMain__navLi--action').addClass('legal');
        this._legalConditionsService.get().then((data) => {
            this.LegalConditionsContent = sanitizer.bypassSecurityTrustHtml(data);
        });
    }

}