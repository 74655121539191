import { Buyer } from '../../../shared/_objects/buyer';
import { StoreOrderLine } from '../../../shared/_objects/order';
import { RegisterToken } from '../../../shared/_objects/registerToken';
import { Transaction } from '../../../shared/_objects/transaction';
import { currentTransaction, currentBuyer, landingCurrency, STORE_ORDER_TYPE } from '../../../globals';
import { LoginService } from '../../../core/login-service/login.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ContextBase } from '../../../shared/_helpers/_contextBase.helper';
import * as $ from 'jquery';
import { FooterService } from '../../../shared/_services/footer.service';
import { TransactionServiceV1 } from '../../../core/services/transactionv1.service';
import { Email } from '../../../shared/_objects';
import { environment } from '../../../../environments/environment';
import { NewPinEmail } from '../../../shared/_objects/email';

@Component({
  selector: 'app-loginmodal-step2',
  templateUrl: 'modal-step_2.component.html',
  styleUrls: ['../loginmodal.component.scss']
})
export class LoginmodalStep2Component implements OnInit {
  @Input() mobile: string;
  @Output() onBuyerCreated: EventEmitter<Buyer> = new EventEmitter<Buyer>();
  @Output() onAuthenticationError: EventEmitter<any> = new EventEmitter<any>();
  @Output() onOpenHelp: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClosingDialog: EventEmitter<any> = new EventEmitter<any>();
  password: string;
  registerToken: RegisterToken = new RegisterToken;
  buyer: Buyer = new Buyer;
  currentTransaction: Transaction;
  helpemail = new Email;
  public _ENVIRONMENT_ = environment;
  public captchKey: string;
  public newPinSend: boolean = false;

  public name: string;
  public email: string;
  public registerpassword: string;

  constructor(private loginService: LoginService,
    private transactionService: TransactionServiceV1,
    public contexto: ContextBase,
    private footerService: FooterService,
    private router: Router) {
     }

  ngOnInit() {
    this.captchKey = environment.captchaKey;
    $('.toLegalConditions').click(() => {
      console.log('clicking');
      this.router.navigate(['/legal-conditions']);
    })
  }

  sendRegisterTokenMobile() {
    this.registerToken.mobile = this.mobile;
    this.loginService.getRegisterToken(this.registerToken)
      .subscribe();
  }

  authenticateUser() {
    this.buyer.mobile = this.mobile;
    this.buyer.currency = this.contexto.getCurrencyInfo(this.contexto.currency.toLowerCase());
    this.loginService.createBuyer(this.buyer, this.password)
      .subscribe(b => {
        console.log(b.body);
        console.log(b.headers);
        this.onBuyerCreated.emit(b.body);
        console.log(b);
        console.log(b.headers.get('x-blw-auth'));
        localStorage.setItem('currentAuth', 'Basic ' + btoa(this.buyer.mobile + ':' + b.headers.get('x-blw-auth')));
        this.currentTransaction = JSON.parse(localStorage.getItem(currentTransaction));
        console.log(this.currentTransaction);
      },
        error => this.onAuthenticationError.emit()
      );
  }

  sortOrderLines() {
    this.currentTransaction.orders.forEach(o => {
      if (o.type === STORE_ORDER_TYPE) {
        o.lines.sort(this.sortProduct);
        // Leverage this function to add ramadan promotion
        let bags = 0;
        o.lines.forEach(l => {
          const name = l.catalog.product.name;
          if (name.toLocaleLowerCase().indexOf('rice') > -1 || name.toLocaleLowerCase().indexOf('riz') > -1
            || name.toLocaleLowerCase().indexOf('sugar') > -1 || name.toLocaleLowerCase().indexOf('sucre') > -1) {
            bags += l.quantity;
          }
        });
      }
    });
  }

  sortProduct(linea: StoreOrderLine, lineb: StoreOrderLine) {
    return linea.catalog.product.priority - lineb.catalog.product.priority;
  }
  persistTransaction(buyer: Buyer) {
    // User is logged in. We can create the transaction
    localStorage.setItem(currentBuyer, JSON.stringify(buyer));
    this.footerService.setFooterBuyer(true);
    if (this.currentTransaction !== null) {
      this.currentTransaction.buyer = buyer;
      this.transactionService.createTransaction(this.currentTransaction)
        .subscribe(t => {
          console.log('created' + t);
          this.currentTransaction = t;
          this.sortOrderLines();
          localStorage.setItem(currentTransaction, JSON.stringify(this.currentTransaction));
        },
          error => {
            if (typeof this.currentTransaction.memberGetMember !== 'undefined') {
              this.currentTransaction.memberGetMember = null;
              this.transactionService.createTransaction(this.currentTransaction)
                .subscribe(t => {
                  console.log('created' + t);
                  this.currentTransaction = t;
                  this.sortOrderLines();
                  localStorage.setItem(currentTransaction, JSON.stringify(this.currentTransaction));
                });
            }
          });
    } else {
      console.log('no transaction');
    }
  }

  openHelp() {
    // this.email = new Email;
    this.helpemail = <Email>{};
    this.helpemail.leadEmail = null;
    this.helpemail.leadName = null;
    this.helpemail.leadPhone = this.mobile;
    this.helpemail.leadMessage = 'user ' + this.mobile + ' requested help for login in a campaign';
    this.helpemail.destinationEmail = this._ENVIRONMENT_.EmailSupport;
    this.helpemail.destinationBBCEmail = this._ENVIRONMENT_.EmailBBC;
    this.loginService.sendMail(this.helpemail).subscribe(()=>{
      console.log('email sent');
      this.onOpenHelp.emit();
    });
  }

  close() {
    this.onClosingDialog.emit()
  }

  navigateTo(page: string) {
    this.router.navigate(['/' + page]);
  }

  public resolved(captchaResponse: string) {
    this.sendPinNotRecievedEmail(captchaResponse);
  }

  sendPinNotRecievedEmail(captcha){
    let userMobile:NewPinEmail = <NewPinEmail>{};
    userMobile.mobile = this.mobile
    this.loginService.sendPinBuyerHelp(userMobile, captcha).subscribe(res=>{
      console.log(res);
      // this.newPinSend = true;
      this.onOpenHelp.emit();
    })
  }

}
