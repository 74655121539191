import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoginService } from "../../core/login-service/login.service";
import { currentBuyer, currentTransaction, landingCurrency, TransactionId } from "../../globals";
import { Buyer, RegisterToken, Transaction } from "../../shared/_objects";
import { HomeUtilsHelper } from "../../shared/_helpers/_homeUtils.helper";
import { TransactionServiceV1 } from "../../core/services/transactionv1.service";
import { ContextBase } from "../../shared/_helpers/_contextBase.helper";
import { PaymentService } from "../../core/payment/payment.service";
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-sisal-component',
    templateUrl: 'sisal.component.html',
    styleUrls: ['../home/home.component.scss', '../../../assets/css/bootstrap-grid.min.css'],
})

export class SisalComponent {
    registerToken: RegisterToken = new RegisterToken;
    public pinNumber: any;
    public captchaResponse: string = '';
    public captchKey: string;
    public mobile: string;
    public password: string;
    public buyer: Buyer = new Buyer;
    public step1: boolean = true;
    public step2: boolean = false;
    public step3: boolean = false;
    public step4: boolean = false;
    isValidPhoneNumber = true;
    public invalidCode: boolean = false;
    public invalidpin: boolean = false;
    currentTransaction: Transaction;
    public transactionIdUniq: any;
    public captchaToken: string;
    public pinAggregated: any;
    public amountAggregated: any;
    public updateHeader: number = 1;
    constructor(private route: ActivatedRoute,
        public loginService: LoginService,
        public contexto: ContextBase,
        private router: Router,
        public paymentService: PaymentService,
        public transactionService: TransactionServiceV1,
        public _homeUtilsHelper: HomeUtilsHelper) {
            this.captchKey = environment.captchaKey;
        console.log(this.route.snapshot.params['pin']);
        this.pinNumber = this.route.snapshot.params['pin'];
        if (JSON.parse(localStorage.getItem(currentBuyer)) !== null) {
            this.step3 = true;
            this.step1 = false;
            if (this.pinNumber !== null) {
                setTimeout(() => {
                    this.createCaptchaDinamic();
                }, 500)
            }
        }
    }

    public resolved(captchaResponse: string) {
        console.log(captchaResponse);
        this.sendRegisterTokenMobile(captchaResponse);
    }

    public resolvedPin(captchaResponse: string) {
        console.log(captchaResponse);
        this.getPinWalletAggregated(captchaResponse);
    }

    public checkBeforeCaptcha() {
        if (this.validatePhoneNumber()) {
            grecaptcha.execute();
        } else {
            this.isValidPhoneNumber = false;
            return;
        }
    }

    public createCaptchaDinamic() {
        grecaptcha.execute();
    }

    validatePhoneNumber(): boolean {
        console.log(this.mobile);
        if (typeof this.mobile === 'undefined' || this.mobile.length < 2) {
            this.isValidPhoneNumber = false;
            return false;
        }else  {
            return true;
        }
    }

    public sendRegisterTokenMobile(token: string) {
        this.captchaToken = token;
        this.registerToken.mobile = '+39' + this.mobile.replace(/[^\d]/g, '');
        console.log(this.registerToken.mobile);
        this.loginService.getRegisterToken(this.registerToken, token)
            .subscribe((r) => {
                console.log(r);
                this.step2 = true;
                this.step1 = false;
            })
    }

    public authentivateuser() {
        this.buyer.mobile = this.registerToken.mobile;
        this.buyer.currency = JSON.parse(localStorage.getItem(landingCurrency));
        this.loginService.createBuyer(this.buyer, this.password)
            .subscribe(b => {
                console.log(b.body);
                console.log(b.headers);
                this.onBuyerCreated(b.body);
                localStorage.setItem('currentAuth', 'Basic ' + btoa(this.buyer.mobile + ':' + b.headers.get('x-blw-auth')));
                this.currentTransaction = JSON.parse(localStorage.getItem(currentTransaction));
                // TODO revisar esta condición
                if (this.buyer.currency !== b.body.currency.id) {
                    if (this.currentTransaction !== null) {
                        this.buyer.currency = this.currentTransaction.srcCurrency;
                    }
                    this.loginService.putBuyer(this.buyer).subscribe(
                        r => {
                            this.buyer = r;
                            if (this.currentTransaction !== null) {
                                this.currentTransaction.srcCurrency = r.currency;
                            }
                            this.persistTransaction(b.body);
                        }
                    );
                } else {
                    this.persistTransaction(b.body);
                };
                setTimeout(() => {
                    this.createCaptchaDinamic();
                }, 500);

            },
                error => this.invalidCode = true
            );
    }

    getPinWalletAggregated(captchaToken: string) {
        const pin = { pin: this.pinNumber }
        const baluwoCashPinCodeRedeem = <any>{};
        baluwoCashPinCodeRedeem.pin = this.pinNumber;
        this.paymentService.setBaluwoCashPinCodeValidation(baluwoCashPinCodeRedeem).subscribe(res => {
            console.log(res);
            this.pinAggregated = <any>{};
            this.pinAggregated = res;
            this.loginService.postSisalBuyerWallet(pin, captchaToken).subscribe(res => {
                this.amountAggregated = <any>{};
                this.amountAggregated = res;
                this.step3 = false;
                this.step4 = true;
                this.updateBuyer();
                this.updateHeader++;
            });
        }, error => {
            this.invalidpin = true;
            this.updateBuyer();
        })
    }

    persistTransaction(buyer: Buyer) {
        localStorage.setItem(currentBuyer, JSON.stringify(buyer));
        if (this.currentTransaction !== null) {
            this.currentTransaction.buyer = buyer;
            this.transactionService.createTransaction(this.currentTransaction)
                .subscribe(t => {
                    console.log('created' + t);
                    this.currentTransaction = t;
                    localStorage.setItem(currentTransaction, JSON.stringify(this.currentTransaction));
                }, error => { console.log(error) });
        }
    }

    onBuyerCreated(buyer: Buyer) {
        if (buyer) {
            console.log('LOGIN tenemos buyer ' + buyer);
            this.buyer = buyer;
            setTimeout(() => {
                this.contexto.currency = this.buyer.currency.id.toUpperCase();
                if (this.buyer.currency.id != this.contexto.currency) {
                    this.contexto.currency = this.buyer.currency.id.toUpperCase();
                    console.log('WEB CURRENCY: ', this.contexto.currency);
                }
                this.createTransactionId(buyer);
                this.step2 = false;
                this.step3 = true;
            }, 200);
        }
    }

    createTransactionId(buyer) {
        this.transactionIdUniq = JSON.parse(localStorage.getItem(TransactionId));
        if (this.transactionIdUniq != null) {
            this.transactionService.setBuyerIdinTransaction(this.transactionIdUniq).subscribe((res) => {
                console.log('BUYER ASSIGNED: ');
            })
        }

    }

    updateBuyer() {
        this.loginService.getBuyer(this.buyer.uuid).subscribe(res => {
            localStorage.setItem(currentBuyer, JSON.stringify(res));
            this.buyer = JSON.parse(localStorage.getItem(currentBuyer));
            console.log('BUYER: ', this.buyer);
            this.updateHeader++;
        })
    }

    goToHomePage() {
        this.router.navigate(['/home'])
    }
}