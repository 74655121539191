import { Component, EventEmitter, Output } from "@angular/core";
import { JsonSchemaFormService } from "angular6-json-schema-form";
import { AbstractControl } from "@angular/forms";
import { BackToStore } from "../../../shared/_services/backtostore.service";
import { ValidityOrderHelper } from "../../../shared/_helpers/_validityOrder.helper";
import { LocationSectorStoreHelper } from "../../../shared/_helpers/_locationSectorStore.helper";
import { ContextBase } from '../../../shared/_helpers/_contextBase.helper';

@Component({
    selector: 'my-input-widget',
    template: `
    <input class="inputFill inputFill--blackbordersimple inputResetsBox  mainSection__select mainSection__select--1" 
    disabled="" 
    style="padding: 10px 15px; width: 60px;" type="text" 
    placeholder="{{this.locationSectorStoreHelper.currentCountry.currency.id}}">
    <input type="number" 
    class="inputFill inputFill--blackbordersimple  mainSection__select inputServiceChooser"
    name="inputAmount"
    value = "inputValue"
    (input)="addItem($event)"
    placeholder="{{'Baluwo.home.addAmount' | translate}}" /><br>
    <span style="font-size: 14px; color: red" *ngIf="noImport && isValidAmount">NO IMPORT</span>
    <span style="font-size: 14px; color: red" *ngIf="!isValidAmount">
    {{'Baluwo.cart.LANDING_BENEFICIARY_AMOUNT_ERROR_1' | translate}}
                                                {{_validityOrderHelper.minBillAmountOrder
                                                | currency : checkCurrency(currency)}}
                                              {{'Baluwo.cart.LANDING_AND' | translate}}
                                                {{_validityOrderHelper.maxBillAmountOrder
                                                | currency : checkCurrency(currency)}}</span>    
    <span style="display: block; width: 100%; font-size:19px; margin-bottom: 10px; font-weight: 700">{{checkCurrency(currency)}} {{exchangedBillAmount.toFixed(2)}}</span>`
})

export class CustomAmountInput {
    formControl: AbstractControl;
    testr: any;
    billAmount: any;
    billPaymentAmount: EventEmitter<any> = new EventEmitter();
    exchangedBillAmount: number = 0.00;
    totalAmount: number;
    noImport: boolean = false;
    isValidAmount: boolean = true;
    public currency: string;
    constructor(
        private jsf: JsonSchemaFormService,
        public amountServiceCustom: BackToStore,
        public contexto: ContextBase,
        public getImportErrorMsgService: BackToStore,
        public _validityOrderHelper: ValidityOrderHelper,
        public locationSectorStoreHelper: LocationSectorStoreHelper
    ) {
        this.getImportErrorMsgService.getImportErrorMsg.subscribe(res => {
            this.noImport = res
        })
        this.currency = this.contexto.currency;
    }

    ngOnInit() {
        this.jsf.initializeControl(this);
    }

    calculateAmount(amount) {
        this.exchangedBillAmount = amount / this._validityOrderHelper.storeRate;
        this.isValidAmount = this._validityOrderHelper.checkValidityOrder(this.exchangedBillAmount, 'bill');
        this.noImport = false;
    }

    checkCurrency(curr) {
        switch (curr) {
            case 'eur':
                return '€';
            case 'usd':
                return '$';
            case 'gbp':
                return '£';
            case 'sek':
                return 'SEK'
        }
    }


    addItem(event) {
        this.calculateAmount(event.target.value);
        this.jsf.updateValue(this, event.target.value);
        if (!this.noImport && this.isValidAmount) {
            this.amountServiceCustom.getInputAmountCustom(event.target.value);
        }
    }
}