import { Injectable } from "@angular/core";
import { ContextBase } from "../_helpers/_contextBase.helper";
declare const fbq: any;
@Injectable()
export class FacebookPixelService {
    constructor(public contexto: ContextBase) { }

    addToCartEvent(order){
        fbq('track', 'AddToCart', {
              content_type: order.type,
              currency: this.contexto.currency
          })
    }

    initiateCheckout(transaction, amount){
        fbq('track', 'InitiateCheckout', {
              content_ids: transaction.id,
              currency: transaction.currency.id,
              num_items: transaction.orders.length,
              value: amount
          })
    }

    AddPaymentInfo(){
        fbq('track', 'AddPaymentInfo', {
            status: true
          })
    }

    FirtsTimeBuyer(transaction){
        fbq('trackCustom', 'FirstTimeBuyer',{
            status: true,
            content_ids: transaction.id,
        })
    }

    RemitlyBuyer(){
        console.log('sending param RemitlyBuyer')
        fbq('trackCustom', 'RemitlyBuyer',{
            status: true
        })
    }
}