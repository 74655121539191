import { CountryService } from './shared/_services/country.services';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpModule } from '@angular/http';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AmountService } from './core/services/amount.service';
import { AppRoutingModule } from './app-routing.module';
import { LoginmodalComponent } from './pages/loginmodal/loginmodal.component';
import { LoginService } from './core/login-service/login.service';
import { LoginmodalStep1Component } from './pages/loginmodal/steps/modal-step_1.component';
import { LoginmodalStep2Component } from './pages/loginmodal/steps/modal-step_2.component';
import { LoginmodalStep3Component } from './pages/loginmodal/steps/modal-step_3.component';
import { LoginmodalStepSuccessComponent } from './pages/loginmodal/steps/modal-step_success.component';
import { LoginmodalStepErrorComponent } from './pages/loginmodal/steps/modal-step_error.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { PaymentService } from './core/payment/payment.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { SuccesspopupComponent } from './pages/successpopup/successpopup.component';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { OnlyNumberDirective } from './shared/_helpers/only-number.directive';
import { CommonService } from './core/services/common.service';
import { AgmCoreModule } from '@agm/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HomeFooterComponent } from './pages/footer/footer.component';
import { FaqsService } from './shared/_services/faqs.service';
import { HelperModule } from './shared/_helpers/helper.module';
import { ErrorPopUpComponent } from './pages/errorpopup/errorpopup.component';
import { BackToStore } from './shared/_services/backtostore.service';
import { TransactionServiceV1 } from './core/services/transactionv1.service';
import { SuccessPopupGenericComponent } from './pages/successpopupgeneric/successpopupgeneric.component';
import { AgentRegistrationWebComponent } from './pages/agentregistrationweb/agentregistrationweb.component';
import { MaterilaBaluwoModule } from './materialbaluwo.module';
import { PaymentPopUpComponent } from './pages/paymentpopup/paymentpopup.component';
import { PinPopUpComponent } from './pages/pinpopup/pinpopup.component';
import { AppCardTermsAndConditionsComponent } from './pages/cardtermsandconditions/appcardtermsandconditions.component';
import { FooterService } from './shared/_services/footer.service';
import { GDPRService } from './shared/_services/gdpr.service';
import { PaymentHelperService } from './shared/_services/paymenthelper.service';
import { BaluwoCardLegalTermsService } from './shared/_services/baluwocardlegalterms';
import { CurrencyAmountService } from './shared/_services/currencyamount.service';
import { APPLegalConditionsComponent } from './pages/legalconditions/applegalconditions.component';
import { GenericPopUpComponent } from './pages/genericpopup/genericpopup.component';
import { HomeComponentModule } from './pages/home/home.module';
import { CartlandingComponentModule } from './pages/cartlanding/cartlanding.module';
import { AboutUsComponentModule } from './pages/aboutus/aboutus.module';
import { AgentBaluwoRegistrationComponentModule } from './pages/agentbaluworegistration/agentbaluworegistration.module';
import { BaluwoCardComponentModule } from './pages/baluwocard/baluwocard.module';
import { AgentRegistrationWebComponentModule } from './pages/agentregistrationweb/agentregistrationweb.module';
import { PartnersModule } from './pages/partners/partners.module';
import { MyaccountComponentModule } from './pages/myaccount/myaccount.module';
import { ThankyoupageComponentModule } from './pages/thankyoupage/thankyoupage.module';
import { SupplierService } from './core/services/suppliers.service';
import { CardTermsAndConditionsModule } from './pages/cardtermsandconditions/cardtermsandconditions.module';
import { FaqsComponentModule } from './pages/faqs/faqs.module';
import { PromotionSectionModule } from './pages/promotionsection/promotionsection.module';
import { LegalConditionsModule } from './pages/legalconditions/legalconditions.module';
import { GDPRComponentModule } from './pages/gdpr/gdpr.module';
import { CustomareCareComponent } from './pages/customarecare/customarecare.component'
import { NationalRechargeModule } from './pages/nationalrecharge/nationalrecharge.module';
import { RecaptchaModule, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
import { MGMModule } from './pages/mgm/mgm.module';
import { LoginmodalStepReferenceComponent } from './pages/loginmodal/steps/modal-step_refer.component';
import { AdvertPopUpComponent } from './pages/advertpopup/advertpopup.component';
import { SisalModule } from './pages/sisal/sisal.module';
import { DiemmesseProductsComponentModule } from './pages/diemmesse/diemmesse.module';
import { LoginmodalStepRegisterComponent } from './pages/loginmodal/steps/modal-step_register.component';
import { LoginmodalStepPasswordComponent } from './pages/loginmodal/steps/modal-step_password.component';
import { LoginmodalStepRecoveryComponent } from './pages/loginmodal/steps/modal-step_recovery.component';
import { LoginmodalStepResetComponent } from './pages/loginmodal/steps/modal-step_reset.component';
import { LoginHelperUserComponent } from './pages/loginmodal/loginhelpers/loginHelperUser.component';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { CookiePolicyComponentModule } from './pages/cookie-policy/cookie-policy.module';
import { CookiePolicyService } from './shared/_services/cookie-policy.service';
import { cookieConfig } from './cookie-config';
import { FraudPopUpComponent } from './pages/fraudpopup/fraudpopup.component';
import { PrivacyPolicyModule } from './pages/privacy-policy/privacy-policy.module';
import { PrivacyPolicyService } from './shared/_services/privacy-policy.service';
import { LegalConditionsService } from './shared/_services/legal-condition.service';
import { AppPrivacyPolicyComponent } from './pages/privacy-policy/app-privacy-policy.component';
import { BuyerInfoService } from './shared/_services/buyerinfo.service';
import { PromotionService } from './core/services/promotion.service';
import { PasswordPopUpComponent } from './pages/passwordpopup/passwordpopup.component';
import { FacebookPixelService } from './shared/_services/facebook-pixel.service';
import { GiftCardService } from './core/services/giftcard.service';
import { HttpInternalServcie } from './shared/_services/_http-internal.service';
import { RemitlyComponent } from './pages/remitly/remitly.component';
import { CategoriesService } from './core/services/categories.service';
import { PopUpTransferOkComponent } from './pages/wallettransfer/popuptransferok/popuptransferok.component';
import { MeterPopUpComponent } from './pages/meterpopup/meterpopup.component';
import { HeaderCountryService } from './shared/_services/header-country.service';
import { PageNotFoundModule } from './pages/pagenotfound/pagenotfound.module';
import { FakeContactModule } from './pages/fakecontact/fakecontact.module';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    AppComponent,
    HomeFooterComponent,
    LoginmodalComponent,
    LoginmodalStep1Component,
    LoginmodalStep2Component,
    LoginmodalStep3Component,
    LoginmodalStepErrorComponent,
    LoginmodalStepSuccessComponent,
    LoginmodalStepReferenceComponent,
    LoginmodalStepRegisterComponent,
    LoginmodalStepPasswordComponent,
    LoginmodalStepRecoveryComponent,
    LoginmodalStepResetComponent,
    AdvertPopUpComponent,
    ContactusComponent,
    SuccesspopupComponent,
    OnlyNumberDirective,
    AppCardTermsAndConditionsComponent,
    ErrorPopUpComponent,
    FraudPopUpComponent,
    PinPopUpComponent,
    GenericPopUpComponent,
    MeterPopUpComponent,
    SuccessPopupGenericComponent,
    PasswordPopUpComponent,
    APPLegalConditionsComponent,
    AppPrivacyPolicyComponent,
    CustomareCareComponent,
    PaymentPopUpComponent,
    RemitlyComponent,
    PopUpTransferOkComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpModule,
    FormsModule,
    HomeComponentModule,
    CartlandingComponentModule,
    NationalRechargeModule,
    SisalModule,
    LegalConditionsModule,
    MaterilaBaluwoModule,
    AppRoutingModule,
    ThankyoupageComponentModule,
    CookiePolicyComponentModule,
    GDPRComponentModule,
    MyaccountComponentModule,
    FakeContactModule,
    MGMModule,
    RecaptchaModule,
    PageNotFoundModule,
    BaluwoCardComponentModule,
    PrivacyPolicyModule,
    PromotionSectionModule,
    DiemmesseProductsComponentModule,
    PartnersModule,
    AgentBaluwoRegistrationComponentModule,
    AgentRegistrationWebComponentModule,
    AboutUsComponentModule,
    CardTermsAndConditionsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    FaqsComponentModule,
    HelperModule,
    Ng4LoadingSpinnerModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBnaYX19bveeuYqzDzcnRsIgLvJ8hxB1XY'
    }),
    Angulartics2Module.forRoot([Angulartics2GoogleAnalytics]),
    // NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  entryComponents: [GenericPopUpComponent, MeterPopUpComponent, LoginmodalStepReferenceComponent, FraudPopUpComponent, PasswordPopUpComponent, PopUpTransferOkComponent, RemitlyComponent, PaymentPopUpComponent, CustomareCareComponent, PinPopUpComponent, SuccessPopupGenericComponent, AgentRegistrationWebComponent, AdvertPopUpComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [CountryService, AmountService, FooterService, HeaderCountryService, CategoriesService, PromotionService, HttpInternalServcie, GiftCardService, FacebookPixelService, SupplierService, LegalConditionsService, GDPRService, PrivacyPolicyService, CookiePolicyService, CurrencyAmountService, BuyerInfoService, PaymentHelperService, BaluwoCardLegalTermsService, HttpClient,
    LoginHelperUserComponent, LoginService, PaymentService, CommonService,
    { provide: LocationStrategy, useClass: HashLocationStrategy }, { provide: RECAPTCHA_LANGUAGE, useValue: localStorage.getItem('baluwoUserLanguage') }, FaqsService, BackToStore, TransactionServiceV1],

  bootstrap: [AppComponent]
})
export class AppModule { }
