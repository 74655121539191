import { Component, OnInit, Optional } from '@angular/core';
import { DinamicMetaTags } from '../../../shared/_helpers/_dinamicMetaTags';
import { Router } from '@angular/router';
import { ContactPopUp } from '../../../shared/_helpers/_contactpopup.helper';
import { UtmCampaignHelper } from '../../../shared/_helpers/_utmcampaigns.helper';

@Component({
  selector: 'app-partners-retailers',
  templateUrl: './partnersretailers.component.html',
  styleUrls: ['./partnersretailers.component.scss'],
})

export class PartnersRetailersComponent implements OnInit {

  constructor(
    private dinamicMetaTags: DinamicMetaTags, 
    public _utmCampaignHelper: UtmCampaignHelper, 
    public _contactPopUp: ContactPopUp,
    private router: Router) {
  }

  ngOnInit() {
    this.dinamicMetaTags.initPartnersMetaTag();
    this._utmCampaignHelper.checkCampaign();
  }

  showContactPopUp(){
    this._contactPopUp.showContactPopup();
  }
}


