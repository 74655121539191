import { Component, Optional, Inject, EventEmitter } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { PaymentService } from "../../core/payment/payment.service";

@Component({
    selector: 'app-pin-popup',
    templateUrl: 'pinpopup.component.html',
    styleUrls: ['../loginmodal/loginmodal.component.scss']
})

export class PinPopUpComponent {
    pincode: string;
    onValidationPass = new EventEmitter();
    isValidated: boolean;
    step1: boolean;
    step2: boolean;
    step3: boolean;
    constructor(
        private dialogue: MatDialog,
        private _paymentService: PaymentService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
        this.step1 = true;
        this.step2 = false;
        this.isValidated = false;
    }

    checkPin() {
        if (this.pincode !== undefined) {
            this._paymentService.sendPinCodeValidation(this.pincode).subscribe(res => {
                this.onValidationPass.emit();
                this.isValidated = true;
                this.step1 = false;
                this.step2 = true;
                this.step3 = false;
            }, error => {
                this.step1 = false;
                this.step2 = false;
                this.step3 = true;
            })
        } else {
            this.step1 = false;
            this.step2 = false;
            this.step3 = true;
        }
    }

    cancelPin() {
        this.dialogue.closeAll();
    }
}