import { Buyer } from '../../shared/_objects/buyer';
import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { Country } from '../../shared/_objects/country';
import { CurrencyExchange } from '../../shared/_objects/currexchange';
import { Order, StoreOrderUniq, StoreOrderUniq2, TopUpOrderUniq } from '../../shared/_objects/order';
import { Sector } from '../../shared/_objects/sector';
import { CatalogSelection, BackStore, BackCharge } from '../../shared/_objects/store';
import { Store } from '../../shared/_objects/store';
import { AmountService } from '../../core/services/amount.service';
import { currentBuyer, TransactionId } from '../../globals';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DinamicMetaTags } from '../../shared/_helpers/_dinamicMetaTags';
import { ContextBase } from '../../shared/_helpers/_contextBase.helper';
import { MatDialog } from '@angular/material/dialog';
import { LoginmodalComponent } from '../loginmodal/loginmodal.component';
import * as $ from 'jquery';
import { Email } from '../../shared/_objects/email';
import { HeaderComponent } from '../section/header/header.component';
import { ScrollerTargetHelper } from '../../shared/_helpers/_scrollerTarget.helper';
import { BackToStore } from '../../shared/_services/backtostore.service';
import { LocationSectorStoreHelper } from '../../shared/_helpers/_locationSectorStore.helper';
import { HomeUtilsHelper } from '../../shared/_helpers/_homeUtils.helper';
import { UtmCampaignHelper } from '../../shared/_helpers/_utmcampaigns.helper';
import { TransactionServiceV1 } from '../../core/services/transactionv1.service';
import { Beneficiary } from '../../shared/_objects/beneficiary';
import { TransactionHelper } from '../../shared/_helpers/_transactionHelper.helper';
import { Conditions } from '../../shared/_objects/conditions';
import { ValidityOrderHelper } from '../../shared/_helpers/_validityOrder.helper';
import { PromotionHelper } from '../../shared/_helpers/_promotions.helper';
import { Subscription } from 'rxjs';
import { ErrorPopUpComponent } from '../errorpopup/errorpopup.component';
import { ServerErrorHelper } from '../../shared/_helpers/servererrors.helper';
import { CustomAmountInput } from "./custom-widgets/custom-input";
import { CommonService } from '../../core/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { AnalyticsHelper } from '../../shared/_helpers/_analytics.helper';
import { PromotionService } from '../../core/services/promotion.service';
import { BannerHelper } from '../../shared/_helpers/_banner.helper';
import { PasswordPopUpComponent } from '../passwordpopup/passwordpopup.component';
import { SuccessPopupGenericComponent } from '../successpopupgeneric/successpopupgeneric.component';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { FacebookPixelService } from '../../shared/_services/facebook-pixel.service';
import { CashPowerMeterHelper } from '../../shared/_helpers/_cashPowerMeter.helper';
import { HeaderCountryService } from '../../shared/_services/header-country.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss', '../../../assets/css/bootstrap-grid.min.css'],
  providers: [CustomAmountInput]
})

export class HomeComponent implements OnInit {
  public _ENVIRONMENT_ = environment;
  amounts: number[] = [];
  exchangeSource: CurrencyExchange;
  exchangeDest: CurrencyExchange;
  order: Order = new Order;
  currentSector: Sector;
  currentCountry: Country;
  beneficiaryTransaction: Beneficiary = new Beneficiary;
  sourceCurrency: string;
  destinationCurrency = 'GMD';
  sourceLanguage: string;
  currentStore: Store;
  validMeter: boolean = true;
  orderConditions: Conditions;

  uniqOrder: StoreOrderUniq;
  noSupplierForMeteNumber: boolean = false;

  @Output() onShowLogin: EventEmitter<any> = new EventEmitter<any>();

  isValidPhoneNumber = true;
  isMobile = true;
  isDesktop = false;
  updateHeader = 1;
  transaction: any;

  orderFormControl = new FormControl('', Validators.required);
  isValidBeneficiaryMobile = true;
  isValidAmount: boolean = true;
  isOkToAddCart: boolean = false;
  buyer: Buyer;
  currentTransactionOld: any;
  manualAmount: boolean = false;
  allowPayment: boolean = false;
  selectedStore: BackStore;
  selectedSector: BackCharge;
  countryPrefix: any;
  data = new Email;
  isSubmitted: boolean = false;
  isDisabled: boolean = true;
  openVoucherTotal: number = 0;
  openVoucherAdded: boolean = false;
  buttonBuy: boolean = false;
  voucherAmount: number;

  folder: string;
  transactionIdUniq: any;


  bigOrder: StoreOrderUniq2;
  topUpOrderUniq: TopUpOrderUniq;
  premobile: any;

  noBeneficiaryInput: boolean = true;
  subscription: Subscription;

  notValidBeneficiaryPhone: boolean = false;
  sectorsIds: any = [];

  @ViewChild(HeaderComponent)
  headerComponent: HeaderComponent;

  errorMesage: any;

  step1: boolean = true;
  step2: boolean = false;

  sectorChoosed: boolean = false;
  cashpowerSuppliers: any[] = [];
  airTimeMinimumAmount: number;

  mySchema: any;
  billInfo: any;

  showPrePayment: boolean = false;
  showPostPayment: boolean = false;
  myLayout: any = [];
  yourNewWidgets: any;
  jsonFormOptions: any = {
    defautWidgetOptions: {
      addable: true,
      validationMessages: {
        required: 'This field is required.',
        minLength: 'Must be at least 8 characters long.',
        pattern: 'there is an error with your pattern'
      }
    }
  }
  superAmount: number;
  exampleJsonObject: any;
  urlFormatted: string;
  noImport: boolean = false;
  numberTitle: string;
  dateTitle: string;
  showNetworkFail: boolean = false;
  showMLIssue: boolean = false;
  manualMeter: boolean = false;
  preMeterId: number;
  checkingMeter: boolean = false;

  @Output() onSuccessLoginModal: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private amountService: AmountService,
    private route: ActivatedRoute,
    private router: Router,
    public contexto: ContextBase,
    public locationSectorStoreHelper: LocationSectorStoreHelper,
    private dialog: MatDialog,
    private dinamicMetaTags: DinamicMetaTags,
    private transactionServiceV1: TransactionServiceV1,
    public _scrollerTargetHelper: ScrollerTargetHelper,
    public _homeUtilsHelper: HomeUtilsHelper,
    public _utmCampaignHelper: UtmCampaignHelper,
    public _transactionHelper: TransactionHelper,
    public _validityOrderHelper: ValidityOrderHelper,
    public _promotionHelper: PromotionHelper,
    public serverErrorHelper: ServerErrorHelper,
    public commonService: CommonService,
    public _analyticsHelper: AnalyticsHelper,
    public _bannerHelper: BannerHelper,
    public translate: TranslateService,
    public promoService: PromotionService,
    public customInput: CustomAmountInput,
    public facebookPixelService: FacebookPixelService,
    private spinnerService: Ng4LoadingSpinnerService,
    public cashPowerMeterHelper: CashPowerMeterHelper,
    private _HeaderCountryService: HeaderCountryService,
    public backToStore: BackToStore) {
    this.currentCountry = new Country;
    this.currentSector = new Sector;
    this.isMobile = (/android|webos|iphone|ipad|ipod|blackberry|windows phone/).test(navigator.userAgent.toLowerCase());
    this.isDesktop = !this.isMobile;
    (this.contexto.isMobile) ? this.folder = "Mobile" : this.folder = "Desktop";
  }

  ngOnInit() {
    this.fetchData();
    // this.resetVars();
    // this.getPromos();
    // this.dinamicMetaTags.initHomeMetaTag();
    // this._transactionHelper.changeCurrencyRef(this.sourceCurrency, this.locationSectorStoreHelper.destinationCurrencyId);

    // this.transactionIdUniq = JSON.parse(localStorage.getItem(TransactionId));

    // if (localStorage.getItem(currentBuyer) != null) {
    //   this.transactionServiceV1.getTransactionLogged(this.contexto.transMessage).subscribe((data) => {
    //     this.transaction = data;
    //     this._transactionHelper.calculateCartTotal(data);
    //     this.checkTransactionOrders();
    //     this.sourceCurrency = JSON.parse(localStorage.getItem(currentBuyer)).currency.id;
    //     this.contexto.currency = this.sourceCurrency;
    //     localStorage.setItem(TransactionId, this.transaction.id);
    //     console.log('transaction active logged: ', this.transaction);
    //     // this.getTransactionNewCurrency(this.transaction.id);
    //   });
    // } else {
    //   if (localStorage.getItem(TransactionId) != null) {
    //     this.getTransactionNotLogged();
    //   } else {
    //     this.transactionServiceV1.getDefaultTransaction(this.contexto.transMessage).subscribe((res) => {
    //       this.transactionIdUniq = res.id;
    //       localStorage.setItem(TransactionId, this.transactionIdUniq);
    //       this.getTransactionNewCurrency(this.transactionIdUniq);
    //     })
    //   }
    // }

    // this.backToStore.currentTransactionEmpty.subscribe(() => {
    //   this.isDisabled = true;
    // })

    // this.route.params.subscribe(params => {
    //   this.checkUrlReturnParams(params);
    // });
    // $('#topMenuBuyLinkMobile, #topMenuBuyLinkDesktop').on('click', () => {
    //   this._scrollerTargetHelper.scrollToTarget("beneficiary");
    // });
  }

  contentsReturnAction() {
    const contentsCountryISO = this.route.snapshot.queryParams['ContentsCountry'];
    const contentsOrder = this.route.snapshot.queryParams['ContentsOrder'];
    console.log(contentsCountryISO);
    console.log(contentsOrder);
    let countrySelected;
    this._HeaderCountryService.headerCountryDefault.subscribe(res => {
      if (res.length) {
        countrySelected = res.filter(country => country.id == contentsCountryISO);
        if (contentsOrder == undefined) {
          this.getSectorsActives(countrySelected[0]);
        } else {
          const sectorSelected = this._homeUtilsHelper.getSectorContentsReturn(contentsOrder);
          if (sectorSelected[0].id === 101) this.commonService.getStaticSupplierOnce(countrySelected[0].id);
          this.locationSectorStoreHelper.currentCountry = countrySelected[0];
          this.locationSectorStoreHelper.currentSector = sectorSelected[0];
          if (sectorSelected[0].type == 'store') this.locationSectorStoreHelper.changeSector()
          this.nextStep();
        }
      }
    })
  }

  fetchData() {
    this._utmCampaignHelper.checkCampaign();
    this.sourceCurrency = this.contexto.currency.toUpperCase();
    this.sourceLanguage = this.contexto.language.toUpperCase();
    // this.transactionServiceV1.getConditions().subscribe(res => {
    //   this.orderConditions = res;
    // })
    this.locationSectorStoreHelper.init();
    if (this.route.snapshot.queryParams['ContentsCountry'] !== undefined) {
      setTimeout(()=>{
      this.contentsReturnAction();
    }, 4000)
  }
  }

  premobileEmitDone(evt) {
    this.premobile = evt;
  }

  checkUrlReturnParams(params: any) {
    console.log(params);
    switch (Object.keys(params)[0]) {
      case 'backstore':
        this.displayBackStoreView();
        break;
      case 'backstorecharge':
        this.displayBackStoreCharge();
        break;
      case 'login':
        this.login();
        break;
      case 'transactionKO':
        this.displayTransactionKo();
        break;
    }
  }

  getPromos() {
    this.promoService.getPromotionActive().subscribe(res => {
      this._promotionHelper.setPromoLength(res.length);
    })
  }

  billPaymentDataInfoEmit(evt) {
    this._homeUtilsHelper.validatePhoneNumber(this.premobile, this.locationSectorStoreHelper.currentCountry.mobilePrefix);
    if (!this._homeUtilsHelper.isValidBeneficiaryMobile) {
      this._scrollerTargetHelper.scrollToTarget("beneficiary");
      this.allowPayment = false;
      return false;
    } else {
      this.addProductCart(evt);
    }
  }

  displayBackStoreView() {
    this.backToStore.currentStore.subscribe(store => {
      this._homeUtilsHelper.isValidBeneficiaryMobile = true;
      if (store.id != null) {
        this.falseStepOne();
        this.selectedStore = store;
        this.premobile = this.selectedStore.phone;
        this.locationSectorStoreHelper.backToStoreStore(this.selectedStore);
        this.createBackStore();
      } else {
        this.falseStepTwo();
      }
    })
  }

  createBackStore() {
    if (this.locationSectorStoreHelper.currentStore.catalogSelection !== undefined) {
      this.locationSectorStoreHelper.currentStore.catalogSelection.forEach((d, i) => {
        this.selectedStore.lines.forEach((m) => {
          if (d.catalog.id == m.catalog.id) {
            d.quantity = m.quantity;
            this.changeClasses(d.catalog.id)
            if (d.catalog.price <= 1) {
              this.voucherAmount = m.quantity;
              $("#voucherinput__" + d.catalog.id).val(m.quantity);
              $("#remove__" + d.catalog.id).removeClass('hidden');
            }
          }
        })
      });
    } else {
      setTimeout(() => {
        this.createBackStore()
      }, 500)
    }
  }

  displayBackStoreCharge() {
    this._homeUtilsHelper.isValidBeneficiaryMobile = true;
    setTimeout(() => {
      this.subscription = this.backToStore.currentEditCharge.subscribe(sector => {
        setTimeout(() => {
          if (sector.length !== 0) {
            this.falseStepOne();
            this.premobile = sector[1].beneficiary;
            if (sector[0].id === 101) this.commonService.getStaticSupplierOnce(this.locationSectorStoreHelper.currentCountry.id);
            this.locationSectorStoreHelper.currentCountry = this.locationSectorStoreHelper.sellingCountries.filter(country => country.id === sector[1].country)[0];
            this.locationSectorStoreHelper.buildBackToStoreEditCharge(sector[0], this.locationSectorStoreHelper.currentCountry, this.locationSectorStoreHelper.currentCountry.mobilePrefix + sector[1].beneficiary);
          } else {
            this.falseStepTwo();
          }
        }, 200)

      })
    }, 200)
  }

  displayTransactionKo() {
    this.transactionServiceV1.getRedsysReturnReceipt().subscribe(res => {
      this._homeUtilsHelper.print(res);
    })
  }

  getTransactionNotLogged() {
    this.transactionServiceV1.getTransaction(this.transactionIdUniq, this.contexto.transMessage).subscribe((data) => {
      this.transaction = data;
      this._transactionHelper.calculateCartTotal(data);
      this.checkTransactionOrders();
      if (this.transaction.currency.id != this.contexto.currency.toUpperCase()) {
        this.getTransactionNewCurrency(this.transactionIdUniq);
      }
      console.log('transaction active: ', this.transaction);
    }, error => {
      console.log('ERROR');
    });
  }

  getTransactionNewCurrency(transactionIdUniq) {
    this.transactionServiceV1.setCurrencyTransaction(transactionIdUniq, this.contexto.currency.toLowerCase()).subscribe((res) => {
      console.log('transaction new currency: ', res);
      this.updateHeader++;
      this._transactionHelper.calculateCartTotal(res);
    })
  }

  checkTransactionOrders() {
    (this.transaction.orders) ? this.buttonBuy = true : this.buttonBuy = false;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getSectorsActives(country: any) {
    // if (country.id === 'GM') {
    //   this.showNetworkFail = true;
    // } else {
    //   this.showNetworkFail = false;
    // }
    if (country.id === 'ML') {
      this.showMLIssue = true;
    } else {
      this.showMLIssue = false;
    }
    this.countrySelected(country.id);
    this.locationSectorStoreHelper.buildSectors(country);
    setTimeout(() => {
      this.sectorsIds = [];
      this.getSectorsReady();
    }, 100);
  }

  getSectorsReady() {
    if (this.locationSectorStoreHelper.sectors) {
      this.locationSectorStoreHelper.sectors.forEach(d => {
        this.sectorsIds.push(d.id);
        this._scrollerTargetHelper.scrollToTarget("buttonStep1");
      })
    } else {
      setTimeout(() => {
        this.getSectorsReady();
      }, 500)
    }
  }

  nextStep() {
    this.falseStepOne();
    this._homeUtilsHelper.isValidBeneficiaryMobile = true;
    this.serverErrorHelper.errorMsgAmountService = null;
  }

  falseStepOne() {
    this.step1 = false;
    this.step2 = true;
    this._scrollerTargetHelper.scrollToTarget("mainTopContainer");
  }

  falseStepTwo() {
    this.resetVars();
    this.step1 = true;
    this.step2 = false;
  }

  changeClasses(id) {
    setTimeout(() => {
      $("#quant__" + id).removeClass('hidden');
      $("#add__" + id).addClass('hidden');
      this.isDisabled = false;
    }, 500)
  }

  compareFn(c1, c2) {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  requestProductOnDemand(quant: any) {
    this.buyer = JSON.parse(localStorage.getItem(currentBuyer));
    if (this.buyer == null) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.login();
    } else if (this.buyer.email === null) {
      this.openEmailPopUp();
    } else {
      if ($("#quant__" + quant).hasClass('hidden')) {
        $("#quant__" + quant).removeClass('hidden');
        $("#add__" + quant).addClass('hidden');
      }
      this.onDemandTransaction();
    }
  }

  onDemandTransaction() {
    this.spinnerService.show();
    this.transactionServiceV1.postContactOnDemand().subscribe(res => {
      this.openSuccessFullOnDemandPopUp();
    }, (error: Error) => {
      this.getError(error);
      this.spinnerService.hide();
    })
  }

  removeProductCartAndQuantityVoucherEmit(evt) {
    this.removeOrderLine(evt.catalogSelection, evt.order);
  }

  removeOrderLine(storeOrderLine: any, order: Order) {
    if (order.lines.length > 1) {
      storeOrderLine.quantity = 0;
      this.checkBeneficiaryPhoneValidity(order);
    } else {
      this.removeOrderfromTransaction(order.id);
    }
  }

  removeOrderfromTransaction(ordId: number) {
    if (localStorage.getItem(currentBuyer) == null) {
      this.transactionServiceV1.deleteTransaction(this.transactionIdUniq, ordId).subscribe((res) => {
        this.transaction = res;
        this._transactionHelper.calculateCartTotal(this.transaction);
      })
    } else {
      this.transactionServiceV1.deleteTransactionLogged(ordId).subscribe((res) => {
        this.transaction = res;
        this._transactionHelper.calculateCartTotal(this.transaction);
      })
    }
  }

  checkBeneficiaryPhoneValidity(storeOrder?: any) {
    console.log(this.premobile);
    this._homeUtilsHelper.validatePhoneNumber(this.premobile, this.locationSectorStoreHelper.currentCountry.mobilePrefix);
    if (!this._homeUtilsHelper.isValidBeneficiaryMobile) {
      this._scrollerTargetHelper.scrollToTarget("beneficiary");
      this.allowPayment = false;
      this.order.srcTotal = null;
      return false;
    } else {
      this.allowPayment = true;
      this.addProductCart(storeOrder)
    }
  }

  addProductCart(storeOrder?: any) {
    this.isDisabled = false;
    this.beneficiaryTransaction.mobile = this.locationSectorStoreHelper.currentCountry.mobilePrefix + this.premobile.replace(/[^+\d]+/g, "");;

    this.uniqOrder = new StoreOrderUniq;
    this.uniqOrder.beneficiary = { mobile: this.beneficiaryTransaction.mobile, name: this.beneficiaryTransaction.name }
    this.uniqOrder.type = this.locationSectorStoreHelper.currentSector.type;
    if (this.uniqOrder.type == "store") {
      this.uniqOrder.store = { id: storeOrder.id };
      this.uniqOrder.lines = [];
      storeOrder.catalogSelection.forEach((d) => {
        if (d.quantity > 0) {
          this.uniqOrder.lines.push({ catalog: { id: d.catalog.id, price: d.catalog.price }, quantity: d.quantity })
        }
      })
    } else if (this.uniqOrder.type == "topup") {
      this.uniqOrder.type = this.topUpOrderUniq.type;
      (storeOrder.srcTotal) ? this.uniqOrder.total = storeOrder.srcTotal : this.uniqOrder.targetTotal = storeOrder.targetTotal;
      this.uniqOrder.meterId = this.topUpOrderUniq.meterid;
    } else if (this.uniqOrder.type === 'bill') {
      this.uniqOrder.billOrderData = storeOrder.billOrderData
      this.uniqOrder.targetTotal = storeOrder.targetTotal;
    } else if (this.uniqOrder.type === 'generic') {
      this.uniqOrder.type = 'giftcard';
      this.uniqOrder.giftCardConfig = { id: storeOrder.id };
      this.uniqOrder.total = storeOrder.amount;
    };

    this.facebookPixelService.addToCartEvent(this.uniqOrder);
    if (this.uniqOrder.lines && this.uniqOrder.lines.length == 0 && this.uniqOrder.type == "store") {
      this.transaction.orders.forEach(d => {
        if (d.store && (d.store.id == this.uniqOrder.store.id)) {
          this.removeOrderfromTransaction(d.id);
        }
      })
    } else {
      this.createTransaction();
      this.buttonBuy = true;
    }
  }

  createTransaction() {
    if (this.transactionIdUniq == null && localStorage.getItem(currentBuyer) == null) {
      this.initTransaction();
    } else if (localStorage.getItem(currentBuyer) !== null) {
      this.transactionServiceV1.setTransactionLogged(this.uniqOrder).subscribe((res) => {
        localStorage.setItem(TransactionId, res.id);
        this.updateHeader++;
        this._transactionHelper.calculateCartTotal(res);
        this.transaction = res;
      }, error => {
        let errorcode = error;
        this.fialTransactionBeneficiary();
        if (errorcode.status !== 401) this.getError(error);
        // this.getError(error);
      })
    } else {
      this.transactionServiceV1.setTransaction(this.transactionIdUniq, this.uniqOrder).subscribe((res) => {
        this.updateHeader++;
        this._transactionHelper.calculateCartTotal(res);
        this.transaction = res;
      }, error => {
        let errorcode = error;
        this.fialTransactionBeneficiary();
        if (errorcode.code == 12) this.getError(error);
        if (errorcode.code == 11) this.getError(error, error.message);
        if (errorcode.code == 18) this.getError(error);
      })
    }
  }

  getError(error, msg?) {
    let errorcode = error;
    if (msg) {
      this.errorMesage = this.serverErrorHelper.getErrorMsg(errorcode.code, errorcode.message);
    } else {
      this.errorMesage = this.serverErrorHelper.getErrorMsg(errorcode.code);
    }
    const dialogErrorRef = this.dialog.open(ErrorPopUpComponent, {
      panelClass: 'loginLayoutResets',
      data: { error: this.errorMesage }
    });
    dialogErrorRef.afterClosed().subscribe(() => { });
  }

  fialTransactionBeneficiary() {
    this.allowPayment = false;
    this.buttonBuy = false;
  }

  initTransaction() {
    this.transactionServiceV1.setCurrencyTransaction(this.transactionIdUniq, this.sourceCurrency.toLowerCase()).subscribe((res) => {
      console.log('transaction new currency: ', res);
      this.transactionServiceV1.setTransaction(this.transactionIdUniq, this.uniqOrder).subscribe((res) => {
        this.updateHeader++;
        this._transactionHelper.calculateCartTotal(res);
      })
    });
  }

  recalculeTotalAmountPersonalized(order: Order, sector?: string) {
    if (order.srcTotal == undefined || order.srcTotal == null) {
      this.manualAmount = true;
      $("#topUpInput").focus();
    } else {
      this.manualAmount = false;
      this.checkValidityOrder(order, sector);
    }
  }

  checkValidityOrder(order, sector) {
    const minimum = this.getMinimumAmountForSector(sector);
    this.isValidAmount = this._validityOrderHelper.checkValidityOrder(order, sector, minimum);
    if (this.isValidAmount) {
      this.isOkToAddCart = true;
      this.buildAmountsOrder(order);
    } else {
      this.isOkToAddCart = false;
      this.order.srcTotal = undefined;
    }
  }

  getMinimumAmountForSector(sector: string) {
    switch (sector) {
      case 'airtime':
        return this.commonService.beneficiaryMinimumAmount;
      case 'cashpower':
        return this.commonService.beneficiaryCashPowerMinimumAmount;
      case 'airdata':
        return this.commonService.beneficiaryDataMinimumAmount;
    }
  }

  // REVIEW CODE: SEK ISSUES
  buildAmountsOrder(order) {
    this.amounts = this.amountService.getAmountList();
    let beneficiarymobile = this.locationSectorStoreHelper.currentCountry.mobilePrefix + this.premobile.replace(/[^+\d]+/g, "");
    const exist = this.amounts.filter(x => x === order.srcTotal)[0];
    if (typeof exist === 'undefined') {
      let newAmount: number;
      if (this.contexto.currency.toLocaleLowerCase() === 'sek') {
        newAmount = order.srcTotal / 10;
      } else {
        newAmount = order.srcTotal;
      }
      this.amountService.amounts.push(newAmount);
      if (order.meterId == null) {
        this.getAirtimeAmounts(this.sourceCurrency, beneficiarymobile);
      } else {
        this.getCashpowerAmounts(order.meterId, this.sourceCurrency, beneficiarymobile)
      }
      this.amountService.amounts.sort(this._homeUtilsHelper.sortNumber);
    }
  }

  getAirtimeAmounts(currency, beneficiarymobile?: string) {
    const beneficiaryMobile = beneficiarymobile;
    this.amountService.calculateAmountCurrency(currency, beneficiaryMobile);
  }

  getCashpowerAmounts(meterId: number, currency, beneficiarymobile?: string) {
    const beneficiaryMobile = beneficiarymobile;
    this.amountService.calculateAmountCashpowerCurrency(meterId, currency, beneficiaryMobile);
  }


  buildAirCashOrder(order) {
    this.beneficiaryTransaction.mobile = this.locationSectorStoreHelper.currentCountry.mobilePrefix + this.premobile;
    this.topUpOrderUniq = new TopUpOrderUniq;
    this.topUpOrderUniq.beneficiary = { mobile: this.beneficiaryTransaction.mobile, name: this.beneficiaryTransaction.name };
    (order.targetTotal) ? this.topUpOrderUniq.targetTotal = order.targetTotal : this.topUpOrderUniq.total = order.srcTotal;
    this.topUpOrderUniq.type = this.locationSectorStoreHelper.currentSector.name.replace(/\s/g, "").toLowerCase();
    this.topUpOrderUniq.meterid = order.meterId;
    this.checkBeneficiaryPhoneValidity(order);
  }

  checkMeterSelection(meterId) {
    console.log(meterId);
    if (meterId == undefined) {
      this.manualMeter = true;
      $("#topUpInputMeter").focus();
    } else {
      this.manualMeter = false;
      this.checkMeterId(meterId);
    }
  }

  recheckMeter(meterId) {
    if (meterId == undefined) {
      this.manualMeter = false;
    } else {
      this.checkMeterSelection(meterId);
    }
  }

  checkMeterId(meterId: any) {
    this.checkingMeter = true;
    this.manualAmount = false;
    console.log(meterId)
    const beneficiarymobile = this.locationSectorStoreHelper.currentCountry.mobilePrefix + this.premobile;
    const mobiledata = { mobile: beneficiarymobile }
    // this.cashPowerMeterHelper.init(mobiledata, meterId);
    this.commonService.getSupplierCashPowerMinimumAmount(this.locationSectorStoreHelper.currentCountry.mobilePrefix + this.premobile, meterId)
    if (!(<string>meterId.toString()).match(this.commonService.dynamicSupplierCashArray[0].carrier.regExp)) {
      this.validMeter = false;
      this.manualMeter = false;
      this.checkingMeter = false;
    } else if (!this.commonService.supplierCashpowerIsEnabled) {
      this.errorMesage = this.serverErrorHelper.getErrorMsg(25);
      this.serverErrorHelper.errorMsgAmountService = this.errorMesage;
      this.noSupplierForMeteNumber = true;
    } else {
      this.noSupplierForMeteNumber = false;
      this.amountService.calculateAmountCashpowerCurrency(meterId, this.contexto.currency.toUpperCase(), beneficiarymobile);
      this.spinnerService.show();
      this.transactionServiceV1.checkCashPowerMeterId(this.locationSectorStoreHelper.currentCountry.id, meterId).subscribe((res) => {
        if (res.customerName == "Meter not found") {
          this.validMeter = false;
          this.spinnerService.hide();
          this.manualMeter = false;
          this.checkingMeter = false;
        } else {
          this.validMeter = true;
          this.manualMeter = false;
          this.checkingMeter = false;
          const exist = this.cashPowerMeterHelper.beneficiaryAvailableMeters.filter(x => x.meterId === meterId)[0];
          console.log(exist);
          if (typeof exist === 'undefined') {
            this.cashPowerMeterHelper.beneficiaryAvailableMeters.push({ meterId: meterId });
          }
          this.spinnerService.hide();
          this.commonService.getPromoCashPower(this.locationSectorStoreHelper.currentCountry.mobilePrefix + this.premobile, meterId);
        }
      }, error => {
        this.spinnerService.hide();
        this.validMeter = false;
        this.manualMeter = false;
        this.checkingMeter = false;
      })
      // this.cashPowerMeterHelper.checkMeterId(this.locationSectorStoreHelper.currentCountry.id, meterId);
      // console.log(this.cashPowerMeterHelper.validMeter);
    }
  }

  setManualAmount(order: Order, sector: string) {
    if (order.total == undefined || order.total == null) {
      this.manualAmount = true;
      $("#topUpInput").focus();
    }
  }

  buyMore(order: Order) {
    this.falseStepTwo();
    this.order.srcTotal = null;
    this.order.meterId = null;
    $('html, body').animate({
      scrollTop: $('.mainSection').offset().top - 160,
      duration: 750
    })
  }

  resetVars() {
    this.selectedStore = null;
    this.locationSectorStoreHelper.currentStore = null;
    this.locationSectorStoreHelper.currentSector = null;
    this.locationSectorStoreHelper.sectors = null;
    this.sectorsIds = [];
    this.sectorChoosed = false;
    this.premobile = undefined;
    this.voucherAmount = null;
    this.isValidAmount = true;
    this.order.srcTotal = undefined;
    this.isOkToAddCart = false;
    this.amountService.amounts = [3, 5, 10, 15, 20, 50];
    this.order.meterId = null;
    this.myLayout = [];
  }

  goToCartlanding() {
    this.router.navigate(['/cartlanding']);
  }

  login() {
    const dialogRef = this.dialog.open(LoginmodalComponent, { panelClass: 'loginLayoutResets' });
    dialogRef.afterClosed().subscribe(b => {
      if (typeof b !== 'undefined') {
        this.buyer = b;
        this.onSuccessLoginModal.emit();
        this.updateHeader++;
        console.log('confirmamos buyer ' + this.buyer.mobile);
        if (this.locationSectorStoreHelper.currentSector.id === this._ENVIRONMENT_.ApiIdOnDemand && this.buyer.email == null) {
          this.openEmailPopUp();
        } else if (this.locationSectorStoreHelper.currentSector.id === this._ENVIRONMENT_.ApiIdOnDemand && this.buyer.email !== null) {
          this.onDemandTransaction();
        }
      }
    });
  }

  openEmailPopUp() {
    const dialogRef = this.dialog.open(PasswordPopUpComponent, { panelClass: 'loginLayoutResets' });
    dialogRef.componentInstance.emailAvailable.subscribe(res => {
      dialogRef.close();
      this.onDemandTransaction();
    })
    dialogRef.afterClosed().subscribe(b => {
    });
  }

  openSuccessFullOnDemandPopUp() {
    this.spinnerService.hide();
    const dialogRef = this.dialog.open(SuccessPopupGenericComponent, {
      panelClass: 'loginLayoutResets',
      data: { message: this.translate.instant('Baluwo.onDemand.success.popup') }
    })
    dialogRef.afterClosed().subscribe(() => {
      this.falseStepTwo();
    });
  }

  countrySelected(id) {
    console.log(id)
    $('.mscBox__selection-product').removeClass('selected');
    $('.mscBox__selection-countries').removeClass('selected');
    $('.country' + id).addClass('selected');
    this.resetVars();
  }

  close() {
    $('._blank').addClass('hide');
    $('.bigPopUp').addClass('hide');
    sessionStorage.setItem('PromoViewed', 'true');
  }

  open() {
    $('._blank').removeClass('hide');
    $('.bigPopUp').removeClass('hide');
  }

}
