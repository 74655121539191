import { Component, EventEmitter, OnInit } from '@angular/core';
import { currentBuyer } from '../../globals';
import { LoginService } from '../../core/login-service/login.service';

@Component({
  selector: 'app-passwordpopup',
  templateUrl: './passwordpopup.component.html',
  styleUrls: ['./passwordpopup.component.scss']
})
export class PasswordPopUpComponent {
public buyerEmail: string;
public buyerEmailValid: boolean = false;
public EMAILREGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
public emailAvailable = new EventEmitter();
  constructor(private loginServive: LoginService) { }

  addBuyerEmail(){
      console.log(this.buyerEmail)
      console.log(this.EMAILREGEX.test(this.buyerEmail));
      this.buyerEmailValid = this.EMAILREGEX.test(this.buyerEmail);
  }

  sendBuyerEmail(){
    const buyer: any = {email: this.buyerEmail};
    this.loginServive.patchBuyer(buyer).subscribe(res=>{
      console.log(res);
      localStorage.setItem(currentBuyer, JSON.stringify(res));
      this.emailAvailable.emit();
    })
  }

}