import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { RecaptchaModule } from "ng-recaptcha";
import { SharedModule } from '../../shared.module';
import { PaymentPopUpWalletComponent } from '../paymentpopup/paymentpopupwallet.component';
import { WalletRechargeComponent } from '../walletrecharge/walletrecharge.component';
import { WalletTransferComponent } from '../wallettransfer/wallettransfer.component';
import { MyaccountComponent } from './myaccount.component';
import { OrderdetailControl } from './order-details/order-details.control';


@NgModule({
	imports: [
		SharedModule,
		RecaptchaModule
	],
	declarations: [
		MyaccountComponent,
		OrderdetailControl,
		WalletRechargeComponent,
		PaymentPopUpWalletComponent,
		WalletTransferComponent
	],
	providers: [
	],
	entryComponents: [
		MyaccountComponent,
		OrderdetailControl,
		WalletRechargeComponent,
		PaymentPopUpWalletComponent,
		WalletTransferComponent
	],
	exports: [

	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})

export class MyaccountComponentModule { }
