import { Component } from "@angular/core";
import { CatalogSelection, Country, Currency, Sector } from "../../../shared/_objects";
import { DiemmesseService } from '../../../core/services/diemmesse.service';
import { environment } from '../../../../environments/environment';
import * as _ from 'lodash';

@Component({
    selector: 'app-diemmesse-products',
    templateUrl: 'diemmesse_products.component.html',
    styleUrls: ['./diemmesse.component.scss', '../../home/home.component.scss', '../../../../assets/css/bootstrap-grid.min.css']
})

export class DiemmesseProductsComponent {
    public _ENVIRONMENT_ = environment;
    public baluwoSellingCountries: Country[] = [];
    public locationArray: any[] = [];
    public sectors: Sector[] = [];
    public countrySelected: Country;
    public suppliersList: any = [];
    public suppliersAirtime: any = [];
    public suppliersAirdata: any = [];
    public suppliersCashpower: any = [];
    public locationStores: any = [];
    public defaultLocation: any;
    public currentSector: Sector;
    public currentLocationStore: any;
    public currentStore: any;
    public catalogSelectionArray: CatalogSelection[] = [];
    public sourceCurrency: string = '€';
    constructor(private _DiemmesseService: DiemmesseService) {
        localStorage.setItem('currentAuth', 'Basic ' + btoa('+393334445556:D13mmess3-!P4ss'));
    }
    ngOnInit() {
        this._DiemmesseService.getSellingCountries().subscribe(res => {
            this.baluwoSellingCountries = res;
            this.baluwoSellingCountries = this.baluwoSellingCountries.filter(country => country.retailer === true);
            const fakeCountry = { value: this.baluwoSellingCountries[3] }
            this.getCountrySectors(fakeCountry);
        })
    }

    compareFn(c1, c2) {
        return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }

    getCountrySectors(event) {
        this.countrySelected = event.value;
        this._DiemmesseService.getSectorsV1(this.countrySelected.id)
            .subscribe(s => {
                this.sectors = s;
                this.sectors = this.sectors.filter(d => d.type === 'store');
                if (this.sectors.length) {
                    this.sectors.forEach(d => {
                        if (d.id === this._ENVIRONMENT_.ApiIdFood) {
                            this.getLocations({value:d});
                        }
                        if(d.name === 'Food'){
                            d.name = 'Cibo';
                        }
                        if(d.name === 'Construction'){
                            d.name = 'Materiale da costruzione';
                        }
                    })
                } else {
                    this.locationArray = [];
                    this.catalogSelectionArray = [];
                }
                this.getSuppliers();
            });

    }

    getLocations(event) {
        console.log(event);
        this.currentSector = event.value;
        if (this.currentSector.id === this._ENVIRONMENT_.ApiIdFood || this.currentSector.id === this._ENVIRONMENT_.ApiIdConstruction) {
            this._DiemmesseService.getLocationsV1(this.countrySelected.id, this.currentSector.id).subscribe(res => {
                this.locationArray = res;
                this.defaultLocation = this.locationArray[0];
                const fakeLocation = { value: this.defaultLocation }
                this.getStores(fakeLocation)

            })
        } else {
            this.catalogSelectionArray = [];
        }
    }

    getSuppliers() {
        this._DiemmesseService.getSuppliersByCountry(this.countrySelected.id).subscribe(res => {
            this.suppliersAirtime = [];
            this.suppliersAirdata = [];
            this.suppliersCashpower = [];
            this.suppliersList = res;
            this.suppliersList.forEach(d => {
                if (d.sector.id === this._ENVIRONMENT_.ApiIdAirTime) {
                    this.suppliersAirtime.push(d);
                } else if (d.sector.id === this._ENVIRONMENT_.ApiIdAirData) {
                    this.suppliersAirdata.push(d);
                } else if (d.sector.id = this._ENVIRONMENT_.ApiIdCashPower) {
                    this.suppliersCashpower.push(d);
                }
            })
        })
    }

    getStores(event) {
        this.catalogSelectionArray = [];
        this._DiemmesseService.getStoresV1(event.value.id, this.currentSector.id, 'EUR').subscribe(res => {
            this.locationStores = res;
                this.currentLocationStore = this.locationStores[0];
                const fakeStore = { value: this.locationStores[0] }
                this.getStore(fakeStore)
        })
    }

    getStore(event) {
        this._DiemmesseService.getStoreLogged(event.value.id).subscribe(res => {
            console.log(res);
            this.catalogSelectionArray = res;
        })
    }


}