import { ContactusComponent } from "../../pages/contactus/contactus.component";
import { SuccesspopupComponent } from "../../pages/successpopup/successpopup.component";
import { MatDialog } from "@angular/material/dialog";
import { Injectable, Output, EventEmitter } from "@angular/core";

@Injectable()
export class ContactPopUp{

    @Output() SuccesspopupComponent: EventEmitter<any> = new EventEmitter<any>();
    
    constructor(private dialog: MatDialog){

    }

    showContactPopup() {
        const dialogRef = this.dialog.open(ContactusComponent, { panelClass: 'loginLayoutResets' });
        dialogRef.afterClosed().subscribe(result => {
            if ('emailsent' === result) {
                const dialogRefSuccess = this.dialog.open(SuccesspopupComponent, { panelClass: 'loginLayoutResets' });
                this.SuccesspopupComponent.emit();
            }
        });
    }
}