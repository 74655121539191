import { Buyer } from '../../shared/_objects/buyer';
import { Email, NewLocation, NewPinEmail } from '../../shared/_objects/email';
import { RegisterToken } from '../../shared/_objects/registerToken';
import { serverBaseUrl, webBaseUrl } from '../../globals';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { AgentRegistration } from '../../shared/_objects/agentregistration';
import { RegisterTokenCard } from '../../shared/_objects/registerTokenCard';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class LoginService {
  // Observable string sources
  private showLoginSource = new Subject<any>();
  email: Email;

  // Observable string streams
  showLoginAnnounced$ = this.showLoginSource.asObservable();

  public baseUrl = serverBaseUrl;
  public webUrl = webBaseUrl;
  constructor(private http: HttpClient) {
  }

  getBuyerLoginInfo(mobile: any, token: any): Observable<any> {
    const sector$ = this.http
      .post(`${this.baseUrl}/v1/buyer/login/info`, mobile, { headers: this.getNoAuthHeaderLogin(token) })
      .pipe(catchError(this.handleErrorLogin));
    return sector$;
  }

  putBuyerLoginInfo(buyerinfo: any): Observable<any> {
    const sector$ = this.http
      .put(`${this.baseUrl}/v1/buyer/login/info`, buyerinfo, { headers: this.getHeadersLogged() })
      .pipe(catchError(this.handleErrorLogin));
    return sector$;
  }

  getRegisterToken(registerToken: RegisterToken, token?: any): Observable<any> {
    const sector$ = this.http
      .post(`${this.baseUrl}/registercode`, registerToken, { headers: this.getNoAuthHeaderLogin(token) })
      .pipe(catchError(this.handleErrorLogin));
    return sector$;
  }

  getRegisterTokenCard(registerToken: RegisterTokenCard, token?: any): Observable<any> {
    const sector$ = this.http
      .post(`${this.baseUrl}/registercode`, registerToken, { headers: this.getNoAuthHeaderLogin(token) })
      .pipe(catchError(this.handleError));
    return sector$;
  }

  getBuyer(uuid: string): Observable<any> {
    const buyer$ = this.http
      .get(`${this.baseUrl}/buyer/${uuid}`, { headers: this.getHeadersLogged() })
      .pipe(catchError(this.handleError));
    return buyer$;
  }

  createBuyer(buyer: Buyer, password: string): Observable<any> {
    buyer.location = 'WebBuyer';
    const headersForAuthentication = new HttpHeaders({
      'x-blw-code': password,
      'Content-Type': 'application/json'
    });
    const buyer$ = this.http
      .post(`${this.baseUrl}/buyer`, buyer, { headers: headersForAuthentication, observe: 'response' })
      .pipe(catchError(this.handleError));
    return buyer$;
  }

  createBuyerWithPassword(password: string, captchaToken: string): Observable<any> {
    const headersForAuthentication = new HttpHeaders({
      'x-blw-code': password,
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('currentAuth'),
      'x-blw-entity': captchaToken.toString()
    });
    const buyer$ = this.http
      .get(`${this.baseUrl}/user/info`, { headers: headersForAuthentication, observe: 'response' })
      .pipe(catchError(this.handleError));
    return buyer$;
  }

  getuserInfo(): Observable<any> {
    const sector$ = this.http
      .get(`${this.baseUrl}/user/info`, { headers: this.getHeadersLogged(), observe: 'response' })
      .pipe(catchError(this.handleError));
    return sector$;
  }

  putBuyer(buyer: Buyer): Observable<any> {
    const buyer$ = this.http
      .put(`${this.baseUrl}/buyer`, buyer, { headers: this.getHeadersLogged() })
      .pipe(catchError(this.handleError));
    return buyer$;
  }

  updateBuyer(buyer: Buyer): Observable<any> {
    const buyer$ = this.http
      .put(`${this.baseUrl}/v1/buyer`, buyer, { headers: this.getHeadersLogged() })
      .pipe(catchError(this.handleError));
    return buyer$;
  }

  patchBuyer(buyer: Buyer): Observable<any> {
    const buyer$ = this.http
      .patch(`${this.baseUrl}/buyer`, buyer, { headers: this.getHeadersLogged() })
      .pipe(catchError(this.handleError));
    return buyer$;
  }

  getMgmReferable() {
    const referable$ = this.http
      .get(`${this.baseUrl}/v1/mgm/referable`, { headers: this.getHeadersLogged() })
      .pipe(catchError(this.handleError));
    return referable$;
  }

  postMgmRefer(code: string) {
    const refer$ = this.http
      .post(`${this.baseUrl}/v1/mgm/refer/${code}`, null, { headers: this.getHeadersLogged() })
      .pipe(catchError(this.handleError));
    return refer$;
  }

  getMgmCondition() {
    const condition$ = this.http
      .get(`${this.baseUrl}/v1/mgm/conditions`, { headers: this.getHeadersLogged() })
      .pipe(catchError(this.handleError));
    return condition$;
  }

  postSisalBuyerWallet(pin: any, token: string) {
    const wallet$ = this.http
      .post(`${this.baseUrl}/v1/buyerwallet/credit/coupon`, pin, { headers: this.getHeadersLoggedToken(token) })
      .pipe(catchError(this.handleError));
    return wallet$;
  }

  postBuyerWallet(pin: any) {
    const wallet$ = this.http
      .post(`${this.baseUrl}/v1/buyerwallet/credit/coupon`, pin, { headers: this.getHeadersLogged() })
      .pipe(catchError(this.handleError));
    return wallet$;
  }

  sendMail(contactEmail: Email) {
    const headersForEmail: HttpHeaders = this.getNoAuthHeader();
    const mail$ = this.http
      .post(`${this.webUrl}assets/emails/send-this-email-login.php`, contactEmail, { headers: headersForEmail, responseType: 'text' })
      .pipe(catchError(this.handleError));
    return mail$
  }

  sendContactEmail(contactEmail: Email) {
    const headersForEmail: HttpHeaders = this.getNoAuthHeader();
    const mail$ = this.http
      .post(`${this.webUrl}assets/emails/send-this-email-3.php`, contactEmail, { headers: headersForEmail, responseType: 'text' })
      .pipe(catchError(this.handleError));
    return mail$;
  }

  sendNewLocation(newLocation: NewLocation) {
    const headersForEmail: HttpHeaders = this.getNoAuthHeader();
    const mail$ = this.http
      .post(`${this.baseUrl}/store/suggest`, newLocation, { headers: this.getHeadersLogged() })
      .pipe(catchError(this.handleError));
    return mail$;
  }

  sendAgentRegistration(newRetailer: AgentRegistration) {
    const newretailer$ = this.http
      .post(`${this.baseUrl}/locutorio/register`, newRetailer, { headers: this.getHeadersLogged() })
      .pipe(catchError(this.handleErrorRegistration));
    return newretailer$;
  }

  sendAgentRegistrationWeb(newRetailer: any): Observable<any> {
    console.log(newRetailer);
    const newretailerd$ = this.http
      .post(`${this.baseUrl}/v1/agent/register`, newRetailer, { headers: this.getNoAuthHeader() })
      .pipe(catchError(this.handleErrorRegistration));
    return newretailerd$;
  }

  unsubscribe(contactMessageInformation: any) {
    const unsub$ = this.http
      .post(`${this.baseUrl}/v1/buyercard/unsubscribe`, contactMessageInformation, { headers: this.getHeadersLogged() })
      .pipe(catchError(this.handleErrorRegistration));
    return unsub$;
  }

  showLogin() {
    this.showLoginSource.next();
  }

  recoverPassword(mobile: any, token: string) {
    const password$ = this.http
      .post(`${this.baseUrl}/v1/buyer/passwordrecovery`, mobile, { headers: this.getNoAuthHeaderLogin(token) })
      .pipe(catchError(this.handleErrorLogin));
    return password$;
  }

  recoverPasswordReset(mobile: any, token: string): Observable<any> {
    const password$ = this.http
      .post(`${this.baseUrl}/v1/buyer/passwordrecovery/reset`, mobile, { headers: this.getNoAuthHeaderLogin(token) })
      .pipe(catchError(this.handleErrorLogin));
    return password$;
  }

  updateUserPassword(password: any) {
    const password$ = this.http
      .patch(`${this.baseUrl}/v1/user/password`, password, { headers: this.getHeadersLogged() })
      .pipe(catchError(this.handleErrorLogin));
    return password$;
  }

  sendPinBuyerHelp(mobile: NewPinEmail, token: string) {
    const email$ = this.http
      .post(`${this.baseUrl}/v1/buyer/pin/help`, mobile, { headers: this.getNoAuthHeaderLogin(token) })
      .pipe(catchError(this.handleErrorLogin));
    return email$;
  }

  private getNoAuthHeader() {
    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'x-blw-src': 'baluwoWeb'
    });
    return headers;
  }

  private getNoAuthHeaderLogin(captchaToken: any) {
    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'x-blw-src': 'baluwoWeb',
      // 'x-blw-entity': JSON.parse(localStorage.getItem('CaptchaToken'))
      'x-blw-entity': captchaToken.toString()
    });
    return headers;
  }

  private getHeadersLogged() {
    const headers = new HttpHeaders({
      'x-blw-src': 'baluwoWeb',
      'Content-type': 'application/json',
      'Authorization': localStorage.getItem('currentAuth')
    })
    return headers;
  }

  private getHeadersLoggedToken(captchaToken: any) {
    const headers = new HttpHeaders({
      'x-blw-src': 'baluwoWeb',
      'Content-type': 'application/json',
      'Authorization': localStorage.getItem('currentAuth'),
      'x-blw-entity': captchaToken.toString()
    })
    return headers;
  }

  private handleError(error: HttpErrorResponse) {
    // log error
    const errorMsg = error.message;
    // throw an application level error
    return Observable.throw(errorMsg);
  }

  private handleErrorLogin(error: HttpErrorResponse) {
    // log error
    const errorMsg = error;
    // throw an application level error
    return Observable.throw(errorMsg);
  }

  private handleErrorRegistration(error: HttpErrorResponse) {
    console.log(error)
    // log error
    // const errorMsg = error.message;
    let errorMsg = error.error;
    // throw an application level error
    return Observable.throw(errorMsg);
  }

}
