export const serverBaseUrl = 'https://qa.baluwo.com/rest';
export const webBaseUrl = 'https://webqa.baluwo.com/'; 
export const webBaseBlogUrl = 'https://webqa.baluwo.com/contents/'; 

// export const serverBaseUrl = 'https://qa.baluwo.com/rest';
// export const webBaseUrl = 'http://localhost:4200';  
// export const webBaseBlogUrl = 'http://webqa.baluwo.com/contents/'; 


export const currentTransaction = 'currTransaction';
export const currentBuyer = 'currentBuyer';
export const landingLanguage = 'landingLanguage';
export const landingCurrency = 'landingCurrency';
export const landingSourceCountry = 'landingSourceCountry';
export const landingSourceCountryCode = 'landingSourceCountryCode';
export const landingDestCurrency = 'landingDestCurrency';
export const landingDestCountry = 'landingDestCountry';
export const landingPath = 'landingPath';
export const topupMobile = 'topupordermobile';
export const finalTransactionTotal = 'finalTransactionTotal';
export const finalTransactionId = 'finalTransactionId';
export const currentStoreId = 'currentStoreId';

export const TOPUP_ORDER_TYPE = 'airtime';
export const CASHPOWER_ORDER_TYPE = 'cashpower';
export const STORE_ORDER_TYPE = 'store';

export const TransactionId = 'TransactionId';
export const PromoViewed = 'PromoViewed';

export const CYBERPACK_PAYMENT_ID = 1;
export const SOFORT_PAYMENT_ID = 2;
export const PAYPAL_PAYMENT_ID = 3;
export const BIZUM_PAYMENT_ID = 11;



export const paymentOkUrl = webBaseUrl + 'thanks';
export const paymentKoUrl = webBaseUrl + 'cartlanding';

export const landingCampaign = false;
