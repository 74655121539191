import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { PromoSectionComponent } from "./promotionsection.component";
import { SharedModule } from "../../shared.module";

@NgModule({
    imports:[
        SharedModule
    ],
    declarations:[
        PromoSectionComponent
    ],
    providers:[
    ],
    exports:[   
    ],
    entryComponents:[PromoSectionComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PromotionSectionModule { }