import { Buyer } from '../../../shared/_objects/buyer';
import { StoreOrderLine } from '../../../shared/_objects/order';
import { Transaction } from '../../../shared/_objects/transaction';
import { currentTransaction, currentBuyer, landingCurrency, STORE_ORDER_TYPE } from '../../../globals';
import { LoginService } from '../../../core/login-service/login.service';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TransactionServiceV1 } from '../../../core/services/transactionv1.service';
import { Email } from '../../../shared/_objects';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-loginmodal-error',
  templateUrl: 'modal-step_error.component.html',
  styleUrls: ['../loginmodal.component.scss']
})
export class LoginmodalStepErrorComponent implements OnInit {

  @Input() mobile: string;
  @Output() onBuyerCreated: EventEmitter<Buyer> = new EventEmitter<Buyer>();
  @Output() onAuthenticationError: EventEmitter<any> = new EventEmitter<any>();
  password: string;
  buyer: Buyer = new Buyer;
  currentTransaction: Transaction;
  helpemail = new Email;
  public _ENVIRONMENT_ = environment;

  @Output() onCloseLogin: EventEmitter<any> = new EventEmitter<any>();
  @Output() onOpenHelp: EventEmitter<any> = new EventEmitter<any>();

  constructor(private loginService: LoginService,
              private transactionService: TransactionServiceV1,
              private router: Router) { }

  ngOnInit() {
  }

 authenticateUser() {
    this.buyer.mobile = this.mobile;
    this.buyer.currency = JSON.parse(localStorage.getItem(landingCurrency));
    this.loginService.createBuyer(this.buyer, this.password)
      .subscribe(b => {this.onBuyerCreated.emit(b.body);
                        console.log(b);
                        localStorage.setItem('currentAuth', 'Basic ' + btoa(this.buyer.mobile + ':' + b.headers.get('x-blw-auth')));
                        this.currentTransaction = JSON.parse(localStorage.getItem(currentTransaction));
                        // TODO revisar esta condición
                        if (this.buyer.currency !== b.body.currency.id) {
                          if (this.currentTransaction !== null) {
                            this.buyer.currency = this.currentTransaction.srcCurrency;
                          }
                          this.loginService.putBuyer(this.buyer).subscribe(
                            r => {
                              this.buyer = r;
                              if (this.currentTransaction !== null) {
                                this.currentTransaction.srcCurrency = r.currency;
                              }
                              this.persistTransaction(b.body);
                            }
                          );
                        } else {
                          this.persistTransaction(b.body);
                        }
                      },
                error => this.onAuthenticationError.emit()
      );
  }
  sortOrderLines() {
      this.currentTransaction.orders.forEach ( o => {
        if (o.type === STORE_ORDER_TYPE) {
          o.lines.sort(this.sortProduct);
          // Leverage this function to add ramadan promotion
          let bags = 0;
          o.lines.forEach(l => {
            const name = l.catalog.product.name;
            if (name.toLocaleLowerCase().indexOf('rice') > -1 || name.toLocaleLowerCase().indexOf('riz') > -1
              || name.toLocaleLowerCase().indexOf('sugar') > -1 || name.toLocaleLowerCase().indexOf('sucre') > -1) {
              bags += l.quantity;
            }
          });
          // if (bags >= 5) {
          //   o.ramadan5bags = true;
          // } else if (bags >= 3) {
          //   o.ramadan3bags = true;
          // }
        }
      });
  }

  sortProduct(linea: StoreOrderLine, lineb: StoreOrderLine) {
    return linea.catalog.product.priority - lineb.catalog.product.priority;
  }
  persistTransaction(buyer: Buyer) {
        // User is logged in. We can create the transaction
        localStorage.setItem(currentBuyer, JSON.stringify(buyer));
        if (this.currentTransaction !== null) {
          this.currentTransaction.buyer = buyer;
          this.transactionService.createTransaction(this.currentTransaction)
             .subscribe(t => {console.log('created' + t);
                              this.currentTransaction = t;
                              this.sortOrderLines();
                              localStorage.setItem(currentTransaction, JSON.stringify(this.currentTransaction));
                              },
                        error => {
                              if (typeof this.currentTransaction.memberGetMember !== 'undefined') {
                                this.currentTransaction.memberGetMember = null;
                                this.transactionService.createTransaction(this.currentTransaction)
                                 .subscribe(t => {console.log('created' + t);
                                                  this.currentTransaction = t;
                                                  this.sortOrderLines();
                                                  localStorage.setItem(currentTransaction, JSON.stringify(this.currentTransaction));
                                                 });
                              }
                        });
        }
  }

  openHelp() {
    this.helpemail = <Email>{};
    this.helpemail.leadEmail = null;
    this.helpemail.leadName = null;
    this.helpemail.leadPhone = this.mobile;
    this.helpemail.leadMessage = 'user ' + this.mobile + ' requested help for login in a campaign';
    this.helpemail.destinationEmail = this._ENVIRONMENT_.EmailSupport;
    this.helpemail.destinationBBCEmail = this._ENVIRONMENT_.EmailBBC;
    this.loginService.sendMail(this.helpemail).subscribe(()=>{
      console.log('email sent');
      this.onOpenHelp.emit();
    });
  }
  closeLogin() {
    this.onCloseLogin.emit();
  }
}


