import { Router } from "@angular/router";
import { Injectable } from "@angular/core";

@Injectable()
export class UtilsHelper {
    constructor(private router: Router) { }

    navigateTo(page) {
        this.router.navigate([page]);
    }
}