import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ValidityOrderHelper } from "../../../shared/_helpers/_validityOrder.helper";
import { HomeUtilsHelper } from "../../../shared/_helpers/_homeUtils.helper";
import { Order, Transaction } from "../../../shared/_objects";

@Component({
    selector: 'app-cl-national-recharge-component',
    templateUrl: './cl-national.component.html',
    styleUrls: ['../cartlanding.component.scss', '../../../../assets/css/bootstrap-grid.min.css']
})

export class CLNationalRechargeComponent {
    cartClass = 'purchaseOrder';

    @Input() order: any;
    @Input() transaction: Transaction;
    @Input() transactionHistory: Transaction;
    @Input() isValidAmount: boolean;
    @Output() removeOrderEmit: EventEmitter<any> = new EventEmitter<any>();

    constructor(public _homeUtilsHelper: HomeUtilsHelper,
        public _validityOrderHelper: ValidityOrderHelper) { }
        
    removeOrder() {
        this.removeOrderEmit.emit(this.order);
    }
}