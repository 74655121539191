import { CyberpackPaymentInfo } from '../../shared/_objects/cyberpackPaymentInfo';
import { PaypalPaymentInfo } from '../../shared/_objects/payPalPaymentInfo';
import { SofortPaymentInfo } from '../../shared/_objects/sofortPaymentInfo';
import { serverBaseUrl, CYBERPACK_PAYMENT_ID, paymentOkUrl, paymentKoUrl, webBaseUrl, TransactionId } from '../../globals';
import { Injectable } from '@angular/core';
import { Http, Headers, Response, ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs';
import { ContextBase } from '../../shared/_helpers/_contextBase.helper';
import { RegisterToken } from '../../shared/_objects/registerToken';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PaymentService {
  payKoUrlPayPal: string;
  payOkUrlPayPal: string;
  payKoUrlSofort: string;
  payOkUrlSofort: string;
  payKoUrlBizum: string;
  payOkUrlBizum: string;
  private baseUrl = serverBaseUrl;
  private webUrl = webBaseUrl;
  public payOkUrl: string;
  public payKoUrl: string;
  payKoUrlPayPalAutoReg: string;
  payOkUrlPayPalAutoReg: string;
  public payOkUrlAutoReg: string;
  public payKoUrlAutoReg: string;
  private lang: string;
  transactionId: string;
  transactionAmount: number;
  cardUrl: string;
  payOkUrlWallet: string;
  payKoUrlWallet: string;
  payOkUrlSofortWallet: string;
  payKoUrlSofortWallet: string;
  constructor(private http: HttpClient, private contexto: ContextBase) {
    this.createPaymentReturn();
  }

  createPaymentReturn(){
    let language;
    this.transactionId = localStorage.getItem(TransactionId);
    if (this.contexto.language !== null && this.contexto.language !== undefined) {
      this.payOkUrl = this.webUrl + '%23/thanks?transactionIdRedys=' + this.transactionId;
      this.payKoUrl = this.webUrl + '%23/thanks?transactionKORedys=' + this.transactionId;
      this.payOkUrlBizum = this.webUrl + '%23/thanks?transactionIdBizums=' + this.transactionId;
      this.payKoUrlBizum = this.webUrl + '%23/thanks?transactionKOBizums=' + this.transactionId;
      this.payOkUrlSofort = this.webUrl + '%23/thanks?transactionId=' + this.transactionId;
      this.payKoUrlSofort = this.webUrl + '%23/thanks?transactionKO=' + this.transactionId;
      this.payOkUrlPayPal = this.webUrl + '%23/thanks?transactionId=' + this.transactionId;
      this.payKoUrlPayPal = this.webUrl + '%23/thanks?transactionKO=' + this.transactionId;

      this.payOkUrlAutoReg = this.webUrl + '%23/partners/agents?success=done';
      this.payKoUrlAutoReg = this.webUrl + '%23/partners/agents';
      this.payOkUrlPayPalAutoReg = this.webUrl + '%23/partners/agents?success=done';
      this.payKoUrlPayPalAutoReg = this.webUrl + '%23/partners/agents';

      this.payOkUrlWallet = this.webUrl + '%23/account;tabindex=2?transactionOkRedys=true';
      this.payKoUrlWallet = this.webUrl + '%23/account;tabindex=2?transactionKORedys=true';
      this.payOkUrlSofortWallet = this.webUrl + '%23/account;tabindex=2?transactionOk=true';
      this.payKoUrlSofortWallet = this.webUrl + '%23/account;tabindex=2?transactionKO=true';
    }
  }

  buildUrl(paymentMethodId, amount) {
    this.createPaymentReturn();
    if (amount) {
      this.cardUrl = `${this.baseUrl}/v1/transaction/paymentinfo/${paymentMethodId}?buyerCardPoints=${amount}&`
    } else {
      this.cardUrl = `${this.baseUrl}/v1/transaction/paymentinfo/${paymentMethodId}?`
    }
  }
  getPrePaymentInfoCyberpackV1(paymentMethodId: number, amount?: number): Observable<CyberpackPaymentInfo> {
    this.buildUrl(paymentMethodId, amount);
    const paymentInfo = <any>this.http
      .get(`${this.cardUrl}ok=${this.payOkUrl}&ko=${this.payKoUrl}`
        , { headers: this.getHeadersLogged() })
      .pipe(catchError(handleError));
    return paymentInfo;
  }

  getPrePaymentInfoBizumV1(paymentMethodId: number, amount?: number): Observable<any> {
    this.buildUrl(paymentMethodId, amount);
    const paymentInfo = <any>this.http
      .get(`${this.cardUrl}ok=${this.payOkUrl}&ko=${this.payKoUrl}`
        , { headers: this.getHeadersLogged() })
      .pipe(catchError(handleError));
    return paymentInfo;
  }

  getPrePaymentInfoPayPalV1(paymentMethodId: number, amount?: number): Observable<PaypalPaymentInfo> {
    this.buildUrl(paymentMethodId, amount);
    const paymentInfo = <any>this.http
      .get(`${this.cardUrl}ok=${this.payOkUrlPayPal}&ko=${this.payKoUrlPayPal}`
        , { headers: this.getHeadersLogged() })
      .pipe(catchError(handleError));
    return paymentInfo;
  }

  getPrePaymentInfoSofortV1(paymentMethodId: number, amount?: number): Observable<SofortPaymentInfo> {
    this.buildUrl(paymentMethodId, amount);
    const paymentInfo = <any>this.http
      .get(`${this.cardUrl}ok=${this.payOkUrlSofort}&ko=${this.payKoUrlSofort}`
        , { headers: this.getHeadersLogged() })
      .pipe(catchError(handleError));
    return paymentInfo;
  }

  getPrePaymentInfoCyberpackV2(mobile: string, paymentMethodId: number, total: number): Observable<CyberpackPaymentInfo> {
    const paymentInfo = <any>this.http
      .get(`${this.baseUrl}/v1/agent/paymentinfo/${mobile}/${paymentMethodId}/${total}?ok=${this.payOkUrlAutoReg}&ko=${this.payKoUrlAutoReg}`
        , { headers: this.getNoAuthHeader() })
      .pipe(catchError(handleError));
    return paymentInfo;
  }

  getPrePaymentInfoPayPalV2(mobile: string, paymentMethodId: number, total: number): Observable<PaypalPaymentInfo> {
    const paymentInfo = <any>this.http
      .get(`${this.baseUrl}/v1/agent/paymentinfo/${mobile}/${paymentMethodId}/${total}?ok=${this.payOkUrlPayPalAutoReg}&ko=${this.payKoUrlPayPalAutoReg}`
        , { headers: this.getNoAuthHeader() })
      .pipe(catchError(handleError));
    return paymentInfo;
  }

  getPrePaymentInfoSofortV2(mobile: string, paymentMethodId: number, total: number): Observable<SofortPaymentInfo> {
    const paymentInfo = <any>this.http
      .get(`${this.baseUrl}/v1/agent/paymentinfo/${mobile}/${paymentMethodId}/${total}?ok=${this.payOkUrlAutoReg}&ko=${this.payKoUrlAutoReg}`
        , { headers: this.getNoAuthHeader() })
      .pipe(catchError(handleError));
    return paymentInfo;
  }

  setFullPaymentCard(): Observable<SofortPaymentInfo> {
    const paymentInfo = <any>this.http
      .post(`${this.baseUrl}/v1/transaction/buyercard`, null, { headers: this.getHeadersLogged() })
      .pipe(catchError(handleError));
    return paymentInfo;
  }

  setFullPaymentWallet(): Observable<SofortPaymentInfo> {
    const paymentInfo = <any>this.http
      .post(`${this.baseUrl}/v1/transaction/buyerwallet`, null, { headers: this.getHeadersLogged() })
      .pipe(catchError(handleError));
    return paymentInfo;
  }

  getRegisterTokenCard(id: string): Observable<any> {
    const sector$ = this.http
      .post(`${this.baseUrl}/v1/transaction/validate/sendcode`, id, { headers: this.getHeadersLogged() })
      .pipe(catchError(handleError));
    return sector$;
  }

  sendPinCodeValidation(pin: any): Observable<any> {
    const pin$ = this.http
      .post(`${this.baseUrl}/v1/transaction/validate/${pin}`, null, { headers: this.getHeadersLogged() })
      .pipe(catchError(handleError));
    return pin$;
  }

  buildBaluwoCashPinUrl(location?: string, amount?: number) {
    if (location === undefined && (amount === undefined || amount === null)) {
      return `${this.baseUrl}/v1/transaction/baluwocash`
    } else if (location === undefined && amount !== undefined) {
      return `${this.baseUrl}/v1/transaction/baluwocash?buyerCardPoints=${amount}`
    } else if (location !== undefined && amount === undefined) {
      return `${this.baseUrl}/v1/transaction/baluwocash?buyerLocation=${location}`
    } else if (location !== undefined && amount !== undefined) {
      return `${this.baseUrl}/v1/transaction/baluwocash?buyerLocation=${location}&buyerCardPoints=${amount}`
    }
  }

  getBaluwoCashPinCode(locationHelp?: string, amountCard?: number): Observable<any> {
    const baluwocashpin$ = this.http
      .get(this.buildBaluwoCashPinUrl(locationHelp, amountCard), { headers: this.getHeadersLogged() })
      .pipe(catchError(handleError));
    return baluwocashpin$;
  }

  setBaluwoCashPinCodeValidation(pincode: string): Observable<any> {
    const baluwocashpin$ = this.http
      .post(`${this.baseUrl}/v1/transaction/baluwocash/check`, pincode, { headers: this.getHeadersLogged() })
      .pipe(catchError(handleError));
    return baluwocashpin$;
  }

  setBaluwoCashPinCodeRedeem(pincode: string): Observable<any> {
    const baluwocashpin$ = this.http
      .post(`${this.baseUrl}/v1/transaction/baluwocash`, pincode, { headers: this.getHeadersLogged() })
      .pipe(catchError(handleError));
    return baluwocashpin$;
  }

  getPaymentInfoWalletCyberpac(paymentType:number, total:number):Observable<any>{
    const buyerwalletpaymentinfo$ = this.http
      .get(`${this.baseUrl}/v1/buyerwallet/paymentinfo/${paymentType}/${total}?ok=${this.payOkUrlWallet}&ko=${this.payKoUrlWallet}`, { headers: this.getNoAuthHeader() })
      .pipe(catchError(handleError));
    return buyerwalletpaymentinfo$;
  }

  getPaymentInfoWalletBizum(paymentType:number, total:number):Observable<any>{
    const buyerwalletpaymentinfo$ = this.http
      .get(`${this.baseUrl}/v1/buyerwallet/paymentinfo/${paymentType}/${total}?ok=${this.payOkUrlWallet}&ko=${this.payKoUrlWallet}`, { headers: this.getNoAuthHeader() })
      .pipe(catchError(handleError));
    return buyerwalletpaymentinfo$;
  }

  getPaymentInfoWalletSofort(paymentType:number, total:number):Observable<any>{
    const buyerwalletpaymentinfo$ = this.http
      .get(`${this.baseUrl}/v1/buyerwallet/paymentinfo/${paymentType}/${total}?ok=${this.payOkUrlSofortWallet}&ko=${this.payKoUrlSofortWallet}`, { headers: this.getNoAuthHeader() })
      .pipe(catchError(handleError));
    return buyerwalletpaymentinfo$;
  }

  getWalletRechargeReceipt():Observable<any>{
    const buyerwalletpaymentReceipt$ = this.http
      .get(`${this.baseUrl}/v1/buyerwallet/cyberpack/receipt`, { headers: this.getHeadersLoggedReceipt(), responseType: 'text' })
      .pipe(catchError(handleError));
    return buyerwalletpaymentReceipt$;
  }


  private getHeadersLogged() {
    const headers = new HttpHeaders({
      'x-blw-src': 'baluwoWeb',
      'Content-type': 'application/json',
      'Authorization': localStorage.getItem('currentAuth')
    });
    return headers;
  }

  private getHeadersLoggedReceipt() {
    const headers = new HttpHeaders({
      'x-blw-src': 'baluwoWeb',
      'Accept': 'text/html',
      'Content-type': 'application/json',
      'Authorization': localStorage.getItem('currentAuth')
    });
    return headers;
  }

  private getNoAuthHeader() {
    const headers = new HttpHeaders({
      'x-blw-src': 'baluwoWeb',
      'Accept': 'application/json'
    });
    return headers;
  }
}


function handleError(error: HttpErrorResponse) {
  return Observable.throw(error.error);
}
