import { Buyer } from './shared/_objects/buyer';
import { Transaction } from './shared/_objects/transaction';
import { CommonService } from './core/services/common.service';
import { currentBuyer, currentTransaction, landingSourceCountry, TransactionId, landingSourceCountryCode } from './globals';
import { LoginService } from './core/login-service/login.service';
import { LoginmodalComponent } from './pages/loginmodal/loginmodal.component';
import { Component, Renderer, SimpleChanges, OnChanges, ViewChild, Inject } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ContextBase } from './shared/_helpers/_contextBase.helper';
import * as $ from 'jquery';
import { FooterService } from './shared/_services/footer.service';
import { Subscription } from 'rxjs';
import { getCountryCallingCode } from 'libphonenumber-js';
import { ToastContainerDirective } from 'ngx-toastr';
import { NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { BuyerInfoService } from './shared/_services/buyerinfo.service';
import { FacebookPixelService } from './shared/_services/facebook-pixel.service';
import { DOCUMENT } from '@angular/common';
import { HeaderCountryService } from './shared/_services/header-country.service';
import { PromotionService } from './core/services/promotion.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss', '../assets/css/bootstrap-grid.min.css'],
  providers:[DeviceDetectorService]
})
export class AppComponent implements OnChanges {
  landingSourceCountry: any;
  landingSourceCountryCode: string;
  buyer: Buyer;
  transaction: Transaction;
  title = 'baluwo';
  showLogin = false;
  isCampaign = true;
  language = 'es';
  currency = 'eur';

  // check if is mobile
  isMobile = true;
  isDesktop = false;

  param1: string;
  param2: string;

  sub: any;
  transactionIdUniq: any;

  @ViewChild(ToastContainerDirective)
  toastContainer: ToastContainerDirective;

  public static UserLang: string;
  subscription: Subscription;
  computerLanguage: string;
  series: string;
  public _ENVIRONMENT_ = environment;

  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService,
    private commonService: CommonService,
    private context: ContextBase,
    private _BuyerInfoService: BuyerInfoService,
    private _HeaderCountryService: HeaderCountryService,
    private facebookPixelService: FacebookPixelService,
    private promotionService: PromotionService,
    private footerService: FooterService,
    private deviceService: DeviceDetectorService,
    @Inject(DOCUMENT) private document: Document
    // private ccService: NgcCookieConsentService
  ) {
    if (typeof localStorage.getItem(currentBuyer) !== 'undefined') {
      this.buyer = JSON.parse(localStorage.getItem(currentBuyer));
    }
    if (typeof localStorage.getItem(currentTransaction) !== 'undefined') {
      this.transaction = JSON.parse(localStorage.getItem(currentTransaction));
    }

    if (localStorage.getItem(currentBuyer) === null) {
      $.get("https://ipinfo.io?token=1b6dc47efdd393", (response) => {
        console.log(response);
        const countryCode = response.country;
        const countryCodePhone = getCountryCallingCode(countryCode);
        console.log(countryCode)
        console.log(countryCodePhone)
        localStorage.setItem(landingSourceCountry, JSON.stringify(countryCode));
        localStorage.setItem(landingSourceCountryCode, JSON.stringify(countryCodePhone));
        this._BuyerInfoService.setCountryCode(countryCode);
      }, "jsonp");
    } else {
      this._BuyerInfoService.setCountryCode(this.buyer.country.id);
    }

    this.router.events.subscribe(
      () => window.scrollTo(0, 0)
    );


    this.context.isMobile = (/android|webos|iphone|ipad|ipod|blackberry|windows phone/).test(navigator.userAgent.toLowerCase());
    this.context.isDesktop = !this.isMobile;

    let userLang;
    if (navigator.language) {
      userLang = navigator.language.split('-')[0];
    } else {
      // TODO: para IE10
      userLang = (navigator as any).userLanguage.split('-')[0];
    }

    this.language = userLang;
    if (this.language !== 'es' && this.language !== 'en' && this.language !== 'fr' && this.language !== 'de') {
      this.language = 'en';
      this.computerLanguage = 'en'
    }else{
      this.computerLanguage = this.language;
    }
    if (localStorage.getItem('baluwoUserLanguage') == null) {
      localStorage.setItem('baluwoUserLanguage', this.computerLanguage.toLowerCase());
      this.router.navigate(['/home'])
      this.context.language = this.computerLanguage;
    } else {
      this.language = localStorage.getItem('baluwoUserLanguage')
    }

    if(localStorage.getItem('baluwoUserCurrency') == null){
      localStorage.setItem('baluwoUserCurrency', this.currency);
    }else{
      this.currency = localStorage.getItem('baluwoUserCurrency');
    }

    this.translate.setDefaultLang(this.language.toLowerCase());

    this.subscription = this.footerService.getFooterBuyer().subscribe(res => {
      if (typeof localStorage.getItem(currentBuyer) !== 'undefined') {
        this.buyer = JSON.parse(localStorage.getItem(currentBuyer));
      }
    })

    this._BuyerInfoService.getBuyerLanguage.subscribe(language => {
      if (typeof language !== 'object') {
        this.language = language.toLowerCase();
        this.context.language = this.language;
      }
    })

    this._HeaderCountryService.setCountryDefaultList();

    const redurectionLink = this.GetParam('remitly');
    console.log(redurectionLink);
    if (redurectionLink) this.facebookPixelService.RemitlyBuyer();

    const referralLink = this.getQueryParam('code');
    console.log(referralLink);

  }

  getQueryParam(param) {
    const results = new RegExp('[\\?&]' + param + '=([^&#]*)').exec(window.location.href);
    // var result =  window.location.search.match(
    //     new RegExp("(\\?|&)" + param + "(\\[\\])?=([^&]*)")
    // );
    let ua = navigator.userAgent
    console.log(/Android/i.test(ua));
    console.log(/iphone|ipad/i.test(ua))
    console.log(results);
    if(results){
      if(/Android/i.test(ua)){
        window.location.href=`https://play.google.com/store/apps/details?id=${this._ENVIRONMENT_.baluwoUserAppStore}&referrer=` + results[1]
      } else if (/iphone|ipad/i.test(ua)){
        this.promotionService.sendReferralCodeIOS(results[1]).subscribe(res=>{
                    console.log(res);
                });
        window.location.href='https://apps.apple.com/es/app/baluwo/id1490261153?referrer=' + results[1]
      } else{
        this.router.navigate(['/home']);
      }
    }

    return results ? results[3] : false;
}

  ngOnChanges(changes: SimpleChanges) {
  }

  ngOnInit() {
    this.context.currency = this.currency;
    this.context.language = this.language;
  }

  // initCookieConsent() {
  //   this.ccService.getConfig().content = this.ccService.getConfig().content || {};

  //   this.ccService.getConfig().content.message = this.translate.instant('Baluwo.cookie.consent.content');
  //   this.ccService.getConfig().content.allow = this.translate.instant('Baluwo.cookie.consent.content.allow');
  //   this.ccService.getConfig().content.deny = this.translate.instant('Baluwo.cookie.consent.content.decline');
  //   this.ccService.getConfig().content.hereLink = this.translate.instant('Baluwo.cookie.consent.content.here');
  //   this.ccService.getConfig().content.hereLinkHref = `/#/${this.language.toLowerCase()}/${this.currency.toLowerCase()}/cookie-policy`;

  //   this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
  //     () => {
  //       // you can use this.ccService.getConfig() to do stuff...
  //     });

  //   this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
  //     () => {
  //       // you can use this.ccService.getConfig() to do stuff...
  //     });

  //   this.initializeSubscription = this.ccService.initialize$.subscribe(
  //     (event: NgcInitializeEvent) => {
  //       // you can use this.ccService.getConfig() to do stuff...
  //     });

  //   this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
  //     (event: NgcStatusChangeEvent) => {
  //       const hasConsented = this.ccService.hasConsented();
  //       // you can use this.ccService.getConfig() to do stuff...
  //       console.log(this.ccService.getConfig().type);
  //       console.log(this.ccService.hasConsented());
  //       if (hasConsented) {

  //       }
  //     });

  //   this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
  //     () => {
  //       // you can use this.ccService.getConfig() to do stuff...
  //     });

  //   this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
  //     (event: NgcNoCookieLawEvent) => {
  //       // you can use this.ccService.getConfig() to do stuff...
  //     });

  //   this.ccService.destroy();
  //   this.ccService.init(this.ccService.getConfig());
  // }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.subscription.unsubscribe();

    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

  onSuccessLogin(buyer: Buyer) {
    console.log('success login con buyer ' + buyer.id);
    localStorage.setItem(currentBuyer, JSON.stringify(buyer));
    if (null != localStorage.getItem(currentTransaction)) {
      const transaction: Transaction = JSON.parse(localStorage.getItem(currentTransaction));
      transaction.buyer = buyer;
      localStorage.setItem(currentTransaction, JSON.stringify(transaction));
    }
  }

  login() {
    const dialogRef = this.dialog.open(LoginmodalComponent, { panelClass: 'loginLayoutResets' });
    dialogRef.afterClosed().subscribe(b => {
      if (typeof b !== 'undefined') {
        this.buyer = b;
        console.log('confirmamos buyer ' + this.buyer.mobile);
      }
    });
  }

  GetParam(name) {
    const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (!results) {
      return 0;
    }
    return results[1] || 0;
  }
}
