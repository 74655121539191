import { Injectable } from '@angular/core';
import { ContextBase } from '../../shared/_helpers/_contextBase.helper';
import { TransactionServiceV1 } from './transactionv1.service';
import { CalculatedEchanged, CalculatedManual, Calculate } from '../../shared/_objects/calculate';
import { Subject } from 'rxjs';
import { HomeUtilsHelper } from '../../shared/_helpers/_homeUtils.helper';
import { ServerErrorHelper } from '../../shared/_helpers/servererrors.helper';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AmountService {

  amountsExchange: Array<CalculatedEchanged> = [];
  manualAmountChanged: CalculatedManual;
  isValidAmount: boolean;
  amounts: Array<number> = [3, 5, 10, 15, 20, 50];
  amountsSek: Array<number>;
  dataAmountsInfo: any;
  fixedAmountAvailable: boolean = false;
  allFixed: boolean = false;
  errorMesage: string;
  private subject = new Subject<any>();

  constructor(public contexto: ContextBase,
    private transactionService: TransactionServiceV1,
    public serverErrorHelper: ServerErrorHelper,
    public _homeUtilsHelper: HomeUtilsHelper) { }

  getAmountList() {
    if (this.contexto.currency.toLowerCase() == 'sek') {
      this.amountsSek = [];
      this.amounts.forEach(d => {
        d = d * 10;
        this.amountsSek.push(d);
      })
      return this.amountsSek;
    } else if (this.contexto.currency.toLowerCase() == 'gbp') {
      this.amounts[this.amounts.length - 1] = 40;
      return this.amounts;
    } else if (this.contexto.currency.toLowerCase() == 'usd') {
      this.amounts.shift();
      return this.amounts;
    } else {
      return this.amounts;
    }
  }

  getFixedAmounts(currency, beneficiaryphone) {
    this.serverErrorHelper.errorMsgAmountService = null;
    this.transactionService.getAirtimeAmountFixed(beneficiaryphone, this.contexto.currency.toUpperCase()).subscribe(res => {
      if (res != null) {
        this.dataAmountsInfo = res;
        this.amounts = [];
        res.fixedAmounts.forEach(d => {
          this.amounts.push(d.facevalue);
        })
        this.buildFixedAmountsArray(res, beneficiaryphone);
        this.fixedAmountAvailable = true;
        this.allFixed = res.allFixed;
      } else {
        if(this.contexto.currency.toLowerCase() == 'usd'){
          this.amounts = [3, 5, 10, 15, 20, 40];
        }else{
          this.amounts = [3, 5, 10, 15, 20, 50];
        }
        this.calculateAmountCurrency(currency, beneficiaryphone);
        this.fixedAmountAvailable = false;
        this.allFixed = false;
      }

    }, error => {
      this.errorMesage = this.serverErrorHelper.getErrorMsg(7);
      this.serverErrorHelper.errorMsgAmountService = this.errorMesage;
      this.amountsExchange = [];
    })
  }

  getAirDataAmounts(currency, beneficiaryphone) {
    this.serverErrorHelper.errorMsgAmountService = null;
    this.transactionService.getAirDataAmountFixed(beneficiaryphone, this.contexto.currency.toUpperCase()).subscribe(res => {
      if (res != null) {
        this.dataAmountsInfo = res;
        this.amounts = [];
        res.fixedAmounts.forEach(d => {
          this.amounts.push(d.targetPrice);
        })
        this.buildFixedAmountsArray(res, beneficiaryphone, res.allFixed);
      } else {
        this.errorMesage = this.serverErrorHelper.getErrorMsg(7);
        this.serverErrorHelper.errorMsgAmountService = this.errorMesage;
        this.amountsExchange = [];
      }
    }, error => {
      this.errorMesage = this.serverErrorHelper.getErrorMsg(7);
      this.serverErrorHelper.errorMsgAmountService = this.errorMesage;
      this.amountsExchange = [];
    })
  }

  getBundleAmounts(currency, beneficiaryphone) {
    this.serverErrorHelper.errorMsgAmountService = null;
    this.transactionService.getBundleAmountFixed(beneficiaryphone, this.contexto.currency.toUpperCase()).subscribe(res => {
      if (res != null) {
        this.dataAmountsInfo = res;
        this.amounts = [];
        res.fixedAmounts.forEach(d => {
          this.amounts.push(d.targetPrice);
        })
        this.buildFixedAmountsArray(res, beneficiaryphone, res.allFixed);
      } else {
        this.errorMesage = this.serverErrorHelper.getErrorMsg(7);
        this.serverErrorHelper.errorMsgAmountService = this.errorMesage;
        this.amountsExchange = [];
      }
    }, error => {
      this.errorMesage = this.serverErrorHelper.getErrorMsg(7);
      this.serverErrorHelper.errorMsgAmountService = this.errorMesage;
      this.amountsExchange = [];
    })
  }

  buildFixedAmountsArray(data, beneficiaryphone, allFixed?) {
    this.amountsExchange = [];
    data.fixedAmounts.forEach(x => {
      let t: CalculatedEchanged = <CalculatedEchanged>{};
      t.amount = x.facevalue;
      t.amountExchanged = x.targetPrice;
      t.beneficiary = beneficiaryphone;
      t.description = x.description;
      t.allFixed = allFixed;
      this.amountsExchange.push(t);
      this.amountsExchange.sort((a, b) => { return a.amount - b.amount });
      return this.amountsExchange;
    })
  }

  calculateAmountCurrency(currency: string, beneficiaryphone?: any) {
    this.amountsExchange = [];
    this.getAmountList().forEach(d => {
      let x = this.buildAmountObject(d, beneficiaryphone);
      this.transactionService.getCalculations(x, currency).subscribe(res => {
        let t: CalculatedEchanged = <CalculatedEchanged>{};
        t.amount = x.total;
        t.amountExchanged = res[0].targetTotal;
        t.beneficiary = beneficiaryphone;
        if (res[1]) t.promo = res[1].targetTotal;
        this.amountsExchange.push(t);
        this.amountsExchange.sort((a, b) => { return a.amount - b.amount });
        return this.amountsExchange;
      }, error => {
        if (error.code === 25) {
          this.errorMesage = this.serverErrorHelper.getErrorMsg(error.code);
          this.serverErrorHelper.errorMsgAmountService = this.errorMesage;
        } else {
          this.errorMesage = this.serverErrorHelper.getErrorMsg(error.code);
          this.serverErrorHelper.errorMsgAmountService = this.errorMesage;
        }
        return;
      })
    })
  }

  calculateManualAmount(amount, currency: string, beneficiaryphone?: any) {
    let x = this.buildAmountObject(amount, beneficiaryphone);
    this.transactionService.getCalculations(x, currency).subscribe(res => {
      this.manualAmountChanged = <CalculatedManual>{};
      this.manualAmountChanged.amountExchanged = res[0].targetTotal;
      if (res[1]) this.manualAmountChanged.promo = res[1].targetTotal;
      this.isValidAmount = true;
    }, error => {
      this.isValidAmount = false;
    });
  }

  buildAmountObject(dat, beneficiaryphone?) {
    let data: Calculate = <Calculate>{};
    data.type = 'airtime';
    data.total = dat;
    data.beneficiary = <any>{};
    data.beneficiary.mobile = beneficiaryphone;
    data.beneficiary.name = 'C';
    return data;
  }

  buildAmountTargetObject(type, dat, beneficiaryphone?) {
    let data: Calculate = <Calculate>{};
    data.type = type;
    data.targetTotal = dat;
    data.beneficiary = <any>{};
    data.beneficiary.mobile = beneficiaryphone;
    data.beneficiary.name = 'C';
    return data;
  }

  calculateAmountCashpowerCurrency(meterid: any, currency: string, beneficiaryphone?: any) {
    this.amountsExchange = [];
    this.getAmountList().forEach(d => {
      let x = this.buildAmountCashpowerObject(d, meterid, beneficiaryphone);
      this.transactionService.getCalculations(x, currency).subscribe(res => {
        let t: CalculatedEchanged = <CalculatedEchanged>{};
        t.amount = x.total;
        t.amountExchanged = res[0].targetTotal;
        if (res[1]) t.promo = res[1].targetTotal;
        this.amountsExchange.push(t);
        this.amountsExchange.sort((a, b) => { return a.amount - b.amount });
        return this.amountsExchange;
      }, error => {
      })
    })
  }

  calculateManualAmountCashpower(amount, meterid, currency: string, beneficiaryphone?: any) {
    let x = this.buildAmountCashpowerObject(amount, meterid, beneficiaryphone);
    this.transactionService.getCalculations(x, currency).subscribe(res => {
      this.manualAmountChanged = <CalculatedManual>{};
      this.manualAmountChanged.amountExchanged = res[0].targetTotal;
      if (res[1]) this.manualAmountChanged.promo = res[1].targetTotal;
      this.isValidAmount = true;
    }, error => {
      if (error.code === 25) {
        this.errorMesage = this.serverErrorHelper.getErrorMsg(error.code);
        this.serverErrorHelper.errorMsgAmountService = this.errorMesage;
      }
      this.isValidAmount = false;
    });
  }

  buildAmountCashpowerObject(dat, meterid: any, beneficiaryphone?) {
    let data: Calculate = <Calculate>{};
    data.type = 'cashpower';
    data.total = dat;
    data.meterId = meterid;
    data.beneficiary = <any>{};
    data.beneficiary.mobile = beneficiaryphone;
    data.beneficiary.name = 'C';
    return data;
  }
}
