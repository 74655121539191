import { Injectable } from "@angular/core";
import { EcommerceNewModelCheckout, EcommerceNewModelPurchase, previewcountry, previewsector, previewstore, userlanguage } from "../_objects/ecommerce";
import { registrationPartner } from "../_objects/registrationPartner";
import { contactus } from "../_objects/contactus";

@Injectable()
export class AnalyticsHelper {

    eCommerce: EcommerceNewModelCheckout;
    eCommercePurchase: EcommerceNewModelPurchase;
    previewCountry: previewcountry;
    previewSector: previewsector;
    previewStore: previewstore;
    registration: registrationPartner;
    contact: contactus;
    userLanguage: userlanguage;

    buildCartLandingAnalyticsData(transaction) {
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        this.eCommerce = <EcommerceNewModelCheckout>{};
        this.eCommerce.ecommerce = <any>{};
        this.eCommerce.ecommerce = <any>{};
        this.eCommerce.ecommerce.items = <any>[];

        this.eCommerce.event = 'checkout';
        this.eCommerce.ecommerce.step = 1;

        transaction.orders.forEach((d) => {
            console.log(d);
            if (d.lines) {
                d.lines.forEach(x => {
                    this.eCommerce.ecommerce.items.push({
                      item_name: x.catalog.product.name,
                        item_id: x.catalog.id,
                        price: x.catalog.price,
                        item_category: d.store.sector.name,
                        quantity: x.quantity
                    })
                })
            } else {
                this.eCommerce.ecommerce.items.push({
                    item_id: d.id,
                    item_category: d.type,
                    quantity: d.total
                })
            }
        });

        (<any>window).dataLayer.push(this.eCommerce);
        console.log(this.eCommerce);
    }

    analyticsPurchaseEvent(transaction){
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        this.eCommercePurchase = <EcommerceNewModelPurchase>{};
        this.eCommercePurchase.event = 'purchase';
        this.eCommercePurchase.ecommerce = <any>{};
        this.eCommercePurchase.ecommerce.items = <any>[];

        

        this.calculateTotalAMount(transaction);
        this.eCommercePurchase.ecommerce.transaction_id = transaction.id;
        this.eCommercePurchase.ecommerce.currency = transaction.currency.id;
        // this.eCommercePurchase.ecommerce.purchase.actionField.revenue = this.totalAmount;

        transaction.orders.forEach((d) => {
          if (d.lines) {
            d.lines.forEach(x => {
              this.eCommercePurchase.ecommerce.items.push({
                item_name: x.catalog.product.name,
                item_id: x.catalog.id,
                item_category: d.store.sector.name,
                quantity: x.quantity
              })
            })
          } else {
            this.eCommercePurchase.ecommerce.items.push({
              item_id: d.id,
              item_category: d.type,
              quantity: d.total
            })
          }
        });

        (<any>window).dataLayer.push(this.eCommercePurchase);
        console.log(this.eCommercePurchase);
    }

    calculateTotalAMount(transactionInfo) {
        let totalAmount = 0;
        transactionInfo.orders.forEach(d => {
          totalAmount += d.total
        })
        this.eCommercePurchase.ecommerce.value = totalAmount;
      }

    sendGoogleAnalyticsSelectedCountry(currentCountry) {
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        this.previewCountry = <previewcountry>{};
        this.previewCountry.event = 'previewcountry';
        this.previewCountry.previewCountryValue = currentCountry.name;
        (<any>window).dataLayer.push(this.previewCountry);
    }
    sendGoogleAnalyticsSelectedSector(currentSector) {
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        this.previewSector = <previewsector>{};
        this.previewSector.event = 'previewsector';
        this.previewSector.previewSectorValue = currentSector.name;
        (<any>window).dataLayer.push(this.previewSector);
    }
    sendGoogleAnalyticsSelectedStore(currentStore) {
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        this.previewStore = <previewstore>{};
        this.previewStore.event = 'previewstore';
        this.previewStore.previewStoreValue = currentStore.name;
        (<any>window).dataLayer.push(this.previewStore);
    }

    buildRegistrationAgentAnalyticsData() {
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        this.registration = <registrationPartner>{};
        this.registration.event = 'registrationPartner';
        (<any>window).dataLayer.push(this.registration);
    }

    buildContactAnalyticsData() {
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        this.contact = <contactus>{};
        this.contact.event = 'contactus';
        (<any>window).dataLayer.push(this.contact);
    }

    sendGoogleAnalyticsLanguage(language) {
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        this.userLanguage = <userlanguage>{};
        this.userLanguage.event = 'userlanguage';
        this.userLanguage.userLangValue = language;
        (<any>window).dataLayer.push(this.userLanguage);
    }
}