import { Injectable } from "@angular/core";
import { PaymentService } from "../../core/payment/payment.service";
import { CyberpackPaymentInfo } from "../_objects/cyberpackPaymentInfo";
import { PaypalPaymentInfo } from "../_objects/payPalPaymentInfo";
import { SofortPaymentInfo } from "../_objects/sofortPaymentInfo";
import { CYBERPACK_PAYMENT_ID, PAYPAL_PAYMENT_ID, SOFORT_PAYMENT_ID, currentBuyer, BIZUM_PAYMENT_ID } from "../../globals";
import { ServerErrorHelper } from "./servererrors.helper";
import { MatDialog } from "@angular/material";
import { ErrorPopUpComponent } from "../../pages/errorpopup/errorpopup.component";
import { PaymentHelperService } from "../_services/paymenthelper.service";
import { PinPopUpComponent } from "../../pages/pinpopup/pinpopup.component";
import { Buyer } from "../_objects/buyer";

@Injectable()
export class PaymentHelper {
    cyberPackId: number = CYBERPACK_PAYMENT_ID;
    paypalId: number = PAYPAL_PAYMENT_ID;
    sofortPaymentId: number = SOFORT_PAYMENT_ID;
    bizumId: number = BIZUM_PAYMENT_ID;
    paymentId: number;
    paymentInfoCyberpack: CyberpackPaymentInfo;
    paymentInfoPayPal: PaypalPaymentInfo;
    paymentInfoSofort: SofortPaymentInfo;
    paymentInfoBizum: any;
    allowPaymentMethod: boolean;
    allowPaymentMethodPaypal: boolean;
    allowPaymentMethodSofort: boolean;
    allowPaymentMethodBizum: boolean;
    validationCode: boolean;
    correctAmount: boolean = true;
    errorMesage: string;
    buyerCardAmount: number;
    newPin: boolean;
    pinValid: boolean;
    buyerInfo: Buyer;
    sisalWallet: boolean = false;

    constructor(private paymentService: PaymentService, 
        public serverErrorHelper: ServerErrorHelper,
        public _paymentHelperService: PaymentHelperService,
        public dialog: MatDialog) { }

    getPaymentInfoCyberpack(cardAmount?: number) {
        this.sisalWallet = false;
        this.paymentInfoCyberpack = new CyberpackPaymentInfo;
        this.paymentService.getPrePaymentInfoCyberpackV1(this.cyberPackId, cardAmount).subscribe(
            p => {
                this.paymentInfoCyberpack = p;
                this.allowPaymentMethod = true;
                this.validationCode = true;
                this._paymentHelperService.updateCall(true);
            }, error => {
                console.log(error);
                let errorCode = error;
                errorCode = errorCode.code;

                if (errorCode === 203) {
                    this.sendValidationCode(cardAmount);
                    this._paymentHelperService.updateCall(false);
                    this.validationCode = false;
                } else if(errorCode === 104){
                    this.allowPaymentMethod = false;
                    this.validationCode = true;
                    this.sisalWallet = true;

                } else{
                    this.allowPaymentMethod = false;
                    this.getError(error);
                }
            }
        );
    }

    getPaymentInfoBizum(cardAmount?: number) {
        this.sisalWallet = false;
        this.paymentService.getPrePaymentInfoBizumV1(this.bizumId, cardAmount).subscribe(
            p => {
                console.log(p);
                this.paymentInfoBizum = p;
                this.allowPaymentMethodBizum = true;
                this.validationCode = true;
                this._paymentHelperService.updateCall(true);
            }, error => {
                console.log(error);
                let errorCode = error;
                errorCode = errorCode.code;
                if (errorCode === 104) {
                    this.allowPaymentMethodBizum = false;
                    // this.getError(error);
                }
            }
        );
    }

    getPaymentInfoPayPal(cardAmount?: number) {
        this.paymentInfoPayPal = new PaypalPaymentInfo;
        this.paymentService.getPrePaymentInfoPayPalV1(this.paypalId, cardAmount).subscribe(
            p => {
                this.paymentInfoPayPal = p;
                this.allowPaymentMethodPaypal = true;
                console.log(p);
            }, error => {
                console.log(error.code);
                let errorCode = error;
                errorCode = errorCode.code;
                if (errorCode === 104) this.allowPaymentMethodPaypal = false;

            }
        );
    }

    getPaymentInfoSofort(cardAmount?: number) {
        this.paymentInfoSofort = new SofortPaymentInfo;
        this.paymentService.getPrePaymentInfoSofortV1(this.sofortPaymentId, cardAmount).subscribe(
            p => {
                this.paymentInfoSofort = p;
                this.allowPaymentMethodSofort = true;
                console.log(p);
            }, error => {
                console.log(error.code);
                let errorCode = error;
                errorCode = errorCode.code;
                if (errorCode === 104) this.allowPaymentMethodSofort = false;
            }
        );
    }

    getError(error) {
        this.correctAmount = false;
        let errorcode = error;
        console.log(errorcode.message);
        this.errorMesage = this.serverErrorHelper.getErrorMsg(errorcode.code, errorcode.message);
        console.log(this.errorMesage);
        const dialogErrorRef = this.dialog.open(ErrorPopUpComponent, {
            panelClass: 'loginLayoutResets',
            data: { error: this.errorMesage }
        });
        dialogErrorRef.afterClosed().subscribe(() => { });
    }

    sendValidationCode(buyerCardAmount: number) {
        this.buyerInfo = JSON.parse(localStorage.getItem(currentBuyer));
        this.paymentService.getRegisterTokenCard(this.buyerInfo.card.id).subscribe(res => {
          console.log(res);
          const dialogRefPin = this.dialog.open(PinPopUpComponent, {
            panelClass: 'loginLayoutResets'
          })
          const sub = dialogRefPin.componentInstance.onValidationPass.subscribe(() => {
            this.validationCode = true;
            this.getPaymentInfoCyberpack(buyerCardAmount);
            this.getPaymentInfoPayPal(buyerCardAmount);
            this.getPaymentInfoSofort(buyerCardAmount);
            this.getPaymentInfoBizum(buyerCardAmount);
            this.dialog.closeAll();
          });
          dialogRefPin.afterClosed().subscribe(() => {
            if (dialogRefPin.componentInstance.isValidated) {
                this._paymentHelperService.updateCall(true);
              this.newPin = false;
            } else {
              this.newPin = true;
              this._paymentHelperService.updateCall(false);
            }
            dialogRefPin.componentInstance.onValidationPass.unsubscribe();
            console.log('UNSUBSCRIBED');
          })
        }, error => {
          console.log(error);
        })
      }
}