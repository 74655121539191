import { Component } from "@angular/core";
import * as $ from 'jquery';

@Component({
    selector: 'app-card-terms',
    templateUrl: 'appcardtermsandconditions.component.html',
    styleUrls: ['../legalconditions/legalconditions.component.scss','appcardtermsandconditions.component.scss']
})

export class AppCardTermsAndConditionsComponent{
    constructor(){}

    ngOnInit(){
        $('.footer').hide();
    }
}