import { Component, EventEmitter, Input, Output } from "@angular/core";
import { HomeUtilsHelper } from "../../../shared/_helpers/_homeUtils.helper";
import { StoreOrderLine } from "../../../shared/_objects/order";
import { ServerErrorHelper } from "../../../shared/_helpers/servererrors.helper";
import { Order, Transaction } from "../../../shared/_objects";

@Component({
    selector: 'app-cl-store-component',
    templateUrl: './cl-store.component.html',
    styleUrls: ['../cartlanding.component.scss','../../../../assets/css/bootstrap-grid.min.css']
})
export class CLStoreComponent {
    cartClass = 'purchaseOrder';
    submodelTotal: number;
    
    @Input() order:Order;
    @Input() transaction:Transaction;
    @Input() transactionHistory: Transaction;
    @Input() promotionV1: any;
    @Input() isValidAmount: boolean;
    @Output() removeOrderEmit: EventEmitter<any> = new EventEmitter<any>();
    @Output() updateTransactionEmit: EventEmitter<any> = new EventEmitter<any>();

    constructor(public serverErrorHelper: ServerErrorHelper,
        public _homeUtilsHelper: HomeUtilsHelper){
    }

    ngOnInit(){
    }

    addInputValueProduct(storeOrderLine: StoreOrderLine, order: any) {
        if (storeOrderLine.quantity <= 0) {
          this.removeOrderLine(storeOrderLine, order);
        } else {
          this.updateTransaction();
        }
      }
    
      addProduct(storeOrderLine: StoreOrderLine, order: any) {
        storeOrderLine.quantity++;
        this.updateTransaction();
      }
    
      substractProduct(storeOrderLine: StoreOrderLine, order: Order) {
        storeOrderLine.quantity--;
        if (storeOrderLine.quantity == 0) {
          this.removeOrderLine(storeOrderLine, order);
        } else {
          this.updateTransaction();
        }
      }
    
      removeOrderLine(storeOrderLine: StoreOrderLine, order: Order) {
        if (order.lines.length > 1) {
          storeOrderLine.quantity = 0;
          this.updateTransaction();
        } else {
          this.removeOrder();
        }
      }

    removeOrder(){
        this.removeOrderEmit.emit(this.order);
    }

    updateTransaction(){
        this.updateTransactionEmit.emit(this.order)
    }
}