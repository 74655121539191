import { Component, Optional, Inject } from "@angular/core";
import { CyberpackPaymentInfo } from "../../shared/_objects/cyberpackPaymentInfo";
import { SofortPaymentInfo } from "../../shared/_objects/sofortPaymentInfo";
import { PaymentService } from "../../core/payment/payment.service";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import * as $ from 'jquery';

@Component({
    selector: 'app_payment-popup',
    templateUrl: './paymentpopup.component.html',
    styleUrls: ['./paymentpopup.component.scss','../agentregistrationweb/agentregistrationweb.component.scss']
})

export class PaymentPopUpComponent {
    paymentInfoCyberpack: CyberpackPaymentInfo;
    paymentInfoSofort: SofortPaymentInfo;
    cyberPackId: number = 1;
    sofortPaymentId: number = 2;
    allowPaymentMethod: boolean = true;
    allowPaymentMethodSofort: boolean = true;
    paymentId: number = 1;
    dataPayment: any;
    registrationAmount: number = 100;
    step: number = 1;
    constructor(private _paymentService: PaymentService,
        private dialogue: MatDialog,
        private route: ActivatedRoute,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    getPaymentInfoCyberpack() {
        this.paymentInfoCyberpack = <CyberpackPaymentInfo>{};
        this._paymentService.getPrePaymentInfoCyberpackV2(this.data.agent.mobile,this.cyberPackId, this.registrationAmount).subscribe(d => {
            console.log(d);
            this.paymentInfoCyberpack = d;
            this.allowPaymentMethod = true;
        });
    }

    getPaymentInfoSofort() {
        this.paymentInfoSofort = <SofortPaymentInfo>{};
        this._paymentService.getPrePaymentInfoSofortV2(this.data.agent.mobile, this.sofortPaymentId, this.registrationAmount).subscribe(p => {
            this.paymentInfoSofort = p;
            this.allowPaymentMethodSofort = true;
            console.log(p);
        },error => {
            console.log(error)
            if (error.status == 406) {
                this.allowPaymentMethodSofort = false;
            }
        });
    }

    checkSelectedPayment(payid) {
        console.log(payid);
        $('*[class*="payment-selected-"]').removeClass('selected');
        $('.payment-selected-' + payid).addClass('selected');
    }

    updateAmount(ev) {
        this.registrationAmount = ev.target.value;
    }

    changeStep() {
        this.step = 2;
        this.getPaymentInfoCyberpack();
        this.getPaymentInfoSofort();
    }

    close(){
        this.dialogue.closeAll();
    }
}