import { Injectable } from "@angular/core";
import * as $ from 'jquery';

@Injectable()
export class ScrollerTargetHelper {
  constructor() { }

  scrollToTarget(target: string, speed?: number) {
    if (!speed) speed = 800;
    let ele: number = $('.header').height();
    setTimeout(() => {
      $('html, body').animate({
        scrollTop: $('#' + target).offset().top - ele,
        duration: speed
      })
    }, 200)
  }
}