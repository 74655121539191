import { Product } from './product';
import { Store } from './store';
export class Catalog {
  id: number;
  product: Product;
  price: number;
  active: boolean;
  store: Store;
}

export class CatalogUniq {
  id: number;
  price: number;
}
