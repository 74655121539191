import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Transaction } from "../_objects/transaction";
import { currentTransaction } from '../../globals';
import { ContextBase } from "./_contextBase.helper";

@Injectable()
export class UtmCampaignHelper {
    utm_campaign: string;
    utm_medium: string;
    utm_source: string;
    utm_content: string;
    utm_term: string;
    currentTransaction: Transaction;
    constructor(private route: ActivatedRoute, public contexto: ContextBase) { }

    checkCampaign() {
        this.currentTransaction = JSON.parse(localStorage.getItem(currentTransaction));
        this.route.queryParams.subscribe(params => {
            this.utm_source = params['utm_source'];
            this.utm_medium = params['utm_medium'];
            this.utm_campaign = params['utm_campaign'];
            this.utm_term = params['utm_term'];
            this.utm_content = params['utm_content'];

            if(Object.keys(params).length != 0){
                this.contexto.transMessage = '';
                 if(this.utm_source != undefined) this.contexto.transMessage += this.utm_source+' ';
                 if(this.utm_medium != undefined) this.contexto.transMessage += this.utm_medium+' ';
                 if(this.utm_campaign != undefined) this.contexto.transMessage += this.utm_campaign+' ';
                 if(this.utm_term != undefined) this.contexto.transMessage += this.utm_term+' ';
                 if(this.utm_content != undefined) this.contexto.transMessage += this.utm_content+' ';

                 if(this.currentTransaction != null){
                    this.currentTransaction.message = this.contexto.transMessage; 
                    localStorage.setItem(currentTransaction, JSON.stringify(this.currentTransaction));
                }
                
            }
        });
    }
}