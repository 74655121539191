import { Component } from "@angular/core";
import { AgentRegistration, AgentRegistrationWeb, AgentRegistrationWebAgent } from "../../shared/_objects/agentregistration";
import { LoginService } from "../../core/login-service/login.service";
import { ContextBase } from "../../shared/_helpers/_contextBase.helper";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { LoginmodalComponent } from "../loginmodal/loginmodal.component";
import * as $ from 'jquery';
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { CountryService } from "../../shared/_services/country.services";
import { SuccesspopupComponent } from "../successpopup/successpopup.component";
import { ErrorPopUpComponent } from "../errorpopup/errorpopup.component";
import { TranslateService } from "@ngx-translate/core";
import { ServerErrorHelper } from "../../shared/_helpers/servererrors.helper";
import { registrationPartner } from '../../shared/_objects/registrationPartner';
import { PaymentPopUpComponent } from "../paymentpopup/paymentpopup.component";

const GOOGLE_API_KEY = 'AIzaSyCBIGRluGDwjlQ4ZLfDybAMZ8tokW5kL3c';

@Component({
    selector: 'agent-registration',
    templateUrl: 'agentregistrationweb.component.html',
    styleUrls: ['./agentregistrationweb.component.scss']
})

export class AgentRegistrationWebComponent {

    agentRegistration = new AgentRegistrationWebAgent;
    agentRegistrationForm: FormGroup;
    countryArray: any[] = [];
    countryPrefix: any;
    data: any;
    landingSourceCountry: string;
    errorMesage: string;
    isSubmitted: boolean = false;
    registration: registrationPartner;
    storeTypes: Array<any>;
    currencies: Array<string>;
    

    constructor(private loginService: LoginService,
        private contexto: ContextBase,
        private fb: FormBuilder,
        private dialog: MatDialog,
        private translate: TranslateService,
        public serverErrorHelper: ServerErrorHelper,
        private countryService: CountryService,
        public dialogRef: MatDialogRef<LoginmodalComponent>) {

        this.landingSourceCountry = JSON.parse(localStorage.getItem('landingSourceCountry'))
        this.countryService.getBuyingCountries().subscribe(
            c => {
                this.countryArray = c;
                this.data.countryPrefix = this.countryArray.filter(country => country.id === this.landingSourceCountry)[0];
            });

        this.agentRegistrationForm = this.fb.group({
            'name': ['', Validators.compose([Validators.required])],
            'bussinessname': ['', Validators.compose([Validators.required])],
            'nationality': ['', Validators.compose([Validators.required])],
            'leadPrefix': [''],
            'phone': [''],
            'mobile': ['', Validators.compose([Validators.required])],
            'contactperson': ['', Validators.compose([Validators.required])],
            'email': ['', Validators.compose([Validators.required, Validators.pattern('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,6}')])],
            'cif': ['', Validators.compose([Validators.required])],
            'province': ['', Validators.compose([Validators.required])],
            'town': ['', Validators.compose([Validators.required])],
            'address': ['', Validators.compose([Validators.required])],
            'postalcode': ['', Validators.compose([Validators.required])],
            'country': ['', Validators.compose([Validators.required])],
            'storetype': ['', Validators.compose([Validators.required])]
        });

        this.data = <AgentRegistrationWeb>{};
        this.storeTypes = [
            { name: this.translate.instant('Baluwo.partners.autoregister.type.agent'), id: 1 },
            { name: this.translate.instant('Baluwo.partners.autoregister.type.food'), id: 2 },
            { name: this.translate.instant('Baluwo.partners.autoregister.type.phone'), id: 3 },
            { name: this.translate.instant('Baluwo.partners.autoregister.type.others'), id: 5 }
        ]
        this.currencies = ['EUR', 'US$', 'GBP', 'SK'];
        this.data.currency = this.currencies[0];

    }

    validateControl(control_name: string, validator_name: string) {
        return this.agentRegistrationForm.controls[control_name].hasError(validator_name) && (!this.agentRegistrationForm.controls[control_name].pristine || this.agentRegistrationForm.controls[control_name].touched);
    }

    buildAnalyticsData() {
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        this.registration = <registrationPartner>{};
        this.registration.event = 'registrationPartner';
        (<any>window).dataLayer.push(this.registration);
    }

    onFileChanged(event) {
        const file = event.target.files[0];
    }

    getAgentTypeServer(type) {
        switch (type) {
            case 1:
                return 'CALLSHOP'
            case 2:
                return 'FOOD'
            case 3:
                return 'PHONESHOP'
            case 4:
                return 'VIRTUAL'
            case 5:
                return 'OTHER'
        }
    }

    onAgentSubmit(data) {
        this.isSubmitted = true;
        this.agentRegistration.name = data.name;
        if (data.phone !== undefined) {
            this.agentRegistration.phone = data.countryPrefix.mobilePrefix + data.phone;
        }
        this.agentRegistration.mobile = data.countryPrefix.mobilePrefix + data.mobile;
        this.agentRegistration.email = data.email;
        this.agentRegistration.cif = data.cif;
        this.agentRegistration.province = data.province;
        this.agentRegistration.town = data.town;
        this.agentRegistration.address = data.address;
        this.agentRegistration.agentName = data.name;
        this.agentRegistration.name = data.bussinessname;
        this.agentRegistration.contact = data.contactperson;
        this.agentRegistration.nationality = this.data.nationality;
        this.agentRegistration.postalCode = this.data.postalcode;
        this.agentRegistration.contactMobile = '+'+this.data.mobile;

        this.agentRegistration.agentType = this.getAgentTypeServer(this.data.storetype);

        if (this.agentRegistrationForm.valid) {
            console.log(this.agentRegistration)
            this.loginService.sendAgentRegistrationWeb(this.agentRegistration).subscribe(() => {
                this.dialogRef.close('emailsent');
                this.buildAnalyticsData();
                this.dialogRef.afterClosed().subscribe(() => {
                    this.isSubmitted = false;
                    this.agentRegistrationForm.reset();
                    const dialogRefSuccess = this.dialog.open(PaymentPopUpComponent, {
                        panelClass: 'loginLayoutResets',
                        data: { agent: this.agentRegistration }
                    });
                })
            }, error => {
                console.log(error)
                this.errorMesage = this.serverErrorHelper.getErrorMsg(error.code)
                console.log('ERROR: ', error.message);
                const dialogErrorRef = this.dialog.open(ErrorPopUpComponent, {
                    panelClass: 'loginLayoutResets',
                    data: { error: this.errorMesage }
                });
                dialogErrorRef.afterClosed().subscribe(() => {
                });
            });
        }
    }

    close() {
        this.dialogRef.close();
    }

    showCMForm() {
        $('.agentRegistrationSection_countryManag').toggleClass('active');
    }
}